import { Map } from 'immutable';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { tokens, Button, Icon } from '@unitoio/mosaic';

import { getIsSyncInProgress } from '~/reducers';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { Box } from '~/components/Box/Box';
import { Card } from '~/components/Card/Card';
import { Title } from '~/components/Title/Title';
import * as linkActions from '~/actions/links';

import ActivityStreamAutoSyncOff from './images/ActivityStreamAutoSyncOff.svg';

const AutoSyncWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: ${(props) => (props.$withinTwoStep ? '70%' : '60%')};
`;

const ImageWrapper = styled.img`
  height: ${tokens.spacing.s9};
`;

export const ACTION_NAMES = {
  CLICKED_SYNC_NOW: 'clicked on sync now',
  CLICKED_TURN_ON_AUTO_SYNC: 'clicked on turn auto-sync on',
  VIEWED_TROUBLESHOOT_MESSAGE: 'viewed troubleshoot messages',
};

export function ActivityStreamEmptyStateAutoSync({ link, withinTwoStep = false }) {
  const linkId = link.get('_id');

  const dispatch = useDispatch();
  const isSyncInProgress = useSelector((state) => getIsSyncInProgress(state, linkId));
  const trackEvent = useTrackEvent();

  function handleOnClickSyncNow() {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on sync now',
      action_taken_from: 'activity log troubleshooting messages',
    });
    dispatch(linkActions.syncLink(linkId));
  }

  function handleOnClickTurnOnAutoSync() {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on turn auto-sync on',
      action_taken_from: 'activity log troubleshooting messages',
    });
    dispatch(linkActions.setAutoSyncLink(linkId));
  }

  // trigger tracking only on 1st render
  useEffect(
    () => {
      trackEvent(trackingTypes.TROUBLESHOOT_MESSAGE_ACTION, {
        action_name: 'viewed troubleshoot messages',
        message: 'turn auto-sync on',
      });
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <AutoSyncWrapper $withinTwoStep={withinTwoStep}>
      <ImageWrapper src={ActivityStreamAutoSyncOff} alt="auto-sync" />
      <Title type="h3">Your flow is not syncing automatically</Title>
      <Card borderless color={tokens.colors.background.neutral.grey} $m={[0.5, 0]} $p={[1, 1.5, 1.5, 1.5]}>
        If you decide not to enable Auto-sync, you will need to manually click the Sync now button every time you want
        to update your flows.
        <Box $m={[1, 0, 0, 0]}>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <Button disabled={isSyncInProgress} onClick={handleOnClickSyncNow}>
            Sync now {isSyncInProgress && <Icon name="circle-notch" kind={Icon.KINDS.SOLID} title="updating" spin />}
          </Button>
        </Box>
      </Card>
      <b>Or</b>
      <div>
        Enabling auto-sync will allow Unito to sync changes based on your plan's update speed.
        <Box $m={[1, 0, 0, 0]}>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <Button variant="outlined" onClick={handleOnClickTurnOnAutoSync}>
            Turn on Auto-sync
          </Button>
        </Box>
      </div>
    </AutoSyncWrapper>
  );
}

ActivityStreamEmptyStateAutoSync.propTypes = {
  link: PropTypes.instanceOf(Map).isRequired,
  withinTwoStep: PropTypes.bool,
};

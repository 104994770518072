import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Icon } from '@unitoio/mosaic';

import * as workflowActions from '~/actions/workflows';
import * as formUtils from '~/utils/forms';
import { getWorkflowById, isLoadingWorkflow } from '~/reducers';
import { Box } from '~/components/Box/Box';

const EditWorkflowName = styled.div`
  align-items: baseline;
  display: flex;
`;

export function WorkflowNameById({ workflowId }) {
  const workflow = useSelector((state) => getWorkflowById(state, workflowId));
  const isLoading = useSelector((state) => isLoadingWorkflow(state, workflowId));
  const [isEditing, setIsEditing] = useState(false);
  const fullWorkflowName = workflow.get('name', '');
  const workflowName = isEditing ? fullWorkflowName : formUtils.shortenString(fullWorkflowName, 50);
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      workflowName,
    },
  });
  const dispatch = useDispatch();
  // Important: defaultValues is cached at the first render within the custom hook, if you want to reset defaultValues please use reset api.
  // https://react-hook-form.com/api#register
  useEffect(() => reset({ workflowName }), [reset, workflowName]);

  if (isLoading) {
    return null;
  }

  function onSubmit({ workflowName: newName }) {
    if (newName.trim() === '') {
      return null;
    }

    return dispatch(workflowActions.saveWorkflow(workflowId, { name: newName }));
  }

  function handleOnKeyDown(event) {
    // 13: Enter key
    // 27: ESC key
    if (event.keyCode === 13) {
      setIsEditing(false);
      onSubmit({ workflowName: event.currentTarget.value });
    } else if (event.keyCode === 27) {
      setIsEditing(false);
    }
  }

  function handleOnBlur(event) {
    setIsEditing(false);
    onSubmit({ workflowName: event.currentTarget.value });
  }

  return (
    <>
      {
        /* eslint-disable jsx-a11y/no-autofocus */
        isEditing ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              autoFocus
              className="form-control"
              maxLength="80"
              name="workflowName"
              onKeyDown={handleOnKeyDown}
              onBlur={handleOnBlur}
              size="80"
              type="text"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register('workflowName')}
            />
          </form>
        ) : (
          <EditWorkflowName onClick={() => setIsEditing(true)} onKeyPress={() => setIsEditing(true)} role="button">
            <Box $m={[0, 1, 0, 0]}>{workflowName}</Box>
            <Icon name="pencil" kind={Icon.KINDS.SOLID} title="edit" />
          </EditWorkflowName>
        )
        /* eslint-disable jsx-a11y/no-autofocus */
      }
    </>
  );
}

WorkflowNameById.propTypes = {
  workflowId: PropTypes.string.isRequired,
};

import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Flex, Icon, tokens, NewTypography } from '@unitoio/mosaic';

import { useGetContainerErrors } from '~/containers/FlowBuilder/hooks/useGetContainerErrors';
import * as trackingTypes from '~/consts/tracking';
import { useSelector } from 'react-redux';
import { isLoadingContainer } from '~/reducers';
import { linkActions } from '~/actions';
import { useInterval } from '~/hooks';

export const FlowHealth = () => {
  const { clearErrors } = useFormContext();
  const { containerAError, containerBError } = useGetContainerErrors();
  const [containerIdA, containerIdB] = useWatch({ name: ['A.containerId', 'B.containerId'] });
  const isContainerALoading = useSelector((state) => isLoadingContainer(state, containerIdA));
  const isContainerBLoading = useSelector((state) => isLoadingContainer(state, containerIdB));
  const { linkId } = useParams();
  const dispatch = useDispatch();

  const fetchLinkCallback = useCallback(async () => {
    try {
      const response = await dispatch(linkActions.getLink(linkId, {}, { displayError: false }));
      const { link } = response;
      // clearErrors current container related form errors if the new health status is positive
      if (link?.A?.container?.health?.lastStatusCode === 200) {
        clearErrors([
          `${trackingTypes.MODULE.TOOL_SELECTION}.A.providerIdentityId`,
          `${trackingTypes.MODULE.TOOL_SELECTION}.A.containerId`,
        ]);
      }
      if (link?.B?.container?.health?.lastStatusCode === 200) {
        clearErrors([
          `${trackingTypes.MODULE.TOOL_SELECTION}.B.providerIdentityId`,
          `${trackingTypes.MODULE.TOOL_SELECTION}.B.containerId`,
        ]);
      }
    } catch {}
  }, [linkId, clearErrors]);

  // trigger a getLink every 10 seconds to keep the health status up to date
  useInterval(fetchLinkCallback, 10000);

  const { errors } = useFormState();
  // also check for providerIdentity errors since we sometimes convert container errors to identity errors
  // if the root cause is authentication or authorization
  const providerIdentityAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.providerIdentityId?.error;
  const providerIdentityBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.providerIdentityId?.error;

  if (isContainerALoading || isContainerBLoading) {
    return (
      <Flex
        vertical
        align="start"
        style={{
          backgroundColor: tokens.colors.background.message.info,
          borderRadius: tokens.spacing.s4,
          marginTop: tokens.spacing.s6,
          padding: tokens.spacing.s5,
        }}
        gap="middle"
      >
        <NewTypography.Title level={4} style={{ margin: 0 }}>
          <Flex align="center" justify="center" gap="small">
            <Icon name="spinner-third" color={tokens.colors.content.secondary.default} kind="solid" spin />
            Loading
          </Flex>
        </NewTypography.Title>
        <Flex>
          <NewTypography.Text data-testid="loadingMessage">
            Just a moment while we load the status of this flow.
          </NewTypography.Text>
        </Flex>
      </Flex>
    );
  }

  if (containerAError || containerBError || providerIdentityAError || providerIdentityBError) {
    return (
      <Flex
        vertical
        align="start"
        style={{
          backgroundColor: tokens.colors.background.message.destructive,
          borderRadius: tokens.spacing.s4,
          marginTop: tokens.spacing.s6,
          padding: tokens.spacing.s5,
        }}
        gap="middle"
      >
        <NewTypography.Title level={4} style={{ margin: 0 }}>
          <Flex align="center" justify="center" gap="small">
            <Icon name="times-circle" color={tokens.colors.content.destructive.default} kind="solid" />
            Unable to sync
          </Flex>
        </NewTypography.Title>
        <Flex>
          <NewTypography.Text data-testid="unhealthyMessage">
            There are issues to investigate preventing your flow’s ability to sync.
          </NewTypography.Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex
      vertical
      align="start"
      style={{
        backgroundColor: tokens.colors.background.message.positive,
        borderRadius: tokens.spacing.s4,
        marginTop: tokens.spacing.s6,
        padding: tokens.spacing.s5,
      }}
      gap="middle"
    >
      <NewTypography.Title level={4} style={{ margin: 0 }}>
        <Flex align="center" justify="center" gap="small">
          <Icon name="check-circle" color={tokens.colors.content.positive.default} kind="solid" />
          Healthy
        </Flex>
      </NewTypography.Title>
      <Flex>
        <NewTypography.Text data-testid="healthyMessage">
          Your flow is healthy and information is syncing.
        </NewTypography.Text>
      </Flex>
    </Flex>
  );
};

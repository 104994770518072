import * as routes from '~/consts/routes';
import * as notificationTypes from '~/consts/notifications';

export const getSubscription = (linkId) => ({
  types: [
    notificationTypes.GET_SUBSCRIPTION_REQUEST,
    notificationTypes.GET_SUBSCRIPTION_SUCCESS,
    notificationTypes.GET_SUBSCRIPTION_FAILURE,
  ],
  url: routes.API_PATHS.NOTIFICATIONS('flows', linkId),
  method: routes.METHODS.GET,
});

export const subscribe = (linkId) => ({
  types: [
    notificationTypes.SUBSCRIBE_REQUEST,
    notificationTypes.SUBSCRIBE_SUCCESS,
    notificationTypes.SUBSCRIBE_FAILURE,
  ],
  url: routes.API_PATHS.NOTIFICATIONS('flows', linkId),
  method: routes.METHODS.POST,
  displayError: false,
});

export const unsubscribe = (linkId) => ({
  types: [
    notificationTypes.UNSUBSCRIBE_REQUEST,
    notificationTypes.UNSUBSCRIBE_SUCCESS,
    notificationTypes.UNSUBSCRIBE_FAILURE,
  ],
  url: routes.API_PATHS.NOTIFICATIONS('flows', linkId),
  method: routes.METHODS.DELETE,
  displayError: false,
});

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import {
  Icon,
  tokens,
  Modal,
  NewAvatar,
  Typography,
  Alert,
  AlertSizes,
  AlertLevels,
  TypographyVariants,
} from '@unitoio/mosaic';

import * as organizationActions from '~/actions/organizations';
import * as workflowActions from '~/actions/workflows';
import * as websocketActions from '~/actions/websocket';
import * as routes from '~/consts/routes';
import * as websocketTypes from '~/consts/websocket';
import { getProviderIdsFromWorkflow, getLinksByLinkIds, getUserId } from '~/reducers';
import { borderRadius, color, fontSize, fontWeight } from 'theme';
import * as formUtils from '~/utils/forms';
import { Dropdown, DropdownEllipsisButton, DropdownItem } from '~/components/Dropdown/Dropdown';
import { LinkItem } from '~/components/LinkItem/LinkItem';
import { ProviderIconById } from '~/components/ProviderIcon/ProviderIconById';
import { Href } from '~/components/Href/Href';

const MAX_PROVIDER_TO_DISPLAY = 5;

const LinkWrapper = styled.div`
  a {
    font-weight: ${fontWeight.regular};
    color: ${color.dark.primary};

    &:hover {
      color: ${color.brand.secondary};
    }
  }
`;

const BlockCount = styled.span`
  color: ${color.dark.hint};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.normal};
  margin: 0;
`;

const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 8px 0;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => props.$isDisabled && 0.65};
  border: 1px solid ${color.brand.containerGray};
  border-radius: ${borderRadius.double};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const TableCell = styled.div`
  text-align: ${(props) => props.$align};
  padding: 0.5rem 1rem;
  flex: 1 0 ${(props) => (props.$cellSize ? props.$cellSize : 'auto')};
`;

const SubItemContainer = styled.div`
  animation: slide-down 0.3s ease-out;
  width: 100%;
  margin-top: 1rem;
`;

const getBlockCountText = (blockCount) => {
  if (blockCount) {
    return `${blockCount} block${blockCount > 1 ? 's' : ''}`;
  }

  return 'Empty workflow';
};

const useWorkflowInformation = (workflow) => {
  const blocks = workflow.get('blocks');
  const providerIds = useSelector((state) => getProviderIdsFromWorkflow(state, workflow.get('id')));
  const groupedByProvider = providerIds.groupBy((providerId) => providerId);
  const sortedBySize = groupedByProvider.toOrderedMap().sort((k, v) => v.size);
  const workflowName = formUtils.shortenString(workflow.get('name'), 45);
  const blockCount = blocks.size;

  return {
    blockCount,
    providersToDisplay: sortedBySize.keySeq(),
    workflowName,
  };
};

function useFetchLinksByWorkflowId(showSubItems, workflowId, organizationId, dispatch) {
  const [isLoading, setIsLoading] = useState(false);
  const userId = useSelector((state) => getUserId(state));

  useEffect(() => {
    async function fetchLinks() {
      if (showSubItems) {
        setIsLoading(true);

        const { links } = await dispatch(workflowActions.getLinksByWorkflowId(workflowId));
        const allWorkflowLinkIds = links.map((link) => link._id);

        if (allWorkflowLinkIds.length) {
          await dispatch(
            websocketActions.subscribe({
              currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_LIST,
              organizationId,
              userId,
              linkIds: allWorkflowLinkIds,
            }),
          );
        }
      }
      setIsLoading(false);
    }
    fetchLinks();
  }, [showSubItems, dispatch, workflowId, organizationId, userId]);

  return isLoading;
}

export const WorkflowItem = ({ workflow, organizationId }) => {
  const dispatch = useDispatch();
  const hasLinks = workflow.get('linkIds').size;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [showSubItems, setShowSubItems] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const syncs = useSelector((state) => getLinksByLinkIds(state, workflow.get('links')));
  const isLoading = useFetchLinksByWorkflowId(showSubItems, workflow.get('_id'), organizationId, dispatch);

  const handleExpandSubItems = () => setShowSubItems(!showSubItems);

  async function openDeleteModal() {
    setIsModalLoading(true);
    setIsModalLoading(false);
    setIsModalOpen(true);
  }

  const { blockCount, providersToDisplay, workflowName } = useWorkflowInformation(workflow);
  const editUrl = `${routes.ABSOLUTE_PATHS.EDIT_WORKFLOW}/${workflow.get('_id')}`;
  const deleteWorkflowAndRefreshUsage = async () => {
    // we need the deleteWorkflow to be completed before we dispatch for usage.
    await dispatch(workflowActions.deleteWorkflow(workflow.get('id')));
    return dispatch(organizationActions.getUsage(organizationId));
  };

  return (
    <>
      <Modal
        confirmLabel="Delete this workflow"
        displayCloseButton
        isOpen={isModalOpen}
        onCancel={closeModal}
        onConfirm={deleteWorkflowAndRefreshUsage}
        onRequestClose={closeModal}
        size="lg"
        title="Delete this workflow?"
        isConfirmActionDestructive
      >
        <Typography variant={TypographyVariants.BODY1}>
          This will permanently delete <strong>{workflow.get('name')}</strong> as well as any flows that have been
          created uniquely for this workflow.
        </Typography>
        <br />
        <Alert level={AlertLevels.INFO} size={AlertSizes.SM}>
          <Typography variant={TypographyVariants.BODY2}>
            If a flow exists outside of this workflow, it will not be deleted. If you want to delete a flow not affected
            by this action, you can do so from the <Href to={routes.ABSOLUTE_PATHS.SYNCS}>flow list</Href>.
          </Typography>
        </Alert>
      </Modal>

      <TableRow>
        <TableCell $cellSize="10%" $align="center">
          {showSubItems && isLoading ? (
            <Icon data-testid="loadingSpinner" name="spinner" kind={Icon.KINDS.SOLID} pulse />
          ) : (
            <Icon
              data-testid="expand-collapse-icon"
              cursor={hasLinks ? 'pointer' : 'cursor'}
              color={!hasLinks ? tokens.colors.content.secondary.disabled : undefined}
              name={showSubItems ? 'chevron-up' : 'chevron-down'}
              onClick={hasLinks ? handleExpandSubItems : () => {}}
              kind={Icon.KINDS.SOLID}
              title={showSubItems ? 'close' : 'open'}
            />
          )}
        </TableCell>
        <TableCell $cellSize="35%">
          <LinkWrapper>
            <Link to={editUrl} title={`Edit this workflow: ${workflowName}`}>
              {workflowName}
            </Link>
          </LinkWrapper>
          <BlockCount>{getBlockCountText(blockCount)}</BlockCount>
        </TableCell>
        <TableCell $cellSize="45%">
          <NewAvatar.Group maxCount={MAX_PROVIDER_TO_DISPLAY} size="default">
            {providersToDisplay.map((providerId) => (
              <ProviderIconById
                providerId={providerId}
                size="default"
                key={`providerIcon ${providerId}`}
                shape="square"
              />
            ))}
          </NewAvatar.Group>
        </TableCell>
        <TableCell>
          {isModalLoading ? (
            <Icon name="spinner" kind={Icon.KINDS.SOLID} title="loading" pulse />
          ) : (
            <Dropdown alignRight btnContent={DropdownEllipsisButton} className="dropdown">
              <DropdownItem to={editUrl} title="Edit this workflow">
                <Icon name="pencil" fixedWidth />
                Edit
              </DropdownItem>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <DropdownItem onClick={openDeleteModal}>
                <Icon name="trash" kind={Icon.KINDS.SOLID} fixedWidth />
                Delete
              </DropdownItem>
            </Dropdown>
          )}
        </TableCell>
        {showSubItems && !isLoading && (
          <Transition in timeout={500} appear>
            <SubItemContainer>
              {syncs
                .valueSeq()
                .map((sync) => (
                  <LinkItem
                    isWorkflow
                    editSyncUrl={`${routes.ABSOLUTE_PATHS.EDIT_WORKFLOW}/${workflow.get('_id')}/links/${sync.get(
                      '_id',
                    )}`}
                    key={sync.get('_id')}
                    sync={sync}
                    providerNameA={sync.getIn(['A', 'providerName'])}
                    providerNameB={sync.getIn(['B', 'providerName'])}
                  />
                ))
                .toArray()}
            </SubItemContainer>
          </Transition>
        )}
      </TableRow>
    </>
  );
};

WorkflowItem.propTypes = {
  workflow: PropTypes.instanceOf(Map).isRequired,
  organizationId: PropTypes.string.isRequired,
};

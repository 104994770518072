import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { Box, Icon, NewTag, NewTypography, Tooltip, tokens, Space } from '@unitoio/mosaic';

import { getLinkById } from '~/reducers';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { TurnOffTestModeModal } from '~/components/TurnOffTestModeModal/TurnOffTestModeModal';
import * as linkActions from '~/actions/links';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';
import { useGetTestModeTerm } from '../hooks/useGetTestModeTerm';
import { useHasTestModeEnabled } from '~/containers/FlowBuilder/hooks/useHasTestModeEnabled';
import { useGetProviderNames } from '../hooks/useGetProviderNames';

const Container = styled(Space)`
  flex: 1;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: ${tokens.spacing.s4};
  padding: ${tokens.spacing.s4};
  width: 100%;
`;

const StyledTrashIcon = styled(Icon)`
  cursor: pointer;
`;

export const TurnOffTestModeBox = () => {
  const { linkId } = useParams();
  const link = useSelector((state) => getLinkById(state, linkId));
  const [containerA, containerB] = useGetContainers();
  const containerIdA = containerA.get('id');
  const containerIdB = containerB.get('id');

  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const testModeTerm = useGetTestModeTerm(linkId);
  const showTurnOffTestModeBox = useHasTestModeEnabled();
  const earliestCreatedAt = link.getIn(['syncSettings', 'earliestCreatedAt']);
  const formattedLinkEarliestCreatedAt = moment(earliestCreatedAt).format('MMMM D, YYYY');

  const [isTurnOffTestModeModalOpen, setIsTurnOffTestModeModalOpen] = useState(false);

  const dispatch = useDispatch();

  const handleClickTrashIcon = useCallback(() => {
    setIsTurnOffTestModeModalOpen(true);
  }, [setIsTurnOffTestModeModalOpen]);

  const handleOnCloseModal = useCallback(() => {
    setIsTurnOffTestModeModalOpen(false);
  }, [setIsTurnOffTestModeModalOpen]);

  const handleOnTurnOffTestMode = useCallback(async () => {
    await dispatch(linkActions.turnOffTestMode(linkId));
    handleOnCloseModal();
  }, [dispatch, handleOnCloseModal, linkId]);

  const providerTerms = useMemo(
    () => (
      <ProviderTermsByName
        providerNameA={providerNameA}
        providerNameB={providerNameB}
        itemTypeA={itemTypeA}
        itemTypeB={itemTypeB}
        containerIdA={containerIdA}
        containerIdB={containerIdB}
        pcdv3={!!itemTypeA && !!itemTypeB}
        plurality="plural"
        termKey="task"
        fallbackTerm="items"
      />
    ),
    [itemTypeA, itemTypeB, providerNameA, providerNameB, containerIdA, containerIdB],
  );

  return (
    showTurnOffTestModeBox && (
      <Container
        flexDirection={Box.flexDirection.ROW}
        justifyContent={Box.justifyContent.SPACE_BETWEEN}
        borderRadius={tokens.spacing.s4}
        borderSize={1}
        p={[tokens.spacing.s4]}
      >
        <NewTypography.Text>
          <NewTag color="blue">Only {testModeTerm}</NewTag> Only sync {providerTerms} created on or after{' '}
          <NewTypography.Text strong>{formattedLinkEarliestCreatedAt}</NewTypography.Text>
        </NewTypography.Text>

        <Tooltip
          content={`Remove 'only ${testModeTerm} items' setting. This action is irreversible and cannot be undone.`}
        >
          <StyledTrashIcon name="trash" kind={Icon.KINDS.SOLID} onClick={handleClickTrashIcon} />
        </Tooltip>

        <TurnOffTestModeModal
          isOpen={isTurnOffTestModeModalOpen}
          taskTerm={providerTerms}
          onRequestClose={handleOnCloseModal}
          onSuccess={handleOnTurnOffTestMode}
          linkId={linkId}
        />
      </Container>
    )
  );
};

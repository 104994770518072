import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PortWidget, DiagramEngine, NodeModel } from '@projectstorm/react-diagrams';

import * as containerTypes from '~/consts/containers';
import {
  getProviderIdentityProviderName,
  getContainerById,
  getProviderIdentityById,
  isLoadingContainer as isLoadingContainerById,
  getProviderCapabilitiesByProviderIdentityIdV3,
} from '~/reducers';
import * as containerActions from '~/actions/containers';
import { WorkBlockLoading } from '~/components/WorkBlock/WorkBlockLoading';
import { WorkBlock } from '~/components/WorkBlock/WorkBlock';
import * as workflowTypes from '~/consts/workflows';
import { WORKBLOCK_HEIGHT, WORKBLOCK_WIDTH } from './WorkBlockModel';

const Node = styled.div`
  width: ${(p) => p.$width}px;
  height: ${(p) => p.$height}px;
  position: relative;
`;

function useSetContainerName(containerName, node) {
  useEffect(() => {
    node.setContainerName(containerName);
    // We don't want this side effect to be run everytime the node changes to mitigate impact on performance
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerName]);
}

export const WorkBlockWidget = ({ node, engine }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const providerName =
    useSelector((state) => getProviderIdentityProviderName(state, node.providerIdentityId)) || node.getToolName();
  const container = useSelector((state) => getContainerById(state, node.providerIdentityId, node.containerId));
  const providerIdentity = useSelector((state) => getProviderIdentityById(state, node.providerIdentityId));
  const isLoadingContainer = useSelector((state) => isLoadingContainerById(state, node.containerId));

  const capabilities = useSelector((state) =>
    getProviderCapabilitiesByProviderIdentityIdV3(state, { providerIdentityId: node.providerIdentityId }),
  );
  // the node providerIdentity might not be set yet so just check here
  const fallbackContainerType = !capabilities.isEmpty()
    ? capabilities.get('containers').first().getIn(['names', 'native'])
    : null;
  const fallbackItemType = !capabilities.isEmpty() ? capabilities.getIn(['item', 'names', 'native']) : null;

  // Force legacy blocks (pre work item release) to request their container information
  // otherwise the blocks won't have the container name and will show 'block unavailable'.
  const containerType = node.getContainerType() ?? fallbackContainerType;
  const itemType = node.getItemType() ?? fallbackItemType;

  // TODO normally here we would set the node's itemType and containerType fields if they're missing
  // TODO but we have an issue with the patchById API endpoint for workflows, it doesn't update the WF blocks field
  // TODO so rather than saving the new info in the nodes and not have it in the blocks, this is intentionally left out
  // TODO until we tackle both at the same time.
  node.setInMemoryContainerType(containerType);
  node.setInMemoryItemType(itemType);

  useSetContainerName(container.get('displayName'), node);

  const isPlaceholder = node.isPlaceholder();
  const isSetupCompleted = node.isSetupCompleted();
  useEffect(() => {
    if (isSetupCompleted && !isPlaceholder && containerType) {
      dispatch(
        containerActions.getContainerById({
          providerIdentityId: node.providerIdentityId,
          containerId: node.containerId,
          containerType,
          itemType,
          options: {
            displayError: false,
          },
        }),
      );
    }
  }, [isSetupCompleted, isPlaceholder, node.providerIdentityId, node.containerId, containerType, itemType, dispatch]);

  const handleMouseUp = (e) => {
    if (!e.target.className.includes('block-of-work-trash')) {
      const model = engine.getModel();
      model.fireEvent({ node }, workflowTypes.DIAGRAM_EVENTS.NODE_DROPPED);
    }
  };

  const handleMouseDown = (e) => {
    // prevent firing nodeGrabbed when clicking on the trash icon. The nodeGrabbed event would reorder the node layer and
    // the delete modal would not open on the first click. see: https://app.asana.com/0/1187642602599284/1192266694389905/f
    if (!e.target.className.includes('block-of-work-trash')) {
      const model = engine.getModel();
      model.fireEvent({ node }, workflowTypes.DIAGRAM_EVENTS.NODE_GRABBED);
    }
  };

  const isDegraded = !isLoadingContainer && node.isSetupCompleted() && container.isEmpty();
  const containerStatus = isDegraded
    ? containerTypes.CONTAINER_STATUSES.DEGRADED
    : containerTypes.CONTAINER_STATUSES.DEFAULT;

  return (
    <Node
      $height={WORKBLOCK_HEIGHT}
      $width={WORKBLOCK_WIDTH}
      className="workblock-custom-node"
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
    >
      {isLoadingContainer || !node.isSetupCompleted() ? (
        <WorkBlockLoading>
          {node.isSetupCompleted() &&
            Object.values(workflowTypes.PORT_POSITIONS).map((position) => (
              <PortWidget
                style={workflowTypes.PORT_CSS_VALUES[position]}
                engine={engine}
                key={position}
                port={node.getPort(position)}
              />
            ))}
        </WorkBlockLoading>
      ) : (
        <WorkBlock
          containerName={
            node.isPlaceholder() ? node.getName() : container.get('displayName', 'This block of work is unavailable')
          }
          containerStatus={node.isPlaceholder() ? containerTypes.CONTAINER_STATUSES.PLACEHOLDER : containerStatus}
          onDeleteClick={() => history.push(`${match.url}/blocks/delete/${node.options.id}`)}
          node={node}
          engine={engine}
          disabled={!node.isPlaceholder() && isDegraded}
          providerName={providerName}
          avatarUrl={providerIdentity.getIn(['profileAvatars', 0], '')}
        />
      )}
    </Node>
  );
};

WorkBlockWidget.propTypes = {
  engine: PropTypes.instanceOf(DiagramEngine).isRequired,
  node: PropTypes.instanceOf(NodeModel).isRequired,
};

import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { List, fromJS } from 'immutable';

import {
  Table,
  Space,
  AntDInput as Input,
  Empty,
  NewSelect,
  Skeleton,
  ProviderIcon,
  Flex,
  tokens,
} from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import { useDebounce } from '~/hooks/useDebounce';
import { useGetAllProvidersIdentities } from '~/hooks';

import { LinkItemDeleteModal } from '../../../components/LinkItem/LinkItemDeleteModal';
import { LinkStatusRenderer } from '../../../components/LinkItem/LinkStatus';
import { SiteAdminTool } from './SiteAdminTool';
import { SyncNowButtonRenderer } from './SyncNowButton';
import { AutoSyncToggleRenderer } from './AutoSyncToggle';
import { SyncDirectionIconsRenderer } from './SyncDirectionIcons';
import { FlowActionsRenderer } from './FlowActions';
import { LinkInformationsRenderer } from './LinkInformation';
import { useGetFlowListLinks } from './hooks/useGetFlowListLinks';

import { AdminInformationRenderer } from './AdminInformation';
import { SiteAdminContext } from '~/contexts';
import { getConnectedProviders } from '~/reducers';
import { useSelector } from 'react-redux';

const desiredKeys = [
  'isAutoSync',
  'A',
  'B',
  'name',
  'isSuspended',
  'kind',
  'syncSettings',
  'state',
  'restricted',
  'syncStatus',
  'user',
  '_id',
];

export const LinkList = ({ linkList }) => {
  const [deleteModalLink, setDeleteModalLink] = useState(fromJS({}));
  const { isSiteAdmin } = useContext(SiteAdminContext);

  const {
    setFilters,
    page,
    states,
    total,
    tools,
    loading,
    initializing,
    pageSize,
    kinds,
    searchString,
    setSiteAdminSearch,
    siteAdminSearchString,
  } = useGetFlowListLinks();
  useGetAllProvidersIdentities();

  const connectedProviders = useSelector(getConnectedProviders);

  const handlePageChange = (pagination) => {
    setFilters({ page: pagination.current - 1 });
  };

  const handleSearch = useDebounce((e) => {
    setFilters({ searchString: e.target.value, page: 0 });
  }, 500);

  const dataSource = linkList
    .map((link) => link.filter((_value, key) => desiredKeys.includes(key)).set('key', link.get('_id')))
    .toJS();

  if (initializing) {
    return <Skeleton />;
  }

  return (
    <>
      {isSiteAdmin && (
        <SiteAdminTool setSiteAdminSearchString={setSiteAdminSearch} siteAdminSearchString={siteAdminSearchString} />
      )}
      <Space style={{ marginBottom: 16 }}>
        <Input.Search onChange={handleSearch} placeholder="Search for a flow" defaultValue={searchString} allowClear />
        <NewSelect
          style={{ minWidth: '125px' }}
          mode="multiple"
          defaultValue={kinds}
          placeholder="Filter by type"
          onChange={(kinds) => setFilters({ kinds, page: 0 })}
          options={Object.entries(linkTypes.KIND_DISPLAY_NAME).map(([optionKind, displayName]) => ({
            value: optionKind,
            label: displayName,
          }))}
        />
        <NewSelect
          style={{ minWidth: '125px' }}
          mode="multiple"
          defaultValue={states}
          onChange={(states) => setFilters({ states, page: 0 })}
          placeholder="Filter by state"
          options={Object.entries(linkTypes.CLIENT_FACING_LINK_STATES).map(([optionKind, displayName]) => ({
            value: optionKind,
            label: displayName,
          }))}
        />
        <NewSelect
          style={{ minWidth: '150px' }}
          mode="multiple"
          defaultValue={tools}
          onChange={(tools) => setFilters({ tools, page: 0 })}
          placeholder="Filter by tools"
          options={connectedProviders.map((connectedProvider) => ({
            value: connectedProvider.get('name'),
            label: (
              <>
                <ProviderIcon size="small" name={connectedProvider.get('name')} />{' '}
                <span style={{ marginLeft: tokens.spacing.s2 }}>{connectedProvider.get('displayName')}</span>
              </>
            ),
          }))}
        />
      </Space>
      <Table
        loading={loading}
        locale={{ emptyText: <Empty description="No flows found" /> }}
        pagination={{
          current: page + 1,
          pageSize,
          total,
          hideOnSinglePage: true,
          showTotal: (totalFlows, range) => `${range[0]}-${range[1]} of ${totalFlows} flows`,
        }}
        showHeader={false}
        dataSource={dataSource}
        onChange={handlePageChange}
      >
        <Table.Column title="Auto sync" dataIndex="autoSync" key="autoSync" render={AutoSyncToggleRenderer} />
        <Table.Column title="Tools" dataIndex="sides" onClick key="sides" render={SyncDirectionIconsRenderer} />
        <Table.Column title="Flow Info" dataIndex="flowInfo" key="flowInfo" render={LinkInformationsRenderer} />
        {isSiteAdmin && (
          <Table.Column title="Admikn" dataIndex="adminTool" key="adminTool" render={AdminInformationRenderer} />
        )}
        <Table.Column title="Sync health" dataIndex="syncHealth" key="syncHealth" render={LinkStatusRenderer} />
        <Table.Column title="Actions" dataIndex="syncNow" key="syncNow" render={SyncNowButtonRenderer} />
        <Table.Column
          title=""
          dataIndex="flowActions"
          key="flowActions"
          render={(_text, link) => FlowActionsRenderer(_text, link, setDeleteModalLink)}
        />
      </Table>
      <LinkItemDeleteModal
        isOpen={!!deleteModalLink.size}
        sync={deleteModalLink}
        onDeleteSync={() => setDeleteModalLink(fromJS({}))}
        onCancel={() => setDeleteModalLink(fromJS({}))}
        onRequestClose={() => setDeleteModalLink(fromJS({}))}
      />
    </>
  );
};

LinkList.propTypes = {
  linkList: PropTypes.instanceOf(List).isRequired,
  linkFilters: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
};

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Bold, Typography } from '@unitoio/mosaic';

import { getItemDefinitionByItemType } from '~/reducers';

export function ContainerItemTypeCount({ providerName, itemType, count }) {
  const legacyCapabilities = useSelector((state) => getItemDefinitionByItemType(state, providerName, itemType));
  let legacyItemNames = Map();
  if (legacyCapabilities) {
    legacyItemNames = legacyCapabilities.get('names', Map());
  }
  const isLegacyItemNamesEmpty = legacyItemNames.isEmpty();

  if (isLegacyItemNamesEmpty) {
    return (
      <Typography variant={Typography.variants.BODY2}>
        <Bold>{count}</Bold> {itemType}
      </Typography>
    );
  }

  let itemCopyToDisplay = itemType;

  if (!isLegacyItemNamesEmpty) {
    itemCopyToDisplay = count > 1 ? legacyItemNames.get('plural') : legacyItemNames.get('singular');
  }

  return (
    <Typography variant={Typography.variants.BODY2}>
      <Bold>{count}</Bold> {itemCopyToDisplay}
    </Typography>
  );
}

ContainerItemTypeCount.propTypes = {
  providerName: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

import { useFormState, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import * as trackingTypes from '~/consts/tracking';
import * as featureTypes from '~/consts/features';
import { getFeatureFlagValue, getLinkById } from '~/reducers';
import { useIsFlowDraft } from './useIsFlowDraft';
import { useGetContainers } from '~/containers/FlowBuilder/hooks/useGetContainers';

export const useGetContainerErrors = () => {
  const { errors } = useFormState();
  const linkId = useWatch({ name: '_id' });
  const isFlowDraft = useIsFlowDraft();
  // fetch container health specifically from the store as it is not tied to the form (since it is a readOnly value that
  // can be updated periodically by the backend)
  const link = useSelector((state) => getLinkById(state, linkId));
  const containerAHealth = link.getIn(['A', 'container', 'health']) ?? Map();
  const containerBHealth = link.getIn(['B', 'container', 'health']) ?? Map();
  const [containerA, containerB] = useGetContainers();

  // Extract lastAccessedAt from store and health to compare which one is more recent
  // If the store is more recent, we favor showing that one because it's the most up to date information
  const lastAccessedAtFromGetContainerA = containerA.get('lastAccessedAt')
    ? new Date(containerA.get('lastAccessedAt'))
    : null;
  const lastAccessedAtFromGetContainerB = containerB.get('lastAccessedAt')
    ? new Date(containerB.get('lastAccessedAt'))
    : null;

  const lastAccessedAtFromHealthA = containerAHealth.get('lastAccessedAt')
    ? new Date(containerAHealth.get('lastAccessedAt'))
    : // it's possible that the health object doesn't exist yet, so default this date to epoch 0
      // to not break the Date comparison below
      new Date(0);
  const lastStatusMessageA = containerAHealth.get('lastStatusMessage');
  const lastStatusCodeA = containerAHealth.get('lastStatusCode');

  const lastAccessedAtFromHealthB = containerBHealth.get('lastAccessedAt')
    ? new Date(containerBHealth.get('lastAccessedAt'))
    : new Date(0);
  const lastStatusMessageB = containerBHealth.get('lastStatusMessage');
  const lastStatusCodeB = containerBHealth.get('lastStatusCode');

  const isSyncStatusRevamp = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.SYNC_STATUS_REVAMP),
  );

  // Containers fetched by the app have their errors stored in the form directly, default to those if
  // sync status revamp is not enabled or flow is a draft
  let containerAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.containerId?.error ?? null;
  let containerBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.containerId?.error ?? null;

  if (isSyncStatusRevamp && !isFlowDraft) {
    if (lastAccessedAtFromHealthA >= lastAccessedAtFromGetContainerA || !lastAccessedAtFromGetContainerA) {
      containerAError = lastStatusCodeA !== 200 ? lastStatusMessageA : null;
    }
    if (lastAccessedAtFromHealthB >= lastAccessedAtFromGetContainerB || !lastAccessedAtFromGetContainerB) {
      containerBError = lastStatusCodeB !== 200 ? lastStatusMessageB : null;
    }
  }

  return { containerAError, containerBError };
};

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Box, ProviderIcon, tokens } from '@unitoio/mosaic';

import { getProviderByName, getUserProviderIdentities } from '~/reducers';
import * as trackingTypes from '~/consts/tracking';
import { FlowBuilderErrorContext } from '~/contexts';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { capitalize } from '~/utils/capitalize';

import { WORK_ITEM_SELECTOR_VARIANT } from '../../../components/WorkItemSelector/SelectorLabel';
import { ProviderIdentitiesSelect } from '../../../components/WorkItemSelector/ProviderIdentitiesSelect';
import { ContainerSelect } from '../../../components/WorkItemSelector/ContainerSelect';
import { NoContainerSelect } from './NoContainerSelect';

const Label = styled(Box)`
  gap: ${tokens.spacing.s4};
`;

export const ToolSelection = ({ side, disabled = false }) => {
  const { watch, setValue, clearErrors } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);

  const trackEvent = useTrackEvent();

  const selectedProviderName = watch(`${side}.providerName`);
  const selectedItemType = watch(`${side}.itemType`);
  const selectedContainerType = watch(`${side}.containerType`);
  const selectedProviderIdentityId = watch(`${side}.providerIdentityId`);

  const provider = useSelector((state) => getProviderByName(state, selectedProviderName));

  const userProviderIdentities = useSelector((state) => getUserProviderIdentities(state, false)); // false because we don't want providers that are "onlyAuthenticate" (githubappuser)
  const providerIdentities = userProviderIdentities.filter((pi) => pi.get('providerName') === selectedProviderName);

  useEffect(() => {
    if (providerIdentities?.size === 1) {
      setValue(`${side}.providerIdentityId`, providerIdentities.keySeq().first(), { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, providerIdentities?.size, setValue, side]);

  const handleProviderIdentityChange = async (newProviderIdentityId) => {
    trackEvent(trackingTypes.EVENT_NAME.ACTION, {
      action_name: 'selected existing account',
      selected_tool_name: selectedProviderName,
      connected_account_qty: providerIdentities.size,
      connected_account: providerIdentities.isEmpty() ? 'new' : 'existing',
    });

    clearErrors([`${pageName}.${side}.providerIdentityId`, `${pageName}.${side}.containerId`]);
    setValue(`${side}.containerId`, null, { shouldDirty: true });
    const newFormData = watch();
    newFormData[side].providerIdentityId = newProviderIdentityId;
  };

  const handleContainerChange = (newContainer, newContainerType) => {
    trackEvent(trackingTypes.EVENT_NAME.ACTION, {
      action_name: 'selected an existing project',
    });
    const newFormData = watch();
    newFormData[side].containerId = newContainer;
    // pass container type here at all times in case user changes item type to a contact based item
    // in which case we might lose this information.
    newFormData[side].containerType = newContainerType;
    clearErrors(`${pageName}.${side}.containerId`);
  };

  const handleContainerTypeChange = (newContainerType) => {
    setValue(`${side}.containerId`, null, { shouldDirty: true });
    clearErrors(`${side}.containerId`);
    const newFormData = watch();
    newFormData[side].containerType = newContainerType;
  };

  return (
    <Box>
      <Box>
        <Box>
          <ProviderIdentitiesSelect
            provider={provider}
            // faking a draft state for oneClick to re-use selectors
            isDraft
            workItemSelectorVariant={WORK_ITEM_SELECTOR_VARIANT.LABEL_INPUT_BLOCK}
            onChange={handleProviderIdentityChange}
            side={side}
            label={
              <Label m={[0, 0, tokens.spacing.s4, 0]} alignItems="center" flexDirection="row">
                <ProviderIcon name={provider.get('name')} />
                {provider.get('displayName')} Account
              </Label>
            }
            selectedProviderIdentityId={selectedProviderIdentityId}
            readOnly={disabled}
          />
        </Box>
        <Box m={[tokens.spacing.s5, 0, 0, 0]}>
          {side === 'A' ? (
            <ContainerSelect
              workItemSelectorVariant={WORK_ITEM_SELECTOR_VARIANT.LABEL_INPUT_BLOCK}
              provider={provider}
              valuesToDisable={[]}
              onChange={handleContainerChange}
              onChangeContainerType={handleContainerTypeChange}
              side={side}
              label={capitalize(selectedContainerType)}
              readOnly={disabled}
              selectedContainerType={selectedContainerType}
            />
          ) : (
            <NoContainerSelect
              provider={provider}
              itemType={selectedItemType}
              selectedContainerType={selectedContainerType}
              valuesToDisable={[]}
              onChange={handleContainerChange}
              side={side}
              label={capitalize(selectedContainerType)}
              providerIdentityId={selectedProviderIdentityId}
              readOnly={disabled}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

ToolSelection.propTypes = {
  side: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

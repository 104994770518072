import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Card, Flex } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import * as featureTypes from '~/consts/features';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getFeatureFlagValue } from '~/reducers';

import cardImageUsage from './images/cardImage_usage.svg';
import cardImageIntegration from './images/cardImage_integrations.svg';
import cardImageNeedInspirationNewTag from './images/cardImage_needinspiration_newtag.svg';

const CARD_TITLES = {
  BROWSE: ' Browse guides and templates',
  CONNECT_TOOL: 'Connect a new tool',
  CHECK_YOUR_USAGE: 'Check your usage',
};

export const CardsDashboard = ({ organizationId }) => {
  const trackEvent = useTrackEvent();
  const isOnItemsKeptInSyncPlan = useSelector(
    (state) => !!getFeatureFlagValue(state, featureTypes.FEATURES.ITEMS_KEPT_IN_SYNC_PAGE, organizationId),
  );

  const isOnBillingUsageOverviewRevamp = useSelector(
    (state) => !!getFeatureFlagValue(state, featureTypes.FEATURES.BILLING_OVERVIEW_REVAMP, organizationId),
  );

  return (
    <Flex justify="space-between" gap="middle">
      <Box
        onClick={() =>
          trackEvent(trackingTypes.EVENT_NAME.ACTION, {
            action_name: `clicked on ${CARD_TITLES.BROWSE.toLowerCase()}`,
          })
        }
      >
        <Card href="https://unito.io/resources/" title={CARD_TITLES.BROWSE} image={cardImageNeedInspirationNewTag} />
      </Box>
      <Box
        onClick={() =>
          trackEvent(trackingTypes.EVENT_NAME.ACTION, {
            action_name: `clicked on ${CARD_TITLES.CONNECT_TOOL.toLowerCase()}`,
          })
        }
      >
        <Card
          as={Link}
          to="/dashboard/profile/connectors"
          title={CARD_TITLES.CONNECT_TOOL}
          image={cardImageIntegration}
        />
      </Box>
      <Box
        onClick={() =>
          trackEvent(trackingTypes.EVENT_NAME.ACTION, {
            action_name: `clicked on ${CARD_TITLES.CHECK_YOUR_USAGE.toLowerCase()}`,
          })
        }
      >
        {isOnBillingUsageOverviewRevamp ? (
          <Card
            as={Link}
            to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/usage`}
            title={CARD_TITLES.CHECK_YOUR_USAGE}
            image={cardImageUsage}
          />
        ) : (
          <Card
            as={Link}
            to={`/dashboard/organizations/${organizationId}/${
              isOnItemsKeptInSyncPlan ? 'items-kept-in-sync' : 'usage'
            }`}
            title={CARD_TITLES.CHECK_YOUR_USAGE}
            image={cardImageUsage}
          />
        )}
      </Box>
    </Flex>
  );
};

CardsDashboard.propTypes = {
  organizationId: PropTypes.string.isRequired,
};

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import { Box, Button, tokens, NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import { FlowBuilderErrorContext } from '~/contexts';
import { getField } from '~/reducers';
import * as fieldTypes from '~/consts/fields';
import * as fieldActions from '~/actions/fields';

import { FieldMappingGroupItem } from './FieldMappingGroupItem';
import { FieldMappingComments } from './FieldMappingComments';
import { FieldMappingStreamAttachments } from './FieldMappingStreamAttachments';
import { FieldMappingPrefix } from './mapping/FieldMappingPrefix';
import { useGetCapabilitiesV3 } from '../hooks/useGetCapabilitiesV3';
import { FieldMappingPlaceholder } from './mapping/FieldMappingPlaceholder';
import { useWatchFieldArray } from '../hooks/useWatchFieldArray';

const GrowingBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const FieldMappingGroup = ({
  fieldIndex,
  containerIdA,
  containerIdB,
  containerTypeA,
  containerTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
  itemTypeA,
  itemTypeB,
}) => {
  const { errors } = useFormState();
  const dispatch = useDispatch();
  const pageName = useContext(FlowBuilderErrorContext);
  const currentAssociation = useWatch({ name: `associations.${fieldIndex}` });
  const providerNameA = useWatch({ name: 'A.providerName' });
  const providerNameB = useWatch({ name: 'B.providerName' });

  const {
    fields: fieldsA,
    append: appendA,
    remove: removeA,
  } = useWatchFieldArray(`associations.${fieldIndex}.A.mapping`);
  const {
    fields: fieldsB,
    append: appendB,
    remove: removeB,
  } = useWatchFieldArray(`associations.${fieldIndex}.B.mapping`);
  const [, , capabilitiesAv2, capabilitiesBv2] = useGetCapabilitiesV3();
  const match = useRouteMatch([
    '/dashboard/flow-builder/:mode(edit)/:linkId',
    '/embed/:embedName/dashboard/flow-builder/:mode(edit)/:linkId',
    '/dashboard/flow-builder/:mode(workflow)/:worflowId/:linkId',
  ]);

  const fieldMappingA = useSelector((state) =>
    getField(state, {
      containerId: containerIdA,
      kind: currentAssociation.A.kind,
      fieldId: currentAssociation.A.field,
      providerIdentityId: providerIdentityIdA,
      providerName: providerNameA,
      containerSide: 'A',
      itemType: itemTypeA,
    }),
  );
  const fieldMappingB = useSelector((state) =>
    getField(state, {
      containerId: containerIdB,
      kind: currentAssociation.B.kind,
      fieldId: currentAssociation.B.field,
      providerIdentityId: providerIdentityIdB,
      providerName: providerNameB,
      containerSide: 'B',
      itemType: itemTypeB,
    }),
  );

  const fieldSemanticA = fieldMappingA.get('semantic') ?? '';
  const fieldSemanticB = fieldMappingB.get('semantic') ?? '';

  const isAssociatingAttachment = [currentAssociation.A.field, currentAssociation.B.field].includes(
    fieldTypes.ATTACHMENTS,
  );
  const isAssociatingComments =
    fieldSemanticA.startsWith(fieldTypes.COMMENTS) || fieldSemanticB.startsWith(fieldTypes.COMMENTS);

  const fieldMappingAIsTitle = fieldSemanticA === fieldTypes.SEMANTIC.ITEM_NAME;
  const fieldMappingBIsTitle = fieldSemanticB === fieldTypes.SEMANTIC.ITEM_NAME;

  const fieldIsSearchableA = fieldMappingA.get('searchable', false);
  const fieldIsSearchableB = fieldMappingB.get('searchable', false);

  const fieldMappingAIsCustomString =
    fieldMappingA.get('kind') === fieldTypes.KINDS.CUSTOM_FIELD &&
    fieldMappingA.get('type') === fieldTypes.TYPES.STRING;
  const fieldMappingBIsCustomString =
    fieldMappingB.get('kind') === fieldTypes.KINDS.CUSTOM_FIELD &&
    fieldMappingB.get('type') === fieldTypes.TYPES.STRING;

  // TODO PCDv3 - aug 28
  const isAssociatingTitleA =
    capabilitiesAv2.hasIn(['options', 'taskNumberPrefix']) &&
    fieldMappingAIsTitle &&
    (fieldMappingBIsTitle || fieldMappingBIsCustomString);

  // TODO PCDv3 - aug 28
  const isAssociatingTitleB =
    capabilitiesBv2.hasIn(['options', 'taskNumberPrefix']) &&
    fieldMappingBIsTitle &&
    (fieldMappingAIsTitle || fieldMappingAIsCustomString);

  const isAssociatingValues =
    !isAssociatingAttachment && !isAssociatingComments && !isAssociatingTitleA && !isAssociatingTitleB;

  const [isPrefetchingValues, setIsPrefetchingValues] = useState(true);

  const disableDelete = fieldsA?.length < 2;

  useEffect(() => {
    const prefetchValues = async () => {
      const sideA = {
        containerId: containerIdA,
        fieldId: currentAssociation.A.field,
        kind: currentAssociation.A.kind,
        providerIdentityId: providerIdentityIdA,
        containerSide: 'A',
        itemType: itemTypeA,
        containerType: containerTypeA,
      };
      const sideB = {
        containerId: containerIdB,
        fieldId: currentAssociation.B.field,
        kind: currentAssociation.B.kind,
        providerIdentityId: providerIdentityIdB,
        containerSide: 'B',
        itemType: itemTypeB,
        containerType: containerTypeB,
      };
      if (isAssociatingValues) {
        const promises = [];
        if (!fieldIsSearchableA) {
          promises.push(dispatch(fieldActions.fetchFieldValues(sideA)));
        }

        if (!fieldIsSearchableB) {
          promises.push(dispatch(fieldActions.fetchFieldValues(sideB)));
        }

        await Promise.all(promises);
      }
      setIsPrefetchingValues(false);
    };
    prefetchValues();
  }, [
    containerIdA,
    containerIdB,
    containerTypeA,
    containerTypeB,
    currentAssociation.A.field,
    currentAssociation.A.kind,
    currentAssociation.B.field,
    currentAssociation.B.kind,
    dispatch,
    fieldIsSearchableA,
    fieldIsSearchableB,
    isAssociatingValues,
    itemTypeA,
    itemTypeB,
    providerIdentityIdA,
    providerIdentityIdB,
    setIsPrefetchingValues,
  ]);

  if (isPrefetchingValues) {
    return <FieldMappingPlaceholder />;
  }

  const handleRemoveGroup = (index) => {
    removeA(index);
    removeB(index);
  };

  if (isAssociatingTitleA || isAssociatingTitleB) {
    return (
      <FieldMappingPrefix
        providerIdentityIdA={providerIdentityIdA}
        providerIdentityIdB={providerIdentityIdB}
        itemTypeA={itemTypeA}
        itemTypeB={itemTypeB}
        containerTypeA={containerTypeA}
        containerTypeB={containerTypeB}
      />
    );
  }

  if (isAssociatingComments) {
    return <FieldMappingComments fieldA={fieldMappingA} fieldB={fieldMappingB} />;
  }

  if (isAssociatingAttachment) {
    return (
      <FieldMappingStreamAttachments side={fieldMappingA.getIn(['itemType', 'canStreamBinary'], false) ? 'A' : 'B'} />
    );
  }

  const fieldNameA = fieldMappingA.getIn(['names', 'plural'], fieldMappingA.get('name'));
  const fieldNameB = fieldMappingB.getIn(['names', 'plural'], fieldMappingB.get('name'));

  return (
    <GrowingBox>
      <Box fullWidth m={[1, 2.5]}>
        {(fieldSemanticA.startsWith(fieldTypes.SEMANTIC.WORKFLOW) ||
          fieldSemanticB.startsWith(fieldTypes.SEMANTIC.WORKFLOW)) && (
          <Box m={[0, 0, tokens.spacing.s4]}>
            <Alert
              level={NewAlertLevel.INFO}
              message={
                <>
                  Any unmapped {fieldNameA}
                  {fieldNameB !== fieldNameA && ` or ${fieldNameB}`} will still sync and be mapped to your default{' '}
                  {fieldNameA}
                  {fieldNameB !== fieldNameA && ` & ${fieldNameB}`} which can be changed on the{' '}
                  <Link to={`${match.url}/rules`}>Rules page</Link>.
                </>
              }
            />
          </Box>
        )}
        <Button
          startIcon="plus"
          block
          disabled={!!errors[pageName]?.incompleteFieldMappingGroup && fieldsA.length > 0 && fieldsB.length > 0}
          onClick={() => {
            appendA({ values: [] });
            appendB({ values: [] });
          }}
        >
          {fieldNameA === fieldNameB ? `Add more ${fieldNameA}` : `Add more ${fieldNameA} & ${fieldNameB}`}
        </Button>
      </Box>
      {fieldsA
        .map((elem, mappingIndex) => (
          <Box
            fullWidth
            key={`${currentAssociation.A.field}-${currentAssociation.B.field}-${
              elem?.values?.[0]?.value || 'newFieldMappingGroup'
            }`}
            p={[0, 0, tokens.spacing.s3, 0]}
          >
            <Box m={[mappingIndex === 0 ? 0 : tokens.spacing.s3, 0, 0, 0]}>
              <FieldMappingGroupItem
                onRemoveGroup={() => handleRemoveGroup(mappingIndex)}
                fieldIndex={fieldIndex}
                fieldAssociation={currentAssociation}
                mappingIndex={mappingIndex}
                containerIdA={containerIdA}
                containerIdB={containerIdB}
                containerTypeA={containerTypeA}
                containerTypeB={containerTypeB}
                providerIdentityIdA={providerIdentityIdA}
                providerIdentityIdB={providerIdentityIdB}
                disableDelete={disableDelete}
                itemTypeA={itemTypeA}
                itemTypeB={itemTypeB}
              />
            </Box>
          </Box>
        ))
        .reverse()}
    </GrowingBox>
  );
};

FieldMappingGroup.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  containerIdA: PropTypes.string.isRequired,
  containerIdB: PropTypes.string.isRequired,
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
  itemTypeA: PropTypes.string.isRequired,
  itemTypeB: PropTypes.string.isRequired,
  containerTypeA: PropTypes.string.isRequired,
  containerTypeB: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useWatch, useFormContext } from 'react-hook-form';

import { ContainerFilePicker as MosaicContainerFilePicker } from '@unitoio/mosaic';

export const ContainerFilePicker = ({
  provider,
  providerIdentityId,
  selectedItemType,
  selectedContainerType,
  onChange,
  side,
  readOnly,
}) => {
  const { setValue } = useFormContext();
  const parentContainer = useWatch({ name: `${side}.parentContainer` });
  const containerId = useWatch({ name: `${side}.containerId` });

  const filePickerValues = {
    parentContainer: { id: parentContainer?.id, parentContainerType: parentContainer?.parentContainerType },
    containerId,
  };

  const handleOnFileChange = async ({ newParentContainer }) => {
    setValue(`${side}.parentContainer`, newParentContainer, { shouldDirty: true });
    // reset containerId/containerType when parentContainer changes
    await onChange(null, null);
  };

  const handleOnContainerChange = async (container) => {
    await onChange(container, selectedContainerType);
  };

  return (
    <MosaicContainerFilePicker
      provider={provider.toJS()}
      providerIdentityId={providerIdentityId}
      containerType={selectedContainerType}
      itemType={selectedItemType}
      onFileChange={(newParentContainer) => handleOnFileChange({ newParentContainer })}
      onContainerChange={async (container) => handleOnContainerChange(container)}
      containerValue={filePickerValues}
      readOnly={readOnly}
    />
  );
};

ContainerFilePicker.propTypes = {
  provider: PropTypes.instanceOf(Map).isRequired,
  selectedItemType: PropTypes.string.isRequired,
  selectedContainerType: PropTypes.string.isRequired,
  providerIdentityId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  readOnly: PropTypes.bool.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { NewAlert as Alert, NewAlertLevel, NewTypography } from '@unitoio/mosaic';

import { getContainerById, getProviderByProviderIdentityId, getProviderIdentityById } from '~/reducers';

export function LaunchRestrictedAlert({
  containerErrorNameA = undefined,
  containerErrorNameB = undefined,
  containerIdA,
  containerIdB,
  containerTypeA,
  containerTypeB,
  providerIdentityIdA,
  providerIdentityIdB,
}) {
  const containerA = useSelector((state) => getContainerById(state, providerIdentityIdA, containerIdA));
  const containerB = useSelector((state) => getContainerById(state, providerIdentityIdB, containerIdB));

  const providerA = useSelector((state) => getProviderByProviderIdentityId(state, providerIdentityIdA));
  const providerB = useSelector((state) => getProviderByProviderIdentityId(state, providerIdentityIdB));

  const providerIdentityA = useSelector((state) => getProviderIdentityById(state, providerIdentityIdA));
  const providerIdentityB = useSelector((state) => getProviderIdentityById(state, providerIdentityIdB));

  const containerAUrl = containerA?.get('url');
  const containerBUrl = containerB?.get('url');

  const { displayName: containerADisplayName } = containerA?.toJS() || {};
  const { displayName: containerBDisplayName } = containerB?.toJS() || {};

  const { displayName: providerADisplayName } = providerA?.toJS() || {};
  const { displayName: providerBDisplayName } = providerB?.toJS() || {};

  const isSameTool = providerADisplayName === providerBDisplayName;

  const { profileDisplayName: identityDisplayNameA, profileEmails: profileEmailsA } = providerIdentityA.toJS();
  const { profileDisplayName: identityDisplayNameB, profileEmails: profileEmailsB } = providerIdentityB.toJS();

  const accountNameA = identityDisplayNameA ?? profileEmailsA[0] ?? 'the original flow creator';
  const accountNameB = identityDisplayNameB ?? profileEmailsB[0] ?? 'the original flow creator';

  const errorOnBothSides = !!containerErrorNameA && !!containerErrorNameB;
  const isForbiddenError = containerErrorNameA === 'ForbiddenError' || containerErrorNameB === 'ForbiddenError';

  const displayableUrlA = (
    <NewTypography.Link target="_blank" href={containerAUrl}>
      {containerADisplayName}
    </NewTypography.Link>
  );
  const displayableUrlB = (
    <NewTypography.Link target="_blank" href={containerBUrl}>
      {containerBDisplayName}
    </NewTypography.Link>
  );

  let containerDisplayNames = '';
  let containerUrls = '';
  if (errorOnBothSides) {
    containerUrls = (
      <>
        {containerAUrl ? displayableUrlA : containerADisplayName} and{' '}
        {containerBUrl ? displayableUrlB : containerBDisplayName}
      </>
    );
    if (isSameTool) {
      // pluralize the containerType if the tools are the same
      containerDisplayNames = `the ${containerTypeA.endsWith('s') ? containerTypeA : `${containerTypeA}s`}`;
    } else {
      // use containerType instead of container name when the tools are different
      // but since containerTypes are pluralized for most modern tools, do a best effort
      // to try and have them singular instead.
      const singularizedContainerTypeA = containerTypeA.endsWith('s') ? containerTypeA.slice(0, -1) : containerTypeA;
      const singularizedContainerTypeB = containerTypeB.endsWith('s') ? containerTypeB.slice(0, -1) : containerTypeB;
      containerDisplayNames = `the ${singularizedContainerTypeA} and the ${singularizedContainerTypeB}`;
    }
  } else {
    containerDisplayNames = containerErrorNameA ? containerADisplayName : containerBDisplayName;
    containerUrls = containerErrorNameA ? displayableUrlA : displayableUrlB;
  }

  const isSameAuthor = accountNameA === accountNameB;

  const getAuthorNameToDisplay = () => {
    const singleAuthorToDisplay = containerErrorNameA ? accountNameA : identityDisplayNameB;
    return errorOnBothSides ? `${accountNameA} or ${accountNameB}` : singleAuthorToDisplay;
  };
  const authorNameToDisplay = isSameAuthor ? accountNameA : getAuthorNameToDisplay();

  if (isForbiddenError) {
    if (isSameTool) {
      return (
        <Alert
          level={NewAlertLevel.ERROR}
          message={
            <NewTypography.Text>
              You can't launch this flow because none of your connected {providerADisplayName} accounts in Unito have
              access to {containerDisplayNames}. Please ask {authorNameToDisplay} to launch it or request access to{' '}
              {containerUrls}.
            </NewTypography.Text>
          }
        />
      );
    }
    return (
      <Alert
        level={NewAlertLevel.ERROR}
        message={
          <NewTypography.Text>
            You can't launch this flow because none of your connected accounts in Unito have access to{' '}
            {containerDisplayNames}. Please ask {authorNameToDisplay} to launch it or request access to {containerUrls}.
          </NewTypography.Text>
        }
      />
    );
  }

  return (
    <Alert
      level={NewAlertLevel.ERROR}
      message={
        <NewTypography.Text>
          There was an error validating {containerDisplayNames}. If this error persists, please contact support.
        </NewTypography.Text>
      }
    />
  );
}

LaunchRestrictedAlert.propTypes = {
  containerErrorNameA: PropTypes.string,
  containerErrorNameB: PropTypes.string,
  containerIdA: PropTypes.string.isRequired,
  containerIdB: PropTypes.string.isRequired,
  containerTypeA: PropTypes.string.isRequired,
  containerTypeB: PropTypes.string.isRequired,
  providerIdentityIdA: PropTypes.string.isRequired,
  providerIdentityIdB: PropTypes.string.isRequired,
};

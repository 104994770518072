import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, tokens } from '@unitoio/mosaic';

import * as appActions from '~/actions/app';
import { getEmbedName, getFeatureFlagValue, getOrganizationName, getOrganizations } from '~/reducers';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import * as appTypes from '~/consts/app';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { NavbarHeader } from '~/components/NavbarStacked/NavbarHeader';
import { NavbarItem } from '~/components/NavbarStacked/NavbarItem';
import { NavbarStacked } from '~/components/NavbarStacked/NavbarStacked';
import { Can } from '~/components/Can/Can';

const StyledHR = styled.hr`
  margin: ${tokens.spacing.s1} 0;
`;

const StyledIcon = styled(Icon)`
  margin-right: ${tokens.spacing.s3};
`;

const WorkspaceSettingsMenu = ({ parentMatch, organizationId }) => {
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const dispatch = useDispatch();
  const organizations = useSelector((state) => getOrganizations(state));
  const embedName = useSelector((state) => getEmbedName(state));
  const workspaceName = useSelector((state) => getOrganizationName(state, organizationId));
  const showItemsKeptInSyncPage = useSelector((state) => !!getFeatureFlagValue(state, 'items-kept-in-sync-page'));

  async function handleOnSelectNewOrganization(newSelectedOrganizationId) {
    if (newSelectedOrganizationId !== organizationId) {
      trackEvent(trackingTypes.EVENT_NAME.ACTION, {
        new_organization_id: newSelectedOrganizationId,
        action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.DIFFERENT_ORGANIZATION,
      });

      await dispatch(appActions.setSelectedOrganizationId(newSelectedOrganizationId));
      const pricingUrlRedirect = `${parentMatch.url}/${newSelectedOrganizationId}/pricing`;
      history.push(pricingUrlRedirect);
    }
  }

  function trackOnClickSameOrganization() {
    trackEvent(trackingTypes.EVENT_NAME.ACTION, {
      action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.CURRENT_ORGANIZATION,
    });
  }

  function getWorkspaceMenu() {
    if (organizations.size < 2) {
      return undefined;
    }

    return (
      <NavbarStacked>
        {organizations
          .map((org) => {
            const isSameOrganization = org.get('id') === organizationId;
            return (
              <NavbarItem
                onClick={() => {
                  if (isSameOrganization) {
                    trackOnClickSameOrganization();
                  } else {
                    handleOnSelectNewOrganization(org.get('id'));
                  }
                }}
                key={org.get('id')}
              >
                {org.get('name')}{' '}
                {isSameOrganization && (
                  <StyledIcon
                    name="check"
                    kind={Icon.KINDS.SOLID}
                    color={tokens.colors.content.branding.unito}
                    title="existing organization"
                    fixedWidth
                  />
                )}
              </NavbarItem>
            );
          })
          .toArray()}
      </NavbarStacked>
    );
  }

  if (!organizationId) {
    return null;
  }

  return (
    <NavbarStacked>
      <NavbarHeader subtitle="Switch workspace?" submenu={getWorkspaceMenu()}>
        {workspaceName}
      </NavbarHeader>
      <Can I="update" a="billing">
        <NavbarItem
          to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/workspace`}
          onClick={() =>
            trackEvent(trackingTypes.EVENT_NAME.ACTION, {
              action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.WORKSPACE_SECTION,
            })
          }
        >
          <StyledIcon name="briefcase" kind={Icon.KINDS.SOLID} fixedWidth />
          About my workspace
        </NavbarItem>
      </Can>

      <NavbarItem
        to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/people`}
        onClick={() =>
          trackEvent(trackingTypes.SETTINGS_MENU_EVENTS.ACTION_NAME, {
            action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.PEOPLE_SECTION,
          })
        }
      >
        <StyledIcon name="users" kind={Icon.KINDS.SOLID} fixedWidth />
        Members &amp; active users
      </NavbarItem>

      <StyledHR />

      {embedName !== appTypes.EMBED.WRIKE && (
        <Can I="update" a="billing">
          <NavbarItem
            to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/pricing`}
            onClick={() =>
              trackEvent(trackingTypes.SETTINGS_MENU_EVENTS.ACTION_NAME, {
                action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.PRICING_SECTION,
              })
            }
          >
            <StyledIcon name="credit-card" fixedWidth />
            Pricing & plans
          </NavbarItem>

          <NavbarItem
            to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/billing`}
            onClick={() =>
              trackEvent(trackingTypes.SETTINGS_MENU_EVENTS.ACTION_NAME, {
                action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.BILLING_SECTION,
              })
            }
          >
            <StyledIcon name="book" kind={Icon.KINDS.SOLID} fixedWidth />
            Usage & billing
          </NavbarItem>
        </Can>
      )}

      {showItemsKeptInSyncPage && (
        <Can I="update" a="billing">
          <NavbarItem
            to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/items-kept-in-sync`}
            onClick={() =>
              trackEvent(trackingTypes.SETTINGS_MENU_EVENTS.ACTION_NAME, {
                action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.ITEMS_IN_SYNC_SECTION,
              })
            }
          >
            <StyledIcon name="magic" kind={Icon.KINDS.SOLID} fixedWidth />
            Items in sync
          </NavbarItem>
        </Can>
      )}

      <NavbarItem
        to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/rewards`}
        onClick={() =>
          trackEvent(trackingTypes.EVENT_NAME.ACTION, {
            action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.REWARDS_SECTION,
          })
        }
      >
        <StyledIcon name="heart" kind={Icon.KINDS.SOLID} fixedWidth />
        Earn rewards
      </NavbarItem>
    </NavbarStacked>
  );
};

WorkspaceSettingsMenu.propTypes = {
  parentMatch: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  organizationId: PropTypes.string.isRequired,
};

export const WorkspaceSettingsMenuWithTrackingFunnel = (props) => (
  <TrackingFunnel contextName={trackingTypes.PAGE.WORKSPACE_SETTINGS}>
    <WorkspaceSettingsMenu {...props} />
  </TrackingFunnel>
);

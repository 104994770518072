import { useSelector } from 'react-redux';

import { getProviderByName, getProviderVisibleFields } from '~/reducers';

import { useGetCapabilitiesV3 } from './useGetCapabilitiesV3';
import { useGetContainers } from './useGetContainers';

export function useAreBothSidesMergeable({ itemTypeA, itemTypeB, providerNameA, providerNameB }) {
  const [capabilitiesAV3, capabilitiesBV3] = useGetCapabilitiesV3();
  const providerIdA = useSelector((state) => getProviderByName(state, providerNameA).get('_id'));
  const providerIdB = useSelector((state) => getProviderByName(state, providerNameB).get('_id'));
  const [containerA, containerB] = useGetContainers();
  const canMergeA = capabilitiesAV3.getIn(['item', 'canMerge'], false);
  const canMergeB = capabilitiesBV3.getIn(['item', 'canMerge'], false);

  // additionally, we need at least one field with the mergeField property set to 'true'
  const fieldsA = useSelector((state) =>
    getProviderVisibleFields(state, providerIdA, itemTypeA, containerA?.get('id')),
  );
  const fieldsB = useSelector((state) =>
    getProviderVisibleFields(state, providerIdB, itemTypeB, containerB?.get('id')),
  );

  const hasAtLeastOneMergeFieldA = fieldsA.some((field) => field.get('mergeField', false));
  const hasAtLeastOneMergeFieldB = fieldsB.some((field) => field.get('mergeField', false));

  return canMergeA && hasAtLeastOneMergeFieldA && canMergeB && hasAtLeastOneMergeFieldB;
}

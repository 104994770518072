import { useWatch } from 'react-hook-form';

import { useGetProviderExtraStep } from './useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from './useHasMissingExtraStepAction';
import { useSelector } from 'react-redux';
import { getFeatureFlagValue } from '~/reducers';
import * as featureTypes from '~/consts/features';
import { useIsFlowDraft } from '~/containers/FlowBuilder/hooks/useIsFlowDraft';

export function useIsWorkItemStepMissingConfig() {
  const providerIdentityIdA = useWatch({ name: 'A.providerIdentityId' });
  const providerIdentityIdB = useWatch({ name: 'B.providerIdentityId' });
  const providerNameA = useWatch({ name: 'A.providerName' });
  const providerNameB = useWatch({ name: 'B.providerName' });
  const containerIdA = useWatch({ name: 'A.containerId' });
  const containerIdB = useWatch({ name: 'B.containerId' });

  const providerAExtraStep = useGetProviderExtraStep(providerNameA);
  const providerBExtraStep = useGetProviderExtraStep(providerNameB);

  const containerAExtraConfigDetails = useHasMissingExtraStepAction(
    providerAExtraStep,
    providerNameA,
    providerIdentityIdA,
    containerIdA,
  );
  const containerBExtraConfigDetails = useHasMissingExtraStepAction(
    providerBExtraStep,
    providerNameB,
    providerIdentityIdB,
    containerIdB,
  );

  return !!containerAExtraConfigDetails || !!containerBExtraConfigDetails;
}

export function useShouldShowWorkItemStepMissing() {
  const isDraft = useIsFlowDraft();
  const isAddOnImprovements = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );
  const isWorkItemStepMissingConfig = useIsWorkItemStepMissingConfig();
  return !(isAddOnImprovements && isDraft) && isWorkItemStepMissingConfig;
}

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Box, Col, Grid, HorizontalSeparator, Icon, Row, tokens, Tooltip, Visible } from '@unitoio/mosaic';

import * as draftActions from '~/actions/drafts';
import { ExportAndSyncErrorContext } from '~/contexts';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useQueryParams } from '~/hooks/useQueryParams';
import * as formUtils from '~/utils/forms';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import { getProviderIdentityByProfileId } from '~/reducers';
import { loadingStates } from '../../FlowBuilder/utils/form';
import { ToolSelection } from '../components/ToolSelection';
import { LoadingPage } from '../components/LoadingPage';
import { ErrorPage } from '../components/ErrorPage';
import { PageHeader } from '../components/PageHeader';
import { Steps } from '../components/Steps';

const ConnectBox = styled(Box)`
  border-radius: ${tokens.spacing.s4};
  position: relative;
`;

const ConnectGrid = styled(Grid)`
  width: 60vw;
`;

const ArrowsCol = styled(Col)`
  align-self: center;
`;

const Arrows = styled(Box)`
  border-radius: 50%;
  width: ${tokens.spacing.s8};
  height: ${tokens.spacing.s8};
  color: ${tokens.colors.content.info.default};
`;

const VerticalSeparator = styled.div`
  border-right: 1px solid ${tokens.colors.content.neutral.n10};
  height: ${tokens.spacing.s4};
`;

const ArrowsContainer = styled(Box)`
  flex: 1;
`;

export const ConnectTools = ({ loadingState, setLoadingState, onComplete, setDraft }) => {
  const { handleSubmit, register, watch, setValue, setError } = useFormContext();
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { errors } = useFormState();
  const { containerId: sideAContainerId, profileId: sideAProfileId, providerId: sideAProviderId } = useQueryParams();

  const sideAProviderIdentity = useSelector((state) =>
    getProviderIdentityByProfileId(state, sideAProfileId, sideAProviderId),
  );

  useEffect(() => {
    trackEvent(trackingTypes.EVENT_NAME.START);
  }, [trackEvent]);

  const providerIdentityIdA = watch(`A.providerIdentityId`);
  const providerIdentityIdB = watch(`B.providerIdentityId`);
  const containerIdA = watch(`A.containerId`);
  const providerNameA = watch(`A.providerName`);
  const providerNameB = watch(`B.providerName`);
  const pageName = useContext(ExportAndSyncErrorContext);

  const hasErrors = !formUtils.isEmptyObject(errors);

  // make sure the query params are present and the values were set before declaring it as preselected
  const shouldPreselect = sideAProviderIdentity && sideAContainerId;

  const canDisplayConnectTools = loadingState === loadingStates.LOADED || loadingState === loadingStates.INITIAL;

  useEffect(() => {
    if (shouldPreselect) {
      setValue('A.providerIdentityId', sideAProviderIdentity.get('_id'), { shouldDirty: true });
      setValue('A.containerId', sideAContainerId, { shouldDirty: true });
    }
  }, [sideAContainerId, sideAProviderIdentity, setValue, shouldPreselect]);

  const handleFormSubmit = async (formValues) => {
    trackEvent(trackingTypes.EVENT_NAME.SUBMIT, {
      selected_tool_names: `${providerNameA}, ${providerNameB}`,
    });
    setLoadingState(loadingStates.LOADING);
    try {
      setDraft(await dispatch(draftActions.createOneClick(formValues)));
      setLoadingState(loadingStates.LOADED);
      onComplete();
    } catch (err) {
      setError(`${pageName}`, {
        type: 'manual',
        error: err.message,
        code: err.code,
        identifier: err.identifier,
        name: err.name,
      });
    }
  };

  return (
    <>
      <Steps hasErrors={hasErrors} activeStep={1} totalStepCircles={2} />
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {canDisplayConnectTools && (
          <>
            <PageHeader title="Connect Trello and Google Sheets" align="center" />
            <Box p={[tokens.spacing.s3, 0, tokens.spacing.s3, 0]} />

            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input type="hidden" {...register('syncDirection', { value: linkTypes.SYNC_DIRECTION.ONE_WAY })} />

            <ConnectGrid>
              <Row nogutter>
                <Col md={12} lg={5}>
                  <ConnectBox
                    borderColor={tokens.colors.content.neutral.n10}
                    p={[tokens.spacing.s6]}
                    data-testid="sideAToolsOneClick"
                  >
                    <ToolSelection
                      side="A"
                      loadingState={loadingStates.INITIAL}
                      disabled={!!(shouldPreselect && providerIdentityIdA && containerIdA)}
                    >
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input type="hidden" {...register('A.providerName', { value: 'trello' })} />
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input type="hidden" {...register('A.itemType', { value: 'card' })} />
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input type="hidden" {...register('A.containerType', { value: 'board' })} />
                    </ToolSelection>
                  </ConnectBox>
                </Col>
                <ArrowsCol lg={2} md={12}>
                  <Visible lg>
                    <ArrowsContainer alignItems="center" p={[tokens.spacing.s4, 0, tokens.spacing.s4, 0]}>
                      <HorizontalSeparator />
                      <Arrows
                        borderColor={tokens.colors.content.neutral.n10}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Tooltip content="This Trello board will be exported to Google Sheets, with data kept up to date in both tools.">
                          <Icon name="arrow-right-arrow-left" />
                        </Tooltip>
                      </Arrows>
                      <HorizontalSeparator />
                    </ArrowsContainer>
                  </Visible>
                  <Visible sm md>
                    <ArrowsContainer flexDirection="column" alignItems="center">
                      <VerticalSeparator />
                      <Arrows
                        borderColor={tokens.colors.content.neutral.n10}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Tooltip content="This Trello board will be exported to Google Sheets, with data kept up to date in both tools.">
                          <Icon name="arrow-right-arrow-left" />
                        </Tooltip>
                      </Arrows>
                      <VerticalSeparator />
                    </ArrowsContainer>
                  </Visible>
                </ArrowsCol>
                <Col md={12} lg={5}>
                  <ConnectBox
                    borderColor={tokens.colors.content.neutral.n10}
                    p={[tokens.spacing.s6]}
                    data-testid="sideAToolsOneClick"
                  >
                    <ToolSelection side="B" loadingState={loadingStates.INITIAL}>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input type="hidden" {...register('B.providerName', { value: 'googlesheets' })} />
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input type="hidden" {...register('B.itemType', { value: 'row' })} />
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <input type="hidden" {...register('B.containerType', { value: 'sheet' })} />
                    </ToolSelection>
                  </ConnectBox>
                </Col>
              </Row>
            </ConnectGrid>
            <Box p={[tokens.spacing.s4, tokens.spacing.s4, 0, 0]} justifyContent="flex-end">
              <Box m={[tokens.spacing.s3, 0, 0, 0]}>
                <Button
                  type="submit"
                  disabled={
                    !providerIdentityIdA ||
                    !providerIdentityIdB ||
                    !containerIdA ||
                    loadingState === loadingStates.LOADING ||
                    loadingState === loadingStates.SAVING
                  }
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </>
        )}

        {loadingState === (loadingStates.LOADING || loadingStates.ERROR) && (
          <Grid>
            <Row justify="center" align="center">
              <Col lg={8} md={8}>
                <PageHeader
                  title="Exporting your Trello board..."
                  align="center"
                  subtitle="Your Trello cards will soon be new rows in Google Sheets."
                />
                <Box alignItems="center" flexDirection="column">
                  {!hasErrors ? <LoadingPage /> : <ErrorPage setLoadingState={setLoadingState} />}
                </Box>
              </Col>
            </Row>
          </Grid>
        )}
      </form>
    </>
  );
};

ConnectTools.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(loadingStates)).isRequired,
  setLoadingState: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  setDraft: PropTypes.func.isRequired,
};

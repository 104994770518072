import { useCallback } from 'react';

import * as errorTypes from '~/consts/errors';
import * as trackingTypes from '~/consts/tracking';

export const useSetContainersErrors = (setError, clearErrors) =>
  useCallback(
    (errors) => {
      const pageName = trackingTypes.MODULE.TOOL_SELECTION;
      const sides = Object.keys(errors) ?? [];
      sides.forEach((side) => {
        if (!errors[side]) {
          clearErrors(`${pageName}.${side}`);
        } else {
          const { message, name: errorName, type: errorType } = errors[side];

          const isAuthError = [
            errorTypes.API_ERROR_TYPES.AUTHENTICATION,
            errorTypes.API_ERROR_TYPES.UNAUTHORIZED,
          ].includes(errorType);
          const errorFieldKey = isAuthError ? `providerIdentityId` : `containerId`;

          setError(`${pageName}.${side}.${errorFieldKey}`, {
            type: 'manual',
            errorName,
            errorType,
            error: message,
          });
        }
      });
    },
    [clearErrors, setError],
  );

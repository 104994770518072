import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useQueryParams } from '~/hooks/useQueryParams';

import { AuthAirtableOAuth2Step1 } from './oauth2/AuthAirtableOAuth2Step1';

export function AirtableInstructions({
  authorizationType,
  children: instructionFields,
  match,
  onCancel,
  onSubmit,
  trackEvent,
}) {
  const { windowOpenerId } = useQueryParams();

  return (
    <Switch>
      <Redirect
        exact
        from={match.path}
        to={`${match.path}/${authorizationType}/step1?windowOpenerId=${windowOpenerId}`}
      />
      <Route
        exact
        path={`${match.path}/oauth2/step1`}
        render={(routeProps) => (
          <AuthAirtableOAuth2Step1 {...routeProps} onSubmit={onSubmit} onCancel={onCancel} trackEvent={trackEvent}>
            {instructionFields}
          </AuthAirtableOAuth2Step1>
        )}
      />
    </Switch>
  );
}

AirtableInstructions.propTypes = {
  authorizationType: PropTypes.string.isRequired,
  children: PropTypes.node,
  error: PropTypes.string,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as organizationActions from '~/actions/organizations';
import { getSelectedOrganizationId } from 'reducers';
import { logger } from '~/utils/logger';

export function useFetchOrganizationFeatureFlags(organizationId) {
  const dispatch = useDispatch();
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const [featureFlags, setFeatureFlags] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { flags } = await dispatch(organizationActions.getFlags(organizationId));
        setFeatureFlags(
          Object.entries(flags).map(([key, value]) => ({
            key,
            value: JSON.stringify(value),
          })),
        );
        setError(null);
      } catch (err) {
        setError(err);
        setFeatureFlags(undefined);
        logger.error('Failed to fetch organization flags', {
          error: err,
          organizationId,
        });
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();

    return () => {
      if (selectedOrganizationId !== organizationId) {
        dispatch(organizationActions.clearFlagsByOrganizationId(organizationId));
      }
    };
  }, [dispatch, organizationId, selectedOrganizationId, setIsLoading]);

  return { featureFlags, error, isLoading };
}

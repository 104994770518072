import React from 'react';
import PropTypes from 'prop-types';
import { NewButton, Result } from '@unitoio/mosaic';
import * as routes from '~/consts/routes';
import { ChatBubble } from '~/components/ChatBubble/ChatBubble';
import { Header } from '~/components/Header/Header';
import { UnableToMountError } from '~/consts/errors';

const DEFAULT_REDIRECT_URL = `/#${routes.ABSOLUTE_PATHS.DASHBOARD}`;
/**
 * Generic top level error encapsulator.
 * */
export function AppError({
  error,
  errorInfo = {
    componentStack: '',
  },
  onDismissError = () => {},
  returnToCTAText = 'Back to dashboard',
  returnToUrl = DEFAULT_REDIRECT_URL,
}) {
  const errorAsString = error?.toString() || error;
  const chatMessage = `
    Hey Unito! I'm getting this error on your app.

    ${errorAsString}
    ${errorInfo.componentStack}
  `;

  let errorToDisplay = errorInfo?.componentStack || errorAsString || 'Something went wrong, please try again later.';
  // special handling for NotFoundError which typically happens for users
  // using Google Translate plugin
  const { name: errorName, message: errorMessage } = error;
  if (
    errorName === 'NotFoundError' &&
    (errorMessage?.startsWith("Failed to execute 'removeChild' on 'Node'") ||
      errorMessage?.startsWith("Failed to execute 'insertBefore' on 'Node'"))
  ) {
    errorToDisplay =
      'The Unito app encountered an error which may have been caused by a browser extension (for example: Google Translate). \n' +
      'Please try disabling your browser extension to prevent this issue from happening again.';
  }

  if (error instanceof UnableToMountError) {
    errorToDisplay =
      'Unito is running but we are having issues contacting our servers. Try to refresh this page or try again later.';
  }

  const handleDismissError = () => {
    onDismissError();
    window.location.assign(returnToUrl);
    window.location.reload();
  };

  return (
    <div className="content">
      <Header />
      <Result
        status="500"
        title="An error occurred"
        subTitle={errorToDisplay}
        extra={[
          <NewButton onClick={handleDismissError} type="primary">
            {returnToCTAText}
          </NewButton>,
          <ChatBubble type="default" message={chatMessage}>
            Get in touch with our team
          </ChatBubble>,
        ]}
      />
    </div>
  );
}

AppError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Error)]).isRequired,
  errorInfo: PropTypes.shape({
    componentStack: PropTypes.string,
  }),
  onDismissError: PropTypes.func,
  returnToCTAText: PropTypes.string,
  returnToUrl: PropTypes.string,
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Skeleton, Space, NewTypography as Typography } from '@unitoio/mosaic';

import * as providerIdentityActions from '~/actions/providerIdentity';
import { useGetAllProvidersIdentities } from '~/hooks/useGetAllProvidersIdentities';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getIsLoadingProviders, getProvidersThatCanConnect } from '~/reducers';
import * as trackingTypes from '~/consts/tracking';
import { ProviderList } from '~/components/ProviderList/ProviderList';
import { ProfileSettingsError } from '~/containers/ProfileSettingsContainer/ProfileSettingsError';

export const ConnectorList = () => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const onDisconnectProviderIdentity = (providerIdentityId) =>
    dispatch(providerIdentityActions.disconnectProviderIdentity(providerIdentityId));

  const providerList = useSelector((state) => getProvidersThatCanConnect(state));
  const isLoadingProviders = useSelector((state) => getIsLoadingProviders(state));

  const {
    allProviderIdentities: providerIdentityList,
    isLoading: isProviderIdentitiesLoading,
    hasError: hasPIdentitiesError,
  } = useGetAllProvidersIdentities();

  const isLoading = isLoadingProviders || isProviderIdentitiesLoading;

  if (hasPIdentitiesError) {
    return (
      <ProfileSettingsError
        title="Sorry we were not able to recover your Accounts."
        message="Please try again later."
      />
    );
  }

  return (
    <Skeleton loading={isLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <ProviderList
          providerList={providerList}
          providerIdentityList={providerIdentityList}
          onDisconnectProviderIdentity={onDisconnectProviderIdentity}
          trackEvent={trackEvent}
        />
        <Typography.Text>
          Looking for another integration?{' '}
          <Typography.Link
            onClick={() =>
              trackEvent(trackingTypes.CONNECTORS.ACTION_NAME, {
                action_name: trackingTypes.CONNECTORS.ACTIONS.UPCOMING_INTEGRATIONS,
              })
            }
            href="https://unito.io/connectors/"
          >
            Vote for it
          </Typography.Link>
        </Typography.Text>
      </Space>
    </Skeleton>
  );
};

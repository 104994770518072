import React from 'react';
import 'isomorphic-fetch';
import { v4 } from 'uuid';
import moment from 'moment';

import { NewButton, Space, notification } from '@unitoio/mosaic';

import * as authActions from '~/actions/auth';
import * as loggingActions from '~/actions/logging';
import * as trackingActions from '~/actions/tracking';
import * as routes from '~/consts/routes';
import { getClientVersion, getToken } from '~/reducers';

export function callMaestroApi(endpoint, method, token, payload, clientVersion, correlationId, signal) {
  const options = {
    method,
    signal,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Unito-Correlation-Id': correlationId,
      'Unito-GMT-offset': new Date().getTimezoneOffset() / 60,
    },
    body: method !== routes.METHODS.GET ? JSON.stringify(payload) : undefined,
  };

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  if (clientVersion) {
    options.headers['unito-client-app-version'] = clientVersion;
  }

  return fetch(
    `${process.env.REACT_APP_PUBLIC_URL || process.env.REACT_APP_PUBLIC_URL}/${routes.BASE_API}/${endpoint}`,
    options,
  );
}

/**
 * Extract common error fields expected in the top level in Datadog.
 * */
/* eslint-disable camelcase */
const extractAndFormatTopFields = (error = {}) => {
  // snakeCase to be compliant with datadog log format
  const { uuid: error_id, name: error_name, severity: level } = error;
  return {
    error_id,
    error_name,
    level,
  };
};

const validateAction = ({ types, method }) => {
  if (!Array.isArray(types) || types.length !== 3 || !types.every((type) => typeof type === 'string')) {
    throw new Error('callAPIMiddleware Error: Expected an array of three string types. Types:', types);
  }

  if (!Object.keys(routes.METHODS).includes(method)) {
    throw new Error(
      'callAPIMiddleware Error: Expected method to be one of the following: "GET", "POST", "PUT", "PATCH", "DELETE". Method:',
      method,
    );
  }
};

const registeredOngoingCalls = new Map();

export function callAPIMiddleware({ dispatch, getState }) {
  return (next) => async (action) => {
    const {
      types,
      url,
      method = routes.METHODS.GET,
      isProtectedUrl = true,
      shouldCallAPI = () => true,
      payload = {},
      meta = {},
      isPolling = false,
      displayError = true,
      token = undefined,
      cancelOnActions = [],
    } = action;

    const state = getState();

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    validateAction({ types, method });

    if (!shouldCallAPI(state)) {
      return {};
    }

    const [requestType, successType, failureType] = types;

    dispatch({
      type: requestType,
      meta: {
        ...meta,
        url,
        initialPayload: payload,
      },
    });

    const authToken = isProtectedUrl ? getToken(state) || token : undefined;
    const clientVersion = getClientVersion(state);
    // eslint-disable-next-line camelcase
    const correlation_id = v4();

    const getMiddlewareErrorContext = (err) => {
      // Sanitize the payload by removing sensitive information.
      const sanitizedPayload = JSON.parse(JSON.stringify(payload));

      delete sanitizedPayload.authenticationOptions?.password;

      return {
        middlewareApiContext: {
          reduxAction: failureType,
          actionMeta: meta,
          actionPayload: sanitizedPayload,
          apiUrl: url,
          method,
        },
        serverError: err,
        ...extractAndFormatTopFields(err),
        correlation_id,
      };
    };

    let response;
    try {
      const controller = new AbortController();
      const { signal } = controller;

      const registerAndFireAPICall = async () => {
        const apiResponse = callMaestroApi(url, method, authToken, payload, clientVersion, correlation_id, signal);
        // Cancel calls as needed if a cancelOnActions was set
        registeredOngoingCalls.forEach((call) => cancelOnActions.includes(call.requestType) && call.controller.abort());
        registeredOngoingCalls.set(correlation_id, { requestType, cancelOnActions, controller });

        return apiResponse;
      };

      response = await registerAndFireAPICall();
    } catch (err) {
      const errorContext = getMiddlewareErrorContext(err);

      // cancels a fetch and triggers a failure event in case of an abort
      // needs to trigger a failure type to revert any loading state, but doesn't throw an error to the end user
      if (errorContext.error_name === 'AbortError') {
        dispatch({
          err,
          type: failureType,
          meta: {
            ...meta,
            initialPayload: payload,
          },
        });

        return { hasRequestAborted: true };
      }
      dispatch(loggingActions.reportException(err, errorContext));
      // catches when cloudflare returns a timeout and displays an error to the user
      if (response?.status === 524) {
        dispatchGenericError(err, response, state, dispatch, {
          excludeHelpLink: meta.displayErrorOptions?.excludeHelpLink,
          excludeGuideLink: meta.displayErrorOptions?.excludeGuideLink,
        });
      }

      return {};
    } finally {
      registeredOngoingCalls.delete(correlation_id);
    }
    const { headers } = response;

    // Reject all responses of content type that are not json
    const isEmptyResponse = response.status === 204;
    const contentType = headers.get('content-type') || '';

    if (!isEmptyResponse && !contentType.startsWith('application/json')) {
      const textResponse = await response.text();
      const isHtmlText = textResponse.toLowerCase().startsWith('<!doctype html>');

      let errorMessage = isHtmlText
        ? 'Oops, something went wrong. If this error occurs again, contact our team for support.'
        : textResponse;

      const isCloudFlareTimeout =
        textResponse.includes('Ray ID') && (response.status === 504 || response.status === 524);
      if (isCloudFlareTimeout) {
        // Cloudflare proxy errors will return an html page, we don't want to display it
        errorMessage =
          'It looks like your request took too long, please try again. If this error occurs again, contact our team for support.';
      }

      // do not wrap the error object in a new Error() otherwise we will get a serialized object [object Object] when we catch it
      // eslint-disable-next-line no-throw-literal
      throw {
        message: errorMessage,
        status: response.status,
        name: response.name,
      };
    }

    const jsonResponse = !isEmptyResponse ? await response.json() : {};
    if (response.ok) {
      // If new version of app, client needs to refresh
      if (typeof jsonResponse === 'object' && jsonResponse?.reload) {
        window.location.reload(true);
        return null;
      }

      dispatch({
        payload: jsonResponse,
        type: successType,
        meta: {
          ...meta,
          responseTimestamp: Date.now(),
          initialPayload: payload,
        },
      });

      return jsonResponse;
    }

    const error = jsonResponse;

    dispatch({
      error,
      type: failureType,
      meta: {
        ...meta,
        initialPayload: payload,
      },
    });

    // An invalid cookie disconnects the user.
    if (error.name === 'InvalidCookieTokenError') {
      dispatch(authActions.logoutUser());

      return {};
    }

    // ONLY display error on non polling calls. Otherwise we will spam sentry and the user
    if (isPolling) {
      return {};
    }

    if (response.status === 400 || response.status >= 500) {
      error.reportedInMiddleware = true;

      // Track user facing errors
      const analyticsErrorPayload = {
        ...error,
        identifier: error.identifier || 'UnknownIdentifier',
        method,
        url,
      };

      dispatch(trackingActions.trackEvent('Had error in console', { error: analyticsErrorPayload }));

      loggingActions.reportException(
        new Error([error.identifier, error.message].filter((m) => m).join(' - ')),
        getMiddlewareErrorContext(error),
      );
    } else {
      error.reportedInMiddleware = false;
    }

    if (displayError) {
      dispatchGenericError(error, response, state, dispatch, {
        excludeHelpLink: meta.displayErrorOptions?.excludeHelpLink,
        excludeGuideLink: meta.displayErrorOptions?.excludeGuideLink,
      });
    }

    throw error;
  };
}

export function dispatchGenericError(
  error,
  response,
  state,
  dispatch,
  { excludeGuideLink = false, excludeHelpLink = false } = {},
) {
  const uuidError = error.uuid ? `(${error.uuid})` : '';
  const message = `Hello Unito! \n\nI had the following error when using your app: \n\n${error.message} ${uuidError}.`;
  if (error.name === 'InvalidCookieTokenError') {
    notification.info({
      message: 'Your session has expired',
      description: error.message,
      placement: 'top',
    });
    return;
  }

  const buttons = [];
  if (!excludeHelpLink) {
    buttons.push({
      name: 'Get help',
      primary: true,
      onClick: () => {
        if (window.HubSpotConversations) {
          window.HubSpotConversations.widget.open();
        } else {
          window.open(
            `mailto:support@unito.io?subject=Error while using Unito - ${error.message} ${uuidError}&body=${message}`,
            '_blank',
          );
        }
      },
    });
  }
  if (!excludeGuideLink) {
    buttons.push({
      name: 'Guide',
      onClick: () => {
        window.open(`https://guide.unito.io/kb-search-results?term=${error.message}`, '_blank');
      },
    });
  }

  notification.error({
    message: 'Something went wrong',
    description: error.message,
    placement: 'topRight',
    btn: (
      <Space>
        {buttons.map((button) => (
          <NewButton key={button.name} onClick={button.onClick}>
            {button.name}
          </NewButton>
        ))}
      </Space>
    ),
  });
}

export function timestamper() {
  return (next) => (action) =>
    next({
      ...action,
      meta: {
        ...action.meta,
        timestamp: moment().toISOString(),
      },
    });
}

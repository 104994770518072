import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Icon, NewButton, tokens } from '@unitoio/mosaic';

import * as workflowActions from '~/actions/workflows';
import * as featureTypes from '~/consts/features';
import * as trackingTypes from '~/consts/tracking';
import { getSelectedOrganizationId, isOrganizationWorkflowLimitReached } from '~/reducers';
import { FeatureLimitTooltip } from '~/components/FeatureFlag/FeatureLimitTooltip';
import { useTrackEvent } from '~/hooks';
import { useGetOrganizationUsage } from '~/hooks/useGetOrganizationUsage';

const { FEATURES } = featureTypes;

export const CreateWorkflowButton = ({ trackingActionName = null }) => {
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const { isUsageLoading, usageError } = useGetOrganizationUsage(selectedOrganizationId);
  const trackEvent = useTrackEvent();
  const isWorkflowLimitReached = useSelector((state) =>
    isOrganizationWorkflowLimitReached(state, selectedOrganizationId),
  );
  return (
    <Box m={[0, 0, 0, tokens.spacing.s2]}>
      <NewButton
        type="primary"
        icon={<Icon name="plus" />}
        loading={isUsageLoading}
        disabled={isUsageLoading || isCreatingWorkflow || isWorkflowLimitReached || usageError}
        onClick={() => {
          dispatch(workflowActions.createWorkflow(setIsCreatingWorkflow, history, selectedOrganizationId));
          trackEvent(trackingTypes.USER_DASHBOARD_EVENTS.ACTION_NAME, {
            action_name: trackingActionName || 'clicked on create a new workflow button',
          });
        }}
      >
        Create workflow
        {isWorkflowLimitReached && ' '}
        {isWorkflowLimitReached && <FeatureLimitTooltip feature={FEATURES.WORKFLOWS} />}
      </NewButton>
    </Box>
  );
};

CreateWorkflowButton.propTypes = {
  trackingActionName: PropTypes.string,
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTrackEvent } from '~/hooks/useTrackEvent';

import { Flex, NewAlert as Alert, NewAlertLevel, NewButton as Button, NewTypography, Space } from '@unitoio/mosaic';

import * as featureTypes from '~/consts/features';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import {
  getFeatureFlagValue,
  getMaxUsageFeatureToDisplay,
  getSelectedOrganizationId,
  isOrganizationOverFeatureLimit,
} from '~/reducers';
import { useGetOrganizationUsage } from '~/hooks/useGetOrganizationUsage';

export const OverPlanLimitBanner = () => {
  const currentOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const { isUsageLoading, usageError } = useGetOrganizationUsage(currentOrganizationId);
  const featureToShow = useSelector((state) => getMaxUsageFeatureToDisplay(state, currentOrganizationId));
  const isOverPlanLimit = useSelector((state) =>
    isOrganizationOverFeatureLimit(state, currentOrganizationId, featureToShow.get('id')),
  );
  const showBannerFeatureFlag = useSelector((state) =>
    getFeatureFlagValue(state, 'show-over-limit-banner', currentOrganizationId),
  );
  const calculationDetailsUrl =
    featureToShow.get('id') === featureTypes.FEATURES.MAX_USERS
      ? 'https://guide.unito.io/what-are-active-users'
      : 'https://guide.unito.io/what-are-items-in-sync';

  const showBanner = showBannerFeatureFlag && isOverPlanLimit;

  const trackEvent = useTrackEvent({
    banner_type: trackingTypes.BANNER_EVENTS.TYPES.OVER_PLAN_LIMIT,
    feature_over_limit: featureToShow.get('id'),
  });

  useEffect(() => {
    if (showBanner) {
      trackEvent(trackingTypes.BANNER_EVENTS.START, {});
    }
  }, [showBanner, trackEvent]);

  if (isUsageLoading || usageError) {
    return null;
  }

  const AlertContent = (
    <Flex align="center">
      <Flex align="flex-start" flex="1">
        <Space>
          <NewTypography.Text size="small">
            You're over the limit of items in sync allowed on your workspace plan.
          </NewTypography.Text>
          <NewTypography.Link href={calculationDetailsUrl} target="_blank">
            Find out more about how this number is calculated.
          </NewTypography.Link>
        </Space>
      </Flex>
      <Flex align="flex-end">
        <Button
          type="primary"
          href={`/#${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${currentOrganizationId}/pricing`}
          onClick={() =>
            trackEvent(trackingTypes.BANNER_EVENTS.ACTION, {
              action_name: trackingTypes.BANNER_EVENTS.ACTIONS.UPGRADE_PLAN,
            })
          }
        >
          Upgrade your plan
        </Button>
      </Flex>
    </Flex>
  );

  return showBanner ? <Alert level={NewAlertLevel.ERROR} message={AlertContent} /> : null;
};

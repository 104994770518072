import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Icon, NewButton, Space } from '@unitoio/mosaic';

import { AccountStatusBadge } from '~/containers/AccountStatusBadge/AccountStatusBadge';
import { HeaderProfileDropdown } from '~/containers/HeaderContainer/HeaderProfileDropdown';
import { InviteCoworkersModal } from '~/containers/InviteCoworkersModal/InviteCoworkersModal';
import { getEmbedName, getSelectedOrganizationId } from '~/reducers';
import * as appTypes from '~/consts/app';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';

export const RightHeaderContent = () => {
  const trackEvent = useTrackEvent();
  const embedName = useSelector((state) => getEmbedName(state));

  const trackHeaderEvent = (actionName, data) => {
    const eventName = trackingTypes.HEADER_BAR_EVENTS.ACTION_NAME;
    trackEvent(eventName, { action_name: actionName, ...data });
  };
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);

  return (
    <>
      <InviteCoworkersModal
        organizationId={selectedOrganizationId}
        isOpen={isInviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
      />
      <Space size="middle" direction="horizontal">
        {embedName !== appTypes.EMBED.WRIKE && <AccountStatusBadge type="link" trackEvent={trackHeaderEvent} />}
        <Space>
          <NewButton
            type="text"
            icon={<Icon name="question-circle" size="lg" />}
            href="https://guide.unito.io/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              trackEvent
                ? trackEvent(trackingTypes.HEADER_BAR_EVENTS.ACTION_NAME, { action_name: 'clicked on resources' })
                : null
            }
          >
            Help center
          </NewButton>
          <NewButton
            iconPosition="start"
            icon={<Icon name="plus" kind={Icon.KINDS.SOLID} />}
            onClick={() => setInviteModalOpen(true)}
          >
            Invite members
          </NewButton>
        </Space>
        <HeaderProfileDropdown />
      </Space>
    </>
  );
};

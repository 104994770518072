import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import * as fieldActions from '~/actions/fields';
import { getSortedProviderNames } from '~/utils/getSortedProviderNames';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import * as trackingTypes from '~/consts/tracking';
import { useGetProviderNames } from './useGetProviderNames';

export function useGetGenerateFieldAssociations() {
  const { setValue } = useFormContext();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { reportException } = useLogger();
  const trackEvent = useTrackEvent();
  const { linkId } = useParams();
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const sortedProviderNames = getSortedProviderNames(providerNameA, providerNameB);

  const generateFieldAssociations = useCallback(async () => {
    setIsLoading(true);
    let timeoutId;
    try {
      await Promise.all([
        dispatch(
          fieldActions.generateFieldAssociations({ itemType: itemTypeA }, { itemType: itemTypeB }, undefined, linkId),
        ),
        // we add an artificial delay here to make sure the educational
        // loading screen is displayed for at least three seconds
        new Promise((resolve) => {
          timeoutId = setTimeout(resolve, 3000);
        }),
      ]);
      const { fieldAssociations } = await dispatch(fieldActions.generateValueMappingOnFieldAssociations(linkId));
      if (fieldAssociations.length) {
        const associations = fieldAssociations.map((association) => {
          if (association.A.mapping?.length && association.B.mapping?.length) {
            const mappingA = association.A.mapping.map((values) => ({
              values: values.map((value) => ({ value })),
            }));
            const mappingB = association.B.mapping.map((values) => ({
              values: values.map((value) => ({ value })),
            }));

            return {
              ...association,
              A: { ...association.A, mapping: mappingA },
              B: { ...association.B, mapping: mappingB },
            };
          }

          return association;
        });
        setValue('associations', associations, { shouldDirty: true });
      }
    } catch (err) {
      reportException(err);
      setIsError(true);
      trackEvent(trackingTypes.BLOCKED, {
        reason: err,
        selected_tool_names: sortedProviderNames,
      });
    } finally {
      setIsLoading(false);
      clearTimeout(timeoutId);
    }
  }, [dispatch, itemTypeA, itemTypeB, linkId, setValue, reportException, trackEvent, sortedProviderNames]);

  return [generateFieldAssociations, isLoading, isError];
}

import React, { useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { getProviderCapabilitiesV3 } from '~/reducers';
import { ContainerSelect } from './ContainerSelect';
import { ContainerFilePicker } from './ContainerFilePicker';
import { providerUsesFilePicker } from '../../containers/FlowBuilder/utils/providerUsesFilePicker';

export function ContainerSelectWrapper({
  valuesToDisable,
  provider,
  side,
  readOnly,
  onChange,
  onChangeContainerType,
  isLoading,
}) {
  const { watch } = useFormContext();

  const selectedProviderName = watch(`${side}.providerName`);
  const selectedItemType = watch(`${side}.itemType`);
  const selectedProviderIdentityId = watch(`${side}.providerIdentityId`);

  const capabilitiesV3 = useSelector((state) =>
    getProviderCapabilitiesV3(state, {
      providerIdentityId: selectedProviderIdentityId,
      providerName: selectedProviderName,
      itemType: selectedItemType,
    }),
  );

  const itemTypeContainers = capabilitiesV3.get('containers', Map());
  const fallbackContainerType = itemTypeContainers.first()?.getIn(['names', 'native']);
  const isSingleContainerType = itemTypeContainers.size === 1;
  const containerType = watch(`${side}.containerType`);

  useEffect(() => {
    if (!containerType && isSingleContainerType && fallbackContainerType) {
      // set containerType at the form level if it's not set and there's only one container type
      onChangeContainerType(fallbackContainerType);
    }
  }, [containerType, onChangeContainerType, isSingleContainerType, fallbackContainerType]);

  const shouldDisplayFilePicker = providerUsesFilePicker(selectedProviderName);

  if (shouldDisplayFilePicker) {
    return (
      <ContainerFilePicker
        provider={provider}
        providerIdentityId={selectedProviderIdentityId}
        selectedContainerType={containerType}
        selectedItemType={selectedItemType}
        onChange={onChange}
        side={side}
        readOnly={readOnly}
      />
    );
  }

  return (
    <ContainerSelect
      valuesToDisable={valuesToDisable}
      provider={provider}
      side={side}
      readOnly={readOnly}
      onChange={onChange}
      onChangeContainerType={onChangeContainerType}
      isLoading={isLoading}
      selectedContainerType={containerType}
    />
  );
}

ContainerSelectWrapper.propTypes = {
  valuesToDisable: PropTypes.arrayOf(PropTypes.container).isRequired,
  provider: PropTypes.instanceOf(Map).isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeContainerType: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

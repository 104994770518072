/**
 * Combines all reducers into a single one.
 * This allows to separate reducers into different files (by concerns),
 * so we don't end up with a single gigantic reducers.js file.
 */
import { combineReducers } from 'redux';
import { reducer as formReducer, formValueSelector } from 'redux-form';
import { fromJS, Map, List } from 'immutable';
import moment from 'moment';

import * as appTypes from '~/consts/app';
import * as authTypes from '~/consts/auth';
import * as billingTypes from '~/consts/billing';
import * as featureTypes from '~/consts/features';
import * as fieldTypes from '~/consts/fields';
import * as inviteTypes from '~/consts/invites';
import * as linkTypes from '~/consts/link';
import * as organizationTypes from '~/consts/organizations';
import * as providerTypes from '~/consts/providers';
import { otherSide as getOtherSide } from '~/utils/otherSide';
import * as formUtils from '~/utils/forms';
import * as fromActivityLogs from './activityLogs';
import * as fromApp from './app';
import * as fromAuth from './auth';
import * as fromBilling from './billing';
import * as fromContainers from './containers';
import * as fromFields from './fields';
import * as fromForm from './form';
import * as fromInvites from './invites';
import * as fromLinks from './links';
import * as fromOrganizations from './organizations';
import * as fromProviderContainers from './providerContainers';
import * as fromProviderIdentities from './providerIdentities';
import * as fromProviders from './providers';
import * as fromUnitoIdentities from './unitoIdentities';
import * as fromWebsockets from './websockets';
import * as fromWorkflows from './workflows';

const { PCD_COMMON } = fieldTypes.KINDS;
const { FEATURES } = featureTypes;

export const getFieldValue = (state, fieldName, formName = 'syncForm') => formValueSelector(formName)(state, fieldName);

export const getContainerFieldValue = (state, { containerSide }, fieldName) =>
  getFieldValue(state, `${containerSide}.${fieldName}`);

export const getOtherSideContainerFieldValue = (state, { containerSide }, fieldName) =>
  getContainerFieldValue(state, { containerSide: getOtherSide(containerSide) }, fieldName);

export const getContainerById = (state, providerIdentityId, containerId, key) => {
  const container = fromProviderIdentities.getContainerById(state.providerIdentities, providerIdentityId, containerId);
  if (key) {
    return container.get(key);
  }

  return container;
};

export const getLinkContainerBySide = (state, { containerSide, linkId }) =>
  fromLinks.getLinkContainerBySide(state.links, { containerSide, linkId });

export const getLinkProviderNameBySide = (state, { containerSide, linkId }) =>
  fromLinks.getLinkProviderNameBySide(state.links, { containerSide, linkId });

export const getContainer = (state, props, useOtherSide = false) => {
  const fn = !useOtherSide ? getContainerFieldValue : getOtherSideContainerFieldValue;
  const existingContainer = fn(state, props, 'existingContainer');
  const containerId = fn(state, props, 'containerId');
  const providerIdentityId = fn(state, props, 'providerIdentityId');

  if (!formUtils.isEmpty(existingContainer) && !existingContainer && !containerId) {
    const newContainerName = getContainerFieldValue(state, props, 'newContainerName');
    return Map({ displayName: newContainerName });
  }

  return getContainerById(state, providerIdentityId, containerId);
};

export const getContainerPlugins = (state, containerId) =>
  fromContainers.getContainerPlugins(state.containers, containerId);

export const getWorkspaceById = (state, { providerIdentityId, workspaceId }) =>
  fromContainers.getWorkspaceById(state.containers, providerIdentityId, workspaceId);

export const getWorkspaces = (state, { providerIdentityId }) =>
  fromContainers.getWorkspaces(state.containers, providerIdentityId);

export const getContainers = (state, { containerSide }) =>
  fromContainers.getContainers(state.containers, containerSide);

export const getCreatedContainersIds = (state) => fromContainers.getCreatedContainersIds(state.containers);

export const getUserId = (state) => fromAuth.getUserId(state.auth);

export const getUserAvatarUrl = (state) => fromAuth.getUserAvatarUrl(state.auth);

export const getUserFullName = (state) => fromAuth.getUserFullName(state.auth);

export const getIsLoadedProviders = (state) => fromProviders.getIsLoaded(state.providers);

export const getIsLoadingProviders = (state) => fromProviders.getIsLoading(state.providers);

export const getProviders = (state) => fromProviders.getAll(state.providers);

export const getSignupIntentBySide = (state, { containerSide }) =>
  fromAuth.getSignupIntentBySide(state.auth, containerSide);

export const getSignupOauthTool = (state) => fromAuth.getSignupOauthTool(state.auth);

export const getSignupSource = (state) => fromAuth.getSignupSource(state.auth);

// Restricted providers have a featureFlag attribute that should be
// enabled to bypass the restriction
export const getEnabledProviders = (state) => {
  let allProviders = getProviders(state);

  return allProviders.map((provider) => {
    const providerVisibility =
      getFeatureFlagValue(state, provider.get('name')) ?? featureTypes.VISIBILITY_VARIANT.VISIBLE;

    return provider.set('visibility', providerVisibility);
  });
};

// Fetch provider by logical family grouping (jira server and cloud, github regular and enterprise)
export const getVisibleProvidersByFamily = (state) => {
  const entities = getProvidersThatCanConnectWithoutModernProviders(state);

  let providerByFamily = Map();

  entities.forEach((provider) => {
    const family = provider.get('displayName').match(/\w+/)[0];
    // We also directly inject family in the provider,
    // which we might do in the backend in the future
    const entity = provider.set('family', family);

    if (!providerByFamily.get(family)) {
      providerByFamily = providerByFamily.set(family, List([entity]));
      return;
    }

    providerByFamily = providerByFamily.update(family, (p) => p.push(entity));
  });

  return providerByFamily;
};

export const getVisibleProviders = (state) => {
  const embedName = getEmbedName(state);
  let entities = getEnabledProviders(state);

  entities = entities.filter((provider) => provider.get('visibility') !== featureTypes.VISIBILITY_VARIANT.HIDDEN);

  if (embedName === appTypes.EMBED.WRIKE) {
    // If embedded, don't display partner in apps list
    // Only display allowed providers for the embedded one
    entities = entities.filter(
      (p) => p.get('name') === embedName || p.get('embeddableWith').find((e) => e === embedName),
    );
  }

  return entities.sortBy((provider) => provider.get('displayName').toLowerCase());
};

export const getProvidersThatCanLogin = (state) => {
  const entities = getVisibleProviders(state);
  return entities.filter((provider) => {
    const authorizationMethods = provider.getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map());
    return authorizationMethods.some((authMethod) =>
      authMethod.get('supportedAuthActions', List()).includes(authTypes.AUTH_ACTIONS.LOGIN),
    );
  });
};

export const getProvidersThatCanSignup = (state) => {
  const entities = getVisibleProviders(state);
  return entities.filter((provider) => {
    const authorizationMethods = provider.getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map());
    return authorizationMethods.some((authMethod) =>
      authMethod.get('supportedAuthActions', List()).includes(authTypes.AUTH_ACTIONS.SIGNUP),
    );
  });
};

export const getProvidersThatCanConnect = (state) => {
  const entities = getVisibleProviders(state);
  return entities.filter((provider) => {
    const authorizationMethods = provider.getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map());
    return authorizationMethods.some((authMethod) => {
      const supportedAuthActions = authMethod.get('supportedAuthActions', List());
      // Still show providers that are deprecated if the user has a connected provider identity
      if (supportedAuthActions.isEmpty()) {
        const userProviderIdentities = getUserProviderIdentities(state);
        return userProviderIdentities.some(
          (providerIdentity) => providerIdentity.get('providerId') === provider.get('_id'),
        );
      }

      return supportedAuthActions.includes(authTypes.AUTH_ACTIONS.CONNECT);
    });
  });
};

export const getProvidersThatCanConnectWithoutModernProviders = (state) => {
  const entities = getVisibleProviders(state);
  const filteredOutModernProviders = entities.filter(
    (provider) => provider.get('connectorName') !== providerTypes.INTEGRATION_BRIDGE_CONNECTOR_NAME,
  );
  return filteredOutModernProviders.filter((provider) => {
    const authorizationMethods = provider.getIn(['capabilitiesV3', 'authentication', 'authorizations'], Map());
    return authorizationMethods.some((authMethod) => {
      const supportedAuthActions = authMethod.get('supportedAuthActions', List());
      // Still show providers that are deprecated if the user has a connected provider identity
      if (supportedAuthActions.isEmpty()) {
        const userProviderIdentities = getUserProviderIdentities(state);
        return userProviderIdentities.some(
          (providerIdentity) => providerIdentity.get('providerId') === provider.get('_id'),
        );
      }

      return supportedAuthActions.includes(authTypes.AUTH_ACTIONS.CONNECT);
    });
  });
};

export const getProviderById = (state, props) => fromProviders.getProviderById(state.providers, props.providerId);

export const getProvider = (state, props, otherSide = false) => {
  const fn = !otherSide ? getContainerFieldValue : getOtherSideContainerFieldValue;
  const providerId = fn(state, props, 'providerId');
  return getProviderById(state, { providerId });
};

export const getProviderDisplayName = (state, providerName) => {
  const provider = fromProviders.getProviderByName(state.providers, providerName);
  return provider?.get('displayName');
};

export const getProviderByProviderIdentityId = (state, providerIdentityId) => {
  const providerName = fromProviderIdentities.getProviderName(state.providerIdentities, providerIdentityId);
  return fromProviders.getProviderByName(state.providers, providerName);
};

/**
 *
 * @param {{ containerSide: string }} props props must include a key named containerSide
 * @param {string} key What part of the capabilities we want to get
 */
// TODO: Rename me getProviderCapabilitiesBySide
export const getProviderCapabilities = (state, props, key, otherSide = false) => {
  const fn = !otherSide ? getContainerFieldValue : getOtherSideContainerFieldValue;
  const providerId = fn(state, props, 'providerId');
  return fromProviders.getCapabilitiesByProviderId(state.providers, providerId, key);
};

export const getProviderCustomFieldBased = (state, containerSide) => {
  const providerCapabilities = getProviderCapabilities(state, { containerSide }, 'capabilities');
  return providerCapabilities.get('customFieldBased', false);
};

export const getProviderCapabilitiesById = (state, providerId, key) =>
  fromProviders.getCapabilitiesByProviderId(state.providers, providerId, key);

export const getProviderSupportedItems = (state, providerId) =>
  fromProviders.getProviderSupportedItems(state.providers, providerId);

export const getProviderVisibleItems = (state, providerId) => {
  const connectorName = fromProviders.getProviderConnectorNameById(state.providers, providerId);

  return getProviderSupportedItems(state, providerId).filter((item, itemName) => {
    const flagValue = getFeatureFlagValue(state, `integration-${connectorName}-item-${itemName}`);
    return flagValue === undefined ? true : flagValue;
  });
};

export const getProviderCapabilitiesByIdV3 = (state, providerId, itemType) =>
  fromProviders.getCapabilitiesByProviderIdV3(state.providers, providerId, itemType);

export const getCapabilitiesForItem = (state, providerId, containerId, itemType) =>
  fromProviders.getCapabilitiesForItem(state.providers, providerId, containerId, itemType);

export const getCustomFieldsTypeDefinition = (state, providerName, itemType) =>
  fromProviders.getCustomFieldsTypeDefinition(state.providers, providerName, itemType);

export const getProviderVisibleFields = (state, providerId, itemType, containerId) =>
  fromProviders.getProviderVisibleFields(state.providers, providerId, itemType, containerId);

export const getProviderSupportedFields = (state, providerId, itemType, containerId) =>
  fromProviders.getProviderSupportedFields(state.providers, providerId, itemType, containerId);

export const getProviderByName = (state, name) => fromProviders.getProviderByName(state.providers, name);

export const getProviderByConnectorName = (state, connectorName) =>
  fromProviders.getProviderByConnectorName(state.providers, connectorName);

export const getSupportedFieldsForItem = (state, providerId, containerId, itemType) =>
  fromProviders.getSupportedFieldsForItem(state.providers, providerId, containerId, itemType);

export const getSupportedCustomFieldTypesForItem = (state, providerId, containerId, itemType) =>
  fromProviders.getSupportedCustomFieldTypesForItem(state.providers, providerId, containerId, itemType);

export const getItemDefinitionByItemType = (state, providerName, itemType) =>
  fromProviders.getItemDefinitionByItemType(state.providers, providerName, itemType);

export const getDefaultParamAccountId = (state) => fromApp.getDefaultParamAccountId(state.app);

export const getProvidersWithExistingProviderIdentities = (state) => {
  const userId = getUserId(state);
  const connectedProviderNames = fromProviderIdentities.getUserProviderNames(state.providerIdentities, userId);

  const syncDefaultProviderA = fromApp.getDefaultParamProviderId(state.app, 'A');
  const syncDefaultProviderB = fromApp.getDefaultParamProviderId(state.app, 'B');

  // Do not pass the embed-name to getVisibleProviders, as we want to display the providers that an embed-wrike user
  // might have set in the standalone app
  const connectableProviders = getProvidersThatCanConnect(state);

  return connectableProviders.filter((p) => {
    const providerName = p.get('name');
    // Only show providers that have provider identities
    return (
      connectedProviderNames.includes(providerName) ||
      // or that were set as default parameters
      [syncDefaultProviderA, syncDefaultProviderB].includes(p.get('_id'))
    );
  });
};

export const getConnectedProviders = (state) => getProvidersWithExistingProviderIdentities(state).toList();

export const getConnectedAndOtherProviders = (state) => {
  const allProviders = getProvidersThatCanConnect(state);
  const connectedProviders = getConnectedProviders(state);
  const connectedProviderIds = connectedProviders.map((p) => p.get('_id'));
  const otherProviders = allProviders.filter((p) => !connectedProviderIds.includes(p.get('_id')));

  return [connectedProviders, otherProviders];
};

export const getUserProviderIdentitiesByProviderId = (state, providerId) => {
  const userId = getUserId(state);
  const provider = getProviderById(state, { providerId });
  return fromProviderIdentities.getUserProviderIdentitiesByProviderName(
    state.providerIdentities,
    userId,
    provider.get('name'),
  );
};

export const isUserConnectedToProvider = (state, providerName) => {
  const userId = getUserId(state);
  return !fromProviderIdentities
    .getUserProviderIdentitiesByProviderName(state.providerIdentities, userId, providerName)
    .isEmpty();
};

export const isUserConnectedToTrello = (state) => {
  const hasUserTrelloSignupIntent =
    getSignupIntentBySide(state, { containerSide: 'A' }) === appTypes.EMBED.TRELLO ||
    getSignupIntentBySide(state, { containerSide: 'B' }) === appTypes.EMBED.TRELLO;
  const hasUserTrelloConnector = isUserConnectedToProvider(state, appTypes.EMBED.TRELLO);
  return hasUserTrelloConnector || hasUserTrelloSignupIntent;
};

export const getSyncSettingsByLinkId = (state, linkId, containerSide) => {
  const link = getLinkById(state, linkId);
  if (containerSide) {
    return link.getIn(['syncSettings', containerSide], Map());
  }
  return link.get('syncSettings', Map());
};

export const formDataToLinkPayload = (state, formData = {}, isFlowBuilder = false) => {
  const {
    A,
    B,
    name,
    workflowId,
    duplicateLinkId,
    testModeVariant = getFeatureFlagValue(state, featureTypes.FEATURES.NEW_TEST_MODE),
    isTestMode = true,
    isAutoSync,
  } = formData;

  const organizationId = getLinkOrganizationId(state, formData._id) ?? getSelectedOrganizationId(state);

  const associations = getFieldAssociations(state);
  const createdContainersIds = getCreatedContainersIds(state);
  const itemFiltersA = isFlowBuilder
    ? formUtils.getItemFiltersForSideForFlowBuilder(formData.A.filters)
    : getItemFiltersForSide(state, 'A');
  const itemFiltersB = isFlowBuilder
    ? formUtils.getItemFiltersForSideForFlowBuilder(formData.B.filters)
    : getItemFiltersForSide(state, 'B');
  const hasWorkflowId = !!workflowId;

  const isReadOnlyA = isFlowBuilder
    ? formUtils.toReadOnlyFlowBuilder(formData.syncDirection, 'A')
    : ((A.readOnly || A.settings?.readOnly) ?? false);

  const isReadOnlyB = isFlowBuilder
    ? formUtils.toReadOnlyFlowBuilder(formData.syncDirection, 'B')
    : ((B.readOnly || B.settings?.readOnly) ?? false);

  let newName = name;
  if (!name) {
    newName = isFlowBuilder
      ? 'New flow'
      : getDefaultLinkName(state, {
          providerNameA: A.providerName,
          providerNameB: B.providerName,
          itemTypeA: A.itemType,
          itemTypeB: A.itemType,
        }); // In cases like trello to trello mirror syncs, the name will be provided.
  }

  const hasASideContainerId = !!formData.A.containerId;
  const aSideContainerIdCreated = createdContainersIds.includes(formData.A?.containerId);
  const hasBSideContainerId = !!formData.B.containerId;
  const bSideContainerIdCreated = createdContainersIds.includes(formData.B?.containerId);

  const subFoldersA = formData.A?.filters?.find((filter) => filter.fieldId === 'subfolders');
  const subFoldersB = formData.B?.filters?.find((filter) => filter.fieldId === 'subfolders');

  // include containerId only if provided or if containerType hasn't been picked yet (work item selection change in drafts)
  // but exclude it otherwise, since this method is also used for real links.
  const containerIdA = A.containerId || !A.containerType ? A.containerId : undefined;
  const containerIdB = B.containerId || !B.containerType ? B.containerId : undefined;

  const parentContainerA = A.parentContainer
    ? { id: A.parentContainer.id, parentContainerType: A.parentContainer.parentContainerType }
    : null;
  const parentContainerB = B.parentContainer
    ? { id: B.parentContainer.id, parentContainerType: B.parentContainer.parentContainerType }
    : null;

  return {
    A: {
      ...(A.providerContainerId ? { providerContainerId: A.providerContainerId } : {}),
      ...(A.providerName ? { providerName: A.providerName } : {}),
      ...(A.providerIdentityId ? { providerIdentityId: A.providerIdentityId } : {}),
      itemType: A.itemType,
      containerId: containerIdA,
      containerType: A.containerType,
      parentContainer: parentContainerA,
      nodes: A.nodes || [],
      existingContainer: hasWorkflowId || (hasASideContainerId && !aSideContainerIdCreated),
      // handle empty strings, form providers are initialized with an empty string
      settings: {
        merge: {
          fields: formData.A.merge?.fields ?? null,
        },
        truthSide: formData.truthSide === 'A',
        filters: {
          ...(isFlowBuilder
            ? formUtils.toLinkPayloadFlowBuilderFilters(formData.A.filters, formData.A.deepFilters)
            : formUtils.toLinkPayloadFilters(formData.A.filters)),
          ...(isFlowBuilder ? formUtils.toLinkNormalizedActions(formData.A.actions) : {}),
        },
        // TODO: closedTasks kept for backwards compatibility (addLink expects it),
        // the new app sync form should only use settings.includeClosedTasks
        includeClosedTasks: isFlowBuilder
          ? formUtils.toClosedTasksFlowBuilder(formData.A.filters)
          : A.closedTasks || A.settings?.includeClosedTasks || false,
        readOnly: isReadOnlyA,
        ...(A.streamAttachments ? { streamAttachments: A.streamAttachments } : {}),
        ...(A.includePublicComments ? { includePublicComments: A.includePublicComments } : {}),
        ...(A.prefix ? { taskNumberPrefix: formUtils.toLinkPayloadPrefixes(A.prefixes) } : {}),
        ...(subFoldersA ? { subfolders: subFoldersA.value === 'included' } : {}),
        filtersInitializedAt: A.filtersInitializedAt,

        itemFieldAssociations: formUtils.getItemFieldAssociations(formData.A.itemFieldAssociations, itemFiltersA),
        onFilterOut: A.onFilterOut,
      },
    },
    B: {
      ...(B.providerContainerId ? { providerContainerId: B.providerContainerId } : {}),
      ...(B.providerName ? { providerName: B.providerName } : {}),
      ...(B.providerIdentityId ? { providerIdentityId: B.providerIdentityId } : {}),
      itemType: B.itemType,
      containerId: containerIdB,
      containerType: B.containerType,
      parentContainer: parentContainerB,
      nodes: B.nodes || [],
      existingContainer: hasWorkflowId || (hasBSideContainerId && !bSideContainerIdCreated),
      settings: {
        merge: {
          fields: formData.B.merge?.fields ?? null,
        },
        truthSide: formData.truthSide === 'B',
        filters: {
          ...(isFlowBuilder
            ? formUtils.toLinkPayloadFlowBuilderFilters(formData.B.filters, formData.B.deepFilters)
            : formUtils.toLinkPayloadFilters(formData.B.filters)),
          ...(isFlowBuilder ? formUtils.toLinkNormalizedActions(formData.B.actions) : {}),
        },
        includeClosedTasks: isFlowBuilder
          ? formUtils.toClosedTasksFlowBuilder(formData.B.filters)
          : B.closedTasks || B.settings?.includeClosedTasks || false,
        readOnly: isReadOnlyB,
        ...(B.streamAttachments ? { streamAttachments: B.streamAttachments } : {}),
        ...(B.includePublicComments ? { includePublicComments: B.includePublicComments } : {}),
        ...(B.prefix ? { taskNumberPrefix: formUtils.toLinkPayloadPrefixes(B.prefixes) } : {}),
        ...(subFoldersB ? { subfolders: subFoldersB.value === 'included' } : {}),
        filtersInitializedAt: B.filtersInitializedAt,

        itemFieldAssociations: formUtils.getItemFieldAssociations(formData.B.itemFieldAssociations, itemFiltersB),
        onFilterOut: B.onFilterOut,
      },
    },
    rules: formData.rules,
    organizationId,
    // This small change just makes it easier for the tests, we used to send the immutable list directly
    // which worked but made it impractical to test payloads in tests.
    associations: isFlowBuilder ? formUtils.toLinkPayloadAssociations(formData.associations) : associations.toJS(),
    name: newName,
    isMultiSync: !!isCurrentMultiSync(state),
    kind: formData.kind ?? linkTypes.KIND.MIRROR_SYNC,
    source: linkTypes.SOURCE.CONSOLE,
    isAutoSync: !!isAutoSync,
    duplicateLinkId: duplicateLinkId || undefined,
    isTestMode: isFlowBuilder ? formUtils.toTestModeFlowBuilder(formData.A.filters || formData.B.filters) : isTestMode,
    testModeVariant,
    // TODO: new app sync form should also default these values in the new flow builder
    // this way we won't have to rely on whether it's part of a workflow or not to determine the values.
    ...(workflowId ? { originatorWorkflow: workflowId } : {}),
    skipFieldValuesAutomapping: hasWorkflowId,
    returnExistingLink: hasWorkflowId,
  };
};

export const formDataToLinkPayloadV2 = (state, formData = {}) => {
  const { A, B, name, workflowId, duplicateLinkId, isAutoSync, syncDirection } = formData;

  const organizationId = formData.organizationId ?? getSelectedOrganizationId(state);

  const sides = ['A', 'B'];

  const linkSides = {};

  const syncSettings = {
    automapUsers: formData.automapUsers,
    manualOptions: formData.manualOptions ? formData.manualOptions : {},
    fieldAssociations: formUtils.toLinkPayloadAssociations(formData.associations),
  };

  for (const side of sides) {
    const {
      containerId,
      containerDisplayName,
      containerUrl,
      containerPath,
      containerType,
      parentContainer,
      providerName,
      providerIdentityId,
      itemType,
      nodes,
      truthSide,
      filters,
      deepFilters,
      actions,
      streamAttachments,
      includePublicComments,
      prefix,
      prefixes,
      itemFieldAssociations,
      onFilterOut,
      filtersInitializedAt,
      merge,
    } = formData[side];

    // earliestCreatedAt is set at the top level of syncSettings, we'll need to derive its value
    // from any earliestCreatedAt filter on either side
    const earliestCreatedAtFilter = filters?.find((filter) => filter.fieldId === fieldTypes.EARLIEST_CREATED_AT);
    if (earliestCreatedAtFilter) {
      syncSettings.earliestCreatedAt = new Date(earliestCreatedAtFilter.value);
    }
    // build link container side

    // include containerId only if provided or if containerType hasn't been picked yet (work item selection change in drafts)
    // but exclude it otherwise, since this method is also used for real links.
    const container =
      containerId || !containerType
        ? { id: containerId, url: containerUrl, path: containerPath, displayName: containerDisplayName }
        : undefined;

    linkSides[side] = {
      providerName,
      providerIdentity: providerIdentityId,
      containerType,
      itemType,
      container,
      parentContainer: parentContainer
        ? {
            id: parentContainer.id,
            parentContainerType: parentContainer.parentContainerType,
          }
        : undefined,
      nodes: formUtils.stripMongooseIds(nodes || []),
    };

    // build syncSettings
    const isReadOnly = formUtils.toReadOnlyFlowBuilder(syncDirection, side);
    const itemFilters = formUtils.getItemFiltersForSideForFlowBuilder(filters);
    const subFolders = filters?.find((filter) => filter.fieldId === 'subfolders');
    syncSettings[side] = {
      merge: {
        fields: merge?.fields ?? null,
      },
      truthSide: truthSide === side,
      ...formUtils.toLinkPayloadFlowBuilderFilters(filters, deepFilters),
      ...formUtils.toLinkNormalizedActions(actions),
      includeClosedTasks: formUtils.toClosedTasksFlowBuilder(filters),
      readOnly: isReadOnly,
      ...(streamAttachments ? { streamAttachments } : {}),
      ...(includePublicComments ? { includePublicComments } : {}),
      ...(prefix ? { taskNumberPrefix: formUtils.toLinkPayloadPrefixes(prefixes) } : {}),
      ...(subFolders ? { subfolders: subFolders.value === 'included' } : {}),
      filtersInitializedAt,
      itemFieldAssociations: formUtils.getItemFieldAssociations(itemFieldAssociations, itemFilters),
      onFilterOut,
    };
  }

  // to be used as a master on/off switch during development time
  // When we're ready to deploy it to more people, force isActive to true here for that featureFlag
  const isModernRulesPageEnabled = getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_RULES_PAGE);
  const modernRules = formUtils.stripMongooseIds(formData.rules);
  if (isModernRulesPageEnabled) {
    modernRules.A.filters.isActive = true;
    modernRules.B.filters.isActive = true;
    modernRules.A.actionsActive = true;
    modernRules.B.actionsActive = true;
  }

  return {
    ...linkSides,
    name,
    state: formData.state,
    isSuspended: formData.isSuspended,
    isAutoSync: !!isAutoSync,
    kind: formData.kind ?? linkTypes.KIND.MIRROR_SYNC,
    source: linkTypes.SOURCE.CONSOLE,
    rules: modernRules,
    containerIds: [A.containerId, B.containerId],
    duplicateLinkId: duplicateLinkId || undefined,
    originatorWorkflow: workflowId,
    organization: organizationId,
    syncSettings,
  };
};

export const getFieldAssociations = (state, linkId) => fromLinks.getFieldAssociations(state.links, linkId);

export const isCurrentMultiSync = (state) => fromLinks.isMultiSync(state.links);

export const getLinkById = (state, linkId) => fromLinks.getLinkById(state.links, linkId);

export const getLinkState = (state, linkId) => fromLinks.getLinkState(state.links, linkId);

export const getLinkByProviderContainers = (state, sideA, sideB, excludedIds) =>
  fromLinks.getLinkByProviderContainers(state.links, sideA, sideB, excludedIds);

export const getActiveLinkByProviderContainers = (state, sideA, sideB, excludedIds) =>
  fromLinks.getLinkByProviderContainers(state.links, sideA, sideB, excludedIds, linkTypes.LINK_STATES.ACTIVE);

// we cant offer duplicate for flows with merge based items for now, so filtering them out
const filterByNonMergeBasedFlows = (state, flows = List()) =>
  flows.filter((flow) => {
    const providerIdentityA = flow.getIn(['A', 'providerIdentity', '_id']);
    const providerIdentityB = flow.getIn(['B', 'providerIdentity', '_id']);
    const itemTypeA = flow.getIn(['A', 'itemType']);
    const itemTypeB = flow.getIn(['B', 'itemType']);
    const itemA = getProviderCapabilitiesByProviderIdentityIdV3(state, {
      providerIdentityId: providerIdentityA,
      itemType: itemTypeA,
    });
    const itemB = getProviderCapabilitiesByProviderIdentityIdV3(state, {
      providerIdentityId: providerIdentityB,
      itemType: itemTypeB,
    });
    return !itemA.getIn(['item', 'canMerge'], false) && !itemB.getIn(['item', 'canMerge'], false);
  });

export const getDuplicatableLinks = (state, sideA, sideB, workflowId) => {
  const providerIdA = fromProviderIdentities
    .getById(state.providerIdentities, sideA.providerIdentityId)
    .get('providerId');
  const providerIdB = fromProviderIdentities
    .getById(state.providerIdentities, sideB.providerIdentityId)
    .get('providerId');

  const workflow = getWorkflowById(state, workflowId);
  const linkIds = workflow.get('linkIds');

  return filterByNonMergeBasedFlows(
    state,
    fromLinks.getDuplicatableLinks(state.links, sideA, sideB, providerIdA, providerIdB, linkIds),
  );
};

export const isDiagnosticLoading = (state) => fromLinks.isDiagnosticLoading(state.links);

export const getLatestDiagnostic = (state) => fromLinks.getDiagnosticResult(state.links);

export const getCustomFields = (state, { containerSide, providerName, itemType }) => {
  const customFields = fromFields.getCustomFields(state.fields, containerSide);

  if (!providerName || !itemType) {
    return customFields;
  }

  const customFieldsTypeDefinition = getCustomFieldsTypeDefinition(state, providerName, itemType);

  if (!customFieldsTypeDefinition) {
    // Even if we don't find the definition in the capabilities, we want to return the custom field in order
    // to not introduce any regression. Why would it happen you say? Well, we have pretty old code and some of
    // our capabilities don't respect the convention about custom field naming.
    return customFields;
  }

  return customFields.filter((customField) => {
    const nativeType = customField.get('nativeType');
    const customFieldTypeDefinition = customFieldsTypeDefinition.find(
      (typeDefinition, key) =>
        typeDefinition.get('names', Map()).get('native', '') === nativeType || key === nativeType,
    );

    return !customFieldTypeDefinition?.get('unsupported', false) && !customFieldTypeDefinition?.get('hidden', false);
  });
};

export const getNativeFields = (state, { providerName, containerId, itemType }) => {
  const provider = getProviderByName(state, providerName);

  if (isModernIntegration(state, { provider })) {
    const providerId = provider.get('_id');
    const capabilities = getCapabilitiesForItem(state, providerId, containerId, itemType);

    return capabilities.get('fields', Map());
  }

  return getProviderCapabilitiesByProviderNameV3(state, { providerName, itemType }).getIn(['item', 'fields'], Map());
};

export const isModernIntegration = (state, { provider }) => {
  const isContainerBuilderEnabled = !!getFeatureFlagValue(state, featureTypes.FEATURES.CONTAINER_BUILDER);

  return (
    isContainerBuilderEnabled && provider?.get('connectorName') === providerTypes.INTEGRATION_BRIDGE_CONNECTOR_NAME
  );
};

const getCustomFieldById = (state, containerSide, fieldId) =>
  getCustomFields(state, { containerSide }).get(fieldId, Map());

export const getFieldValues = (
  state,
  { containerSide, fieldId, isFilterField, kind, input = {} },
  sorted = false,
  includeHidden = false,
) => {
  let fieldValues = fromFields
    .getFieldValues(state.fields, kind, fieldId, containerSide)
    .filter((fieldValue) => includeHidden || !fieldValue.get('isHidden'));

  // Filter out other filter list, whiteList <=> blackList are mutually exclusive
  if (isFilterField) {
    const otherFieldName =
      input.name === `${fieldId}WhiteList${containerSide}`
        ? `${fieldId}BlackList${containerSide}`
        : `${fieldId}WhiteList${containerSide}`;

    const formFieldValue = getFieldValue(state, otherFieldName) || [];
    const valuesToExclude = formFieldValue || [];
    fieldValues = fieldValues.filterNot((fieldValue) => valuesToExclude.includes(fieldValue.get('id')));
  }

  if (fieldValues.isEmpty() || !sorted) {
    return fieldValues;
  }

  if (fieldValues.first().has('displayOrder')) {
    return fieldValues.sortBy((fv) => fv.get('displayOrder'));
  }

  return fieldValues.sortBy((fv) => fv.get('displayName', '').toLowerCase());
};

export const getFieldById = (state, { containerSide, fieldId, kind }) =>
  fromFields.getFieldById(state.fields, kind, fieldId, containerSide);

export function getFields(state, associations, providerIdentityIds, providerNames, itemTypes, containerIds) {
  return associations.map((association) => {
    const fieldA = getField(state, {
      containerSide: 'A',
      containerId: containerIds?.A,
      fieldId: association?.A?.field,
      kind: association?.A?.kind,
      providerIdentityId: providerIdentityIds.A,
      providerName: providerNames.A,
      itemType: itemTypes.A,
    });

    const fieldB = getField(state, {
      containerSide: 'B',
      containerId: containerIds?.B,
      fieldId: association?.B?.field,
      kind: association?.B?.kind,
      providerIdentityId: providerIdentityIds.B,
      providerName: providerNames.B,
      itemType: itemTypes.B,
    });

    return {
      association,
      fieldA,
      fieldB,
    };
  });
}

// TODO: refactor this method, we pass providerName just to get the `_id` of the provider. Pass providerId instead !
export function getField(state, { fieldId, kind, providerName, containerSide, containerId, itemType }) {
  const providerId = getProviderByName(state, providerName).get('_id');
  const providerCapabilities = getCapabilitiesForItem(state, providerId, containerId, itemType);

  // customFields from capabilities
  if (kind === fieldTypes.KINDS.CUSTOM_FIELD) {
    // TODO: Migrate to use containerId
    const customField = getCustomFieldById(state, containerSide, fieldId);
    return customField.isEmpty() ? customField : customField.set('kind', kind).set('fieldId', fieldId);
  }

  if (kind === PCD_COMMON) {
    // linkBlock is a "virtual" field of Unito, so we need to explicitly create it, as it doesn't belong in PCDv3
    return fromJS({
      fieldId: 'linkBlock',
      kind: 'pcdCommon',
      names: {
        native: 'linkBlock',
        singular: 'description footer',
        plural: 'description footers',
        help: 'Description footer',
      },
      readOnlyOnUpdate: true,
      semantic: null,
      type: 'linkBlock',
    });
  }

  const pcdFields = providerCapabilities.get('fields', Map());
  const field = pcdFields.get(fieldId, Map());
  return field.isEmpty() ? field : field.set('kind', fieldTypes.KINDS.PCD_TYPED_FIELD).set('fieldId', fieldId);
}

export function getFieldBySemantic(state, { semantic, providerName, containerId, itemType }) {
  const providerId = getProviderByName(state, providerName).get('_id');
  const providerCapabilities = getCapabilitiesForItem(state, providerId, containerId, itemType);

  const pcdFields = providerCapabilities.get('fields', Map());
  return pcdFields.find((pcdField) => pcdField.get('semantic') === semantic) ?? Map();
}

export const isContainerInMultiSync = (state, { containerSide, isEdit }) => {
  const container = getContainer(state, { containerSide });
  const minSize = isEdit ? 1 : 0;
  return container.get('syncedIn', List()).size > minSize;
};

export const getToken = (state) => fromAuth.getToken(state.auth);

export const getIsAuthenticated = (state) => fromAuth.getIsAuthenticated(state.auth);

export const isUserSiteAdmin = (state) => fromAuth.isSiteAdmin(state.auth);

export const getLoginProviderIdentity = (state) => fromAuth.getLoginProviderIdentity(state.auth);

export const getPlans = (state) => fromBilling.getPlans(state.billing);

export const getOrganizations = (state) => fromOrganizations.getOrganizations(state.organizations);

export const getSelectedOrganizationId = (state) => {
  const selectedOrganizationId = fromApp.getSelectedOrganizationId(state.app);
  // If no selected organization, retrieve the first one
  if (!selectedOrganizationId) {
    const firstOrganization = getNonExpiredOrFirstOrganization(state);
    return firstOrganization.get('_id');
  }

  return selectedOrganizationId;
};

export const getOrganizationById = (state, id) => fromOrganizations.getById(state.organizations, id);

export const getOrganizationHasFlows = (state, id) =>
  fromOrganizations.getOrganizationHasFlows(state.organizations, id);

export const getOrganizationHasWorkflows = (state, id) =>
  fromOrganizations.getOrganizationHasWorkflows(state.organizations, id);

export const getOrganizationPendingDraftId = (state, id) =>
  fromOrganizations.getOrganizationPendingDraftId(state.organizations, id);

const getOrganizationPlanId = (state, organizationId) =>
  fromOrganizations.getSelectedPlanId(state.organizations, organizationId);

export const getOrganizationSubscriptionId = (state, organizationId) =>
  fromOrganizations.getStripeSubscriptionId(state.organizations, organizationId);

export const getOrganizationCustomerId = (state, organizationId) =>
  fromOrganizations.getCustomerId(state.organizations, organizationId);

export const getIsCurrentPlanTaskSync = (state) => {
  const organizationId = getSelectedOrganizationId(state);
  const planId = getOrganizationPlanId(state, organizationId);

  return planId.includes(billingTypes.PLAN_TYPES.TASK_SYNC);
};

export const hasOrganizationTrelloTeamSetup = (state) => {
  const organizationId = getSelectedOrganizationId(state);
  const organization = getOrganizationById(state, organizationId);
  const trelloTeams = organization.getIn(['partnerInfos', 'trello'], Map());
  const hasTrelloTeamSetup = trelloTeams instanceof Map && !trelloTeams.isEmpty();
  return hasTrelloTeamSetup;
};

export const isOnFreeTrial = (state, organizationId) =>
  fromOrganizations.isOnFreeTrial(state.organizations, organizationId);

export const isOnFreeWithWrikePlan = (state, organizationId) =>
  fromOrganizations.isOnFreeWithWrikePlan(state.organizations, organizationId);

export const isOnCustomPlan = (state, organizationId) =>
  fromOrganizations.isOnCustomPlan(state.organizations, organizationId);

export const isEnterpriseAccount = (state, organizationId) =>
  fromOrganizations.isEnterpriseAccount(state.organizations, organizationId);

export const isVipAccount = (state, organizationId) =>
  fromOrganizations.isVipAccount(state.organizations, organizationId);

export const isOnLegacyPlan = (state, currentSubscription, organizationId) => {
  const organization = getOrganizationById(state, organizationId);
  const visiblePlans = getPlans(state);

  const isLegacy =
    !currentSubscription.get('cancelAtPeriodEnd') &&
    !isOnFreeTrial(state, organizationId) &&
    !isOnFreeWithWrikePlan(state, organizationId) &&
    !isOnCustomPlan(state, organizationId) &&
    !isOrganizationAccountPaused(state, organizationId) &&
    !visiblePlans.has(organization.get('planId'));
  return isLegacy;
};

export const isOnMirrorLegacy = (state, organizationId) =>
  fromOrganizations.isOnMirrorLegacy(state.organizations, organizationId);

export const isOrganizationAccountScheduledToPause = (state, organizationId) => {
  const selectedOrganizationId = organizationId || getSelectedOrganizationId(state);
  const subscriptionId = getOrganizationSubscriptionId(state, selectedOrganizationId);
  const organization = getOrganizationById(state, selectedOrganizationId);

  return (
    organization.get('status') !== organizationTypes.STATUSES.PAUSED &&
    fromBilling.isSubscriptionScheduledToPause(state.billing, subscriptionId)
  );
};

export const getPausedDate = (state, organizationId) => {
  const selectedOrganizationId = organizationId || getSelectedOrganizationId(state);
  const subscriptionId = getOrganizationSubscriptionId(state, selectedOrganizationId);

  return fromBilling.getPausedDate(state.billing, subscriptionId);
};

export const isOrganizationAccountPaused = (state, organizationId) => {
  const selectedOrganizationId = organizationId || getSelectedOrganizationId(state);
  return fromOrganizations.isPaused(state.organizations, selectedOrganizationId);
};

export const isUsageLoadingByOrganizationId = (state, organizationId) =>
  fromOrganizations.getUsageLoadingByOrganizationId(state.organizations, organizationId);

export const getUsageErrorByOrganizationId = (state, organizationId) =>
  fromOrganizations.getUsageErrorByOrganizationId(state.organizations, organizationId);

export const isOrganizationOverFeatureLimit = (state, organizationId, featureId) => {
  const currentPlan = fromOrganizations.getCurrentOrganizationPlan(state.organizations, organizationId);
  const planFeatureMax = currentPlan.getIn(['features', featureId, 'limit']);

  const orgUsage = getUsageByOrganizationId(state, organizationId);
  const featureUsage = orgUsage.getIn([featureId, 'usage']);

  return planFeatureMax === featureTypes.UNLIMITED ? false : featureUsage > Number(planFeatureMax);
};

export const getDailyItemsKeptInSyncByOrganizationId = (state, organizationId) =>
  fromOrganizations.getDailyActivityByType(
    state.organizations,
    organizationId,
    organizationTypes.DAILY_ACTIVITY_TYPE.COUNT_ITEMS_KEPT_IN_SYNC,
  );

export const getDistinctDailyItemsKeptInSyncByOrganizationId = (state, organizationId) =>
  fromOrganizations.getDailyActivityByType(
    state.organizations,
    organizationId,
    organizationTypes.DAILY_ACTIVITY_TYPE.COUNT_DISTINCT_ITEMS_KEPT_IN_SYNC,
  );

export const getCountItemsPerItemTypePerContainer = (state, organizationId) =>
  fromOrganizations.getCountItemsPerItemTypePerContainer(state.organizations, organizationId);

export const getProviderNameFromContainer = (state, organizationId) =>
  fromOrganizations.getProviderNameFromContainer(state.organizations, organizationId);

export const getItemsKeptInSyncLastRefresh = (state, organizationId) => {
  const dailyEntries = fromOrganizations.getDailyActivityByType(
    state.organizations,
    organizationId,
    organizationTypes.DAILY_ACTIVITY_TYPE.LAST_REFRESHED_AT,
  );
  // Return the y value of the last item of the List (i.e the last refresh date of today)
  const lastEntry = dailyEntries.last();
  return lastEntry?.y;
};

export const getCurrentItemsKeptInSyncByOrganizationId = (state, organizationId) => {
  const dailyEntries = fromOrganizations.getDailyActivityByType(
    state.organizations,
    organizationId,
    organizationTypes.DAILY_ACTIVITY_TYPE.COUNT_ITEMS_KEPT_IN_SYNC,
  );
  // Return the y value of the last item of the List (i.e the item of today)
  const lastEntry = dailyEntries.last();
  return lastEntry?.y ?? 0;
};

export const getDistinctCurrentItemsKeptInSyncByOrganizationId = (state, organizationId) => {
  const dailyEntries = fromOrganizations.getDailyActivityByType(
    state.organizations,
    organizationId,
    organizationTypes.DAILY_ACTIVITY_TYPE.COUNT_DISTINCT_ITEMS_KEPT_IN_SYNC,
  );
  // Return the y value of the last item of the List (i.e the item of today)
  const lastEntry = dailyEntries.last();
  return lastEntry?.y ?? 0;
};

export const getChangesSyncedLast30dByOrganizationId = (state, organizationId) => {
  const dailyEntries = fromOrganizations.getDailyActivityByType(
    state.organizations,
    organizationId,
    organizationTypes.DAILY_ACTIVITY_TYPE.COUNT_CHANGES_SYNCED_30D,
  );
  // Return the y value of the last item of the List (i.e the item of today)
  const lastEntry = dailyEntries.last();
  return lastEntry?.y ?? 0;
};

export const getActiveContainers = (state) => fromContainers.getActiveContainers(state.containers);

export const getSyncsWithItemsKeptInSync = (state, organizationId) => {
  const allLinks = getAllLinks(state);
  let itemsPerLink = fromOrganizations.getItemsPerLinkByOrganizationId(state, organizationId);

  // in production filter out links that are not found (deleted)
  // keep them in dev/staging for when we use the data coming from our production env (by using the UNITO_SNOWFLAKE_DISABLE_MOCK envvar)
  // since we show UNITO internal org data in dev/staging, we skip the link filtering, otherwise we would never see any data.
  if (process.env.NODE_ENV === 'production') {
    itemsPerLink = itemsPerLink.filter((v, k) => allLinks.get(k));
  }

  return itemsPerLink
    .mapEntries(([k, v], index) => [
      k,
      Map({
        _id: k,
        itemsCount: v,
        name: allLinks.getIn([k, 'name']) || `Link #${index} ( ${k} )`,
        containerA: allLinks.getIn([k, 'A', 'container']) || Map(),
        containerB: allLinks.getIn([k, 'B', 'container']) || Map(),
        providerA: getProviderByName(state, allLinks.getIn([k, 'A', 'providerName'])),
        providerB: getProviderByName(state, allLinks.getIn([k, 'B', 'providerName'])),
        readOnlyA: !!allLinks.getIn([k, 'syncSettings', 'A', 'readOnly']),
        readOnlyB: !!allLinks.getIn([k, 'syncSettings', 'B', 'readOnly']),
      }),
    ])
    .sortBy((v) => -v.get('itemsCount'))
    .toList();
};

export const getSyncsWithItemsKeptInSyncByLinkId = (state, organizationId, linkId) => {
  const allSyncs = getSyncsWithItemsKeptInSync(state, organizationId);
  return allSyncs.find((sync) => sync.get('_id') === linkId);
};

export const getMetricsByOrganizationId = (state, organizationId) =>
  fromOrganizations.getMetrics(state.organizations, organizationId);

export const getReportUrlByOrganizationId = (state, organizationId, reportId) =>
  fromOrganizations.getReportUrl(state.organizations, organizationId, reportId);

export const getUsageByOrganizationId = (state, orgId) => {
  const organizationId = orgId || getSelectedOrganizationId(state);
  return fromOrganizations.getUsage(state.organizations, organizationId);
};

// TODO remove me in favour of getOrganizationPlanFeatures, this is a legacy way of retrieving the current plan.
export const getCurrentPlan = (state, currentSubscription) => {
  const currentPlanId = currentSubscription.getIn(['plan', 'id']);
  return fromBilling.getPlanById(state.billing, currentPlanId);
};

// This method returns features for a plan specifically tied to an organization
export const getOrganizationPlanProfile = (state, organizationId) => {
  const orgId = organizationId || getSelectedOrganizationId(state);
  return fromOrganizations.getCurrentOrganizationPlan(state.organizations, orgId);
};

export const getOrganizationPlanFeaturesWithUsage = (state, orgId) => {
  const organizationId = orgId || getSelectedOrganizationId(state);
  const organizationUsage = getUsageByOrganizationId(state, organizationId);
  const organizationFeatures = getOrganizationPlanProfile(state, organizationId).get('features', List());
  const isEmbed = !!getEmbedName(state);

  if (!isEmbed) {
    return organizationFeatures.mergeDeep(organizationUsage);
  }

  // Workflows aren't available in embed yet™
  return organizationFeatures
    .mergeDeep(organizationUsage)
    .filter((featureWithUsage) => featureWithUsage.get('id') !== 'workflows');
};

export const getMaxUsageFeatureToDisplay = (state, orgId) => {
  // TODO: This is not viable on the long term, find a way to have Bouncer drive this.
  // Add a new property to the plan-variant flag payload ?
  // Or should we just rely on the items-kept-in-sync-page flag ?
  const featureId = ['20211101', '20230501'].includes(getFeatureFlagValue(state, 'plans-pricing-variant'))
    ? featureTypes.FEATURES.MAX_ITEMS_KEPT_IN_SYNC
    : featureTypes.FEATURES.MAX_USERS;

  const featuresWithUsage = getOrganizationPlanFeaturesWithUsage(state, orgId);
  return featuresWithUsage.get(featureId) || Map();
};

export const getPlanProfile = (state, planId) => fromBilling.getPlanById(state.billing, planId);

// TODO delete this + add new test instead;
export const isMirrorPowerUpShown = (state, embedName) =>
  isUserConnectedToTrello(state) &&
  // We do this because we don't want to show it if embedName is an embed tool.
  // We do want to show it on standalone for the CS team, that's why we do this instead of just
  // comparing embedName === appTypes.EMBED.TRELLO .
  !Object.values(appTypes.EMBED)
    .filter((toolName) => toolName !== appTypes.EMBED.TRELLO)
    .includes(embedName);

export const getTaskSyncsTaskCount = (state, organizationId) => {
  const usage = fromOrganizations.getUsage(state.organizations, organizationId);
  return usage.getIn([FEATURES.MAX_MIRROR_SYNCS, 'usage'], 0);
};

export const isOrganizationWorkflowLimitReached = (state, organizationId) => {
  const usage = fromOrganizations.getUsage(state.organizations, organizationId);
  const nonDeletedWorkflowCount = usage.getIn([FEATURES.WORKFLOWS, 'usage'], 0);
  const currentOrganizationPlan = fromOrganizations.getCurrentOrganizationPlan(state.organizations, organizationId);
  const features = currentOrganizationPlan.get('features', List());
  const workflowFeature = features.find((feature) => feature.get('id') === FEATURES.WORKFLOWS);
  const workflowLimit = workflowFeature?.get('limit');
  return nonDeletedWorkflowCount >= workflowLimit;
};

export const getOrganizationSendEmailReceiptChoice = (state, organizationId) =>
  fromOrganizations.getOrganizationSendEmailReceiptChoice(state.organizations, organizationId);

export const getNonExpiredOrFirstOrganization = (state) =>
  fromOrganizations.getNonExpiredOrFirstOrganization(state.organizations);

export const getNonExpiredOrFirstOrganizationId = (state) =>
  fromOrganizations.getNonExpiredOrFirstOrganizationId(state.organizations);

export const getOrganizationName = (state, organizationId) =>
  fromOrganizations.getName(state.organizations, organizationId);

export const getOrganizationBillingEmail = (state, organizationId) =>
  fromOrganizations.getBillingEmail(state.organizations, organizationId);

export const getCollaboratorsStatsByOrgId = (state, organizationId) =>
  fromOrganizations.getCollaboratorsStatsByOrgId(state.organizations, organizationId);

const getOrganizationStatus = (state, organizationId) =>
  fromOrganizations.getOrganizationStatus(state.organizations, organizationId);

export const getOrganizationTraits = (state, organizationId) =>
  fromOrganizations.getOrganizationTraits(state.organizations, organizationId);

export const isOrganizationTrialExpired = (state, organizationId) => {
  const paymentStatus = getOrganizationStatus(state, organizationId);
  const { TRIAL_EXPIRED } = organizationTypes.STATUSES;
  return paymentStatus === TRIAL_EXPIRED;
};

export const isOrganizationChurned = (state, organizationId) => {
  const orgStatus = getOrganizationStatus(state, organizationId);
  return orgStatus === organizationTypes.STATUSES.CHURNED;
};

export const isOrganizationResold = (state, organizationId) => {
  const orgStatus = getOrganizationStatus(state, organizationId);
  return orgStatus === organizationTypes.STATUSES.RESOLD;
};

export const isOrganizationDelinquent = (state, organizationId) => {
  const paymentStatus = getOrganizationStatus(state, organizationId);
  const { DELINQUENT } = organizationTypes.STATUSES;
  return paymentStatus === DELINQUENT;
};

export const isOrganizationAccountCanceled = (state, organizationId) => {
  const paymentStatus = getOrganizationStatus(state, organizationId);
  const { CANCELED } = organizationTypes.STATUSES;
  return paymentStatus === CANCELED;
};

export const isOrganizationAccountSuspended = (state, givenOrganizationId) => {
  const organizationId = givenOrganizationId || getSelectedOrganizationId(state);
  const paymentStatus = getOrganizationStatus(state, organizationId);
  const { CHURNED, EXPIRED } = organizationTypes.STATUSES;
  return [CHURNED, EXPIRED].includes(paymentStatus);
};

export const isOrganizationAccountPaying = (state, organizationId) => {
  const paymentStatus = getOrganizationStatus(state, organizationId);
  const { PAYING } = organizationTypes.STATUSES;
  return paymentStatus === PAYING;
};

export const getOrganizationPartnerInfosAccountIds = (state, organizationId, providerName) => {
  const organization = getOrganizationById(state, organizationId);
  return organization.getIn(['partnerInfos', providerName], Map());
};

export const getFeatureFlagValue = (state, flagName, orgId) => {
  const organizationId = orgId || getSelectedOrganizationId(state) || organizationTypes.LD_ANONYMOUS_ORGANIZATION_ID;
  return fromOrganizations.getFlagValue(state.organizations, organizationId, flagName);
};

export const getFlags = (state, orgId) => {
  const organizationId = orgId || getSelectedOrganizationId(state) || organizationTypes.LD_ANONYMOUS_ORGANIZATION_ID;
  return fromOrganizations.getAllFlags(state.organizations, organizationId);
};

export const organizationNeedsPayment = (state, organizationId) =>
  isOrganizationAccountSuspended(state, organizationId) ||
  isOrganizationTrialExpired(state, organizationId) ||
  isOrganizationAccountPaused(state, organizationId);

const getCustomerById = (state, customerId) => fromBilling.getCustomerById(state.billing, customerId);

export const customerHasPaymentSource = (state, customerId) => {
  const customer = getCustomerById(state, customerId);
  return !!customer.get('defaultSource');
};

export const getCustomerDefaultPaymentSource = (state, customerId) =>
  fromBilling.getCustomerDefaultPaymentSource(state.billing, customerId);

export const getCustomerInvoices = (state, customerId) => fromBilling.getCustomerInvoices(state.billing, customerId);

export const getActiveCoupon = (state, customerId, organizationId) => {
  const subscriptionId = getOrganizationSubscriptionId(state, organizationId);
  const subscriptionCoupon = fromBilling.getSubscriptionCoupon(state.billing, subscriptionId);
  const customerCoupon = fromBilling.getCustomerCoupon(state.billing, customerId);
  // Subscription coupon wins over customer coupon
  return subscriptionCoupon || customerCoupon || new Map();
};

export const getOrganizationCustomer = (state, organizationId) => {
  const customerId = getOrganizationCustomerId(state, organizationId);
  return getCustomerById(state, customerId);
};

export const getOrganizationSubscription = (state, organizationId) => {
  const subscriptionId = getOrganizationSubscriptionId(state, organizationId);
  return fromBilling.getSubscriptionById(state.billing, subscriptionId);
};

export const organizationHasActiveSubscription = (state, organizationId) => {
  const planId = getOrganizationPlanId(state, organizationId);
  const currentSubscription = getOrganizationSubscription(state, organizationId);
  // FIXME: This should be returned by the getConfig call, so we get the real names of
  // env vars UNITO_STRIPE_DEFAULT_PLAN_ID and UNITO_STRIPE_EMBED_WRIKE_PLAN_ID
  return !currentSubscription.isEmpty() && !['reseller-wrike', 'trial'].includes(planId);
};

export const getOrganizationMembers = (state, organizationId) =>
  fromOrganizations.getMembersByOrgId(state.organizations, organizationId);

export const getOrganizationRoles = (state, organizationId) =>
  fromOrganizations.getOrganizationRoles(state.organizations, organizationId);

export const getOrganizationCoworkers = (state, organizationId) =>
  fromOrganizations.getCoworkersByOrgId(state.organizations, organizationId);

export const getSortedOrganizationMembers = (state, organizationId) => {
  const members = getOrganizationMembers(state, organizationId);
  return members.sortBy((m) => m.getIn(['user', 'fullName'], '').toLowerCase());
};

export const getSortedOrganizationMembersPlusInvites = (state, organizationId) => {
  const members = getOrganizationMembers(state, organizationId);
  const cInvites = getInvitesGroupedByEmail(state);

  const filteredInvites = cInvites.filter((invite) => {
    const isPending = invite.get('state') === inviteTypes.STATES.PENDING;
    const validUntil = moment(invite.get('validUntil'));
    const isExpired = validUntil < moment.now();
    return isPending && !isExpired;
  });

  const concatMembers = members.concat(filteredInvites);
  return concatMembers.sortBy((m) => m.getIn(['user', 'fullName'], '').toLowerCase() || m.get('email'));
};

export const getSortedOrganizationCoworkers = (state, organizationId) => {
  const coworkers = getOrganizationCoworkers(state, organizationId);
  return coworkers.sortBy((c) => c.get('fullName', '').toLowerCase());
};

export const getIsLoadingInvites = (state) => fromInvites.getIsLoading(state.invites);

export const getIsLoadedInvites = (state) => fromInvites.getIsLoaded(state.invites);

export const getInvitesGroupedByUser = (state) => fromInvites.getInvitesGroupedByUser(state.invites);

export const getInvitesGroupedByEmail = (state) => fromInvites.getInvitesGroupedByEmail(state.invites);

export const getUserEmail = (state) => fromAuth.getUserEmail(state.auth);

export const getUserPendingInvite = (state) => {
  const userId = getUserId(state);
  const invite = fromInvites.getInviteByUserId(state.invites, userId);
  return fromInvites.isInvitePending(invite) ? invite : Map();
};

export const getUserKeepInformed = (state) => fromAuth.getKeepInformed(state.auth);

export const getUserAccountCreationDate = (state) => fromAuth.getUserAccountCreationDate(state.auth);

export const isSavingSync = (state) => fromLinks.isSavingSync(state.links);

export const isLoadedLinks = (state) => fromLinks.isLoaded(state.links);

export const isSideReadOnly = (state, { containerSide }) =>
  getContainerFieldValue(state, { containerSide }, 'readOnly');

export const isLoadedUsers = (state) => fromFields.isLoadedUsers(state.fields);

export const isLoadedProviderIdentities = (state) => fromProviderIdentities.isLoaded(state.providerIdentities);

export const areCustomFieldsLoaded = (state, { containerSide }) =>
  fromFields.areCustomFieldsLoaded(state.fields, { containerSide });

export const isLoadingProviderIdentities = (state) =>
  fromProviderIdentities.isAllowedProviderIdentityIdsLoading(state.providerIdentities);

export const isLoadingFieldValue = (state, containerSide, fieldId) =>
  fromFields.isLoadingFieldValue(state.fields, containerSide, fieldId);

export const isLoadingFieldValues = (state) => fromFields.isLoadingFieldValues(state.fields);

export const getIsCustomFieldsLoading = (state) => fromFields.getIsCustomFieldsLoading(state.fields);

export const getEmbedName = (state) => fromApp.getEmbedName(state.app);

export const isInProviderEmbed = (state, embedProvider) => getEmbedName(state) === embedProvider;

export const getClientVersion = (state) => fromApp.getClientVersion(state.app);

export const getDefaultParamContainerId = (state, side) => fromApp.getDefaultParamContainerId(state.app, side);

export const getIsLoadingApp = (state) => fromApp.getIsLoading(state.app);

export const getSessionId = (state) => fromApp.getSessionId(state.app);

export const getSortedLinks = (state) => fromLinks.getSortedLinks(state.links);

export const getUserProviderIdentities = (state, includeProviderShowOnlyOnAuthentication = true) => {
  const userId = getUserId(state);
  const allPIs = fromProviderIdentities.getUserProviderIdentities(state.providerIdentities, userId);
  if (!includeProviderShowOnlyOnAuthentication) {
    const canConnectProviders = getProvidersThatCanConnect(state);
    return allPIs.filter((pi) => !!canConnectProviders.get(pi.get('providerId')));
  }

  return allPIs;
};

export const getProviderIdentitiesByIds = (state, providerIdentitiesIds) =>
  fromProviderIdentities.getByIds(state.providerIdentities, providerIdentitiesIds);

export const getProviderIdentityById = (state, providerIdentityId) =>
  fromProviderIdentities.getById(state.providerIdentities, providerIdentityId);

export const getIsProviderIdentitiesLoading = (state) => fromProviderIdentities.isLoading(state.providerIdentities);
export const getIsProviderIdentitiesLoaded = (state) => fromProviderIdentities.isLoaded(state.providerIdentities);
export const hasProviderIdentitiesError = (state) => fromProviderIdentities.hasError(state.providerIdentities);
export const getProviderIdentitiesErrors = (state) => fromProviderIdentities.getAllErrors(state.providerIdentities);

export const getProviderIdentityProviderName = (state, providerIdentityId) =>
  fromProviderIdentities.getProviderName(state.providerIdentities, providerIdentityId);

export const getProviderCredentialsById = (state, providerIdentityId) =>
  fromProviderIdentities.getProviderCredentialsById(state.providerIdentities, providerIdentityId);

// [DEPRECATED] we should not use this function because it causes problems for site-admin users browsing
// customers' flows. This is because we don't load in the customers' providerIdentities so doing
// providerIdentity.get('providerName') will yield undefined and we'll return empty capabilities.
export const getProviderCapabilitiesByProviderIdentityId = (state, { providerIdentityId }, key) => {
  const providerIdentity = getProviderIdentityById(state, providerIdentityId);
  const providerName = providerIdentity.get('providerName');
  const provider = fromProviders.getProviderByName(state.providers, providerName);
  return fromProviders.getCapabilitiesByProviderId(state.providers, provider.get('_id'), key);
};

export const getProviderCapabilitiesByProviderName = (state, providerName, key) => {
  const provider = fromProviders.getProviderByName(state.providers, providerName);
  return fromProviders.getCapabilitiesByProviderId(state.providers, provider.get('_id'), key);
};

// TODO check whether we should pass through getProviderCapabilitiesByProviderIdentityIdV3 for non siteadmin users
export const getProviderCapabilitiesV3 = (state, { providerName, itemType }) =>
  getProviderCapabilitiesByProviderNameV3(state, { providerName, itemType });

export const getProviderCapabilitiesByProviderNameV3 = (state, { providerName, itemType }) => {
  const provider = fromProviders.getProviderByName(state.providers, providerName);

  return fromProviders.getCapabilitiesByProviderIdV3(state.providers, provider.get('_id'), itemType);
};

export const getProviderCapabilitiesByProviderIdentityIdV3 = (state, { providerIdentityId, itemType }) => {
  const providerIdentity = getProviderIdentityById(state, providerIdentityId);
  const providerName = providerIdentity.get('providerName');
  const provider = fromProviders.getProviderByName(state.providers, providerName);
  return fromProviders.getCapabilitiesByProviderIdV3(state.providers, provider.get('_id'), itemType);
};

export const getProvidersDefaultItemAndContainerTypes = (state) =>
  fromProviders.getProvidersDefaultItemAndContainerTypes(state.providers);

export const getInitialContainerId = (state, { containerSide }) => {
  const containerId = fromApp.getDefaultParamContainerId(state.app, containerSide);
  return containerId || getContainerFieldValue(state, { containerSide }, 'containerId');
};

export const getInitialNewContainerName = (state, { containerSide }) => {
  const otherSide = containerSide === 'A' ? 'B' : 'A';
  const existingContainer = getContainerFieldValue(state, { containerSide }, 'existingContainer');
  if (existingContainer) {
    return undefined;
  }

  const existingContainerOtherSide = getContainerFieldValue(state, { containerSide: otherSide }, 'existingContainer');
  const providerIdA = getContainerFieldValue(state, { containerSide }, 'providerId');
  const providerIdB = getContainerFieldValue(state, { containerSide: otherSide }, 'providerId');
  const isSameProvider = providerIdA === providerIdB;

  // A NEW container will take the name of the EXISTING one on the other side.
  // If both have the same provider, a ' Sync' suffix will be added.
  if (existingContainerOtherSide) {
    const containerOtherSide = getContainer(state, { containerSide: otherSide });
    const defaultDisplayName = containerOtherSide.get('displayName', 'Unito');
    return isSameProvider ? `${defaultDisplayName} Sync` : defaultDisplayName;
  }

  // Both containers are NEW
  return 'Unito Block';
};

// If the draft has been completed in the last 10s, we can assume that this is the initial sync
// Since at link creation we don't have any syncStatus yet (when we land on the link page), we set
// `initial` by default if this is an autoSync link.
export const getIsNewLinkWithAutoSync = (state, linkId) => {
  const link = getLinkById(state, linkId);
  const linkDraftCompletedAtDate = new Date(link.get('draftCompletedAt'));
  const nowMinus10secDate = new Date(Date.now() - 10000);
  return linkDraftCompletedAtDate > nowMinus10secDate && link.get('isAutoSync');
};

const getDefaultLinkName = (state, { providerNameA, providerNameB, itemTypeA, itemTypeB }) => {
  const isExistingA = getContainerFieldValue(state, { containerSide: 'A' }, 'existingContainer');
  const isExistingB = getContainerFieldValue(state, { containerSide: 'B' }, 'existingContainer');

  const displayNameA = isExistingA
    ? getContainer(state, { containerSide: 'A' }).get('displayName')
    : getContainerFieldValue(state, { containerSide: 'A' }, 'newContainerName');

  const displayNameB = isExistingB
    ? getContainer(state, { containerSide: 'B' }).get('displayName')
    : getContainerFieldValue(state, { containerSide: 'B' }, 'newContainerName');

  const readOnlyA = getContainerFieldValue(state, { containerSide: 'A' }, 'readOnly');
  const readOnlyB = getContainerFieldValue(state, { containerSide: 'B' }, 'readOnly');

  let providerItemTermA;
  let providerItemTermB;
  let providerDisplayNameA;
  let providerDisplayNameB;
  if (providerNameA && itemTypeA) {
    const providerA = getProviderByName(state, providerNameA);
    providerDisplayNameA = providerA.get('displayName');
    const itemA = getProviderCapabilitiesByIdV3(state, providerA.get('_id'), itemTypeA);
    providerItemTermA = itemA.getIn(['item', 'names', 'plural']);
  }
  if (providerNameB && itemTypeB) {
    const providerB = getProviderByName(state, providerNameB);
    providerDisplayNameB = providerB.get('displayName');
    const itemB = getProviderCapabilitiesByIdV3(state, providerB.get('_id'), itemTypeB);
    providerItemTermB = itemB.getIn(['item', 'names', 'plural']);
  }

  return (
    formUtils.generateDefaultLinkName({
      providerDisplayNameA,
      providerDisplayNameB,
      containerNameA: displayNameA,
      containerNameB: displayNameB,
      readOnlyA,
      readOnlyB,
      providerItemTermA,
      providerItemTermB,
    }) || 'New flow'
  );
};

const getFormItemTerms = (state, formData) => {
  let providerItemTermA;
  let providerItemTermB;

  if (formData.A.providerName && formData.A.itemType) {
    const providerA = getProviderByName(state, formData.A.providerName);
    let itemA;
    if (formData.A.containerId) {
      itemA = getCapabilitiesForItem(state, providerA.get('_id'), formData.A.containerId, formData.A.itemType);
      // sadly the new capabilitiesForItem doesn't have 100% the same structure as our classic PCD
      // fetch from the correct place here.
      providerItemTermA = itemA.getIn(['names', 'plural']);
    } else {
      // For classic connectors we can return the providerTerm earlier than usual
      // when we don't have a containerId yet, for drafts saving purposes we can still save the draft as
      // e.g.: Asana Tasks, rather than just Asana because the term is already known before the container is chosen
      itemA = getProviderCapabilitiesByIdV3(state, providerA.get('_id'), formData.A.itemType);
      providerItemTermA = itemA.getIn(['item', 'names', 'plural']);
    }
  }
  if (formData.B.providerName && formData.B.itemType) {
    const providerB = getProviderByName(state, formData.B.providerName);
    let itemB;
    if (formData.B.containerId) {
      itemB = getCapabilitiesForItem(state, providerB.get('_id'), formData.B.containerId, formData.B.itemType);
      providerItemTermB = itemB.getIn(['names', 'plural']);
    } else {
      itemB = getProviderCapabilitiesByIdV3(state, providerB.get('_id'), formData.B.itemType);
      providerItemTermB = itemB.getIn(['item', 'names', 'plural']);
    }
  }

  return [providerItemTermA, providerItemTermB];
};

export const getDefaultDraftName = (state, formData) => {
  const containerADisplayName = getContainerById(state, formData.A.providerIdentityId, formData.A.containerId).get(
    'displayName',
  );
  const containerBDisplayName = getContainerById(state, formData.B.providerIdentityId, formData.B.containerId).get(
    'displayName',
  );

  const providerA = getProviderByName(state, formData.A.providerName);
  const providerB = getProviderByName(state, formData.B.providerName);

  const isReadOnlyA = formUtils.toReadOnlyFlowBuilder(formData.syncDirection, 'A');
  const isReadOnlyB = formUtils.toReadOnlyFlowBuilder(formData.syncDirection, 'B');

  const [providerItemTermA, providerItemTermB] = getFormItemTerms(state, formData);

  return formUtils.generateDefaultLinkName({
    providerDisplayNameA: providerA.get('displayName'),
    providerDisplayNameB: providerB.get('displayName'),
    containerNameA: containerADisplayName,
    containerNameB: containerBDisplayName,
    readOnlyA: isReadOnlyA,
    readOnlyB: isReadOnlyB,
    providerItemTermA,
    providerItemTermB,
  });
};

export const getProviderIdentityByProfileId = (state, profileId, providerId) =>
  fromProviderIdentities.getProviderIdentityByProfileId(state.providerIdentities, profileId, providerId);

export const isLoadingContainers = (state, providerIdentityId) =>
  fromContainers.isLoading(state.containers, providerIdentityId);

export const isLoadingContainer = (state, containerId) =>
  fromContainers.isContainerLoading(state.containers, containerId);

export const getAllLinks = (state) => fromLinks.getLinks(state.links);

export const getAllLinkBySelectedOrganizationId = (state) => {
  const organizationId = getSelectedOrganizationId(state);
  const allLinks = fromLinks.getLinks(state.links);

  return allLinks.filter(
    (link) => link.get('organization') === organizationId || link.getIn(['organization', '_id']) === organizationId,
  );
};
export const getLinksByLinkIds = (state, linkIds) => fromLinks.getLinksByIds(state, linkIds);

// TODO - remove those, multisync is going away
export const getAllSyncsWithoutMultisync = (state) => fromLinks.getAllSyncsWithoutMultisync(state.links);
export const getSortedSyncsWithMultiSyncs = (state) => fromLinks.getSortedSyncsWithMultiSyncs(state.links);

export const getTaskTermForProvider = (state, providerId, plurality) =>
  fromProviders.getTaskTermForProvider(state.providers, providerId, plurality);

export const getAreProviderCapabilitiesForItemsLoaded = (
  state,
  providerIdA,
  providerIdB,
  containerIdA,
  containerIdB,
  itemTypeA,
  itemTypeB,
) =>
  fromProviders.getAreProviderCapabilitiesForItemsLoaded(
    state.providers,
    providerIdA,
    providerIdB,
    containerIdA,
    containerIdB,
    itemTypeA,
    itemTypeB,
  );

export const getTermForProviders = (state, providerIdA, providerIdB, term, plurality) =>
  fromProviders.getTermForProviders(state.providers, providerIdA, providerIdB, term, plurality);

export const getProviderByNamePreferredAuthMethod = (state, providerId) =>
  fromProviders.getByNamePreferredAuthMethod(state.providers, providerId);

export const isProviderItemCustomFieldBased = (state, providerName, itemType) =>
  fromProviders.isCustomFieldsBased(state.providers, providerName, itemType);

export const getContextSource = (state) => {
  const embedName = getEmbedName(state);
  const isEmbed = !!embedName;
  return isEmbed ? `embed-${embedName}` : 'standalone';
};

export const getShowInviteCoworkersModal = (state) => fromInvites.getShowInviteCoworkersModal(state.invites);

export const getInviteWorkspaceName = (state) => fromInvites.getInviteWorkspaceName(state.invites);

export const getUnitoIdentitiesByIds = (state, unitoIdentityIds) =>
  List(unitoIdentityIds.map((unitoIdentityId) => fromUnitoIdentities.getById(state.unitoIdentities, unitoIdentityId)));

export const getUnitoIdentityById = (state, unitoIdentityId) =>
  fromUnitoIdentities.getById(state.unitoIdentities, unitoIdentityId);

export function getUnitoIdentitiesByOrganizationId(state, organizationId) {
  const entityIds = fromUnitoIdentities.getByOrganizationId(state.unitoIdentities, organizationId);
  const unsortedUnitoIdentities = entityIds.map((id) => {
    const unitoIdentity = getUnitoIdentityById(state, id);
    const populatedProviderIdentities = unitoIdentity
      .get('providerIdentities')
      .map((providerIdentityId) => getProviderIdentityById(state, providerIdentityId));
    return unitoIdentity.mergeIn(['providerIdentities'], populatedProviderIdentities);
  });

  return unsortedUnitoIdentities.sortBy((unitoIdentity) =>
    unitoIdentity.getIn(['providerIdentities', 0, 'profileDisplayName']),
  );
}

export function getProviderIdentitiesOfUnitoIdentity(state, { mergeWithIds, unitoIdentity }) {
  const unitoIdentitiesToMergeWith = getUnitoIdentitiesByIds(state, mergeWithIds);
  if (unitoIdentitiesToMergeWith.isEmpty()) {
    return getProviderIdentitiesByIds(state, unitoIdentity.get('providerIdentities'));
  }

  const providerIdentityIdsToMerge = unitoIdentitiesToMergeWith.reduce(
    (initialState, entity) => initialState.concat(entity.get('providerIdentities')),
    List(),
  );

  return getProviderIdentitiesByIds(state, providerIdentityIdsToMerge.toSet());
}

export const getScripts = (state) => fromApp.getScripts(state.app);
export const getScriptOutput = (state) => fromApp.getScriptOutput(state.app);
export const getSiteadminSearchString = (state) => fromApp.getSiteadminSearchString(state.app);

export const getProductNameAppContext = (state) => fromApp.getProductNameContext(state.app);

export const isOnMirrorEmbedContext = (state) => {
  const embedName = getEmbedName(state);
  const productNameContext = getProductNameAppContext(state);
  const { EMBED, PRODUCT_NAMES } = appTypes;

  return embedName === EMBED.TRELLO && productNameContext === PRODUCT_NAMES.TASK_SYNC;
};

export const getProviderIdsFromWorkflow = (state, workflowId) => {
  const blocks = fromWorkflows.getBlocksByWorkflowId(state.workflows, workflowId);
  const providerIdentitiesById = fromProviderIdentities.getAllProviderIdentities(state.providerIdentities);
  return blocks.map((block) => providerIdentitiesById.getIn([block.get('providerIdentity'), 'providerId']));
};

export const getWorkflows = (state) => fromWorkflows.getWorkflows(state.workflows);

export const getContainersByProviderIdentityId = (state, providerIdentityId) =>
  fromProviderIdentities.getContainersByProviderIdentityId(state.providerIdentities, providerIdentityId);

export const getWorkflowById = (state, workflowId) => fromWorkflows.getWorkflowById(state.workflows, workflowId);

export const getContainerIdsByProviderInstanceId = (state, providerInstanceId) =>
  fromProviderContainers.getContainerIdsByProviderInstanceId(state.providerContainers, providerInstanceId);

export const getSearchableProviders = (state) => fromProviders.getSearchableProviders(state.providers);

export const isLoadingWorkflow = (state) => fromWorkflows.isLoadingWorkflow(state.workflows);

export const getLinkWorkflowCreatorName = (state, linkId) => {
  const link = getLinkById(state, linkId);
  return link.get('originatorWorkflow', '');
};

export const getSignupIntentProduct = (state) => fromAuth.getSignupIntentProduct(state.auth);

export const getAbilitiesByOrganizationId = (state, organizationId) =>
  fromAuth.getAbilitiesByOrganizationId(state.auth, organizationId);

export const getCurrentAbilities = (state) => {
  const organizationId = getSelectedOrganizationId(state);
  return getAbilitiesByOrganizationId(state, organizationId);
};

export const getIsLoadingActivityLogs = (state) => fromActivityLogs.getIsLoadingActivityLogs(state.activityLogs);

export const getActivityLogs = (state) => fromActivityLogs.getActivityLogs(state.activityLogs);

export const getLastActivityLogsFetchTimestamp = (state) =>
  fromActivityLogs.getLastActivityLogsFetchTimestamp(state.activityLogs);

export const getFirstActivityLogsByItemId = (state, itemId) =>
  fromActivityLogs.getFirstActivityLogsByItemId(state.activityLogs, itemId);

export const getIsAutoMapping = (state) => fromFields.getIsAutoMapping(state.fields);

export const getCapabilitiesFieldTypes = (state) => fromFields.getCapabilitiesFieldTypes(state.fields);

export const getItemFiltersForSide = (state, side, filterOutAllObjectsOption = false) => {
  let itemFilters = getFieldValue(state, `${side}.itemFilters`) || [];

  if (!Array.isArray(itemFilters)) {
    itemFilters = [itemFilters];
  }

  if (filterOutAllObjectsOption) {
    return itemFilters.filter((itemFilter) => itemFilter !== 'all');
  }

  return itemFilters;
};

export const hasMandatoryConditionalContainers = (state, providerId) =>
  fromProviders.hasMandatoryConditionalContainers(state.providers, providerId);

export const hasProviderCustomFieldOfTypeSupport = (state, providerId, customFieldType = 'string') => {
  const customFields = fromProviders.getCapabilitiesByProviderId(state.providers, providerId, 'customFields');
  return !!customFields.find(
    (customField, toolCustomFieldType) =>
      toolCustomFieldType === customFieldType || customField.get('type') === customFieldType,
  );
};

export const getIsCalendarTool = (state, providerId, itemType) => {
  const provider = getProviderById(state, { providerId });

  return providerTypes.CALENDAR_ITEMTYPE.some(
    (calendarProvider) =>
      calendarProvider.providerName === provider.get('name') && calendarProvider.itemType === itemType,
  );
};

export const getMergeOptionsBySide = (state, linkId, side) =>
  fromLinks.getMergeOptionsBySide(state.links, linkId, side);

export const getLinkOrganizationId = (state, linkId) => fromLinks.getLinkOrganizationId(state.links, linkId);

export const hasActiveLinksWithValueMapping = (state, excludeTaskSync) =>
  fromLinks.hasActiveLinksWithValueMapping(state.links, excludeTaskSync);

export const getLinkSyncStatus = (state, linkId) => fromLinks.getLinkSyncStatus(state.links, linkId);

export const getLinkSyncStatusActivity = (state, linkId) => fromLinks.getLinkSyncStatusActivity(state.links, linkId);

export const hasPositiveCountLinksByKind = (state) => fromLinks.getLastCountLinks(state) > 0;

export const getIsSyncInProgress = (state, linkId) => {
  const linkSyncStatusActivity = getLinkSyncStatusActivity(state, linkId);
  return [
    linkTypes.LINK_ACTIVITY_STATUS.SYNCING,
    linkTypes.LINK_ACTIVITY_STATUS.TRIGGERED,
    linkTypes.LINK_ACTIVITY_STATUS.INITIALIZING,
  ].includes(linkSyncStatusActivity);
};

const appReducer = combineReducers({
  activityLogs: fromActivityLogs.reducer,
  app: fromApp.reducer,
  auth: fromAuth.reducer,
  billing: fromBilling.reducer,
  containers: fromContainers.reducer,
  fields: fromFields.reducer,
  form: formReducer.plugin(fromForm.reducer),
  invites: fromInvites.reducer,
  links: fromLinks.reducer,
  organizations: fromOrganizations.reducer,
  providers: fromProviders.reducer,
  providerContainers: fromProviderContainers.reducer,
  providerIdentities: fromProviderIdentities.reducer,
  unitoIdentities: fromUnitoIdentities.reducer,
  workflows: fromWorkflows.reducer,
  websockets: fromWebsockets.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === authTypes.LOGOUT_USER_SUCCESS) {
    // eslint-disable-next-line
    state = {
      ...state,
      auth: undefined,
      billing: undefined,
      containers: undefined,
      fields: undefined,
      invites: undefined,
      links: undefined,
      organizations: undefined,
      providerContainers: undefined,
      providerIdentities: undefined,
      unitoIdentities: undefined,
      workflows: undefined,
      websockets: undefined,
    };
  }
  return appReducer(state, action);
};

import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import { useFormContext, useWatch } from 'react-hook-form';

import { getLinkById } from '~/reducers';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';

import { useGetIsLastAssociationCompleted } from './useGetIsLastAssociationCompleted';
import { useGetIncompleteGroupField } from './useGetIncompleteGroupField';
import { useGetMissingMandatoryFieldsToMap } from './useGetMissingMandatoryFieldsToMap';

export const FLOW_STEP_TOOLS = 'tools';
export const FLOW_STEP_MAPPING = 'mappings';

// this hook allows to know which step was last completed for a given linkId
export function useGetLastCompletedFlowStep(linkId) {
  const {
    formState: { errors },
  } = useFormContext();
  const link = useSelector((state) => getLinkById(state, linkId));
  const linkState = useWatch({ name: 'state' });
  const isDraftFlow = !linkId || linkState === linkTypes.LINK_STATES.DRAFT;
  const fieldAssociations = useWatch({ name: 'associations' });
  useGetIsLastAssociationCompleted(fieldAssociations);
  useGetIncompleteGroupField();
  useGetMissingMandatoryFieldsToMap();

  // if the flow is not a draft, the last completed step is always mappings, even if there are errors
  if (!isDraftFlow) {
    return FLOW_STEP_MAPPING;
  }

  const sideA = link.get('A', Map());
  const sideB = link.get('B', Map());
  const hasContainerA = !!sideA.getIn(['container', 'id']);
  const hasContainerB = !!sideB.getIn(['container', 'id']);
  if (!hasContainerA || !hasContainerB) {
    // undefined meaning tool selection was not completed yet (first step)
    return undefined;
  }
  // only real check we have for associations is to see if we have some...
  // also they must be complete and we musn't have any errors
  const hasAssociations = !!fieldAssociations?.length;
  const hasIncompleteOrInvalidMappings =
    errors[trackingTypes.MODULE.MAPPINGS]?.missingMandatoryFieldsA ||
    errors[trackingTypes.MODULE.MAPPINGS]?.missingMandatoryFieldsB ||
    errors[trackingTypes.MODULE.MAPPINGS]?.incompleteFieldMappingGroup ||
    errors[trackingTypes.MODULE.MAPPINGS]?.lastAssociationNotCompleted;
  // we're not really checking the rules step here since for our use case
  // we will be using this hook to show the same resource whether on rules or mappings
  // since mappings come after rules, we only check mappings.
  if (hasAssociations && !hasIncompleteOrInvalidMappings) {
    return FLOW_STEP_MAPPING;
  }

  return FLOW_STEP_TOOLS;
}

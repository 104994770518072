import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { getLinkById } from '~/reducers';

import {
  NewAlert as Alert,
  Box,
  NewModal as Modal,
  NewAlertLevel,
  NewTypography as Typography,
  tokens,
} from '@unitoio/mosaic';

import { useGetTestModeTerm } from '../../containers/FlowBuilder/hooks/useGetTestModeTerm';

export function TurnOffTestModeModal({ isOpen, onRequestClose, onSuccess, taskTerm, linkId }) {
  const testModeTerm = useGetTestModeTerm(linkId);
  const link = useSelector((state) => getLinkById(state, linkId));
  const earliestCreatedAt = link.getIn(['syncSettings', 'earliestCreatedAt'], new Date());
  const formattedLinkEarliestCreatedAt = moment(earliestCreatedAt).format('MMMM D, YYYY');
  return (
    <Modal
      title={`Remove 'only ${testModeTerm} items' setting`}
      open={isOpen}
      onCancel={onRequestClose}
      onOk={onSuccess}
      closable
      okType="danger"
      okButtonProps={{ danger: true, type: 'primary' }}
      okText="Remove"
    >
      <Box m={[0, 0, tokens.spacing.s5, 0]}>
        <Box m={[0, 0, tokens.spacing.s4, 0]}>
          <Typography.Text>
            Any items matching your filters will now sync. This will include {taskTerm} created before{' '}
            {formattedLinkEarliestCreatedAt}. Do you confirm you want to remove?
          </Typography.Text>
        </Box>
        <Alert level={NewAlertLevel.WARNING} message="This action is irreversible and cannot be undone." />
      </Box>
    </Modal>
  );
}

TurnOffTestModeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  taskTerm: PropTypes.element.isRequired,
  linkId: PropTypes.string.isRequired,
};

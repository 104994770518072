import React from 'react';
import { useSelector } from 'react-redux';

import * as featureTypes from '~/consts/features';
import { LinkList } from './LinkList/LinkList';
import { ProjectSyncList } from './ProjectSyncList';
import { getFeatureFlagValue } from '~/reducers';

export const SyncListContainer = ({
  page,
  total,
  setPage,
  pageSize,
  setPageSize,
  setSearch,
  setKinds,
  commonProps,
  routeProps,
}) => {
  const hasLinkListRevampFeatureFlag = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.LINK_LIST_REVAMP),
  );

  if (hasLinkListRevampFeatureFlag) {
    return (
      <LinkList
        {...commonProps}
        {...routeProps}
        pagination={{ page, pageSize, total }}
        onSetKinds={setKinds}
        onChangePage={({ page: newPage, pageSize: newPageSize }) => {
          setPage(newPage);
          setPageSize(newPageSize);
        }}
        onSearch={setSearch}
      />
    );
  }

  return (
    <ProjectSyncList
      {...commonProps}
      {...routeProps}
      pagination={{ page, pageSize, total }}
      onSetKinds={setKinds}
      onChangePage={({ page: newPage, pageSize: newPageSize }) => {
        setPage(newPage);
        setPageSize(newPageSize);
      }}
      onSearch={setSearch}
    />
  );
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { STORE_KEYS } from '~/consts/store';
import { useLocalStorage } from '~/hooks';
import { isUserSiteAdmin } from '~/reducers';

const FLOW_HISTORY_LIMIT = 5;

export const useSetFlowHistory = ({ linkId }) => {
  const [lastVisitedFlowIDs, setLastVisitedFlowIDs] = useLocalStorage(STORE_KEYS.FLOWHISTORY, []);
  const isSiteAdmin = useSelector((state) => isUserSiteAdmin(state));

  if (!isSiteAdmin) {
    return [];
  }

  const updateLastVisitedFlowIDs = (linkID) => {
    try {
      // Create a copy of the lastVisitedFlowIDs array
      let updatedFlowIDs = [...lastVisitedFlowIDs];

      // Check if the linkID is already present in the array
      const flowIndex = updatedFlowIDs.indexOf(linkID);

      if (flowIndex !== -1) {
        // If present, remove it from its current position
        updatedFlowIDs.splice(flowIndex, 1);
      }

      // Push the linkID to the last position of the array
      updatedFlowIDs.push(linkID);

      // Clean up the array to ensure it doesn't go above 10 entries
      if (updatedFlowIDs.length > FLOW_HISTORY_LIMIT) {
        updatedFlowIDs.splice(0, updatedFlowIDs.length - FLOW_HISTORY_LIMIT);
      }

      // Set the updated array in local storage
      setLastVisitedFlowIDs(updatedFlowIDs);
    } catch (e) {
      console.error('Failed to save flow history', e);
    }
  };

  useEffect(() => {
    if (linkId) {
      updateLastVisitedFlowIDs(linkId);
    }
  }, [linkId]);

  return lastVisitedFlowIDs;
};

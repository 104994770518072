import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { getLinkById, getIsNewLinkWithAutoSync } from '~/reducers';
import * as linkTypes from '~/consts/link';

export function useGetSyncStatusActivityAndHealth(linkId) {
  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const syncStatus = currentLink.get('syncStatus', Map());
  const isNewLinkWithAutoSync = useSelector((state) => getIsNewLinkWithAutoSync(state, linkId));

  const health = syncStatus.get('healthStatus');
  const activity = isNewLinkWithAutoSync ? linkTypes.LINK_ACTIVITY_STATUS.INITIALIZING : syncStatus.get('activity');

  return [activity, health];
}

import PropTypes from 'prop-types';
import React, { Component, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Map } from 'immutable';

import { Skeleton as InitSkeleton } from '@unitoio/mosaic';

import * as featureTypes from '~/consts/features';
import * as appActions from '~/actions/app';
import * as billingActions from '~/actions/billing';
import * as inviteActions from '~/actions/invites';
import * as organizationActions from '~/actions/organizations';
import * as providerActions from '~/actions/providers';
import * as trackingActions from '~/actions/tracking';
import * as websocketActions from '~/actions/websocket';
import * as loggingActions from '~/actions/logging';
import { ActivityLogs } from '~/containers/ActivityLogs/ActivityLogs';
import { AuthenticatedWorkflowDesigner } from '~/containers/WorkflowDesigner/AuthenticatedWorkflowDesigner';
import { FlowBuilder } from '~/containers/FlowBuilder/FlowBuilder';
import { HeaderContainerWorkflow } from '~/containers/HeaderContainer/HeaderContainerWorkflow';
import { HeaderContainerItemStatus } from '~/containers/HeaderContainer/HeaderContainerItemStatus';
import { ProfileSettingsContainer } from '~/containers/ProfileSettingsContainer/ProfileSettingsContainer';
import { SyncList } from '~/containers/SyncList/SyncList';
import { SyncsTabNav } from '~/containers/SyncsTabNav/SyncsTabNav';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { UserOrgInviteModal } from '~/containers/UserOrgInviteModal/UserOrgInviteModal';
import { WorkflowList } from '~/containers/WorkflowDesigner/WorkflowList';
import { WorkItemStatus } from '~/containers/WorkItemStatus/WorkItemStatus';
import { DevTools } from '~/containers/Admin/DevTools/DevTools';
import { OneClickExport } from '~/containers/OneClickExport/OneClickExport';
import * as appTypes from '~/consts/app';
import * as authTypes from '~/consts/auth';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import * as websocketTypes from '~/consts/websocket';
import { SiteAdminContext, TrackingFunnelContext } from '~/contexts';
import {
  getAllLinks,
  getEmbedName,
  getOrganizationHasWorkflows,
  getOrganizationById,
  getOrganizations,
  getSelectedOrganizationId,
  getSignupIntentProduct,
  getUserAccountCreationDate,
  getUserId,
  getUserKeepInformed,
  getUserPendingInvite,
  isUserSiteAdmin,
  getOrganizationHasFlows,
  getOrganizationPendingDraftId,
  getIsLoadedProviders,
  getFeatureFlagValue,
} from '~/reducers';
import { isOrganizationValid } from '~/utils/isOrganizationValid';
import { getSearchParams } from '~/utils/getSearchParams';
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { NotFound } from '~/components/NotFound/NotFound';
import { Modal } from '~/components/Modal/Modal';
import { Loading } from '~/components/Loading/Loading';
import * as linkActions from '~/actions/links';
import { LayoutDashboard } from '../Layout/LayoutDashboard';

import marketingOptInImage from './marketing-optin-modal.svg';
import { IS_SITE_ADMIN_VIEW_TOGGLED } from '../HeaderContainer/components/SiteAdminViewSwitch';

const MarketingOptInImg = styled.img`
  display: block;
  margin: 2rem auto;
`;

class DashboardComponent extends Component {
  static propTypes = {
    acceptMarketingEmails: PropTypes.func.isRequired,
    embedName: PropTypes.string,
    fetchOrgResources: PropTypes.func.isRequired,
    logError: PropTypes.func.isRequired,
    fetchPlans: PropTypes.func.isRequired,
    fetchOrganizations: PropTypes.func.isRequired,
    fetchUserResources: PropTypes.func.isRequired,
    hasFlows: PropTypes.bool.isRequired,
    hasWorkflows: PropTypes.bool.isRequired,
    subscribeToWebSocket: PropTypes.func.isRequired,
    areProvidersLoaded: PropTypes.bool.isRequired,
    keepInformed: PropTypes.bool,
    linkList: PropTypes.instanceOf(Map).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    organizationId: PropTypes.string,
    organizations: PropTypes.instanceOf(Map).isRequired,
    pendingDraftId: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    refuseMarketingEmails: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    signupIntentProduct: PropTypes.oneOf(Object.values(appTypes.PRODUCT_NAMES)).isRequired,
    userAccountCreationDate: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userPendingInvite: PropTypes.instanceOf(Map).isRequired,
    hasWorkflowDesignerDeprecationFlag: PropTypes.bool,
  };

  static contextType = SiteAdminContext;

  static defaultProps = {
    embedName: undefined,
    keepInformed: null,
    organizationId: undefined,
    pendingDraftId: null,
  };

  state = {
    errorOnMount: null,
    isLoading: true,
  };

  async componentDidMount() {
    const { fetchOrganizations, fetchOrgResources, fetchUserResources, organizationId, history } = this.props;
    try {
      const { organizations } = await fetchOrganizations();

      if (organizations.length > 0) {
        await fetchUserResources(organizationId);
        await fetchOrgResources(organizationId);
        await this.fetchPlansSafe();

        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false });

        history.push('/no-workspaces');
      }
    } catch (err) {
      this.setState({ errorOnMount: err });
    }
  }

  async componentDidUpdate(prevProps) {
    const { fetchOrgResources, organizationId, linkList, userId, subscribeToWebSocket } = this.props;

    const linkHasSyncActivity = linkList.some((link) => link.get('syncStatus'));

    // here we need to check if the user is authenticated otherwise fetchOrgResources will be called during logout
    if (organizationId && prevProps.organizationId && prevProps.organizationId !== organizationId) {
      this.setState({ isLoading: true });
      await fetchOrgResources(organizationId);
      await this.fetchPlansSafe();
      this.setState({ isLoading: false });
    }

    // TEMPORARY FIX until we fully release pagination.
    // Resubscribe to websocket directly if the initial subscription in getLinks failed, so we don't re-fetch all links.
    if (!linkList.isEmpty() && !linkHasSyncActivity) {
      const linkIds = Array.from(linkList.keys());

      subscribeToWebSocket({
        currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_LIST,
        organizationId,
        userId,
        linkIds,
      });
    }
  }

  getMarketingEmailModal() {
    const { acceptMarketingEmails, keepInformed, refuseMarketingEmails } = this.props;
    const suggestMarketingEmails = keepInformed === null && this.isUserOverHalfOfTrialPeriod();

    return (
      <Modal
        isOpen={suggestMarketingEmails}
        displayCloseButton
        title={
          <span>
            <span role="img" aria-label="wave">
              👋
            </span>{' '}
            Looking for more ways to work united?
          </span>
        }
        cancelLabel="No thanks"
        confirmLabel="Subscribe"
        onCancel={refuseMarketingEmails}
        onConfirm={acceptMarketingEmails}
        size="md"
      >
        <MarketingOptInImg alt="Modal opt-in image" src={marketingOptInImage} />
        <div>
          <p>Do you like blockers in your workflows?</p>
          <p>
            Neither do we. That's why our newsletter is stuffed full of workflow tips, expert advice, and secret
            techniques you can use to get more out of Unito.
          </p>
        </div>
      </Modal>
    );
  }

  fetchPlansSafe = async () => {
    const { fetchPlans, logError } = this.props;
    try {
      await fetchPlans();
    } catch (err) {
      logError(err);
    }
  };

  isUserOverHalfOfTrialPeriod = () => {
    const { userAccountCreationDate } = this.props;
    const userSignupDate = new Date(userAccountCreationDate);

    const halfOfTrialPeriodTs = userSignupDate.setDate(userSignupDate.getDate() + 7);
    const currentDateTs = new Date().getTime();
    return currentDateTs > halfOfTrialPeriodTs;
  };

  trackDashboardAction = (actionName, data) => {
    const { trackEvent } = this.props;
    const eventName = trackingTypes.USER_DASHBOARD_EVENTS.ACTION_NAME;
    trackEvent(eventName, { action_name: actionName, ...data });
  };

  getDefaultRedirectRoute = () => {
    const { embedName, signupIntentProduct, hasFlows, hasWorkflows, hasWorkflowDesignerDeprecationFlag } = this.props;
    const hasOnlyWorkflows = hasWorkflows && !hasFlows && !embedName && !hasWorkflowDesignerDeprecationFlag;
    const comingFromMirror = signupIntentProduct === appTypes.PRODUCT_NAMES.TASK_SYNC;
    const shouldShowFromMirror = comingFromMirror && !hasWorkflows && !hasFlows;

    if (hasOnlyWorkflows) {
      return routes.ABSOLUTE_PATHS.WORKFLOWS;
    }
    if (shouldShowFromMirror) {
      return routes.ABSOLUTE_PATHS.TASK_SYNC;
    }
    return routes.ABSOLUTE_PATHS.SYNCS;
  };

  renderSyncRoutes = (match, organizationId, embedName, areProvidersLoaded, hasWorkflowDesignerDeprecationFlag) => (
    <Switch>
      <Route
        path={`${match.path}/syncs`}
        render={(props) => (
          <InitSkeleton loading={!areProvidersLoaded} active>
            <SyncList key={organizationId} trackDashboardAction={this.trackDashboardAction} {...props} />
          </InitSkeleton>
        )}
      />

      <Route
        path={`${match.path}/workflows`}
        render={({ match: workflowMatch }) => {
          if (embedName || hasWorkflowDesignerDeprecationFlag) {
            return <NotFound goBackLink={routes.ABSOLUTE_PATHS.DASHBOARD} goBackText="Back to dashboard" />;
          }

          return (
            <Switch>
              <InitSkeleton loading={!areProvidersLoaded} active>
                <Route
                  path={`${workflowMatch.path}/edit/:workflowId`}
                  render={(props) => (
                    <TrackingFunnelContext.Provider
                      // eslint-disable-next-line react/jsx-no-constructed-context-values
                      value={{
                        name: trackingTypes.FUNNELS.WORKFLOW,
                        sharedProperties: { workflow_id: props.match.params.workflowId },
                      }}
                    >
                      <AuthenticatedWorkflowDesigner {...props} />
                    </TrackingFunnelContext.Provider>
                  )}
                />
                <Route
                  path={`${workflowMatch.path}`}
                  render={(props) => <WorkflowList organizationId={organizationId} {...props} />}
                />
              </InitSkeleton>
            </Switch>
          );
        }}
      />
    </Switch>
  );

  render() {
    const {
      embedName,
      hasFlows,
      match,
      location,
      organizationId,
      userPendingInvite,
      organizations,
      pendingDraftId,
      areProvidersLoaded,
      hasWorkflowDesignerDeprecationFlag,
    } = this.props;

    const { isSiteAdmin } = this.context;

    const { errorOnMount, isLoading } = this.state;

    if (errorOnMount) {
      throw errorOnMount;
    }

    const isFlowBuilderRoute = location.pathname.includes('flow-builder');

    if (isLoading || organizations.isEmpty()) {
      return <Loading />;
    }

    const NotFoundComponent = () => (
      <NotFound goBackLink={routes.ABSOLUTE_PATHS.DASHBOARD} goBackText="Back to dashboard" />
    );

    const renderFlowBuilderRoutes = () => {
      if (!isSiteAdmin) {
        if (!hasFlows) {
          return <Redirect to={routes.ABSOLUTE_PATHS.FLOW_BUILDER_ADD} />;
        }
        if (pendingDraftId) {
          // pendingDraftId when the org has one draft and no active flows
          return <Redirect to={`${match.path}/flow-builder/edit/${pendingDraftId}`} />;
        }
      }
      return this.renderSyncRoutes(
        match,
        organizationId,
        embedName,
        areProvidersLoaded,
        hasWorkflowDesignerDeprecationFlag,
      );
    };

    return (
      <>
        {isSiteAdmin && <DevTools />}
        {this.getMarketingEmailModal()}
        <UserOrgInviteModal invite={userPendingInvite} />
        {!isFlowBuilderRoute && (
          <Switch>
            <Route path={`${match.path}/workflows/edit/`} component={HeaderContainerWorkflow} />

            <Route path={`${match.path}/items/:itemId/status`} component={HeaderContainerItemStatus} />
          </Switch>
        )}
        <LayoutDashboard>
          <Switch>
            <Route
              path={[
                `${match.path}/syncs/project-sync`,
                `${match.path}/syncs/task-sync`,
                `${match.path}/workflows`,
                `${match.path}/embed/:embedName/syncs/project-sync`,
                `${match.path}/embed/:embedName/syncs/task-sync`,
                `${match.path}/embed/:embedName/workflows`,
              ]}
              exact
              render={(props) => <SyncsTabNav {...props} trackDashboardAction={this.trackDashboardAction} />}
            />
          </Switch>
          <Switch>
            <Route
              path={[
                `${match.path}/flow-builder/:mode(add)`,
                `${match.path}/flow-builder/:mode(edit|duplicate)/:linkId`,
                `${match.path}/flow-builder/:mode(workflow)/:workflowId/:linkId`,
              ]}
              render={(props) => (
                <TrackingFunnel
                  contextName={
                    props.match.params.workflowId ? trackingTypes.PAGE.WORKFLOW_LIST : trackingTypes.PAGE.FLOW_LIST
                  }
                >
                  {areProvidersLoaded ? <FlowBuilder {...props} /> : <Loading />}
                </TrackingFunnel>
              )}
            />

            <Route
              path={[`${match.path}/syncs`, `${match.path}/links`, `${match.path}/workflows`]}
              render={() => <Switch>{renderFlowBuilderRoutes()}</Switch>}
            />

            <Redirect strict exact from={`${match.path}/organizations/`} to={`${match.path}/organizations`} />
            <Route
              path={[`${match.path}/organizations`, `${match.path}/profile`]}
              render={(props) =>
                !organizationId ? <Loading /> : <ProfileSettingsContainer {...props} organizationId={organizationId} />
              }
            />
            <Route path={`${match.path}/activityLogs`} render={(props) => <ActivityLogs {...props} />} />

            <Route
              path={[
                `${match.path}/${routes.BASE_PATHS.ONE_CLICK_EXPORT}`,
                `/embed/:embedName/dashboard/${routes.BASE_PATHS.ONE_CLICK_EXPORT}`,
              ]}
              render={(props) => (
                <TrackingFunnel contextName={trackingTypes.PAGE.EXPORT_AND_SYNC}>
                  <OneClickExport {...props} />
                </TrackingFunnel>
              )}
            />

            <Route path={`${match.path}/items/:itemId/status`} component={WorkItemStatus} />
            <Redirect exact from={routes.ABSOLUTE_PATHS.DASHBOARD} to={this.getDefaultRedirectRoute()} />
            <Redirect exact from="/embed/:embedName/dashboard" to={routes.ABSOLUTE_PATHS.SYNCS} />
            <Route render={NotFoundComponent} />
          </Switch>
        </LayoutDashboard>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const embedName = getEmbedName(state);
  // TODO re-validate cases where we pass an orgId as a query parameter
  const { organizationId: searchParamsOrgId } = getSearchParams(ownProps.location?.search) || {};
  const fallbackOrgId = searchParamsOrgId || getSelectedOrganizationId(state);
  // when coming from embed the organizationId will already be set.
  let organizationId;
  if (ownProps.organizationId) {
    const embedOrg = getOrganizationById(state, ownProps.organizationId);
    if (isOrganizationValid(embedOrg)) {
      organizationId = ownProps.organizationId;
    } else {
      organizationId = fallbackOrgId;
    }
  } else {
    organizationId = fallbackOrgId;
  }

  const hasWorkflows = getOrganizationHasWorkflows(state, organizationId);
  const hasFlows = getOrganizationHasFlows(state, organizationId);
  const pendingDraftId = getOrganizationPendingDraftId(state, organizationId);
  const areProvidersLoaded = getIsLoadedProviders(state, organizationId);
  const hasWorkflowDesignerDeprecationFlag = getFeatureFlagValue(state, featureTypes.FEATURES.WORKFLOWS_DEPRECATION);

  return {
    embedName,
    // TODO: get rid of this FF, it would seem this flag is already permanently enabled in production.
    linkList: getAllLinks(state),
    userAccountCreationDate: getUserAccountCreationDate(state),
    keepInformed: getUserKeepInformed(state),
    hasFlows,
    hasWorkflows,
    userPendingInvite: getUserPendingInvite(state),
    organizationId,
    pendingDraftId,
    organizations: getOrganizations(state),
    signupIntentProduct: getSignupIntentProduct(state),
    userId: getUserId(state),
    areProvidersLoaded,
    hasWorkflowDesignerDeprecationFlag,
  };
};

const updateUser =
  ({ keepInformed }) =>
  (dispatch, getState) =>
    dispatch({
      url: `v1/users/${getUserId(getState())}`,
      method: 'PATCH',
      types: [authTypes.UPDATE_USER_REQUEST, authTypes.UPDATE_USER_SUCCESS, authTypes.UPDATE_USER_FAILURE],
      payload: { keepInformed },
    });

const mapDispatchToProps = (dispatch) => ({
  logError: (error, context) => dispatch(loggingActions.reportException(error, context)),
  fetchOrganizations: async () => dispatch(organizationActions.getOrganizations()),
  fetchUserResources: async (organizationId) => {
    dispatch(appActions.setSelectedOrganizationId(organizationId));
    dispatch(inviteActions.getUserPendingInvites());
  },
  fetchPlans: () => dispatch(billingActions.getPlans()),
  fetchOrgResources: async (organizationId) => {
    await dispatch(organizationActions.getFlags(organizationId)); // getLinks is dependent on paginated-flow-list feature flag
    await Promise.all([
      dispatch(organizationActions.getOrganizationById(organizationId)),
      dispatch(
        linkActions.getLinks({
          page: 0,
          pageSize: 20,
          kinds: [linkTypes.KIND.MIRROR_SYNC, linkTypes.KIND.REPORT_SYNC, linkTypes.KIND.MULTI_SYNC],
        }),
      ),
      // orgId has to be passed explicitly here for embed
      dispatch(billingActions.getOrganizationPlanProfile(organizationId)),
      dispatch(providerActions.getProviders({ apiVersion: routes.API_VERSION_V2 })),
    ]);
    // ⚠️ Keep those async for performance, components dependent on these calls will render in loading state
    dispatch(organizationActions.getUsage());
    dispatch(organizationActions.getItemsUsage(organizationId));
  },
  acceptMarketingEmails: () => dispatch(updateUser({ keepInformed: true })),
  refuseMarketingEmails: () => dispatch(updateUser({ keepInformed: false })),
  trackEvent: (...params) => {
    dispatch(trackingActions.trackEvent(...params));
  },
  subscribeToWebSocket: ({ currentPage, organizationId, userId, linkIds }) =>
    dispatch(websocketActions.subscribe({ currentPage, organizationId, userId, linkIds })),
});

const Dashboard = withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardComponent));

const DashboardWithContext = (props) => {
  const isSiteAdmin = useSelector((state) => isUserSiteAdmin(state));
  const [isSiteAdminViewEnabled] = useLocalStorage(IS_SITE_ADMIN_VIEW_TOGGLED, true);
  const isSiteAdminEnabled = isSiteAdmin && isSiteAdminViewEnabled;
  const siteAdminInfo = useMemo(() => ({ isSiteAdmin: isSiteAdminEnabled }), [isSiteAdminEnabled]);
  return (
    <SiteAdminContext.Provider value={siteAdminInfo}>
      <Dashboard {...props} />
    </SiteAdminContext.Provider>
  );
};

DashboardWithContext.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  organizationId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export { DashboardWithContext as Dashboard };

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { NewAlert as Alert, Flex, NewAlertLevel, tokens } from '@unitoio/mosaic';

import { getProviderByName } from '~/reducers';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { useGetMissingMandatoryFieldsToMap } from '../../../hooks/useGetMissingMandatoryFieldsToMap';

const BoldText = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

export const MandatoryFieldsAlertWrapper = ({ enableAlert = true }) => {
  const [missingMandatoryFieldsA, missingMandatoryFieldsB] = useGetMissingMandatoryFieldsToMap(enableAlert);

  return (
    <Flex vertical gap="small">
      {!missingMandatoryFieldsA.isEmpty() && (
        <SingleMandatoryFieldAlert missingMandatoryFields={missingMandatoryFieldsA} containerSide="A" />
      )}
      {!missingMandatoryFieldsB.isEmpty() && (
        <SingleMandatoryFieldAlert missingMandatoryFields={missingMandatoryFieldsB} containerSide="B" />
      )}
    </Flex>
  );
};

MandatoryFieldsAlertWrapper.propTypes = {
  enableAlert: PropTypes.bool,
};

export function SingleMandatoryFieldAlert({ missingMandatoryFields, containerSide }) {
  const { watch } = useFormContext();
  const provider = useSelector((state) => getProviderByName(state, watch(`${containerSide}.providerName`)));
  const containerId = watch(`${containerSide}.containerId`);

  const hasMoreThanOneField = missingMandatoryFields.size > 1;
  const plurality = hasMoreThanOneField ? 'plural' : 'singular';
  const conjugatedVerb = hasMoreThanOneField ? 'are' : 'is';
  // TODO: Remove this once field terms are supported by ProviderTermsByName
  const fieldTerm = hasMoreThanOneField ? 'fields' : 'field';

  return (
    <Alert
      level={NewAlertLevel.WARNING}
      message={
        <>
          The following{' '}
          <ProviderTermsByName
            termKey={fieldTerm}
            plurality={plurality}
            providerIdA={provider.get('_id')}
            containerIdA={containerId}
          />{' '}
          {conjugatedVerb} required to be mapped on the <BoldText>{provider.get('displayName')}</BoldText> side:{' '}
          <BoldText>
            {missingMandatoryFields
              .map((pcdField) => pcdField.getIn(['names', 'singular'], ''))
              .toArray()
              .join(', ')}
          </BoldText>
          .
        </>
      }
    />
  );
}

SingleMandatoryFieldAlert.propTypes = {
  missingMandatoryFields: PropTypes.instanceOf(Map).isRequired,
  containerSide: PropTypes.oneOf(['A', 'B']).isRequired,
};

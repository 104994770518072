import { useSelector } from 'react-redux';

import { getProviderByName } from '~/reducers';
import * as fieldTypes from '~/consts/fields';
import { useProviderItemSupportsMerging } from './useProviderItemSupportsMerging';
import { useIsSideReadOnly } from './useIsSideReadOnly';

export function useGetProvidersCanCreateWorkItem({
  containerIdA,
  containerIdB,
  providerNameA,
  providerNameB,
  itemTypeA,
  itemTypeB,
}) {
  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));
  const itemASupportsMerge = useProviderItemSupportsMerging(providerNameA, itemTypeA);
  const itemBSupportsMerge = useProviderItemSupportsMerging(providerNameB, itemTypeB);
  const isASideReadOnly = useIsSideReadOnly(providerA?.get('_id'), containerIdA, itemTypeA);
  const isBSideReadOnly = useIsSideReadOnly(providerB?.get('_id'), containerIdB, itemTypeB);

  if (providerA.isEmpty() || providerB.isEmpty()) {
    return {
      oneWayToolRestricted: false,
      target: fieldTypes.TARGET.BOTH,
    };
  }

  const oneWayToolRestricted = isASideReadOnly || isBSideReadOnly;

  if (isASideReadOnly) {
    return {
      oneWayToolRestricted,
      target: fieldTypes.TARGET.B,
    };
  }

  if (isBSideReadOnly) {
    return {
      oneWayToolRestricted,
      target: fieldTypes.TARGET.A,
    };
  }

  if (itemASupportsMerge) {
    if (!itemBSupportsMerge) {
      return {
        oneWayToolRestricted: false, // dont restrict flow creation but force direction
        target: fieldTypes.TARGET.B,
      };
    }
  }

  if (itemBSupportsMerge) {
    if (!itemASupportsMerge) {
      return {
        oneWayToolRestricted: false,
        target: fieldTypes.TARGET.A,
      };
    }
  }

  return {
    oneWayToolRestricted,
    target: fieldTypes.TARGET.BOTH,
  };
}

import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Box, tokens, Icon, Toggle } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';
import * as featureTypes from '~/consts/features';
import { Href } from '~/components/Href/Href';
import { PlanFeature } from '~/components/PlanFeature/PlanFeature';

import { useGetProviderNames } from '../hooks/useGetProviderNames';
import { useGetProviders } from '../hooks/useGetProviders';

const StreamAttachmentsLabel = styled((props) => <Box as="label" {...props} />)`
  font-size: ${tokens.fontSize.f7};
  display: inline;
  vertical-align: middle;
`;

const StreamAttachmentsSection = styled(Box)`
  color: ${tokens.colors.content.neutral.n30};
  font-size: ${tokens.fontSize.f7};
`;

export const FieldMappingStreamAttachments = ({ side }) => {
  const { setValue } = useFormContext();
  const [providerNameA, providerNameB] = useGetProviderNames();
  const [watchedStreamAttachmentsA, watchedStreamAttachmentsB] = useWatch({
    name: ['A.streamAttachments', 'B.streamAttachments'],
  });
  const watchedStreamAttachmentsBySide = side === 'A' ? watchedStreamAttachmentsA : watchedStreamAttachmentsB;

  const [providerA, providerB] = useGetProviders(providerNameA, providerNameB);

  const onToggleStreamAttachments = () => {
    const watchedStreamAttachmentsSide = side === 'A' ? watchedStreamAttachmentsA : watchedStreamAttachmentsB;

    // Setting value here for UI fluidity, it looks less laggy if we change it before the update is made
    setValue(`${side}.streamAttachments`, !watchedStreamAttachmentsSide, { shouldDirty: true });
  };

  return (
    <Box fullWidth m={[tokens.spacing.s4, 2.5]}>
      <PlanFeature name={featureTypes.FEATURES.STREAM_ATTACHMENTS}>
        {(hasAccess) => (
          <AttachmentSide
            provider={side === 'A' ? providerA : providerB}
            hasAccess={hasAccess}
            watchedStreamAttachmentsBySide={watchedStreamAttachmentsBySide}
            onToggleStreamAttachments={onToggleStreamAttachments}
          />
        )}
      </PlanFeature>
    </Box>
  );
};

export const AttachmentSide = ({ hasAccess, watchedStreamAttachmentsBySide, onToggleStreamAttachments, provider }) => {
  return (
    <>
      <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4]} flexDirection="row" alignItems="baseline">
        <Toggle
          $disabledState={!hasAccess}
          disabled={!hasAccess}
          value={!!watchedStreamAttachmentsBySide}
          onClick={onToggleStreamAttachments}
        />

        <StreamAttachmentsLabel m={[0, 0, 0, tokens.spacing.s3]} htmlFor="streamAttachments">
          Allow anyone with the link to access the attachments.
        </StreamAttachmentsLabel>
      </Box>
      <StreamAttachmentsSection>
        Keep this disabled to require that users log into {provider.get('displayName')} in order to access attachments.
      </StreamAttachmentsSection>
      <StreamAttachmentsSection>
        <Icon kind={Icon.KINDS.SOLID} name="lock" color={tokens.colors.content.neutral.n40} /> This option is only
        available for Pro and above plans. <Href to={`${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/pricing`}>Learn more</Href>
        .
      </StreamAttachmentsSection>
    </>
  );
};

FieldMappingStreamAttachments.propTypes = {
  side: PropTypes.oneOf(['A', 'B']).isRequired,
};

import { fromJS } from 'immutable';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import {
  Alert,
  Box,
  Button,
  NewCol,
  Icon,
  NewRow,
  tokens,
  Tooltip,
  Divider,
  NewGrid,
  notification,
  Flex,
  Result,
  NewButton,
  Skeleton,
} from '@unitoio/mosaic';

import * as draftActions from '~/actions/drafts';
import * as linkActions from '~/actions/links';
import { RESOURCE_ERROR_TYPE } from '~/consts/errors';
import * as linkTypes from '~/consts/link';
import * as trackingTypes from '~/consts/tracking';
import * as appTypes from '~/consts/app';
import * as featureTypes from '~/consts/features';
import * as fieldTypes from '~/consts/fields';
import * as routes from '~/consts/routes';
import { useGetAllProvidersIdentities } from '~/containers/FlowBuilder/hooks/useGetAllProvidersIdentities';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { getEmbedName, getFeatureFlagValue } from '~/reducers';
import { capitalize } from '~/utils/capitalize';
import { Href } from '~/components/Href/Href';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { WorkItemSelector } from '~/components/WorkItemSelector/WorkItemSelector';
import * as formUtils from '../utils/form';
import { PageContainer } from './PageContainer/PageContainer';
import { PageHeader } from '../components/PageHeader';
import { SyncStatusPageAlert } from '../components/SyncStatusPageAlert';
import { useIsModernIntegration } from '../hooks/useIsModernIntegration';
import { useHasAnomaliesOnPage, PAGES } from '../hooks/useGetAnomalies';
import { useGetContainerTypes } from '../hooks/useGetContainerTypes';
import { ExtraUserStep } from '../components/ExtraUserStep/ExtraUserStep';
import { useProviderItemsCompatible } from '../hooks/useProviderItemsCompatible';
import { useGetProviders } from '../hooks/useGetProviders';
import { DuplicateBanner } from '../components/DuplicateBanner';
import { ConnectToolSidePanel } from './SidePanels/ConnectToolSidePanel';
import { WorkItemSelectorRevamp } from '../../../components/WorkItemSelector/WorkItemSelectorRevamp';
import { useGetProvidersCanCreateWorkItem } from '../hooks/useGetProvidersCanCreateWorkItem';
import { AlreadyActiveFlowAlert } from '../components/AlreadyActiveFlowAlert';
import { useIsFlowDuplicate } from '../hooks/useIsFlowDuplicate';
import { useAreBothSidesReadOnly } from '../hooks/useAreBothSidesReadOnly';
import { useShouldDisableConfirmButton } from '../hooks/useShouldDisableConfirmButton';
import { useRedirectToPage } from '../hooks/useRedirectToPage';

const ConnectBox = styled(Box)`
  border-radius: ${tokens.spacing.s4};
  position: relative;
`;

// FIXME if we dont use an InlineDiv, the tooltip wrapping
// the icon will not show properly :(, need to fix this somehow...
const InlineDiv = styled.div`
  justify-content: flex-end;
  display: inline-flex;
  width: 100%;
`;

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw9};
`;

const OpenSidePanelLink = styled.span`
  color: ${tokens.colors.content.info.default};
  cursor: pointer;
`;

export const getRedirectPage = ({ canCreateTarget, setValue }) => {
  // If the tool combination can only be one-way create, skip the flow direction page entirely
  // and redirect to the guide to show the user the flow is forced one-way
  if (canCreateTarget !== fieldTypes.TARGET.BOTH) {
    setValue('syncDirection', canCreateTarget);
    return routes.FLOW_BUILDER_PAGES.GUIDE;
  }

  return routes.FLOW_BUILDER_PAGES.FLOW_DIRECTION;
};

export const ConnectTools = ({ match, loadingState, isDuplicating }) => {
  const { linkId, workflowId } = match.params;
  const { setValue, watch, formState, reset, setLoadingState } = useFormContext();
  const redirectToPage = useRedirectToPage();
  const trackEvent = useTrackEvent();
  const linkState = useWatch({ name: 'state' });
  const embedName = useSelector((state) => getEmbedName(state));
  const dispatch = useDispatch();
  const formData = useWatch();

  const isEmbed = !!embedName;
  const isDraftFlow = !linkId || linkState === linkTypes.LINK_STATES.DRAFT;
  const isDuplicate = useIsFlowDuplicate();

  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const [providerA, providerB] = useGetProviders(providerNameA, providerNameB);
  const containerIdA = watch('A.containerId');
  const containerIdB = watch('B.containerId');
  const providerIdentityIdA = watch('A.providerIdentityId');
  const providerIdentityIdB = watch('B.providerIdentityId');
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const [, containerTypeB] = useGetContainerTypes();

  const [hasSyncStatusErrors, hasSyncStatusWarnings] = useHasAnomaliesOnPage(linkId, PAGES.CONNECT_TOOLS);

  const areItemsCompatible = useProviderItemsCompatible({
    providerA,
    containerIdA,
    itemTypeA,
    providerB,
    containerIdB,
    itemTypeB,
    areProviderIdentitiesSelected: providerIdentityIdA && providerIdentityIdB,
  });

  // TODO: move to ConnectToolsRevamp when we're closer to being ready
  const isProviderAModernIntegration = useIsModernIntegration(providerA);
  const isProviderBModernIntegration = useIsModernIntegration(providerB);

  const isInWorkflow = !!workflowId;

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  const isAddOnImprovements = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.ADD_ON_IMPROVEMENTS),
  );

  const areBothSidesReadOnly = useAreBothSidesReadOnly({
    providerIdA: providerA.get('_id'),
    providerIdB: providerB.get('_id'),
    itemTypeA,
    itemTypeB,
    containerIdA,
    containerIdB,
  });

  const shouldDisableConfirmButton = useShouldDisableConfirmButton(providerA, providerB, loadingState);

  useEffect(() => {
    trackEvent(trackingTypes.START);
    if ([appTypes.EMBED.WRIKE, appTypes.EMBED.TRELLO].includes(embedName) && !linkId) {
      setValue('A.providerName', embedName, { shouldDirty: true });
      trackEvent(trackingTypes.ACTION, {
        action_name: 'selected a tool',
        selected_tool_name: embedName,
      });
    }
    // exclude trackEvent from the dep array to prevent extra dispatch of start event when doing first tool change
    // because url change triggers a re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { target: canCreateTarget } = useGetProvidersCanCreateWorkItem({
    containerIdA,
    containerIdB,
    providerNameA,
    providerNameB,
    itemTypeA,
    itemTypeB,
  });

  const handleOnSubmitClick = async () => {
    const providers = [
      { name: providerNameA, itemType: itemTypeA },
      { name: providerNameB, itemType: itemTypeB },
    ];

    providers.sort((a, b) => a.name.localeCompare(b.name));

    const sortedProviderNames = providers.map((p) => p.name).join(', ');
    const sortedItemTypes = providers.map((p) => p.itemType).join(', ');

    trackEvent(trackingTypes.EVENT_NAME.SUBMIT, {
      selected_tool_names: sortedProviderNames,
      selected_work_items: sortedItemTypes,
    });

    if (!isDuplicate) {
      redirectToPage(getRedirectPage({ canCreateTarget, setValue }));
      return;
    }

    setLoadingState(formUtils.loadingStates.SAVING);
    try {
      const { link: linkDuplicate } = await dispatch(linkActions.duplicateSync(linkId, formData, false));
      const linkDuplicateFormData = formUtils.linkPayloadToFlowBuilderFormData(
        fromJS({ ...linkDuplicate, duplicateLinkId: linkId }),
      );
      const { link: duplicatedLink } = await dispatch(draftActions.createDraft(linkDuplicateFormData));
      const duplicatedLinkFormData = formUtils.linkPayloadToFlowBuilderFormData(fromJS(duplicatedLink));
      reset(duplicatedLinkFormData, { keepValues: false, keepDirty: false, keepDirtyValues: false, keepErrors: true });
      setLoadingState(formUtils.loadingStates.SAVED);
      redirectToPage(getRedirectPage({ canCreateTarget, setValue }), duplicatedLink._id);
    } catch (err) {
      notification.error({
        message: 'A problem occurred while duplicating this flow.',
        description: 'Try again. If this error persists, please contact support.',
        placement: 'top',
      });

      setLoadingState(formUtils.loadingStates.SAVED);
    }
  };

  // when coming from workflow context, prevent changing providers/containers
  // since we don't support updating the blocks in the WF (even if we do for drafts normally).
  const isReadOnly = !isDraftFlow || isInWorkflow || (isEmbed && !isDraftFlow);

  const showAddOnConfiguration = (isAddOnImprovements && !isDraftFlow && !isDuplicate) || !isAddOnImprovements;

  let readOnlyTooltipIcon = 'info-circle';
  let readOnlyTooltip = 'Certain fields cannot be edited here as they may cause issues with the flow.';

  if (isDuplicate && !isInWorkflow) {
    readOnlyTooltip = (
      <>
        When duplicating a flow, you can change the{' '}
        <ProviderTermsByName
          providerNameB={providerNameB}
          plurality="plural"
          termKey="container"
          pcdv3
          itemTypeB={itemTypeB}
          containerTypeB={containerTypeB}
          containerIdB={containerIdB}
        />{' '}
        that are syncing, but not the connected tools or accounts.
      </>
    );
    readOnlyTooltipIcon = 'lock';
  } else if (isInWorkflow) {
    readOnlyTooltip = `Tools connected in the workflow designer can't be changed here. To choose a different tool or project, go back and create a flow with a new block of work.`;
    readOnlyTooltipIcon = 'lock';
  } else if (isEmbed) {
    readOnlyTooltip = `You cannot edit this side of the flow while using Unito embedded in ${capitalize(embedName)}`;
    readOnlyTooltipIcon = 'lock';
  }
  const breakpoints = NewGrid.useBreakpoint();
  const isLargeScreen = breakpoints.md || breakpoints.lg || breakpoints.xl || breakpoints.xxl;

  const { isLoading, isLoaded } = useGetAllProvidersIdentities();
  const isPageLoaded = isLoaded && !isLoading && loadingState !== formUtils.loadingStates.LOADING;
  const formErrors = formState.errors;

  const hasProviderIdentitiesError = !!formErrors.root?.[RESOURCE_ERROR_TYPE.PROVIDER_IDENTITIES_ERROR];
  const hasDuplicationError = !!formErrors.root?.[RESOURCE_ERROR_TYPE.DUPLICATION_ERROR];
  const shouldShowErrorState = !!hasProviderIdentitiesError || !!hasDuplicationError;

  if (shouldShowErrorState) {
    const [firstError] = Object.values(formErrors.root);
    if (firstError) {
      return (
        <Result
          status={firstError.type}
          title="Something went wrong"
          subTitle={firstError.message}
          extra={[
            <NewButton href={`/#${routes.ABSOLUTE_PATHS.DASHBOARD}`} type="default">
              Back to dashboard
            </NewButton>,
            <NewButton onClick={() => window.location.reload()} type="primary">
              Retry
            </NewButton>,
          ]}
        />
      );
    }
  }

  return (
    <PageContainer>
      <PageHeader
        title="Start by choosing the tools you want to connect"
        subtitle={
          <>
            You can either connect two different tools or connect work within the same tool.{' '}
            <OpenSidePanelLink
              onClick={() => {
                setIsSidePanelOpen(true);
                trackEvent(trackingTypes.ACTION, {
                  action_name: trackingTypes.FLOW_BUILDER_SIDE_PANEL.ACTIONS.NEED_HELP,
                  selected_tool_name: `${providerNameA},${providerNameB}`,
                });
              }}
            >
              Need some help selecting your tools?
            </OpenSidePanelLink>
            <ConnectToolSidePanel isOpen={isSidePanelOpen} onClose={() => setIsSidePanelOpen(false)} linkId={linkId} />
          </>
        }
      />
      <DuplicateBanner />
      {(hasSyncStatusErrors || hasSyncStatusWarnings) && (
        <SyncStatusPageAlert key="sync_status_connect_tools" page={PAGES.CONNECT_TOOLS} />
      )}

      <NewRow justify="center" gutter={0}>
        <NewCol lg={11} sm={24}>
          <Skeleton avatar paragraph title loading={!isPageLoaded}>
            <ConnectBox
              borderColor={tokens.colors.content.neutral.n10}
              p={[tokens.spacing.s4]}
              data-testid="sideATools"
            >
              {isProviderAModernIntegration ? (
                <WorkItemSelectorRevamp
                  side="A"
                  loadingState={loadingState}
                  isEdit={isReadOnly && !(isDuplicate && !isInWorkflow)}
                  isDuplicating={isDuplicating}
                />
              ) : (
                <WorkItemSelector
                  side="A"
                  loadingState={loadingState}
                  isEdit={isReadOnly && !(isDuplicate && !isInWorkflow)}
                  isDuplicating={isDuplicating}
                />
              )}
              {isReadOnly && (
                <InlineDiv>
                  <Tooltip content={readOnlyTooltip}>
                    <Icon aria-label={readOnlyTooltip} name={readOnlyTooltipIcon} kind={Icon.KINDS.SOLID} />
                  </Tooltip>
                </InlineDiv>
              )}
            </ConnectBox>
          </Skeleton>
        </NewCol>
        <NewCol lg={2} sm={24} justify="center">
          <Flex justify="center">
            <Divider
              type={isLargeScreen ? 'horizontal' : 'vertical'}
              style={{ height: tokens.spacing.s7 }}
              margin={isLargeScreen ? tokens.spacing.s8 : undefined}
            />
          </Flex>
        </NewCol>
        <NewCol lg={11} sm={24}>
          <Skeleton avatar paragraph title loading={!isPageLoaded}>
            <ConnectBox
              borderColor={tokens.colors.content.neutral.n10}
              p={[tokens.spacing.s4]}
              data-testid="sideBTools"
            >
              {isProviderBModernIntegration ? (
                <WorkItemSelectorRevamp
                  side="B"
                  loadingState={loadingState}
                  isEdit={isReadOnly && !(isDuplicate && !isInWorkflow)}
                  isDuplicating={isDuplicating}
                />
              ) : (
                <WorkItemSelector
                  side="B"
                  loadingState={loadingState}
                  isEdit={isReadOnly && !(isDuplicate && !isInWorkflow)}
                  isDuplicating={isDuplicating}
                />
              )}
              {isReadOnly && (
                <InlineDiv>
                  <Tooltip content={readOnlyTooltip}>
                    <Icon aria-label={readOnlyTooltip} name={readOnlyTooltipIcon} kind={Icon.KINDS.SOLID} />
                  </Tooltip>
                </InlineDiv>
              )}
            </ConnectBox>
          </Skeleton>
        </NewCol>
      </NewRow>

      <AlreadyActiveFlowAlert linkId={linkId} />
      {showAddOnConfiguration && (
        <NewRow>
          <NewCol lg={24} md={24}>
            <ExtraUserStep />
          </NewCol>
        </NewRow>
      )}
      {(isDraftFlow || isDuplicate) && (
        <Box>
          {(!areItemsCompatible || areBothSidesReadOnly) && (
            <Box m={[tokens.spacing.s5, 0, 0, 0]} data-testid="showRestrictedFlowCreationAlert">
              <Alert level="warning" size="md">
                <Bold>{`${providerA?.get('displayName')} ${itemTypeA ?? ''}`}</Bold> and{' '}
                <Bold>{`${providerB?.get('displayName')} ${itemTypeB ?? ''}`} flows are not supported</Bold> at the
                moment. Please{' '}
                <Href href="https://form.typeform.com/to/BD7KME9M?typeform-source=unito1.typeform.com" target="_blank">
                  let us know what you'd like to achieve
                </Href>{' '}
                and help Unito evolve to meet your needs.
              </Alert>
            </Box>
          )}
          <Box justifyContent="flex-end" m={[tokens.spacing.s7, 0, 0, 0]}>
            <Button
              disabled={shouldDisableConfirmButton}
              type="submit"
              loading={loadingState === formUtils.loadingStates.SAVING}
              onClick={handleOnSubmitClick}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      )}
    </PageContainer>
  );
};

ConnectTools.propTypes = {
  loadingState: PropTypes.oneOf(Object.values(formUtils.loadingStates)).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({ linkId: PropTypes.string, workflowId: PropTypes.string }).isRequired,
  }).isRequired,
  isDuplicating: PropTypes.bool.isRequired,
};

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLogger } from '~/hooks/useLogger';
import { areCustomFieldsLoaded, getIsCustomFieldsLoading } from '~/reducers';
import * as fieldActions from '~/actions/fields';

import { statuses } from '../consts';

export function useGetCustomFields({
  containerIdA,
  containerIdB,
  providerIdentityIdA,
  providerIdentityIdB,
  itemTypeA,
  itemTypeB,
}) {
  const dispatch = useDispatch();
  const { reportException } = useLogger();
  const isLoading = useSelector((state) => getIsCustomFieldsLoading(state));
  const areCFsLoadedA = useSelector((state) => areCustomFieldsLoaded(state, { containerSide: 'A' }));
  const areCFsLoadedB = useSelector((state) => areCustomFieldsLoaded(state, { containerSide: 'B' }));

  const [status, setStatus] = useState(isLoading ? statuses.LOADING : statuses.INITIAL);

  useEffect(() => {
    async function fetchCustomFields() {
      try {
        const fetchToDispatchCustomFields = [];

        if (!areCFsLoadedA) {
          fetchToDispatchCustomFields.push({
            containerId: containerIdA,
            providerIdentityId: providerIdentityIdA,
            containerSide: 'A',
            itemType: itemTypeA,
          });
        }
        if (!areCFsLoadedB) {
          fetchToDispatchCustomFields.push({
            containerId: containerIdB,
            providerIdentityId: providerIdentityIdB,
            containerSide: 'B',
            itemType: itemTypeB,
          });
        }
        // If the link is being loaded, the container ids might not be set yet
        if (status === statuses.INITIAL && containerIdA && containerIdB) {
          setStatus(statuses.LOADING);
          await Promise.all(
            fetchToDispatchCustomFields.map((fetchParams) => dispatch(fieldActions.getCustomFields(fetchParams))),
          );
          setStatus(statuses.SUCCESS);
        }
      } catch (err) {
        reportException(err, { containerIdA, containerIdB, providerIdentityIdA, providerIdentityIdB }); // FIXME: What to do if error in terms of UI
        setStatus(statuses.ERROR_CF);
      }
    }

    fetchCustomFields();
  }, [
    areCFsLoadedA,
    areCFsLoadedB,
    containerIdA,
    containerIdB,
    dispatch,
    itemTypeA,
    itemTypeB,
    providerIdentityIdA,
    providerIdentityIdB,
    reportException,
    status,
  ]);

  return status;
}

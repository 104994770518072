import { useSelector } from 'react-redux';
import { Map, List } from 'immutable';

import { getLinkById } from '~/reducers';

import { flowBuilderTypes } from '../consts';
import { getSyncDirection, getRulesBySide } from '../utils/form';

const getFilters = (link) => {
  const closedTasksA = link.getIn(['syncSettings', 'A', 'closedTasks'], null);
  const closedTasksB = link.getIn(['syncSettings', 'B', 'closedTasks'], null);
  if (closedTasksA === null && closedTasksB === null) {
    return null;
  }

  const rulesA = getRulesBySide(link, 'A');
  const rulesB = getRulesBySide(link, 'B');
  if (rulesA.length === 0 && rulesB.length === 0) {
    return null;
  }

  return [...rulesA, ...rulesB];
};

export function useDraftStatus(linkId) {
  let connectToolsStatus = flowBuilderTypes.STEP_STATUSES.WAITING;
  let flowDirectionStatus = flowBuilderTypes.STEP_STATUSES.WAITING;
  let rulesStatus = flowBuilderTypes.STEP_STATUSES.WAITING;
  let mappedFieldsStatus = flowBuilderTypes.STEP_STATUSES.WAITING;

  const link = useSelector((state) => getLinkById(state, linkId));

  if (linkId) {
    const sideA = link.get('A', Map());
    const sideB = link.get('B', Map());
    const syncDirection = getSyncDirection(link.get('syncSettings', Map()));
    const filters = getFilters(link);
    const fieldAssociations = link.getIn(['syncSettings', 'fieldAssociations'], List());

    if (!sideA.isEmpty() || !sideB.isEmpty()) {
      connectToolsStatus = flowBuilderTypes.STEP_STATUSES.DONE;
    }

    if (syncDirection && connectToolsStatus === flowBuilderTypes.STEP_STATUSES.DONE) {
      flowDirectionStatus = flowBuilderTypes.STEP_STATUSES.DONE;
    }

    if (filters?.length > 0 && flowDirectionStatus === flowBuilderTypes.STEP_STATUSES.DONE) {
      rulesStatus = flowBuilderTypes.STEP_STATUSES.DONE;
    }

    if (!fieldAssociations.isEmpty() && rulesStatus === flowBuilderTypes.STEP_STATUSES.DONE) {
      mappedFieldsStatus = flowBuilderTypes.STEP_STATUSES.DONE;
    }
  }

  return {
    [flowBuilderTypes.STEPS.CONNECT]: {
      status: connectToolsStatus,
    },
    [flowBuilderTypes.STEPS.FLOW_DIRECTION]: {
      status: flowDirectionStatus,
    },
    [flowBuilderTypes.STEPS.RULES]: {
      status: rulesStatus,
    },
    [flowBuilderTypes.STEPS.MAPPING]: {
      status: mappedFieldsStatus,
    },
    [flowBuilderTypes.STEPS.REVIEW]: {
      status: flowBuilderTypes.STEP_STATUSES.WAITING,
    },
  };
}

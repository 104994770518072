import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';

import { getProviderCapabilitiesById } from '~/reducers';
import { capitalize } from '~/utils/capitalize';

const PLURALITY = {
  SINGULAR: 'singular',
  PLURAL: 'plural',
};

function ProviderTermsInner({
  plurality = PLURALITY.SINGULAR,
  termKey,
  termsProviderA,
  termsProviderB,
  textTransform,
  middleWord = 'and',
}) {
  const termProviderA = termsProviderA.getIn([termKey, plurality]);
  const termProviderB = termsProviderB.getIn([termKey, plurality]);

  if (!termProviderA && !termProviderB) {
    return termKey;
  }

  if ((termProviderA && !termProviderB) || termProviderA === termProviderB) {
    return textTransform === 'capitalize' ? capitalize(termProviderA) : termProviderA;
  }

  if (!termProviderA && termProviderB) {
    return textTransform === 'capitalize' ? capitalize(termProviderB) : termProviderB;
  }

  return textTransform === 'capitalize'
    ? `${capitalize(termProviderA)} ${middleWord} ${termProviderB}`
    : `${termProviderA} ${middleWord} ${termProviderB}`;
}

ProviderTermsInner.propTypes = {
  middleWord: PropTypes.string,
  plurality: PropTypes.oneOf(Object.values(PLURALITY)),
  termKey: PropTypes.string.isRequired,
  termsProviderA: PropTypes.instanceOf(Map).isRequired,
  termsProviderB: PropTypes.instanceOf(Map).isRequired,
  textTransform: PropTypes.oneOf(['capitalize']),
};

const mapStateToProps = (state, ownProps) => ({
  termsProviderA: getProviderCapabilitiesById(state, ownProps.providerIdA, 'terms'),
  termsProviderB: getProviderCapabilitiesById(state, ownProps.providerIdB, 'terms'),
});

export const ProviderTerms = connect(mapStateToProps)(ProviderTermsInner);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Switch, Route, Redirect } from 'react-router-dom';

import { notification } from '@unitoio/mosaic';

import * as appActions from '~/actions/app';
import * as billingActions from '~/actions/billing';
import * as inviteActions from '~/actions/invites';
import {
  customerHasPaymentSource,
  getOrganizationCustomerId,
  getOrganizationSubscription,
  getShowInviteCoworkersModal,
  isOnCustomPlan,
  organizationHasActiveSubscription,
  getEmbedName,
} from '~/reducers';
import * as appTypes from '~/consts/app';
import * as trackingTypes from '~/consts/tracking';
import { Box } from '~/components/Box/Box';
import { Loading } from '~/components/Loading/Loading';
import { RoutedNavTabs } from '~/components/NavTabs/RoutedNavTabs';
import { InviteCoworkersModal } from '~/containers/InviteCoworkersModal/InviteCoworkersModal';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';

import { BillingHistory } from './BillingHistory/BillingHistory';
import { BillingInformation } from './BillingInformation/BillingInformation';
import { BillingOverview } from './BillingOverview/BillingOverview';

class BillingComponent extends Component {
  static propTypes = {
    currentSubscription: PropTypes.instanceOf(Map).isRequired,
    hasActiveSubscription: PropTypes.bool.isRequired,
    hasPaymentSource: PropTypes.bool.isRequired,
    hideInviteCoworkersModal: PropTypes.func.isRequired,
    isCustomPlan: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({ productName: PropTypes.string, organizationId: PropTypes.string }),
      url: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }).isRequired,
    orgCustomerId: PropTypes.string,
    organizationId: PropTypes.string.isRequired,
    setProductNameContext: PropTypes.func.isRequired,
    showInviteCoworkersModal: PropTypes.bool.isRequired,
    updateSubscription: PropTypes.func.isRequired,
    isWrikeEmbed: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    orgCustomerId: undefined,
  };

  state = {
    isSourceUpdating: false,
  };

  componentDidMount() {
    const { match, setProductNameContext } = this.props;
    setProductNameContext(appTypes.mapUrlParamToProductName[match.params.productName]);
  }

  updateSource = async (planId, stripeToken) => {
    const { updateSubscription } = this.props;
    this.setState({ isSourceUpdating: true });
    try {
      await updateSubscription(planId, stripeToken);
    } finally {
      this.setState({ isSourceUpdating: false });
    }
  };

  updateCreditCard = async (stripeToken) => {
    const { currentSubscription } = this.props;
    this.updateSource(currentSubscription.getIn(['plan', 'id']), stripeToken);
  };

  render() {
    const {
      currentSubscription,
      hasActiveSubscription,
      hasPaymentSource,
      hideInviteCoworkersModal,
      isCustomPlan,
      match,
      organizationId,
      orgCustomerId,
      showInviteCoworkersModal,
    } = this.props;
    const { isSourceUpdating } = this.state;
    const { isWrikeEmbed } = this.props;

    // Show the loading screen when the plans are being retrieved or when the subscription is updating
    if (isSourceUpdating) {
      return <Loading />;
    }

    const showBillingInformation =
      !currentSubscription.get('cancelAtPeriodEnd') && (hasActiveSubscription || hasPaymentSource);

    const ALL_TAB_ROUTES = [
      {
        path: `${match.url}/overview`,
        tab: 'Overview',
      },
      {
        path: `${match.url}/information`,
        tab: 'Billing Information',
      },
      {
        path: `${match.url}/history`,
        tab: 'Billing History',
      },
    ];

    const CUSTOM_TAB_ROUTES = [
      {
        path: `${match.url}/overview`,
        tab: 'Overview',
      },
    ];

    return (
      <div className="billing-container">
        <InviteCoworkersModal
          organizationId={organizationId}
          isOpen={showInviteCoworkersModal}
          onClose={hideInviteCoworkersModal}
        />
        <Box $m={[0, 0, 2]}>
          <RoutedNavTabs
            className="billing-container__navigation"
            isJustified={false}
            routes={isCustomPlan || isWrikeEmbed ? CUSTOM_TAB_ROUTES : ALL_TAB_ROUTES}
            tabStyle="underline"
            isSticky
          />
        </Box>
        <section>
          <Switch>
            <Route
              render={() => (
                <TrackingFunnel contextName={trackingTypes.SUB_PAGE.SETTINGS_USAGE}>
                  <BillingOverview />
                </TrackingFunnel>
              )}
              exact
              path={`${match.path}/overview`}
            />
            <Route
              render={(props) => (
                <BillingInformation
                  {...props}
                  showBillingInformation={showBillingInformation}
                  isPaymentSourceLoading={isSourceUpdating}
                  onUpdatePaymentSource={this.updateCreditCard}
                  orgCustomerId={orgCustomerId}
                  organizationId={organizationId}
                />
              )}
              exact
              path={`${match.url}/information`}
            />
            <Route
              render={(props) => <BillingHistory {...props} organizationId={organizationId} />}
              exact
              path={`${match.url}/history`}
            />
            <Redirect from={`${match.url}/`} to={`${match.url}/overview`} />
          </Switch>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateSubscription: async (planId, stripeToken) => {
    await dispatch(billingActions.updateSubscription(ownProps.match.params.organizationId, planId, stripeToken));
    notification.success({
      message: 'Subscription updated',
      description: 'Your billing information has been updated successfully and will be included in your next invoice.',
      placement: 'topRight',
    });
  },
  hideInviteCoworkersModal: () => dispatch(inviteActions.hideInviteCoworkersModal()),
  setProductNameContext: (productName) => dispatch(appActions.setProductNameContext(productName)),
});

const mapStateToProps = (state, ownProps) => {
  const { organizationId } = ownProps.match.params;
  const orgCustomerId = getOrganizationCustomerId(state, organizationId);

  return {
    organizationId,
    orgCustomerId,
    currentSubscription: getOrganizationSubscription(state, organizationId),
    hasActiveSubscription: organizationHasActiveSubscription(state, organizationId),
    hasPaymentSource: customerHasPaymentSource(state, orgCustomerId),
    isCustomPlan: isOnCustomPlan(state, organizationId),
    showInviteCoworkersModal: getShowInviteCoworkersModal(state),
    isWrikeEmbed: getEmbedName(state) === appTypes.EMBED.WRIKE,
  };
};

export const BillingContainer = connect(mapStateToProps, mapDispatchToProps)(BillingComponent);

import { useSelector } from 'react-redux';

import * as linkTypes from '~/consts/link';
import * as featureTypes from '~/consts/features';
import { getFeatureFlagValue, getLinkById } from '~/reducers';
import { useGetSyncStatusActivityAndHealth } from './useGetSyncStatusActivityAndHealth';

export function useGetSyncActivityStatus(linkId, isSuspended = false) {
  // old activity status, strip out when we move to revamp exclusively
  const [activity, health] = useGetSyncStatusActivityAndHealth(linkId);

  const hasSyncStatusRevampFlag = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.SYNC_STATUS_REVAMP),
  );

  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const linkState = currentLink.get('state');

  const isLinkStateDraft = linkState === linkTypes.LINK_STATES.DRAFT;
  const isLinkDegraded = linkTypes.DEGRADED_LINK_STATES.includes(linkState);

  if (isLinkStateDraft) {
    return linkTypes.LINK_STATES.DRAFT;
  }

  if (isSuspended) {
    return linkTypes.LINK_ACTIVITY_STATUS.SUSPENDED;
  }

  if (isLinkDegraded) {
    return linkTypes.LINK_ACTIVITY_STATUS.DEGRADED;
  }

  if (!hasSyncStatusRevampFlag) {
    return activity || health;
  }

  // sync status revamp logic
  const lastStatusCodeA = currentLink.getIn(['A', 'container', 'health', 'lastStatusCode'], null);
  const lastStatusCodeB = currentLink.getIn(['B', 'container', 'health', 'lastStatusCode'], null);

  // if one of the status code is not available, we consider the link as healthy until proven otherwise
  if (!lastStatusCodeA || !lastStatusCodeB) {
    return linkTypes.LINK_ACTIVITY_STATUS.HEALTHY;
  }

  if (lastStatusCodeA.toString().startsWith(2) && lastStatusCodeB.toString().startsWith(2)) {
    return linkTypes.LINK_ACTIVITY_STATUS.HEALTHY;
  }

  return linkTypes.LINK_ACTIVITY_STATUS.DEGRADED;
}

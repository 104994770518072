import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, Icon, Link, SearchInput, Toggle, Typography, TypographyVariants, tokens } from '@unitoio/mosaic';

import * as activityLogsActions from '~/actions/activityLogs';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { useDataTable } from '~/hooks/useDataTable';
import { useInterval } from '~/hooks/useInterval';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import {
  getActivityLogs,
  getIsLoadingActivityLogs,
  getLastActivityLogsFetchTimestamp,
  getProviderByName,
} from '~/reducers';
import { ActivityLogsDataTable } from '~/components/ActivityLogsDataTable/ActivityLogsDataTable';

import { dataTableSort, minimizedActivityLogsColumns } from '../../../ActivityLogs/activityLogsConstants';
import { SidePanel } from './SidePanel/SidePanel';
import { EmptyActivityStream } from './SyncStatusFormActivityStreamEmptyState';

const getErrorState = (error, isSearchingByURL) => {
  if (isSearchingByURL) {
    return (
      <Typography variant={Typography.variants.BODY2} align={Typography.aligns.CENTER}>
        <Icon
          color={tokens.colors.content.warning.default}
          name="exclamation-triangle"
          kind={Icon.KINDS.SOLID}
          key="exclamation-triangle"
        />{' '}
        There was an error getting this item's information, try again later.
      </Typography>
    );
  }

  return error?.message
    ? `${error.message} after turning on Auto-refresh via the toggle above`
    : 'Oops, something went wrong. Please try again in a moment.';
};

const trackUrlPaste = (trackEvent) => {
  trackEvent(trackingTypes.ACTION, {
    action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.PASTE_URL,
  });
};

function SyncStatusFormActivityStream({ changePage, link }) {
  const linkId = link.get('_id');
  const organizationId = link.getIn(['organization', '_id'], link.get('organization'));
  const providerA = useSelector((state) => getProviderByName(state, link.getIn(['A', 'providerName'])));
  const providerB = useSelector((state) => getProviderByName(state, link.getIn(['B', 'providerName'])));

  const dispatch = useDispatch();
  const { reportException } = useLogger();
  const trackEvent = useTrackEvent();
  const history = useHistory();

  const [isLiveData, setIsLiveData] = useState(true);
  const [isInitialLogsFetch, setIsInitialLogsFetch] = useState(true);
  const [urlSearch, setURLSearch] = useState('');
  const [error, setError] = useState(null);

  const [selectedItemId, setSelectedItemId] = useState(null);

  const activityLogsData = useSelector((state) => getActivityLogs(state));
  const isFetchingActivityLogs = useSelector(getIsLoadingActivityLogs);
  const lastActivityFetchTimestamp = useSelector(getLastActivityLogsFetchTimestamp);

  const humanReadableLastActivityFetchDate = lastActivityFetchTimestamp
    ? new Date(lastActivityFetchTimestamp).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'UTC',
        timeZoneName: 'short',
      })
    : 'N/A';

  const minimizedActivityLogsColumnsValue = useMemo(() => minimizedActivityLogsColumns(setSelectedItemId), []);

  const tableData = activityLogsData.get('activityLogs').toJS();
  const recordsCount = activityLogsData.get('activityLogsCount');

  const fetchActivityLogs = useCallback(
    async (page = 0, pageSize = 10, urlSearchValue = '') =>
      new Promise((resolve, reject) => {
        setError(null);
        const fetchActivity = async () => {
          if (!isFetchingActivityLogs) {
            try {
              const res = await dispatch(
                activityLogsActions.fetchActivityLogs(
                  organizationId,
                  page,
                  pageSize,
                  { generalSearch: `${linkId},${urlSearchValue}` },
                  [{ field: 'changeTime', direction: 'desc' }],
                  null,
                  true,
                ),
              );

              resolve(res);
            } catch (err) {
              reportException(err);
              setError(err);
              setIsLiveData(false);

              reject(err);
            }
            setIsInitialLogsFetch(false);
          }
        };

        fetchActivity();
      }),
    [dispatch, isFetchingActivityLogs, linkId, organizationId, reportException],
  );

  const dataTableData = useDataTable({
    dataTableSort,
    recordsCount,
    columns: minimizedActivityLogsColumnsValue,
    data: tableData,
    fetchData: fetchActivityLogs,
    currentPage: 0,
    pageSize: 10,
    urlSearch,
  });

  useInterval(() => fetchActivityLogs(0, 10, urlSearch), isLiveData && !selectedItemId ? 5000 : null);

  // Wrapping in useCallback because the Mimic's side panel implementation expects a callback in order to prevent unecessary re-rendering
  const closeSidePanel = useCallback(() => {
    setSelectedItemId(null);
  }, []);

  const handleOnInputChange = async (e) => {
    setURLSearch(e.target.value);
    try {
      const response = await fetchActivityLogs(0, 10, e.target.value);

      const actionName = response.activityLogsCount
        ? trackingTypes.ACTIVITY_LOG.ACTIONS.WORK_ITEM_FOUND
        : trackingTypes.ACTIVITY_LOG.ACTIONS.NO_ITEM_FOUND;

      trackEvent(trackingTypes.ACTION, {
        action_name: actionName,
      });
    } catch (err) {
      trackEvent(trackingTypes.BLOCKED, {
        reason: trackingTypes.ACTIVITY_LOG.ACTIONS.ERROR_SEARCHING,
      });
    }
  };

  return (
    <>
      {selectedItemId && <SidePanel isOpen={!!selectedItemId} itemId={selectedItemId} onClose={closeSidePanel} />}

      <Box
        m={[0, 0, 1, 0]}
        flexDirection={Box.flexDirection.ROW}
        alignItems={Box.alignItems.FLEX_END}
        justifyContent={Box.justifyContent.SPACE_BETWEEN}
      >
        <Box>
          <Box m={[0, 0, tokens.spacing.s2, 0]}>
            <Box flexDirection={Box.flexDirection.ROW} alignItems={Box.alignItems.CENTER}>
              <Typography variant={TypographyVariants.H3}>Most recent activity</Typography>
              <Button
                variant="subtle"
                size="sm"
                type="href"
                onClick={() => {
                  trackEvent(trackingTypes.ACTION, {
                    action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.OPEN_DIAGNOSTICS,
                  });
                  history.push(`${routes.ABSOLUTE_PATHS.ACTIVITY_LOGS}?search=${linkId}`);
                }}
              >
                <Icon name="external-link" />
              </Button>
            </Box>
          </Box>
          <Box m={[0, 0, tokens.spacing.s5, 0]} flexDirection={Box.flexDirection.ROW}>
            <Box>
              <Typography variant={TypographyVariants.BODY1} color={tokens.colors.content.neutral.n30}>
                If you want to find the activity for a specific item paste its URL below to get more information.
                <Link
                  href="https://guide.unito.io/how-to-find-an-item-url"
                  isExternalLink
                  onClick={() =>
                    trackEvent(trackingTypes.ACTION, {
                      action_name: trackingTypes.ACTIVITY_LOG.ACTIONS.HELP_URL,
                    })
                  }
                >
                  Need help finding your item's URL?
                </Link>
              </Typography>
            </Box>

            <Box flexDirection={Box.flexDirection.ROW} m={[0, 0, 0, tokens.spacing.s9]}>
              <Box>
                <Toggle value={isLiveData} onClick={() => setIsLiveData(!isLiveData)} />
              </Box>
              <Box m={[0, 0, 0, tokens.spacing.s3]}>
                <Typography align="center">Auto-refresh</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <SearchInput
              placeholder="Search for an item by its URL"
              value={urlSearch}
              onChange={handleOnInputChange}
              label="urlSearchInput"
              onClear={() => setURLSearch('')}
              onPaste={() => trackUrlPaste(trackEvent)}
            />
          </Box>
        </Box>
      </Box>
      <Box
        overflow={Box.overflow.AUTO}
        borderRadius={tokens.spacing.s4}
        borderSize={1}
        borderColor={tokens.colors.content.neutral.n10}
        borderStyle="solid"
      >
        <ActivityLogsDataTable
          fixedTableWidth
          dataTableData={dataTableData}
          hasData={tableData.length !== 0}
          isLoading={isInitialLogsFetch || (isFetchingActivityLogs && !isLiveData)}
          emptyStateComponent={
            error ? (
              getErrorState(error, !!urlSearch)
            ) : (
              <EmptyActivityStream
                changePage={changePage}
                link={link}
                providerIdA={providerA.get('name')}
                providerIdB={providerB.get('name')}
                isSearchingByURL={!!urlSearch}
              />
            )
          }
          isSearchingByURL={!!urlSearch}
        />
      </Box>
      <Box m={[tokens.spacing.s3, 0, 0, 0]}>
        <Typography color={tokens.colors.content.neutral.n30} fontSize={tokens.fontSize.f6}>
          Last updated: {humanReadableLastActivityFetchDate}
        </Typography>
      </Box>
    </>
  );
}

SyncStatusFormActivityStream.propTypes = {
  changePage: PropTypes.func.isRequired,
  link: PropTypes.instanceOf(Map).isRequired,
};

const SyncStatusFormActivityStreamWithTracking = (props) => (
  <TrackingFunnel contextName={trackingTypes.FUNNELS.ACTIVITY_LOG}>
    <SyncStatusFormActivityStream {...props} />
  </TrackingFunnel>
);

export { SyncStatusFormActivityStreamWithTracking as SyncStatusFormActivityStream };

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import { FlowBuilderErrorContext } from '~/contexts';
import * as containerTypes from '~/consts/containers';
import * as trackingTypes from '~/consts/tracking';
import { getProviderCapabilitiesV3, getProviderCapabilitiesByProviderIdentityId } from '~/reducers';
import { ContainerAlerts } from '~/containers/ContainerAlerts/ContainerAlerts';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { isValidUrl } from '~/utils/isValidUrl';
import { capitalize } from '~/utils/capitalize';
import { SelectField } from '~/components/SelectField/SelectField';

import { useFetchContainers } from '../../containers/FlowBuilder/hooks/useFetchContainers';
import { ContainerErrors } from './ContainerErrors';
import { NewProjectModalRevamp } from '../ContainersSelectRevamp';
import * as formUtils from '../../containers/FlowBuilder/utils/form';
import { SelectorLabel, WORK_ITEM_SELECTOR_VARIANT } from './SelectorLabel';
import { useIsFlowDuplicate } from '../../containers/FlowBuilder/hooks/useIsFlowDuplicate';

export const getContainerOptions = (containers, valuesToDisable) => {
  const recursivelyCleanupChildOption = (option) =>
    option.children.reduce((acc, child) => {
      if (!child.children || child.children.length === 0) {
        return [
          ...acc,
          {
            label: child.displayName,
            value: child.id,
            disabled: valuesToDisable.includes(child.id),
          },
        ];
      }

      const withChildrens = {
        label: child.displayName,
        ...(child.selectable ? { value: child.id } : {}),
        children: recursivelyCleanupChildOption(child),
      };
      return [...acc, withChildrens];
    }, []);

  return containers.map((firstLevelContainer) => {
    // first level children are the workspace

    // if there is no children and the container is not selectable, we return an empty options array
    // to make sure the container is displayed as the workspace and not selectable
    if (firstLevelContainer.children?.length === 0 && !firstLevelContainer.selectable) {
      return {
        disabled: valuesToDisable.includes(firstLevelContainer.id),
        label: firstLevelContainer.displayName,
        options: [],
      };
    }

    if (!firstLevelContainer.children || firstLevelContainer.children.length === 0) {
      return {
        disabled: valuesToDisable.includes(firstLevelContainer.id),
        label: firstLevelContainer.displayName,
        value: firstLevelContainer.id,
      };
    }

    // else start looping recursively in the childrens to build the rest of the tree
    return {
      disabled: valuesToDisable.includes(firstLevelContainer.id),
      label: firstLevelContainer.displayName,
      options: recursivelyCleanupChildOption(firstLevelContainer),
    };
  });
};

export const ContainerSelect = ({
  readOnly = false,
  valuesToDisable = [],
  side,
  provider,
  onChange = () => null,
  onChangeContainerType = () => null,
  workItemSelectorVariant = WORK_ITEM_SELECTOR_VARIANT.NATURAL_LANGUAGE,
  label = 'in the',
  isLoading = false,
  selectedContainerType = null,
}) => {
  const trackEvent = useTrackEvent();

  const {
    watch,
    formState: { errors },
  } = useFormContext();
  const selectedProviderName = watch(`${side}.providerName`);
  const selectedContainerId = watch(`${side}.containerId`);
  const providerIdentityId = watch(`${side}.providerIdentityId`);
  const selectedItemType = watch(`${side}.itemType`);
  const {
    params: { workflowId },
  } = useRouteMatch();
  const isDuplicate = useIsFlowDuplicate();
  const pageName = useContext(FlowBuilderErrorContext);
  const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const capabilitiesV2 = useSelector((state) =>
    getProviderCapabilitiesByProviderIdentityId(state, { providerIdentityId }),
  );
  const capabilitiesV3 = useSelector((state) =>
    getProviderCapabilitiesV3(state, {
      providerIdentityId,
      providerName: selectedProviderName,
      itemType: selectedItemType,
    }),
  );

  const itemTypeContainers = capabilitiesV3.get('containers', Map());
  const isSearchable = capabilitiesV3.getIn(['containers', selectedContainerType, 'searchable', 'isSearchable']);

  const [loadedState, containers] = useFetchContainers({
    containerId: selectedContainerId,
    containerType: selectedContainerType,
    providerIdentityId,
    isSearchable,
    searchValue,
    side,
    itemType: selectedItemType,
  });

  const providerDisplayName = provider.get('displayName');
  // we don't want to call getContainerOptions function if container selector is present on screen at all time.
  // i.e: one click
  const containerOptions = providerIdentityId ? getContainerOptions(containers, valuesToDisable) : [];

  const rawContainerTerm = capabilitiesV3.getIn(['containers', selectedContainerType, 'names', 'singular'], 'project');
  const containerTerm = capitalize(rawContainerTerm);

  const termArticle = formUtils.hasVowelAsFirstLetter(containerTerm) ? 'an' : 'a';
  const containerSelectPlaceholder = `Choose ${termArticle} ${rawContainerTerm}`;

  const customOptionsFilter = isValidUrl(searchValue) ? (options) => options : undefined;

  const containerTypeOptions = itemTypeContainers
    .map((container) => ({
      value: container.getIn(['names', 'native']),
      label: capitalize(container.getIn(['names', 'singular'])),
    }))
    .toArray();

  // We rely on capabilities v2 for the workspace because we don't have
  // hierarchy yet. Without that, create a container with mandatory workspace
  // are broken. "workspace" doesn't exist in capabilities v3.
  const workspaceTerm =
    capabilitiesV3.getIn(['workspaces', selectedContainerType, 'names', 'singular'], '') ||
    capabilitiesV2.getIn(['terms', 'workspace', 'singular'], '');

  const searchPlaceholder = formUtils.getSearchContainerPlaceholdertext(capabilitiesV3, selectedContainerType);
  function getOnInputChange() {
    if (!isSearchable) {
      return () => {};
    }

    return debounce((value) => {
      if (!value) {
        return;
      }
      const valueToSet = value.trim().length === 0 ? null : value;
      setSearchValue(valueToSet);
    }, containerTypes.DEBOUNCE_TIMEOUT);
  }

  const hasNoContainerOptionsError = containerOptions.some((option) => option.options?.length === 0);
  let containerOptionsError = null;
  if (hasNoContainerOptionsError) {
    containerOptionsError = `Unito can't access ${providerDisplayName} data. Please try again in a few minutes or reach out to help@unito.io`;
  }

  // if we have an actual container error (not auth related), we can display it here.
  const containerError = errors[pageName]?.[side]?.containerId?.error ?? containerOptionsError;

  // Check if the whole side has errors (not just containerId)
  // we do not want to show a loading state if we have an error because we would just display
  // a forever loading icon
  const containerSideHasError = errors[pageName]?.[side];

  if (isLoading) {
    <LoadingPlaceholder width={tokens.spacing.s9} height={tokens.spacing.s6} borderRadius={tokens.spacing.s4} />;
  }

  const isReadOnlyContainerType = readOnly || (selectedContainerType && containerTypeOptions.length === 1);
  // To make the UI less clunky, we don't display the loading state when field is read only and already preselected
  const isLoadingContainerTypes =
    !isReadOnlyContainerType &&
    selectedContainerId &&
    loadedState !== formUtils.loadingStates.LOADED &&
    loadedState !== formUtils.loadingStates.ERROR;
  return (
    <>
      <NewProjectModalRevamp
        providerIdentityId={providerIdentityId}
        providerDisplayName={provider.get('displayName')}
        closeModal={() => setIsCreateNewOpen(false)}
        handleOnChangeNewContainer={async (container) => {
          await onChange(container, selectedContainerType);
        }}
        isOpen={isCreateNewOpen}
        containerTerm={containerTerm}
        workspaceTerm={workspaceTerm}
        containerType={selectedContainerType}
        itemType={selectedItemType}
      />
      <SelectorLabel workItemSelectorVariant={workItemSelectorVariant} withMargin label={label}>
        {workItemSelectorVariant !== WORK_ITEM_SELECTOR_VARIANT.LABEL_INPUT_BLOCK && (
          <SelectField
            value={selectedContainerType}
            name={`${side}.containerType`}
            readOnly={isReadOnlyContainerType}
            isLoading={isLoadingContainerTypes}
            searchable
            placeholder="Choose a container"
            onChange={onChangeContainerType}
            options={containerTypeOptions}
            fullWidth
            disabled={!providerIdentityId}
          />
        )}
        {selectedContainerType && (
          <SelectField
            value={selectedContainerId}
            name={`${side}.containerId`}
            createText={`Create a new ${provider.get('displayName')} ${containerTerm}`}
            readOnly={readOnly}
            noResultsText={`No ${containerTerm} found`}
            searchPlaceholder={searchPlaceholder}
            optionsLoading={loadedState === formUtils.loadingStates.LOADING}
            isLoading={
              selectedContainerId &&
              loadedState !== formUtils.loadingStates.LOADED &&
              loadedState !== formUtils.loadingStates.ERROR
            }
            isError={!!containerSideHasError || !!hasNoContainerOptionsError}
            searchable
            onFilter={customOptionsFilter}
            placeholder={containerSelectPlaceholder}
            onCreate={
              capabilitiesV3.getIn(['containers', selectedContainerType, 'canCreate'])
                ? () => {
                    trackEvent(trackingTypes.EVENT_NAME.ACTION, {
                      selected_tool_name: selectedProviderName,
                      action_name: 'clicked on create new container',
                    });
                    setIsCreateNewOpen(true);
                  }
                : undefined
            }
            onChange={async (containerId) => {
              // we need to return the full container to save a bunch of infos from it in the link
              // but look it up against the original loaded containers, the containerOptions don't
              // have the full container info
              let container = containers.find((container) => {
                // for nested containers like Asana, Trello, etc, check in children since actual containers
                // are grouped per workspace within the tool
                if (container.children) {
                  return container.children.some((option) => option.id === containerId);
                }
                return container.id === containerId;
              });
              if (container.children) {
                container = container.children.find((option) => option.id === containerId);
              }
              const { id = containerId, url, displayName, path } = container || {};
              await onChange({ id, url, displayName, path }, selectedContainerType);
            }}
            options={containerOptions}
            onSearch={getOnInputChange()}
            isHighlighted={isDuplicate && !workflowId}
            disabled={!providerIdentityId}
          />
        )}
      </SelectorLabel>
      <ContainerErrors containerError={containerError} selectedProviderName={selectedProviderName} />
      <ContainerAlerts severity="warning" providerIdentityId={providerIdentityId} containerId={selectedContainerId} />
    </>
  );
};

ContainerSelect.propTypes = {
  provider: PropTypes.instanceOf(Map).isRequired,
  side: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  valuesToDisable: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  workItemSelectorVariant: PropTypes.oneOf(Object.values(WORK_ITEM_SELECTOR_VARIANT)),
  label: PropTypes.string,
  onChangeContainerType: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedContainerType: PropTypes.string,
};

import { Map } from 'immutable';

import { useSelector } from 'react-redux';
import { getLinkById } from '~/reducers';

export const getTestModeTermToUse = (link = Map(), asAdverb = false) => {
  const earliestCreatedAt = link.getIn(['syncSettings', 'earliestCreatedAt']);
  const createdAt = link.get('createdAt');
  if (earliestCreatedAt && createdAt) {
    const earliestCreatedAtDate = new Date(earliestCreatedAt);
    const createdAtDate = new Date(createdAt);
    const shouldUseRecentTerm = earliestCreatedAtDate < createdAtDate;
    if (shouldUseRecentTerm) {
      return asAdverb ? 'recently' : 'recent';
    }
  }
  return asAdverb ? 'newly' : 'new';
};

// If a flow has an earliestCreatedAt date set to before its creation date
// we'll want to use the copy 'recent' rather than 'new' to make it less confusing to users.
export const useGetTestModeTerm = (linkId, asAdverb = false) => {
  // using the redux selector here because this hook is used outside of the FlowBuilder as well.
  const link = useSelector((state) => getLinkById(state, linkId));
  return getTestModeTermToUse(link, asAdverb);
};

import PropTypes from 'prop-types';
import React from 'react';
import { fromJS } from 'immutable';
import { useHistory } from 'react-router-dom';

import { Icon, Dropdown, Flex, tokens } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import { DuplicateDropdownItem } from '~/components/LinkItem/DuplicateDropdownItem';
import { getHasTestModeEnabled } from '~/containers/FlowBuilder/hooks/useHasTestModeEnabled';
import { getTestModeTermToUse } from '~/containers/FlowBuilder/hooks/useGetTestModeTerm';

export const FlowActions = ({ link, deleteCallback, turnOffTestMode }) => {
  const history = useHistory();
  const linkId = link.key;
  const hasTestModeEnabled = getHasTestModeEnabled(fromJS(link));
  const isSuspended = link.isSuspended;
  const linkState = link.state;
  const restricted = link.restricted ?? false;
  const testModeTerm = getTestModeTermToUse(fromJS(link));

  const isLinkStateDraft = linkState === linkTypes.LINK_STATES.DRAFT;
  const showRemoveTestModeButton = hasTestModeEnabled && !isSuspended && !restricted && !isLinkStateDraft;

  const getItemsOptions = () => {
    const items = [];

    if (restricted && !isLinkStateDraft) {
      items.push({
        key: 'edit',
        label: (
          <Flex style={{ alignItems: 'center' }}>
            <Icon name="lock" kind={Icon.KINDS.SOLID} fixedWidth style={{ marginRight: tokens.spacing.s2 }} />
            <small>You will need to contact the author to edit this flow.</small>
          </Flex>
        ),
      });
    }

    if (!isSuspended && !restricted && !isLinkStateDraft) {
      items.push({
        key: 'edit',
        label: (
          <Flex style={{ alignItems: 'center' }}>
            <Icon name="pencil" kind={Icon.KINDS.SOLID} fixedWidth style={{ marginRight: tokens.spacing.s2 }} />
            <div>Edit</div>
          </Flex>
        ),
        onClick: () => {
          history.push(routes.getEditFlowBuilderRoute(linkId));
        },
      });
    }

    if (!isLinkStateDraft) {
      items.push({
        key: 'duplicate',
        label: <DuplicateDropdownItem link={fromJS(link)} linkId={linkId} />,
      });
    }

    if (showRemoveTestModeButton) {
      items.push({
        key: 'remove-test-mode',
        label: (
          <Flex style={{ alignItems: 'center' }}>
            <Icon name="rocket" kind={Icon.KINDS.SOLID} fixedWidth style={{ marginRight: tokens.spacing.s2 }} /> Remove
            "only {testModeTerm} items"
          </Flex>
        ),
        onClick: () => {
          turnOffTestMode();
        },
      });
    }

    if (isLinkStateDraft) {
      items.push({
        key: 'resume-draft',
        label: (
          <Flex style={{ alignItems: 'center' }}>
            <Icon name="pencil" kind={Icon.KINDS.SOLID} fixedWidth style={{ marginRight: tokens.spacing.s2 }} /> Edit
            draft
          </Flex>
        ),
        onClick: () => {
          history.push(`${routes.getEditFlowBuilderRoute(linkId)}`);
        },
      });
    }

    if (!restricted) {
      items.push({
        key: 'delete',
        label: (
          <Flex style={{ alignItems: 'center' }}>
            <Icon name="trash" kind={Icon.KINDS.SOLID} fixedWidth style={{ marginRight: tokens.spacing.s2 }} /> Delete
          </Flex>
        ),
        onClick: () => {
          deleteCallback(fromJS(link));
        },
      });
    }

    return items;
  };

  return (
    <Dropdown menu={{ items: getItemsOptions() }}>
      <Icon name="ellipsis" data-testid={`flow-actions-dropdown-${link.key}`} />
    </Dropdown>
  );
};

// abstraction from the antD specs for a table render
export const FlowActionsRenderer = (_text, link, deleteCallback) => (
  <FlowActions link={link} deleteCallback={deleteCallback} />
);

FlowActions.propTypes = {
  link: PropTypes.shape({
    key: PropTypes.string,
  }).isRequired,
  deleteCallback: PropTypes.func.isRequired,
};

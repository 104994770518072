import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  Alert,
  AlertLevels,
  AlertSizes,
  Box,
  Modal,
  notification,
  TagsInput,
  tokens,
  Typography,
} from '@unitoio/mosaic';

import { getOrganizationById } from '~/reducers';
import * as inviteActions from '~/actions/invites';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import * as formUtils from '~/utils/forms';

const inputValidator = (value) => {
  if (!formUtils.validateEmailAddress(value)) {
    return `The email address "${value}" is not properly formatted.`;
  }
  return undefined;
};

const InviteCoworkersModalInner = ({ isOpen, onClose, organizationId }) => {
  const organization = useSelector((state) => getOrganizationById(state, organizationId));

  const [failedInvites, setFailedInvites] = useState([]);
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      coworkers: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'coworkers',
  });

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
    }
  }, [isOpen, trackEvent]);

  const handleOnAddEmail = (newEmail) => {
    trackEvent(trackingTypes.ACTION, {
      action_name: trackingTypes.VIRALITY.EXPERIMENTS.ADD_ANOTHER_COLLABORATOR,
    });
    append({ value: newEmail.trim() });
  };

  const handleOnRemoveEmail = (_removeEmail, index) => {
    remove(index);
  };

  const onSubmit = async (data) => {
    const { coworkers } = data;

    const localFailedInvites = [];

    const responses = await Promise.allSettled(
      coworkers.map((coworker) => dispatch(inviteActions.sendInvite(organizationId, coworker.value))),
    );

    responses.forEach((response, index) => {
      if (response.status === 'rejected') {
        localFailedInvites.push(coworkers[index].value);
      }
    });

    const inviteQty = coworkers.length - failedInvites.length;
    const hasFailedInvite = !!localFailedInvites.length;

    trackEvent(trackingTypes.SUBMIT, { invite_qty: inviteQty });
    if (hasFailedInvite) {
      trackEvent(trackingTypes.BLOCKED, {
        reason: 'we could not send email to invite',
        invite_qty: inviteQty,
        invite_qty_failed: failedInvites.length,
      });
      setFailedInvites(localFailedInvites);
    } else {
      notification.success({
        message: 'Invitations have been sent',
        description: `We've sent an email to ${inviteQty} person with instructions to join your workspace.`,
        placement: 'topRight',
      });

      reset();
      onClose();
    }
  };

  const handleOnClose = () => {
    trackEvent(trackingTypes.ACTION, { action_name: 'close' });
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      size={Modal.sizes.LG}
      displayCloseButton
      confirmLabel="Send invitations"
      isConfirmButtonDisabled={fields.length === 0}
      displayCancelButton={false}
      onConfirm={handleSubmit(onSubmit)}
      onRequestClose={handleOnClose}
      title={`Invite members to ${organization.get('name')}`}
    >
      <form
        // Prevent the page from refreshing when pressing enter key
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <TagsInput
          tags={fields.map((tag) => tag.value)}
          onAddTag={handleOnAddEmail}
          onRemoveTag={handleOnRemoveEmail}
          placeholder="Add one or more email addresses"
          inputValidator={inputValidator}
        />
        {!!failedInvites.length && (
          <Box m={[tokens.spacing.s4, 0, 0, 0]}>
            <Alert level={AlertLevels.ERROR} size={AlertSizes.SM}>
              <Typography variant={Typography.variants.BODY2}>
                We were unable to send invitations to the following email addresses:{' '}
                {failedInvites.map((failedInvite, index) => {
                  if (index === failedInvites.length - 1) {
                    return <span key={failedInvite}>{failedInvite}</span>;
                  }
                  return <span key={failedInvite}>{failedInvite}, </span>;
                })}
                . Please try again later.
              </Typography>
            </Alert>
          </Box>
        )}

        <Box m={[tokens.spacing.s4, 0, 0, 0]}>
          <Typography variant={Typography.variants.BODY2} color={tokens.colors.content.neutral.n30}>
            Press Enter/⏎ Return after each email address.
          </Typography>
          <Typography variant={Typography.variants.BODY2} color={tokens.colors.content.neutral.n30}>
            Your teammates will receive an invitation email and receive access to this workspace.
          </Typography>
        </Box>
      </form>
    </Modal>
  );
};

InviteCoworkersModalInner.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export const InviteCoworkersModal = (props) => (
  <TrackingFunnel
    contextName={trackingTypes.VIRALITY.VIRALITY}
    sharedProperties={{ virality_experiment: trackingTypes.VIRALITY.EXPERIMENTS.INVITE_USERS }}
  >
    <InviteCoworkersModalInner {...props} />
  </TrackingFunnel>
);

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { tokens } from '@unitoio/mosaic';

import { getSelectedOrganizationId, getOrganizationPlanProfile } from '~/reducers';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import * as organizationActions from '~/actions/organizations';
import { getPlanTier } from '~/utils/getPlanTier';
import { borderRadius, fontSize } from 'theme';

import Check from '~/images/Check.svg';
import { Input } from '~/components/Input/Input';
import { Modal } from '~/components/Modal/Modal';
import { Button } from '~/components/Button/Button';
import { Box } from '~/components/Box/Box';

const SuccessfulPaymentSection = styled.div`
  background-color: ${tokens.colors.background.message.positive};
  text-align: left;
  padding: ${tokens.spacing.s5};
  margin-top: ${tokens.spacing.s4};
  border-radius: ${borderRadius.double};
`;

const ModalTitle = styled.h3`
  font-size: ${fontSize.h3};
  text-align: center;
  margin: ${tokens.spacing.s6} 0;
`;

const FeedbackButton = styled(Button)`
  margin: 0 ${tokens.spacing.s3} ${tokens.spacing.s3} 0;
`;

const FEEDBACK_VALUES = {
  searchEngine: { label: 'Search Engine', followUpQuestion: 'What did you search?' },
  marketPlace: { label: 'Marketplace (Trello, etc.)', followUpQuestion: 'Which marketplace?' },
  coworker: { label: 'Coworker', followUpQuestion: 'How did your coworker describe Unito?' },
  friend: { label: 'Friend', followUpQuestion: 'How did your friend describe Unito?' },
  onlineArticle: { label: 'Online article', followUpQuestion: 'Where was the online article?' },
  youtube: { label: 'YouTube', followUpQuestion: 'Which YouTube channel?' },
  reviewWebsite: { label: 'Review website', followUpQuestion: 'Which review site?' },
  socialMedia: { label: 'Social media', followUpQuestion: 'Which social media site?' },
  other: { label: 'Other', followUpQuestion: 'Can you tell us more information?' },
};

const FeedbackButtons = ({ activeValue, onSelectFeedback }) => {
  const trackEvent = useTrackEvent();

  const handleClickOption = (value, label) => {
    trackEvent(trackingTypes.ACTION, {
      survey_name: 'first hear about Unito',
      action_name: `clicked on ${label}`,
    });
    onSelectFeedback(value);
  };

  return Object.entries(FEEDBACK_VALUES).map(([value, { label }]) => (
    <FeedbackButton
      key={value}
      reverse={activeValue !== value}
      btnStyle="darkBlue"
      onClick={() => handleClickOption(value, label)}
    >
      {label}
    </FeedbackButton>
  ));
};

FeedbackButtons.propTypes = {
  activeValue: PropTypes.string,
  onSelectFeedback: PropTypes.func.isRequired,
};

export function ConversionFeedbackModal({ onSubmit, isOpen, onClose }) {
  const [feedback, setFeedback] = useState(null);
  const dispatch = useDispatch();
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));

  const orgPlanProfile = useSelector((state) => getOrganizationPlanProfile(state));
  const orgPlanId = orgPlanProfile.get('id');

  const { handleSubmit, register, watch, reset } = useForm();
  const watchFields = watch('qualitativeFeedback');

  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackingTypes.START, {
      survey_name: 'first hear about Unito',
      plan_selected: orgPlanId,
      plan_tier_selected: getPlanTier(orgPlanId),
    });
  }, [trackEvent, orgPlanId]);

  const onConfirm = (formValues) => {
    dispatch(
      organizationActions.patchOrganization(organizationId, {
        conversionFeedback: feedback,
        conversionQualitativeFeedback: formValues.qualitativeFeedback,
      }),
    );
    trackEvent(trackingTypes.SUBMIT, { survey_name: 'first hear about Unito' });
    onSubmit();
  };

  const handleChangeFeedback = (value) => {
    setFeedback(value);
    reset();
  };

  return (
    <Modal size="lg" type="plainModal" overflow="visible" isOpen={isOpen} onRequestClose={onClose} displayCloseButton>
      <form onSubmit={handleSubmit(onConfirm)}>
        <Box $p={[0.25]}>
          {!feedback && (
            <SuccessfulPaymentSection>
              <Box as="span" $m={[0, 1, 0, 0]}>
                <img src={Check} height={40} alt="check" />
              </Box>
              Your payment has been processed successfully!
            </SuccessfulPaymentSection>
          )}
          <ModalTitle>
            How did you first <b>hear</b> about Unito?
          </ModalTitle>
          <FeedbackButtons activeValue={feedback} onSelectFeedback={handleChangeFeedback} />
          {feedback && (
            <Box $m={[1.5, 0, 0, 0]}>
              {FEEDBACK_VALUES[feedback].followUpQuestion}
              <Input
                className="form-control"
                type="text"
                placeholder="e.g., collaboration tool, sync, Trello integration, etc."
                style={{ borderRadius: borderRadius.double }}
                {...register('qualitativeFeedback', { required: true })}
              />
              <Box $m={[0.5, 0, 0, 0]}>
                <Button block type="submit" disabled={!watchFields}>
                  Confirm
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </Modal>
  );
}

ConversionFeedbackModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

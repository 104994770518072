import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { IntegrationPathFinder, NewAlert, NewAlertLevel } from '@unitoio/mosaic';
import { TrackingFunnel } from '@unitoio/sherlock';

import { FlowBuilderErrorContext } from '~/contexts';
import * as trackingTypes from '~/consts/tracking';
import { useSelector } from 'react-redux';
import { getProviderIdentityProviderName, getProviderIdentityById } from '~/reducers';

import { useConsoleTrackingFunnelContext } from '~/hooks/useConsoleTrackingFunnelContext';

export function PathFinderConnectTools({
  side,
  providerIdentityId,
  onNodeChange,
  onItemTypeChange,
  selectedNodes = null,
  selectedItemType = null,
  readOnly,
  isSaving,
}) {
  // Bring Logic around flowbuilder here so the IntegrationPathFinder component can live in different context (workflow designer, flow builder, standalone).
  // return <PathFinder />;

  const {
    formState: { errors },
  } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);
  const onGraphStateChange = (nodes = [], itemType, newContainerType) => {
    onNodeChange(nodes, newContainerType, itemType);
  };

  const providerName = useSelector((state) => getProviderIdentityProviderName(state, providerIdentityId));
  const providerIdentity = useSelector((state) => getProviderIdentityById(state, providerIdentityId));
  // use attempt as a key to force a re-rendering of the pathfinder if we updated the state of a PI
  const lastValidationAttempt = providerIdentity.get('lastValidationAttempt');

  const controlledGraphState =
    selectedNodes !== null
      ? {
          controlledGraphState: {
            pathSegments: selectedNodes,
            itemType: selectedItemType,
          },
        }
      : {};

  const consoleTrackingFunnelContext = useConsoleTrackingFunnelContext();

  return (
    <TrackingFunnel
      contextName={trackingTypes.MODULE.TOOL_SELECTION}
      sharedProperties={{
        ...consoleTrackingFunnelContext,
        selected_tool_name: providerName,
      }}
    >
      <IntegrationPathFinder
        key={lastValidationAttempt}
        credentialId={providerIdentityId}
        rootPath="/"
        onGraphStateChange={onGraphStateChange}
        onItemTypeChange={onItemTypeChange}
        readOnly={readOnly}
        isSaving={isSaving}
        {...controlledGraphState}
      />
      {errors[pageName]?.[side]?.containerId && (
        <NewAlert level={NewAlertLevel.ERROR} message={`Error: ${errors[pageName][side].containerId.message}`} />
      )}
    </TrackingFunnel>
  );
}

PathFinderConnectTools.propTypes = {
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  providerIdentityId: PropTypes.string.isRequired,
  onNodeChange: PropTypes.func.isRequired,
  onItemTypeChange: PropTypes.func.isRequired,
  selectedNodes: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({ label: PropTypes.string, path: PropTypes.string }),
      relation: PropTypes.shape({ label: PropTypes.string, path: PropTypes.string }),
    }).isRequired,
  ),
  selectedItemType: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

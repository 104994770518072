import { useSelector } from 'react-redux';

import { getProviderCapabilitiesV3, getProviderByName } from '~/reducers';

export function useGetProviderTerms(providerName, itemType, containerType) {
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const capabilities = useSelector((state) => getProviderCapabilitiesV3(state, { providerName, itemType }));

  return {
    providerDisplayName: provider.get('displayName'),
    itemName: {
      singular: capabilities.getIn(['item', 'names', 'singular']),
      plural: capabilities.getIn(['item', 'names', 'plural']),
    },
    containerName: {
      singular: capabilities.getIn(['containers', containerType, 'names', 'singular']),
      plural: capabilities.getIn(['containers', containerType, 'names', 'plural']),
    },
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';

import { getSupportedFieldsForItem, getProviderByName } from '~/reducers';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { tokens, Box, Typography } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as fieldTypes from '~/consts/fields';
import * as trackingTypes from '~/consts/tracking';

import { RulesRow } from '../../../../components/RulesRow';
import { PCDFieldsSelect, displayTypes } from '../../../../components/PCDFieldsSelect';

const additionalOptions = [
  {
    disabled: undefined,
    disabledText: undefined,
    value: fieldTypes.EARLIEST_CREATED_AT,
    label: 'Create date',
  },
];

export const AppendNewFilter = ({
  side,
  itemType,
  containerType,
  providerIdentityId,
  providerName,
  containerId,
  addNewRule,
  append,
  groupedFields,
  isDisabledOptionHandler,
  linkState,
  displayCreatedDateFieldFilter,
}) => {
  const trackEvent = useTrackEvent();
  const provider = useSelector((state) => getProviderByName(state, providerName));
  const providerFields = useSelector((state) =>
    getSupportedFieldsForItem(state, provider.get('_id'), containerId, itemType),
  );
  const hasCreatedDate = providerFields.some((field) => field.get('semantic') === fieldTypes.SEMANTIC.CREATED_AT);
  const handleOnChange = (newFieldId, field) => {
    trackEvent(trackingTypes.EVENT_NAME.ACTION, { action_name: 'clicked on add a new rule' });
    addNewRule({ newFieldId, append, groupedFields, field });
  };

  const options =
    linkState === linkTypes.LINK_STATES.DRAFT
      ? additionalOptions
          .map((o) => {
            const option = displayCreatedDateFieldFilter(o, hasCreatedDate);
            if (!option) return null;
            return {
              ...option,
              ...isDisabledOptionHandler(Map(o), groupedFields),
            };
          })
          .filter(Boolean)
      : [];

  return (
    <RulesRow hoverable={false} itemType={itemType} containerType={containerType}>
      <Box m={[0, tokens.spacing.s3, 0, 0]}>
        <Typography variant="h4">AND</Typography>
      </Box>
      <PCDFieldsSelect
        isOptionDisabledHandler={(newFieldId) => isDisabledOptionHandler(newFieldId, groupedFields)}
        containerSide={side}
        itemType={itemType}
        placeholder="Add a new rule"
        searchPlaceholder="Search for a rule"
        displayType={displayTypes.FILTERS_ONLY}
        providerIdentityId={providerIdentityId}
        providerName={providerName}
        containerId={containerId}
        onChange={handleOnChange}
        additionalOptions={options}
        value={null}
      />
    </RulesRow>
  );
};

const fieldShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

AppendNewFilter.propTypes = {
  side: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  providerIdentityId: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  addNewRule: PropTypes.func.isRequired,
  append: PropTypes.func.isRequired,
  isDisabledOptionHandler: PropTypes.func.isRequired,
  linkState: PropTypes.string.isRequired,
  displayCreatedDateFieldFilter: PropTypes.func.isRequired,
  groupedFields: PropTypes.shape({
    [PropTypes.string]: fieldShape,
  }).isRequired,
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import * as inviteActions from '~/actions/invites';
import { getSelectedOrganizationId } from '~/reducers';
import * as trackingTypes from '~/consts/tracking';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { Box, notification, tokens } from '@unitoio/mosaic';
import { Input } from '~/components/Input/Input';
import { Button } from '~/components/Button/Button';

const Grid = styled.div`
  display: grid;
  gap: ${tokens.spacing.s4};
  grid-template-columns: repeat(2, 1fr);
  padding: ${tokens.spacing.s4} ${tokens.spacing.s4} ${tokens.spacing.s4} 0;
`;

const GridSpan2 = styled.div`
  grid-column: span 2 / auto;
`;

const Required = styled.span`
  color: ${tokens.colors.content.warning.default};
  margin-left: ${tokens.spacing.s2};
`;

export const ReferralForm = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitSuccessful, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  async function onSubmit(formValues) {
    trackEvent(trackingTypes.EVENT_NAME.ACTION, {
      action_name: trackingTypes.SETTINGS_MENU_EVENTS.ACTIONS.CLICK_REWARDS_FORM_SUBMIT,
    });
    try {
      await dispatch(inviteActions.sendReferral(organizationId, formValues));
      notification.success({
        message: 'Thank you for your submission!',
        description:
          'We will contact you once your referred contact has signed up and has become a paying Unito customer.',
        placement: 'topRight',
      });
    } catch (err) {
      trackEvent(trackingTypes.EVENT_NAME.BLOCKED, {
        reason: err.message,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box m={[0, 0, 2, 0]}>
        <Grid>
          <div>
            First Name<Required>*</Required>
            <Input className="form-control" type="text" {...register('firstName', { required: true })} />
          </div>

          <div>
            Last Name<Required>*</Required>
            <Input className="form-control" type="text" {...register('lastName', { required: true })} />
          </div>

          <GridSpan2>
            Email<Required>*</Required>
            <Input className="form-control" type="email" {...register('email', { required: true })} />
          </GridSpan2>

          <GridSpan2>
            Company<Required>*</Required>
            <Input className="form-control" type="text" {...register('company', { required: true })} />
          </GridSpan2>

          <GridSpan2>
            Why you think they'll love Unito?
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <textarea className="form-control" type="text" {...register('feedback')} />
          </GridSpan2>
        </Grid>
        <Button type="submit" btnStyle="primary" disabled={isSubmitted || !isValid}>
          Submit
        </Button>
      </Box>
    </form>
  );
};

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getOrganizationPlanFeaturesWithUsage, getSelectedOrganizationId } from '~/reducers';

export const useGetFeatureLimit = (featureName) => {
  const organizationId = useSelector(getSelectedOrganizationId);
  const organizationPlanFeaturesWithUsage = useSelector((state) =>
    getOrganizationPlanFeaturesWithUsage(state, organizationId),
  );

  const orgFeature = organizationPlanFeaturesWithUsage.get(featureName);

  if (!orgFeature || orgFeature.isEmpty()) {
    return null;
  }

  return orgFeature.get('limit');
};

useGetFeatureLimit.PropsType = {
  featureName: PropTypes.string.isRequired,
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import * as formUtils from '~/utils/forms';
import { getContainerById, getProviderByName, getProviderCapabilitiesByIdV3 } from '~/reducers';

export function useDefaultLinkName(setValue, watch) {
  const watchName = watch('name');
  const watchContainerIdA = watch('A.containerId');
  const watchContainerIdB = watch('B.containerId');
  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const watchProviderIdentityIdA = watch('A.providerIdentityId');
  const watchProviderIdentityIdB = watch('B.providerIdentityId');
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const watchSyncDirection = watch('syncDirection');

  const isReadOnlyA = formUtils.toReadOnlyFlowBuilder(watchSyncDirection, 'A');
  const isReadOnlyB = formUtils.toReadOnlyFlowBuilder(watchSyncDirection, 'B');

  const containerADisplayName = useSelector((state) =>
    getContainerById(state, watchProviderIdentityIdA, watchContainerIdA).get('displayName'),
  );
  const containerBDisplayName = useSelector((state) =>
    getContainerById(state, watchProviderIdentityIdB, watchContainerIdB).get('displayName'),
  );

  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));
  const itemA = useSelector((state) => getProviderCapabilitiesByIdV3(state, providerA.get('_id'), itemTypeA));
  const itemB = useSelector((state) => getProviderCapabilitiesByIdV3(state, providerB.get('_id'), itemTypeB));
  const providerItemTermA = itemA.getIn(['item', 'names', 'plural']);
  const providerItemTermB = itemB.getIn(['item', 'names', 'plural']);

  const defaultName = formUtils.generateDefaultLinkName({
    providerDisplayNameA: providerA.get('displayName'),
    providerDisplayNameB: providerB.get('displayName'),
    containerNameA: containerADisplayName,
    containerNameB: containerBDisplayName,
    readOnlyA: isReadOnlyA,
    readOnlyB: isReadOnlyB,
    providerItemTermA,
    providerItemTermB,
  });

  useEffect(() => {
    if (!watchName || ['Untitled', 'New Flow'].includes(watchName)) {
      setValue('name', defaultName, { shouldDirty: true });
    }
  }, [setValue, watchName, defaultName]);
}

import { Map } from 'immutable';
import { useSelector } from 'react-redux';

import * as fieldTypes from '~/consts/fields';
import { getCustomFields } from '~/reducers';

import * as formUtils from '../../../utils/form';

export function useGetFirstMappingWithValueMappingIndex(
  fieldAssociations,
  providerFieldsA,
  providerFieldsB,
  isLoading,
) {
  const customFieldsA = useSelector((state) => getCustomFields(state, { containerSide: 'A' }));
  const customFieldsB = useSelector((state) => getCustomFields(state, { containerSide: 'B' }));

  let firstMappingWithValueMapping = -1;

  if (isLoading) {
    return firstMappingWithValueMapping;
  }

  fieldAssociations.forEach((fieldAssociation, index) => {
    const isFieldACustom = fieldAssociation.A?.kind === fieldTypes.KINDS.CUSTOM_FIELD;
    const isFieldBCustom = fieldAssociation.B?.kind === fieldTypes.KINDS.CUSTOM_FIELD;
    const fieldA = isFieldACustom
      ? customFieldsA.get(fieldAssociation.A.field)
      : providerFieldsA.get(fieldAssociation.A?.field, Map());
    const fieldB = isFieldBCustom
      ? customFieldsB.get(fieldAssociation.B.field)
      : providerFieldsB.get(fieldAssociation.B?.field, Map());

    const isFieldAMappable = formUtils.hasFieldValues(fieldA);
    const isFieldBMappable = formUtils.hasFieldValues(fieldB);

    if (isFieldAMappable && isFieldBMappable) {
      firstMappingWithValueMapping = index;
    }
  });

  return firstMappingWithValueMapping;
}

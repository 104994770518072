/**
 * @name LinkConstants
 * Constants for link module
 */
import { tokens } from '@unitoio/mosaic';

export const ENTITY_NAME = 'links';

export const ADD_LINK = 'ADD_LINK';
export const ADD_LINK_FAILURE = `${ADD_LINK}_FAILURE`;
export const ADD_LINK_REQUEST = `${ADD_LINK}_REQUEST`;
export const ADD_LINK_SUCCESS = `${ADD_LINK}_SUCCESS`;
export const AUTOMAP_USERS_FAILURE = 'AUTOMAP_USERS_FAILURE';
export const AUTOMAP_USERS_REQUEST = 'AUTOMAP_USERS_REQUEST';
export const AUTOMAP_USERS_SUCCESS = 'AUTOMAP_USERS_SUCCESS';
export const DELETE_LINK_FAILURE = 'DELETE_LINK_FAILURE';
export const DELETE_LINK_REQUEST = 'DELETE_LINK_REQUEST';
export const DELETE_LINK_SUCCESS = 'DELETE_LINK_SUCCESS';
export const DIAGNOSE_LINK_FAILURE = 'DIAGNOSE_LINK_FAILURE';
export const DIAGNOSE_LINK_REQUEST = 'DIAGNOSE_LINK_REQUEST';
export const DIAGNOSE_LINK_SUCCESS = 'DIAGNOSE_LINK_SUCCESS';
export const DUPLICATE_SYNC_REQUEST = 'DUPLICATE_SYNC_REQUEST';
export const DUPLICATE_SYNC_SUCCESS = 'DUPLICATE_SYNC_SUCCESS';
export const DUPLICATE_SYNC_FAILURE = 'DUPLICATE_SYNC_FAILURE';
export const GET_LINKS_FAILURE = 'GET_LINKS_FAILURE';
export const GET_LINKS_REQUEST = 'GET_LINKS_REQUEST';
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS';
export const GET_LINK_FAILURE = 'GET_LINK_FAILURE';
export const GET_LINK_REQUEST = 'GET_LINK_REQUEST';
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS';
export const UPDATE_SYNC_LIST_STATUSES = 'UPDATE_SYNC_LIST_STATUSES';
export const UPDATE_CURRENT_SYNC_STATUS = 'UPDATE_CURRENT_SYNC_STATUS';
export const SAVE_LINK_FAILURE = 'SAVE_LINK_FAILURE';
export const SAVE_LINK_REQUEST = 'SAVE_LINK_REQUEST';
export const SAVE_LINK_SUCCESS = 'SAVE_LINK_SUCCESS';
export const SAVE_LINK_V2_FAILURE = 'SAVE_LINK_V2_FAILURE';
export const SAVE_LINK_V2_REQUEST = 'SAVE_LINK_V2_REQUEST';
export const SAVE_LINK_V2_SUCCESS = 'SAVE_LINK_V2_SUCCESS';
export const SET_AUTO_SYNC_LINK_FAILURE = 'SET_AUTO_SYNC_LINK_FAILURE';
export const SET_AUTO_SYNC_LINK_REQUEST = 'SET_AUTO_SYNC_LINK_REQUEST';
export const SET_AUTO_SYNC_LINK_SUCCESS = 'SET_AUTO_SYNC_LINK_SUCCESS';
export const SET_MANUAL_SYNC_LINK_FAILURE = 'SET_MANUAL_SYNC_LINK_FAILURE';
export const SET_MANUAL_SYNC_LINK_REQUEST = 'SET_MANUAL_SYNC_LINK_REQUEST';
export const SET_MANUAL_SYNC_LINK_SUCCESS = 'SET_MANUAL_SYNC_LINK_SUCCESS';
export const SYNC_LINK_FAILURE = 'SYNC_LINK_FAILURE';
export const SYNC_LINK_REQUEST = 'SYNC_LINK_REQUEST';
export const SYNC_LINK_SUCCESS = 'SYNC_LINK_SUCCESS';
export const GET_TASK_SYNC_TASK_COUNT_REQUEST = 'GET_TASK_SYNC_TASK_COUNT_REQUEST';
export const GET_TASK_SYNC_TASK_COUNT_FAILURE = 'GET_TASK_SYNC_TASK_COUNT_FAILURE';
export const GET_TASK_SYNC_TASK_COUNT_SUCCESS = 'GET_TASK_SYNC_TASK_COUNT_SUCCESS';
export const TURN_OFF_TEST_MODE_REQUEST = 'TURN_OFF_TEST_MODE_REQUEST';
export const TURN_OFF_TEST_MODE_SUCCESS = 'TURN_OFF_TEST_MODE_SUCCESS';
export const TURN_OFF_TEST_MODE_FAILURE = 'TURN_OFF_TEST_MODE_FAILURE';
export const PATCH_LINK_REQUEST = 'PATCH_LINK_REQUEST';
export const PATCH_LINK_SUCCESS = 'PATCH_LINK_SUCCESS';
export const PATCH_LINK_FAILURE = 'PATCH_LINK_FAILURE';
export const EDIT_LINK_FIELDS_REQUEST = 'EDIT_LINK_FIELDS_REQUEST';
export const EDIT_LINK_FIELDS_SUCCESS = 'EDIT_LINK_FIELDS_SUCCESS';
export const EDIT_LINK_FIELDS_FAILURE = 'EDIT_LINK_FIELDS_FAILURE';
export const SYNC_LINK_ITEM_REQUEST = 'SYNC_LINK_ITEM_REQUEST';
export const SYNC_LINK_ITEM_SUCCESS = 'SYNC_LINK_ITEM_SUCCESS';
export const SYNC_LINK_ITEM_FAILURE = 'SYNC_LINK_ITEM_FAILURE';
export const VALIDATE_LINK_CAN_BE_AUTOPOPULATED_REQUEST = 'VALIDATE_LINK_CAN_BE_AUTOPOPULATED_REQUEST';
export const VALIDATE_LINK_CAN_BE_AUTOPOPULATED_SUCCESS = 'VALIDATE_LINK_CAN_BE_AUTOPOPULATED_SUCCESS';
export const VALIDATE_LINK_CAN_BE_AUTOPOPULATED_FAILURE = 'VALIDATE_LINK_CAN_BE_AUTOPOPULATED_FAILURE';
export const AUTOPOPULATE_FIELDS_REQUEST = 'AUTOPOPULATE_FIELDS_REQUEST';
export const AUTOPOPULATE_FIELDS_SUCCESS = 'AUTOPOPULATE_FIELDS_SUCCESS';
export const AUTOPOPULATE_FIELDS_FAILURE = 'AUTOPOPULATE_FIELDS_FAILURE';
export const GET_COUNT_LINKS_REQUEST = 'GET_COUNT_LINKS_REQUEST';
export const GET_COUNT_LINKS_SUCCESS = 'GET_COUNT_LINKS_SUCCESS';
export const GET_COUNT_LINKS_FAILURE = 'GET_COUNT_LINKS_FAILURE';

export const RESET_DIAGNOSTIC = 'RESET_DIAGNOSTIC';

export const LINK_STATES = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
  DISCONNECTED: 'disconnected',
  DELETED: 'deleted',
  INACCESSIBLE: 'inaccessible',
  DRAFT: 'draft',
  /** Intermediary state used to differenciate a new flow that was prepopulated with from another flow */
  DUPLICATE: 'duplicate',
};

export const CLIENT_FACING_LINK_STATES = {
  [LINK_STATES.DRAFT]: 'Draft',
  [LINK_STATES.ACTIVE]: 'Active',
};

export const LINK_ACTIVITY_STATUS = {
  TRIGGERED: 'triggered',
  INITIALIZING: 'initializing',
  SYNCING: 'syncing',
  HEALTHY: 'healthy',
  WARNING: 'warning',
  DEGRADED: 'degraded',
  INACTIVE: 'inactive',
  NEW: 'new',
  LOADING: 'loading',
  PAUSED: 'paused',
  SUSPENDED: 'suspended',
};

export const LINK_STATUS_VALUES = {
  /**
   * spin: Whether the icon should spin
   * inProgress: Whether the status is a "syncing" status (is the flow currently syncing or not)
   */
  [LINK_ACTIVITY_STATUS.HEALTHY]: {
    iconColor: tokens.colors.content.positive.default,
    copy: 'Healthy',
    legend: 'Your flow is healthy and information is syncing',
    legendRank: 1,
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.secondary.default,
    iconName: 'check-circle',
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.WARNING]: {
    iconColor: tokens.colors.content.warning.default,
    copy: 'Review recommended',
    legend: 'A review is recommended, but your flow is still syncing',
    legendRank: 2,
    backgroundColor: tokens.colors.background.message.warning,
    borderColor: tokens.colors.content.warning.default,
    iconName: 'exclamation-triangle',
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.DEGRADED]: {
    iconColor: tokens.colors.content.destructive.default,
    copy: 'Unable to sync',
    legend: `The are issues to investigate preventing your flow's ability to sync`,
    legendRank: 3,
    backgroundColor: tokens.colors.background.message.destructive,
    borderColor: tokens.colors.content.destructive.default,
    iconName: 'times-circle',
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.INACTIVE]: {
    iconColor: tokens.colors.content.neutral.n20,
    copy: 'No recent activity',
    legend: 'No recent activity or changes synced in the past 3 months',
    legendRank: 4,
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.neutral.n20,
    iconName: 'clock',
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.LOADING]: {
    backgroundColor: tokens.colors.content.neutral.n10,
    borderColor: tokens.colors.content.secondary.default,
    iconName: 'spinner',
    spin: true,
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.NEW]: {
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.secondary.default,
    iconName: 'plus',
    legend: 'Your flow is inactive and needs to be set up to start syncing',
    legendRank: 5,
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.PAUSED]: {
    copy: 'Flow paused',
    legend: 'Auto-sync is disabled and your flow is not automatically syncing',
    legendRank: 6,
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.secondary.default,
    iconName: 'pause-circle',
    inProgress: false,
  },
  [LINK_STATES.DRAFT]: {
    copy: 'Draft',
    legend: 'Your flow is a draft and needs to be set up to start syncing',
    legendRank: 7,
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.neutral.n20,
    iconName: 'pencil',
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.TRIGGERED]: {
    copy: 'Your flow is currently syncing or Unito is looking for changes',
    legend: 'Your flow is currently syncing or Unito is looking for changes',
    legendRank: 8,
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.secondary.default,
    iconName: 'rotate',
    spin: true,
    inProgress: true,
  },
  [LINK_ACTIVITY_STATUS.SYNCING]: {
    copy: 'Syncing',
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.secondary.default,
    iconName: 'rotate',
    spin: true,
    inProgress: true,
  },
  [LINK_ACTIVITY_STATUS.SUSPENDED]: {
    iconColor: tokens.colors.content.destructive.default,
    copy: 'Suspended',
    backgroundColor: tokens.colors.background.message.destructive,
    borderColor: tokens.colors.content.destructive.default,
    iconName: 'times-circle',
    inProgress: false,
  },
  [LINK_ACTIVITY_STATUS.INITIALIZING]: {
    copy: 'Initial sync in progress',
    backgroundColor: tokens.colors.global.primary.light,
    borderColor: tokens.colors.content.secondary.default,
    iconName: 'rotate',
    spin: true,
    inProgress: true,
  },
};

export const KIND = {
  MIRROR_SYNC: 'mirrorSync',
  REPORT_SYNC: 'reportSync',
  MULTI_SYNC: 'multiSync',
  TASK_SYNC: 'taskSync',
};

export const KIND_DISPLAY_NAME = {
  [KIND.MIRROR_SYNC]: 'Flows',
  [KIND.REPORT_SYNC]: 'Export & Sync',
  [KIND.TASK_SYNC]: 'Mirror',
};

export const GUIDE_VARIANT_TYPE = {
  REGULAR: 'regular',
  LITE: 'lite',
};
export const GUIDE_VARIANTS = {
  [KIND.MIRROR_SYNC]: GUIDE_VARIANT_TYPE.REGULAR,
  [KIND.MULTI_SYNC]: GUIDE_VARIANT_TYPE.REGULAR,
  [KIND.TASK_SYNC]: GUIDE_VARIANT_TYPE.REGULAR,
  [KIND.REPORT_SYNC]: GUIDE_VARIANT_TYPE.LITE,
};

export const SOURCE = {
  CONSOLE: 'console',
  TRELLO_POWER_UP_CARD_SYNC: 'trelloPowerUpCardSync',
};

export const POST_MESSAGE_TYPES = {
  LEFT_EDIT_SYNC: 'LEFT_EDIT_SYNC',
};

export const SYNC_DIRECTION = {
  TWO_WAY: 'twoWay',
  ONE_WAY: 'oneWay',
};

export const ON_FILTER_OUT_VALUES = {
  /** The sister task will be closed, only if it is not the original task.This is the default behaviour. */
  CLOSE_CLONE: 'closeClone',
  /** The sister task will be closed */
  CLOSE: 'close',
  /** The sister task is kept as is, but is no longer synced */
  KEEP_IN_SYNC: 'keepInSync',
  /** The sister task is kept in sync, AND filter values(e.g.labels) are NOT auto-set */
  IGNORE: 'ignore',
};

export const DEGRADED_LINK_STATES = [LINK_STATES.DISABLED, LINK_STATES.DISCONNECTED, LINK_STATES.INACCESSIBLE];

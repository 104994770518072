import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Icon, Space, tokens, NewTypography, Flex, Tag } from '@unitoio/mosaic';

import * as routes from '~/consts/routes';
import * as linkTypes from '~/consts/link';
import * as fieldTypes from '~/consts/fields';
import { getTestModeTermToUse } from '~/containers/FlowBuilder/hooks/useGetTestModeTerm';
import { getHasTestModeEnabled } from '~/containers/FlowBuilder/hooks/useHasTestModeEnabled';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';

const LinkBlack = styled(Link)`
  color: ${tokens.colors.content.neutral.n40};
  &:hover {
    color: ${tokens.colors.content.info.default};
  }
`;

const UnderText = styled(NewTypography.Text)`
  color: ${tokens.colors.content.neutral.n30};
`;

const getTag = (link) => {
  const showTestModeTag = getHasTestModeEnabled(fromJS(link));
  const testModeTerm = getTestModeTermToUse(fromJS(link));

  if (link.kind === linkTypes.KIND.TASK_SYNC) {
    return <Tag label="Mirror" />;
  }

  if (showTestModeTag) {
    return (
      <Tag
        label={
          <div>
            Only {testModeTerm}{' '}
            <ProviderTermsByName
              providerNameA={link.A.providerName}
              providerNameB={link.B.providerName}
              termKey="task"
              plurality="plural"
              pcdv3={!!link.A.itemType && !!link.B.itemType}
              itemTypeA={link.A.itemType}
              itemTypeB={link.B.itemType}
              containerIdA={link.A?.container?.id}
              containerIdB={link.B?.container?.id}
              fallbackTerm="items"
            />
          </div>
        }
      />
    );
  }

  return null;
};

export const LinkInformation = ({ link }) => {
  const editFlowRoute = routes.getEditFlowBuilderRoute(link.key);

  return (
    <Flex vertical>
      <LinkBlack to={editFlowRoute}>
        <Flex vertical>
          <Space>
            {link.name}
            {getTag(link)}
          </Space>
          {link.state !== linkTypes.LINK_STATES.DRAFT && (
            <UnderText>
              <Icon style={{ marginRight: tokens.spacing.s3 }} name="history" kind={Icon.KINDS.SOLID} />
              Last sync: {link.lastSyncRequest ? moment(link.lastSyncRequest).fromNow() : 'Never'}
            </UnderText>
          )}
        </Flex>
      </LinkBlack>
      {!link.restricted && (
        <UnderText>
          <UnderText>
            Managed by <a href={`mailto:${link.user.email}`}>{link.user.fullName}</a>
          </UnderText>
        </UnderText>
      )}
    </Flex>
  );
};

// abstraction from the antD specs for a table render
export const LinkInformationsRenderer = (_text, link) => <LinkInformation link={link} />;

LinkInformation.propTypes = {
  link: PropTypes.shape({
    state: PropTypes.oneOf([linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.ACTIVE]),
    key: PropTypes.string,
    name: PropTypes.string,
    kind: PropTypes.oneOf([...Object.values(fieldTypes.KINDS)]),
    lastSyncRequest: PropTypes.string,
    restricted: PropTypes.bool,
    syncSettings: PropTypes.shape({
      earliestCreatedAt: PropTypes.string,
    }),
    user: PropTypes.shape({
      email: PropTypes.string,
      fullName: PropTypes.string,
    }),
  }),
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';

import {
  areCustomFieldsLoaded,
  getCustomFields,
  getProviderByProviderIdentityId,
  getCapabilitiesForItem,
} from '~/reducers';
import { useLogger } from '~/hooks/useLogger';
import * as fieldTypes from '~/consts/fields';

import { LOADING_STATUSES } from '../consts/flowBuilder';
import { useGetField } from './useGetField';
import { useGetProviderNameBySide } from './useGetProviderNames';

function shouldFetchFieldValues({ areCFsLoaded, customField, field, fieldValues, kind, searchString, status }) {
  // Need to fetch field values if the field is searchable and there is a search string
  const fieldIsSearchable = field.get('searchable', false) && !!searchString;
  if (fieldIsSearchable) {
    return true;
  }

  if (status !== LOADING_STATUSES.INITIAL) {
    return false;
  }

  if (kind === fieldTypes.KINDS.CUSTOM_FIELD) {
    // Don't fetch custom field values if custom fields are not loaded or if the field is not found.
    if (customField.isEmpty() || !areCFsLoaded) {
      return false;
    }

    // We should only fetch field values on enum fields. Non enum type are handled as preset of defined options (e.g boolean, non-boolean)
    return customField.get('type') === fieldTypes.TYPES.ENUM;
  }

  // PCD field values
  return !field.isEmpty() && fieldValues.isEmpty();
}

/**
 * Prefetches the field values of a connector that doesn't require typeahead
 */
export function useFetchFieldValues({
  containerId,
  containerSide,
  containerType,
  fieldId,
  parentFieldId,
  kind,
  providerIdentityId,
  searchString,
  setStatus,
  status,
  fieldValues,
  itemType,
  fetchFieldValues,
}) {
  const dispatch = useDispatch();
  const { reportException } = useLogger();
  const field = useGetField({ providerIdentityId, fieldId, itemType, containerId, includeHidden: true });
  const areCFsLoaded = useSelector((state) => areCustomFieldsLoaded(state, { containerSide }));
  const providerName = useGetProviderNameBySide(containerSide);
  const customFields = useSelector((state) => getCustomFields(state, { containerSide, providerName, itemType }));
  const customField = customFields.get(fieldId, Map());
  const provider = useSelector((state) => getProviderByProviderIdentityId(state, providerIdentityId));
  const itemCapabilities = useSelector((state) =>
    getCapabilitiesForItem(state, provider.get('_id'), containerId, itemType),
  );
  const areItemCapabilitiesLoaded = !itemCapabilities.isEmpty();
  const shouldFetch = shouldFetchFieldValues({
    areCFsLoaded,
    customField,
    field,
    fieldValues,
    kind,
    searchString,
    status,
    itemCapabilities,
  });

  useEffect(() => {
    if (areCFsLoaded && !shouldFetch && areItemCapabilitiesLoaded) {
      setStatus(LOADING_STATUSES.LOADED);
    } else if (shouldFetch) {
      fetchFieldValues({
        setStatus,
        dispatch,
        reportException,
        payload: {
          containerId,
          containerType,
          fieldId,
          parentField: parentFieldId,
          kind,
          providerIdentityId,
          searchString,
          containerSide,
          itemType,
        },
      });
    }
  }, [
    areCFsLoaded,
    containerId,
    containerSide,
    containerType,
    dispatch,
    fetchFieldValues,
    fieldId,
    itemType,
    kind,
    parentFieldId,
    providerIdentityId,
    reportException,
    searchString,
    setStatus,
    shouldFetch,
    areItemCapabilitiesLoaded,
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { NewAlert as Alert, Box, NewAlertLevel, tokens } from '@unitoio/mosaic';

import { getProviderByName } from '~/reducers';
import { useGetMissingDeepFilterItemNames } from '../hooks/useGetMissingDeepFilterItemNames';

const ProviderDisplayName = ({ providerName }) =>
  useSelector((state) => getProviderByName(state, providerName).get('displayName'));

export function MandatoryDeepFiltersMissingAlert({ side }) {
  const { watch } = useFormContext();
  const providerName = watch(`${side}.providerName`);

  const [missingDeepFilterFieldNameA, missingDeepFilterFieldNameB] = useGetMissingDeepFilterItemNames();
  const getItemFieldIdSide = side === 'A' ? missingDeepFilterFieldNameA : missingDeepFilterFieldNameB;

  return (
    <Box m={[tokens.spacing.s5, tokens.spacing.s0]}>
      <Alert
        level={NewAlertLevel.WARNING}
        message={
          <>
            <ProviderDisplayName providerName={providerName} /> needs at least one {getItemFieldIdSide} trigger.
          </>
        }
      />
    </Box>
  );
}

MandatoryDeepFiltersMissingAlert.propTypes = {
  side: PropTypes.oneOf(['A', 'B']).isRequired,
};

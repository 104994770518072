import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import { FlowBuilderErrorContext } from '~/contexts';
import * as linkTypes from '~/consts/link';
import * as fieldTypes from '~/consts/fields';
import * as trackingTypes from '~/consts/tracking';
import * as routes from '~/consts/routes';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import * as featureTypes from '~/consts/features';

import { useAreFiltersInitialized } from '~/containers/FlowBuilder/hooks/useAreFiltersInitialized';
import { useSelector } from 'react-redux';
import { getFeatureFlagValue } from '~/reducers';
import { GuideStepTitleWithTime } from './GuideStepTitleWithTime';
import { GuideStepTitle } from './GuideStepTitle';
import { GuideStep } from './GuideStep';
import { useGetLabelAndStatusFromAnomalies, PAGES } from '../../hooks/useGetAnomalies';
import { useMandatoryDeepFiltersMissing } from '../../hooks/useMandatoryDeepFiltersMissing';
import { useGetIncompleteItemFieldAssociation } from '../../hooks/useGetIncompleteItemFieldAssociation';
import { useIsWorkItemsPageCompleted } from '../../hooks/useIsWorkItemsPageCompleted';
import { useGetIncompleteActions } from '../../hooks/useGetIncompleteActions';
import RulesIcon from '../../images/steps_icons/rules.svg';

export function useGetFiltersCount() {
  const [syncDirection, filtersA, filtersB, rules] = useWatch({
    name: ['syncDirection', 'A.filters', 'B.filters', 'rules'],
  });

  const hasAccessToModernRules = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_RULES_PAGE),
  );

  const modernRulesCount = getModernRulesCount(rules);

  if (!syncDirection) {
    return 0;
  }

  const useModernFiltersCountA = rules.A.filters.isActive || hasAccessToModernRules;
  const useModernFiltersCountB = rules.B.filters.isActive || hasAccessToModernRules;
  const sideAFiltersCount = useModernFiltersCountA ? modernRulesCount.A : (filtersA?.length ?? 0);
  const sideBFiltersCount = useModernFiltersCountB ? modernRulesCount.B : (filtersB?.length ?? 0);

  // we have to return side specific count because when changing flow directions
  // from A <-> B to i.e. A -> B, filters on the B side are still maintained by the backend
  // so we would display an incorrect count. We can remove this logic after auto save V2 is implemented
  // and we make sure to manually reset the filters/rules on the side that is not being used
  if (syncDirection === fieldTypes.TARGET.A) {
    return sideBFiltersCount;
  }

  if (syncDirection === fieldTypes.TARGET.B) {
    return sideAFiltersCount;
  }

  return sideAFiltersCount + sideBFiltersCount;
}

function getModernRulesCount(rules = {}) {
  const totalRules = { A: 0, B: 0 };

  ['A', 'B'].forEach((side) => {
    const startSyncing = Object.values(rules?.[side]?.filters?.startSyncing ?? {}) ?? [];
    // stop syncing count is disabled for now because we're hiding stopSyncing
    // check the git history for the original implementation to re - enable it
    const stopSyncing = [];

    // itemsQueryLimit should be counted as a rule (even if it's not really a filter behind the scenes)
    const hasItemQueryLimit = rules?.[side]?.itemsQueryLimit !== null && rules?.[side]?.itemsQueryLimit !== undefined;
    let countBySide = hasItemQueryLimit ? 1 : 0;
    [startSyncing, stopSyncing].forEach((rule) => {
      rule.forEach((value) => {
        if (Array.isArray(value)) {
          countBySide += value.length;
        } else {
          countBySide += 1;
        }
      });
    });
    totalRules[side] = Number.isNaN(countBySide) ? 0 : countBySide;
  });

  return totalRules;
}

export const GuideStepRules = ({ linkState }) => {
  const { linkId } = useParams();
  const {
    formState: { errors },
  } = useFormContext();
  useMandatoryDeepFiltersMissing();
  useGetIncompleteItemFieldAssociation();
  useGetIncompleteActions();
  const [providerNameA, providerNameB, containerIdA, containerIdB, syncDirection] = useWatch({
    name: ['A.providerName', 'B.providerName', 'A.containerId', 'B.containerId', 'syncDirection'],
  });
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const isWorkItemStepCompleted = useIsWorkItemsPageCompleted();
  const filtersCount = useGetFiltersCount();
  const areFiltersInitialized = useAreFiltersInitialized();
  const isSyncDirectionSet = !!syncDirection;
  const pageName = useContext(FlowBuilderErrorContext);

  const hasFiltersInitialized = filtersCount > 0 || areFiltersInitialized;

  const providerIdentityAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.providerIdentityId;
  const providerIdentityBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.providerIdentityId;
  const containerAError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.A?.containerId;
  const containerBError = errors[trackingTypes.MODULE.TOOL_SELECTION]?.B?.containerId;
  const hasContainerErrors = providerIdentityAError || providerIdentityBError || containerAError || containerBError;
  const hasRulesErrors = !!errors[pageName];

  const [labelFromAnomalies, statusFromAnomalies] = useGetLabelAndStatusFromAnomalies(linkId, PAGES.RULES);

  if (hasContainerErrors) {
    return hasFiltersInitialized ? (
      <GuideStep status="inaccessible" statusLabel="Flow rules status">
        <GuideStepTitle title="Rules created" number={filtersCount} />
      </GuideStep>
    ) : (
      <GuideStep status="inaccessible" statusLabel="Flow rules status">
        <GuideStepTitleWithTime
          title={
            <>
              3. Choose the{' '}
              <ProviderTermsByName
                providerNameA={providerNameA}
                providerNameB={providerNameB}
                termKey="task"
                plurality="plural"
                pcdv3
                itemTypeA={itemTypeA}
                itemTypeB={itemTypeB}
                containerIdA={containerIdA}
                containerIdB={containerIdB}
              />{' '}
              you want to sync
            </>
          }
          icon={RulesIcon}
          time={4}
        />
      </GuideStep>
    );
  }

  if (statusFromAnomalies) {
    return (
      <GuideStep
        status={statusFromAnomalies}
        actionLabel={labelFromAnomalies}
        actionSlug={routes.FLOW_BUILDER_PAGES.RULES}
        statusLabel="Flow rules status"
      >
        <GuideStepTitle title="Rules created" number={filtersCount} />
      </GuideStep>
    );
  }

  if (isWorkItemStepCompleted && hasFiltersInitialized) {
    let hasFiltersStatus = 'edit';
    if (!hasFiltersInitialized || hasRulesErrors) {
      hasFiltersStatus = 'review';
    } else if (linkState === linkTypes.LINK_STATES.DRAFT) {
      hasFiltersStatus = 'done';
    }

    return (
      <GuideStep
        status={hasFiltersStatus}
        actionSlug={routes.FLOW_BUILDER_PAGES.RULES}
        actionLabel="Edit rules"
        statusLabel="Flow rules status"
      >
        <GuideStepTitle title="Rules created" number={filtersCount} />
      </GuideStep>
    );
  }

  return (
    <GuideStep
      status={isSyncDirectionSet && isWorkItemStepCompleted ? 'current' : 'default'}
      actionLabel={isSyncDirectionSet && isWorkItemStepCompleted ? 'Continue' : null}
      actionSlug={routes.FLOW_BUILDER_PAGES.RULES}
      statusLabel="Flow rules status"
    >
      <GuideStepTitleWithTime
        title={
          <>
            3. Choose the{' '}
            <ProviderTermsByName
              providerNameA={providerNameA}
              providerNameB={providerNameB}
              termKey="task"
              plurality="plural"
              pcdv3
              itemTypeA={itemTypeA}
              itemTypeB={itemTypeB}
              containerIdA={containerIdA}
              containerIdB={containerIdB}
            />{' '}
            you want to sync
          </>
        }
        icon={RulesIcon}
        time={4}
      />
    </GuideStep>
  );
};

GuideStepRules.propTypes = {
  linkState: PropTypes.oneOf(Object.values(linkTypes.LINK_STATES)).isRequired,
};

import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { Redirect } from 'react-router-dom';
import { reduxForm, Fields, Field, SubmissionError } from 'redux-form';
import { isMobile } from 'react-device-detect';

import { Alert, Box, tokens } from '@unitoio/mosaic';
import { ErrorBoundary } from '@unitoio/sherlock';

import * as authActions from '~/actions/auth';
import * as routes from '~/consts/routes';
import * as authTypes from '~/consts/auth';
import * as trackingTypes from '~/consts/tracking';
import * as providerTypes from '~/consts/providers';
import { AppError } from '~/containers/AppError/AppError';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getIsAuthenticated } from '~/reducers';
import * as formUtils from '~/utils/forms';
import { color } from 'theme';

import unitoLogo from '~/images/unito_logo_color_horiz.svg';
import { Title } from '~/components/Title/Title';
import { Href } from '~/components/Href/Href';
import { Card } from '~/components/Card/Card';

import { ResetChangeFields } from './ResetChangeFields';
import { ResetPasswordField } from './ResetPasswordField';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${color.light.gray};
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100vh;
`;

const Content = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 auto;
  padding-top: 4vh;
`;

const TextCenter = styled.div`
  text-align: center;
`;

const ModifierFontSize = styled.span`
  font-size: ${tokens.fontSize.f7};
`;

const UnitoLogo = styled.img`
  width: 10rem;
  height: auto;
  margin-bottom: 2rem;
`;

const MediaContainer = styled.div`
  max-width: 35rem;
  width: 35rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ResetPasswordContainer = reduxForm({
  form: 'resetPasswordForm',
  validate: (values) => {
    const passwordEmpty = formUtils.isEmpty(values.resetPassword);
    const isPasswordInvalid = !passwordEmpty && !formUtils.isPasswordValid(values.resetPassword);
    const confirmPasswordEmpty = formUtils.isEmpty(values.confirmPassword);
    const confirmPasswordEqualPassword = values.resetPassword === values.confirmPassword;
    const isConfirmPasswordInvalid = !passwordEmpty && !confirmPasswordEmpty && !confirmPasswordEqualPassword;
    const isEmailInvalid = !formUtils.isEmpty(values.email) && !formUtils.validateEmailAddress(values.email);

    const resetPassword = isPasswordInvalid ? 'Invalid password' : undefined;
    const confirmPassword = isConfirmPasswordInvalid ? "Passwords don't match" : undefined;
    const email = isEmailInvalid ? 'Invalid email' : undefined;

    return {
      email,
      resetPassword,
      confirmPassword,
    };
  },
})(ResetPasswordContainerWithErrorBoundary);

function useClearSubmitErrorsWhenChangingAction(clearSubmitErrors, action) {
  // Redux-Form doesn't implement hooks, therefore there its action creators aren't hook friendly (wrapped in a useCallback)
  // clearSubmitErrors is a simple redux action and can't hold stale reference, hence why it is safe to ignore the dependency
  // array here. See action creator implementation: https://github.com/redux-form/redux-form/blob/d1067cb6f0fb76d76d91462676cd0b2c3927f9db/src/actions.js#L254-L259
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clearSubmitErrorsCallback = useCallback(() => clearSubmitErrors(), []);
  useEffect(() => {
    clearSubmitErrorsCallback();
  }, [clearSubmitErrorsCallback, action]);
}

function onSubmit(action, validationId, trackEvent, reportException, reportWarning) {
  return async (formValues, dispatch) => {
    try {
      if (action === authTypes.RESET_PROCESSES.RESET) {
        await dispatch(authActions.resetPassword(formValues.email.trim()));

        trackEvent(trackingTypes.ACTION, {
          action_name: trackingTypes.AUTHENTICATION.ACTIONS.RESET_PASSWORD,
          selected_tool_name: providerTypes.COGNITO,
        });
      } else {
        await dispatch(authActions.resetPasswordChange(formValues.resetPassword, validationId));

        trackEvent(trackingTypes.ACTION, {
          action_name: trackingTypes.AUTHENTICATION.ACTIONS.CHANGE_PASSWORD,
          selected_tool_name: providerTypes.COGNITO,
        });
      }
    } catch (error) {
      if (error.name === 'ExpiredResetPasswordLink') {
        trackEvent(trackingTypes.BLOCKED, {
          selected_tool_name: providerTypes.COGNITO,
          reason: 'link to password reset expired',
        });
      }
      if (error.code < 500) {
        reportWarning(error.message, { identifier: 'onSubmit ResetPasswordContainer' });
      } else {
        reportException(error, { identifier: 'onSubmit ResetPasswordContainer' });
      }
      throw new SubmissionError({ _error: { name: error.name, message: error.message } });
    }
  };
}

function ResetPasswordContainerInner({
  asyncValidating,
  clearSubmitErrors,
  error,
  match,
  handleSubmit,
  submitting,
  submitSucceeded,
}) {
  const { action, validationId } = match.params;
  const { reportException, reportWarning } = useLogger();
  const trackEvent = useTrackEvent({ action_taken_from: action });
  const isAuthenticated = useSelector((state) => getIsAuthenticated(state));
  useClearSubmitErrorsWhenChangingAction(clearSubmitErrors, action);

  const trackAction = (actionName) => {
    trackEvent(trackingTypes.ACTION, {
      action_name: actionName,
    });
  };

  const isSubmitDisabled = () => submitting || asyncValidating || submitSucceeded;

  const getErrorMessage = () => {
    if (!error) {
      return null;
    }

    const errorNameMap = {
      ExpiredResetPasswordLink: () => (
        <span>
          Sorry, the link you clicked has expired. Please try{' '}
          <Href to={routes.ABSOLUTE_PATHS.RESET}>resetting your password</Href> again.
        </span>
      ),
      MaestroError: (message) => (
        <span>
          {message} <Href href={`https://guide.unito.io/kb-search-results?term=${message}`}>Get help</Href>
        </span>
      ),
    };

    const { name, message } = error;

    const renderErrorMessage = errorNameMap[name] || errorNameMap.MaestroError;
    return renderErrorMessage(message);
  };

  const getSuccessMessage = () => {
    const mapActionToMessage = {
      [authTypes.RESET_PROCESSES.RESET]: (
        <span>If you already have a Unito account, we will send an email to the address below.</span>
      ),
      [authTypes.RESET_PROCESSES.CHANGE_PASSWORD]: (
        <span>
          Your password has been changed, you can now <Href to={routes.ABSOLUTE_PATHS.LOGIN}>log in</Href> with your new
          password.
        </span>
      ),
    };

    if (submitSucceeded) {
      return mapActionToMessage[action];
    }

    return null;
  };

  if (isAuthenticated) {
    return <Redirect to={routes.ABSOLUTE_PATHS.DASHBOARD} />;
  }

  const successMessage = getSuccessMessage();
  const errorMessage = getErrorMessage();
  const isReset = match.params.action === authTypes.RESET_PROCESSES.RESET;

  return (
    <Container className="container">
      <GlobalStyle />
      <Content onSubmit={handleSubmit(onSubmit(action, validationId, trackEvent, reportException, reportWarning))}>
        <TextCenter>
          <Href href="https://unito.io/" onClick={() => trackAction('clicked on Unito logo')}>
            <UnitoLogo src={unitoLogo} alt="Unito logo" />
          </Href>
          <Title type="h1">
            <strong>{isReset ? 'Forgot your password?' : 'Change your password.'}</strong>
          </Title>
        </TextCenter>
        <MediaContainer>
          <Card
            color={tokens.colors.global.primary.light}
            padding={isMobile ? tokens.spacing.s6 : `${tokens.spacing.s6} ${tokens.spacing.s8}`}
            boxShadow
          >
            {successMessage && (
              <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
                <Alert level="success">{successMessage}</Alert>
              </Box>
            )}
            {errorMessage && (
              <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
                <Alert level="error">{errorMessage} </Alert>
              </Box>
            )}
            <Box m={[tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s4, tokens.spacing.s0]}>
              {isReset
                ? 'Enter your email below and we will send you instructions to reset your password.'
                : 'Your new password must be at least 12 characters, and should contain at least one uppercase and one lowercase letter.'}
            </Box>
            {isReset ? (
              <Field component={ResetPasswordField} name="email" disableSubmit={isSubmitDisabled()} />
            ) : (
              <Fields
                component={ResetChangeFields}
                names={['resetPassword', 'confirmPassword']}
                disableSubmit={isSubmitDisabled()}
              />
            )}
          </Card>
        </MediaContainer>
        <Box m={[tokens.spacing.s4, tokens.spacing.s0, tokens.spacing.s0, tokens.spacing.s0]}>
          <TextCenter>
            <ModifierFontSize>
              <strong>
                <Href to={routes.ABSOLUTE_PATHS.LOGIN} linkStyle="blue" onClick={() => trackAction('clicked Log in')}>
                  Back to the log in page
                </Href>
              </strong>
            </ModifierFontSize>
          </TextCenter>
        </Box>
      </Content>
    </Container>
  );
}

function ResetPasswordContainerWithErrorBoundary(props) {
  const { reportException } = useLogger();
  return (
    <ErrorBoundary
      FallbackComponent={AppError}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, { ...errMessageContext, componentStack })
      }
    >
      <ResetPasswordContainerInner {...props} />
    </ErrorBoundary>
  );
}

ResetPasswordContainerInner.propTypes = {
  asyncValidating: PropTypes.bool.isRequired,
  clearSubmitErrors: PropTypes.func.isRequired,
  error: PropTypes.shape({
    name: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string.isRequired,
      validationId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

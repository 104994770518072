import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Map, List } from 'immutable';

import { Button } from '@unitoio/mosaic';

import * as billingTypes from '~/consts/billing';
import * as trackingTypes from '~/consts/tracking';
import { getTaskSyncsTaskCount, isOrganizationAccountPaying } from '~/reducers';
import { TrackingFunnel } from '~/containers/TrackingFunnel/TrackingFunnel';
import { getPlanTier } from '~/utils/getPlanTier';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';

import { PaymentCheckoutModal } from '../PaymentCheckoutModal/PaymentCheckoutModal';
import { BlockIntervalChangePlanModal } from './BlockIntervalChangePlanModal';
import { BlockSelectPlanModal } from './BlockSelectPlanModal';
import { BlockChangePlanModal } from './BlockChangePlanModal';
import { ConfirmChangePlanModal } from './ConfirmChangePlanModal';

const onClickSelectPlan = async (event, plan, setShowModal, trackEvent, reportInfo) => {
  // The blur has to be forced manually else the button will stay focused once the modal is closed
  event.target.blur();

  setShowModal(true);

  reportInfo(`START ${trackingTypes.MODULE.PAYMENT} funnel`, { funnel: { action: trackingTypes.START } });
  trackEvent(`${trackingTypes.SUB_PAGE.WORKSPACE_PRICING}_${trackingTypes.ACTION}`, {
    action_name: 'clicked on plan',
    plan_selected: plan.get('id'),
    plan_tier_selected: getPlanTier(plan.get('id')),
  });
};

const isPlanSelectable = (plan, taskSyncsTaskCount) => {
  const mirrorLimit = plan.getIn(['features', 'MAX_MIRROR_SYNCS', 'limit']);
  const isMirrorUsageOverLimit = mirrorLimit && mirrorLimit < taskSyncsTaskCount;

  const usageIsBelowPlanLimits = plan.get('rejectionReasons', List()).isEmpty() && !isMirrorUsageOverLimit;

  return usageIsBelowPlanLimits;
};

const handleChangePlan = (currentPlan, plan, onSelect, trackEvent) => {
  onSelect(plan.get('id'));

  trackEvent(`${trackingTypes.USER_PRICING_EVENTS.PLAN_CHANGE}_${trackingTypes.SUBMIT}`, {
    current_plan: currentPlan.get('id'),
    plan_selected: plan.get('id'),
    plan_tier_selected: getPlanTier(plan.get('id')),
  });
};

const getBtnLabel = (currentPlan, plan, isPayingAccount) => {
  const currentPlanAmountPerMonth =
    currentPlan.get('interval') === 'year' ? currentPlan.get('amount', 0) / 12 : currentPlan.get('amount', 0);
  const newPlanAmountPerMonth = plan.get('interval') === 'year' ? plan.get('amount', 0) / 12 : plan.get('amount', 0);

  const isUpgrade = currentPlanAmountPerMonth < newPlanAmountPerMonth;

  if (!isPayingAccount) {
    return 'Select plan';
  }

  return isUpgrade ? 'Upgrade' : 'Downgrade';
};

export function SelectPlanButton({ currentPlan, hasPaymentSource, onSelect, plan, orgCustomerId }) {
  const { organizationId } = useParams();

  // Not using the trackingFunnel contextName here because this tracking was implemented pre funnel era and they don't have the same
  // funnels depending on whether the user buys a plan for the first time or if they upgrade/downgrade to a new plan.
  const trackEvent = useTrackEvent({}, false);
  const { reportInfo } = useLogger();
  const [showModal, setShowModal] = useState(false);
  const taskSyncsTaskCount = useSelector((state) => getTaskSyncsTaskCount(state, organizationId));
  const isPayingAccount = useSelector((state) => isOrganizationAccountPaying(state, organizationId));

  const planIsSelectable = isPlanSelectable(plan, taskSyncsTaskCount);
  const showCheckout = !hasPaymentSource && planIsSelectable;

  const getConfirmationModal = () => {
    const isPausePlan = currentPlan.get('id').includes(billingTypes.PLAN_TYPES.PAUSE);
    const blockShorterInterval = !isPausePlan && plan.get('interval') < currentPlan.get('interval');

    // Block on the number of active users only if the user is picking a yearly plan
    if (!hasPaymentSource) {
      if (!planIsSelectable) {
        return (
          <BlockSelectPlanModal
            newPlan={plan}
            onRequestClose={() => setShowModal(false)}
            trackEvent={trackEvent}
            rejectionReasons={plan.get('rejectionReasons')}
          />
        );
      }

      return null;
    }

    if (blockShorterInterval) {
      return (
        <BlockIntervalChangePlanModal
          currentPlan={currentPlan}
          newPlan={plan}
          onRequestClose={() => setShowModal(false)}
          trackEvent={trackEvent}
        />
      );
    }

    if (!planIsSelectable) {
      return (
        <BlockChangePlanModal
          currentPlan={currentPlan}
          newPlan={plan}
          onRequestClose={() => setShowModal(false)}
          trackEvent={trackEvent}
          rejectionReasons={plan.get('rejectionReasons')}
        />
      );
    }

    return (
      <ConfirmChangePlanModal
        currentPlan={currentPlan}
        newPlan={plan}
        onRequestClose={() => setShowModal(false)}
        onSubmit={() => handleChangePlan(currentPlan, plan, onSelect, trackEvent)}
      />
    );
  };

  return (
    <>
      <Button onClick={(event) => onClickSelectPlan(event, plan, setShowModal, trackEvent, reportInfo)}>
        {getBtnLabel(currentPlan, plan, isPayingAccount)}
      </Button>
      <TrackingFunnel contextName={trackingTypes.MODULE.PAYMENT}>
        <PaymentCheckoutModal
          isOpen={showModal && showCheckout}
          onCancel={() => setShowModal(false)}
          onSuccess={(planId, token) => onSelect(planId, token)}
          planId={plan.get('id')}
          organizationId={organizationId}
          orgCustomerId={orgCustomerId}
        />
      </TrackingFunnel>
      {showModal && !showCheckout && getConfirmationModal()}
    </>
  );
}

SelectPlanButton.propTypes = {
  currentPlan: PropTypes.instanceOf(Map).isRequired,
  hasPaymentSource: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  plan: PropTypes.instanceOf(Map).isRequired,
  orgCustomerId: PropTypes.string.isRequired,
};

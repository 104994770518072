import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Box, Col, Grid, LoadingPlaceholder, Row, tokens, Typography } from '@unitoio/mosaic';

import * as featureTypes from '~/consts/features';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { hasOrganizationTrelloTeamSetup, getMaxUsageFeatureToDisplay, getOrganizationPlanProfile } from '~/reducers';

import { UsageCard } from './UsageCard';
import { ChangesCard } from './ChangesCard';
import { HoursSavedCard } from './HoursSavedCard';

const NoPaddingGrid = styled(Grid)`
  padding: 0 !important;
`;

function getFeatures(organizationId, valueMetricId, hasTrelloTeamSetup, isUnlimitedMirrorsOrg) {
  const mainValueFeature =
    valueMetricId === featureTypes.FEATURES.MAX_ITEMS_KEPT_IN_SYNC
      ? {
          name: featureTypes.FEATURES.MAX_ITEMS_KEPT_IN_SYNC,
          link: `${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/items-kept-in-sync`,
          linkCopy: 'See details',
          trackingActionName: trackingTypes.USAGE_EVENTS.ACTIONS.SEE_DETAILS_ITEMS_IN_SYNC,
        }
      : {
          name: featureTypes.FEATURES.MAX_USERS,
          link: `${routes.ABSOLUTE_PATHS.ORGANIZATIONS}/${organizationId}/people/active-users`,
          linkCopy: 'See details',
          trackingActionName: trackingTypes.USAGE_EVENTS.ACTIONS.SEE_DETAILS_ACTIVE_USERS,
        };

  const features = [
    mainValueFeature,
    {
      name: featureTypes.FEATURES.MAX_AUTO_SYNCS,
      link: routes.ABSOLUTE_PATHS.PROJECT_SYNC,
      linkCopy: 'Go to flow list',
      trackingActionName: trackingTypes.USAGE_EVENTS.ACTIONS.SEE_FLOW_LIST,
    },
  ];

  if (hasTrelloTeamSetup && !isUnlimitedMirrorsOrg) {
    features.push({
      name: featureTypes.FEATURES.MAX_MIRROR_SYNCS,
      link: routes.ABSOLUTE_PATHS.TASK_SYNC,
      linkCopy: 'Go to mirror list',
      trackingActionName: trackingTypes.USAGE_EVENTS.ACTIONS.SEE_MIRROR_LIST,
    });
  }

  return features;
}

function UsageBlockWrapper({ children }) {
  return (
    <Box m={[tokens.spacing.s6, 0]}>
      <Typography variant={Typography.variants.H2}>Usage</Typography>
      <Box m={[tokens.spacing.s5, 0, 0, 0]}>
        <NoPaddingGrid>
          <Row align="center" nogutter>
            {children}
          </Row>
        </NoPaddingGrid>
      </Box>
    </Box>
  );
}

UsageBlockWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export function UsageBlock({ isLoading }) {
  const { organizationId } = useParams();
  const valueMetricId = useSelector((state) => getMaxUsageFeatureToDisplay(state)).get('id');
  const hasTrelloTeamSetup = useSelector((state) => hasOrganizationTrelloTeamSetup(state));
  const maxMirrorSyncsLimit = useSelector((state) => getOrganizationPlanProfile(state)).getIn([
    'features',
    featureTypes.FEATURES.MAX_MIRROR_SYNCS,
    'limit',
  ]);
  const isUnlimitedMirrorsOrg = maxMirrorSyncsLimit === featureTypes.UNLIMITED;

  const features = getFeatures(organizationId, valueMetricId, hasTrelloTeamSetup, isUnlimitedMirrorsOrg);

  if (isLoading) {
    return (
      <UsageBlockWrapper>
        {[...Array(6).keys()].map((index) => (
          <Col lg={6} md={6} key={`usage-card-loading-${index}`}>
            <Box m={[tokens.spacing.s2]}>
              <LoadingPlaceholder width="100%" height="6.25rem" borderRadius={tokens.spacing.s4} />
            </Box>
          </Col>
        ))}
      </UsageBlockWrapper>
    );
  }

  return (
    <UsageBlockWrapper>
      {features.map((feature) => (
        <Col lg={6} md={6} key={`usage-card-${feature.name}`}>
          <Box m={[tokens.spacing.s2]}>
            <UsageCard
              featureName={feature.name}
              featureLink={feature.link}
              featureLinkCopy={feature.linkCopy}
              trackingActionName={feature.trackingActionName}
            />
          </Box>
        </Col>
      ))}
      <Col lg={6} md={6} key="usage-card-changes">
        <Box m={[tokens.spacing.s2]}>
          <ChangesCard />
        </Box>
      </Col>
      <Col lg={6} md={6} key="usage-card-hours-saved">
        <Box m={[tokens.spacing.s2]}>
          <HoursSavedCard />
        </Box>
      </Col>
    </UsageBlockWrapper>
  );
}

UsageBlock.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

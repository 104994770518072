import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notification } from '@unitoio/mosaic';

import * as appActions from '~/actions/app';
import * as workflowActions from '~/actions/workflows';
import { getScripts, getSiteadminSearchString } from '~/reducers';
import { Button } from '~/components/Button/Button';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { Modal } from '~/components/Modal/Modal';
import { SearchBox } from '~/components/SearchBox/SearchBox';

import { UnitoPrompt } from './UnitoPrompt';

export const SiteAdminSearch = ({ isWorkflow = false, onChangeSiteAdminSearch, onReset }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showUnitoPrompt, setShowUnitoPrompt] = useState(false);
  const dispatch = useDispatch();
  const scripts = useSelector(getScripts);
  const siteadminSearchString = useSelector(getSiteadminSearchString);

  // Dirty hack for initialization, couldn't find a way to do it better
  const [initialSiteadminSearch] = useState(siteadminSearchString);
  useEffect(() => {
    if (initialSiteadminSearch && initialSiteadminSearch !== '') {
      onChangeSiteAdminSearch(initialSiteadminSearch);
    }
  }, [initialSiteadminSearch, onChangeSiteAdminSearch]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(appActions.getScripts());
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const onSearch = async () => {
    const sanitizedSearch = siteadminSearchString.trim();
    if (sanitizedSearch.split(',').every((elem) => !!elem.match(/^[0-9a-fA-F]{24}$/))) {
      appActions.setSiteadminSearchString(sanitizedSearch);
      if (isWorkflow) {
        await dispatch(workflowActions.searchWorkflows(sanitizedSearch));
      }
      onChangeSiteAdminSearch(sanitizedSearch);
    } else {
      notification.error({
        message: 'Invalid search',
        description:
          'Make sure to enter valid "Link Id", "Organization Id", "Multi-sync Id" or "User Id". Separate multiple searches with commas.',
        placement: 'top',
      });
    }
  };

  const openUnitoPrompt = () => {
    setShowUnitoPrompt(true);
  };

  const closeUnitoPrompt = () => {
    setShowUnitoPrompt(false);
  };

  const onResetSearch = async () => {
    appActions.setSiteadminSearchString('');
    if (isWorkflow) {
      await dispatch(workflowActions.searchWorkflows());
    }
    onReset();
  };

  const linkPlaceholder =
    'Search links by LinkId, OrganizationId, MultisyncId or UserId. Separate multiple searches with commas.';
  const wfPlaceholder =
    'Search workflows by WorkflowId, LinkId, OrganizationId or UserId. Separate multiple searches with commas.';

  if (isLoading) {
    return <InlineLoading size="small" />;
  }

  return (
    <>
      <Modal
        isOpen={showUnitoPrompt}
        type="plainModal"
        displayCloseButton
        onRequestClose={closeUnitoPrompt}
        size="full"
      >
        <UnitoPrompt scripts={scripts} onClose={closeUnitoPrompt} />
      </Modal>

      <SearchBox
        placeholder={isWorkflow ? wfPlaceholder : linkPlaceholder}
        onChange={(searchString) => dispatch(appActions.setSiteadminSearchString(searchString))}
        onPressEnter={onSearch}
        value={siteadminSearchString}
      >
        <Button btnStyle="secondary" onClick={onSearch}>
          Search
        </Button>
        <Button btnStyle="error" onClick={onResetSearch}>
          Reset
        </Button>
        <Button onClick={openUnitoPrompt} btnStyle="dark">
          Unito Prompt
        </Button>
      </SearchBox>
    </>
  );
};

SiteAdminSearch.propTypes = {
  isWorkflow: PropTypes.bool,
  onChangeSiteAdminSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export const FEATURES = {
  ADD_ON_IMPROVEMENTS: 'add-on-improvements',
  SUBTASK: 'subtask',
  MAX_AUTO_SYNCS: 'MAX_AUTO_SYNCS',
  CHANGES: 'changes',
  CLOSED_TASKS: 'closedTasks',
  MAX_MIRROR_SYNCS: 'MAX_MIRROR_SYNCS',
  STREAM_ATTACHMENTS: 'streamAttachments',
  MAPPED_FIELDS: 'mapped-fields',
  MAX_USERS: 'MAX_USERS',
  MAX_ITEMS_KEPT_IN_SYNC: 'MAX_ITEMS_KEPT_IN_SYNC',
  CUSTOM_FIELD: 'CUSTOM_FIELD',
  WORKFLOWS: 'workflows',
  PERMISSIONS: 'PERMISSIONS',
  EMBED_LOOKER_REPORTS: 'embed-looker-reports',
  SINGLE_AND_MULTIPLE_SELECT_MAPPING_ENABLED: 'single-and-multi-select-mapping-enabled',
  FLOW_BUILDER_PREFIX: 'flow-builder-prefix',
  ITEMS_KEPT_IN_SYNC_PAGE: 'items-kept-in-sync-page',
  SYNC_OBSERVABILITY_ERROR_DETAILS: 'sync-observability-error-details',
  CONTAINER_BUILDER: 'container-builder',
  DELETE_PRESERVE_OUT_OF_SYNC_ITEM_FILTER: 'delete-preserve-out-of-sync-item-filter',
  TEST_MODE_DEFAULT: 'test-mode-default',
  NEW_TEST_MODE: 'new-test-mode',
  PAGINATED_FLOW_LIST: 'paginated-flow-list',
  MODERN_RULES_PAGE: 'modern-rules-page',
  CAN_MODIFY_CREATED_AT: 'can-modify-created-at',
  SYNC_STATUS_REVAMP: 'sync-status-revamp',
  LINK_LIST_REVAMP: 'link-list-revamp',
  HIDE_CONNECTOR_BY_PROVIDER_NAME: 'hide-connector-by-provider-name',
  WORKFLOWS_DEPRECATION: 'workflows-deprecation',
  NOTIFICATION_SYSTEM: 'notification-system',
  INDIVIDUAL_CONNECTOR_VISIBILITY: 'individual-connector-visibility',
};

export const TEST_MODE_VARIANT = {
  NOW: 'now',
  NONE: 'none',
  LAST_MONTH: 'last_month',
  NULL: 'null', // Temporary variant as we still support the old test mode FF. This is so it doesn't conflict for older user.
};

export const VISIBILITY_VARIANT = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

export const LIMIT_MESSAGES_PER_FEATURE = {
  [FEATURES.WORKFLOWS]: 'You have reached your workflow limit.',
  [FEATURES.CUSTOM_FIELD]: 'You have reached your custom fields limit.',
  [FEATURES.CLOSED_TASKS]: 'Including historical data in your flows is not available on your current plan.',
  [FEATURES.SUBTASK]: 'Including subtasks in your flows is not available on your current plan.',
  [FEATURES.STREAM_ATTACHMENTS]: 'Streaming attachments between tools is not available on your current plan.',
  [FEATURES.MAPPED_FIELDS]:
    'Your current plan limits the maximum number of field mappings available per flow. If you want to map more fields, you’ll need to upgrade your plan.',
};

export const UNLIMITED = 'unlimited';

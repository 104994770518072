import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box } from '@unitoio/mosaic';

import {
  isEnterpriseAccount,
  isOnFreeTrial,
  isOnMirrorLegacy,
  isOrganizationAccountPaused,
  isOrganizationChurned,
  isOrganizationResold,
  isOrganizationTrialExpired,
  isVipAccount,
} from '~/reducers';

import { CurrentPlanDetail } from './CurrentPlanDetail';
import { NextPlanDetail } from './NextPlanDetail';

export const DetailedPlanBlock = () => {
  const { organizationId } = useParams();
  const isEnterprise = useSelector((state) => isEnterpriseAccount(state, organizationId));
  const isVip = useSelector((state) => isVipAccount(state, organizationId));
  const isTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const isTrialExpired = useSelector((state) => isOrganizationTrialExpired(state, organizationId));
  const isResold = useSelector((state) => isOrganizationResold(state, organizationId));
  const isMirrorLegacy = useSelector((state) => isOnMirrorLegacy(state, organizationId));
  const isPaused = useSelector((state) => isOrganizationAccountPaused(state, organizationId));
  const isChurned = useSelector((state) => isOrganizationChurned(state, organizationId));

  if (isEnterprise || isVip || isMirrorLegacy || isPaused || isChurned) {
    return null;
  }

  return (
    <Box flexDirection={Box.flexDirection.COLUMN}>
      <Box data-testid="currentPlanDetail">
        <CurrentPlanDetail />
      </Box>

      {!isTrialing && !isTrialExpired && !isResold && (
        <Box data-testid="nextPlanDetail">
          <NextPlanDetail />
        </Box>
      )}
    </Box>
  );
};

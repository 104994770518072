import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom';

import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { getDuplicatableLinks, getProviderByProviderIdentityId, getProviderCapabilitiesByIdV3 } from '~/reducers';
import * as draftActions from '~/actions/drafts';
import { DuplicateModal } from '~/components/DuplicateFlow/DuplicateModal';
import { FlowSelection } from '~/components/DuplicateFlow/FlowSelection';

import { trackableHoC } from '../../TrackableHoC/TrackableHoC';
import { getSyncDirection } from '../../FlowBuilder/utils/form';
import { WorkflowDiagramModel } from '../models/WorkflowDiagramModel';

const LINK_SELECTION = 'link-selection';

export const WorkflowDuplicateFlowForm = trackableHoC(
  ({ workflowModel, parentMatch, updateWorkflow, trackEvent, workflowId }) => {
    const {
      control,
      formState: { isSubmitting },
      handleSubmit,
      watch,
    } = useForm();

    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { nodeId } = match.params;
    const node = workflowModel.getNode(nodeId);

    const existingSimilarLinks = useSelector((state) =>
      getDuplicatableLinks(state, node.getSideA(), node.getSideB(), workflowId),
    );
    const providerA = useSelector((state) =>
      getProviderByProviderIdentityId(state, node.getSideA().providerIdentityId),
    );
    const providerB = useSelector((state) =>
      getProviderByProviderIdentityId(state, node.getSideB().providerIdentityId),
    );

    const [workblockA, workblockB] = workflowModel.getSiblingNodes(node);

    const capabilitiesA = useSelector((state) => getProviderCapabilitiesByIdV3(state, providerA.get('_id')));
    const capabilitiesB = useSelector((state) => getProviderCapabilitiesByIdV3(state, providerB.get('_id')));

    const fallbackItemTypeA = capabilitiesA.getIn(['item', 'names', 'native']);
    const fallbackContainerTypeA = capabilitiesA.get('containers').first().getIn(['names', 'native']);

    const fallbackItemTypeB = capabilitiesB.getIn(['item', 'names', 'native']);
    const fallbackContainerTypeB = capabilitiesB.get('containers').first().getIn(['names', 'native']);

    // soft migration for legacy workflows which don't have the itemType/containerType stored in their blocks yet.
    // We use the default item/container types of the respective providers to populate the blocks.
    // this was easier to implement then a script to update both blocks and diagramRepresentation of all existing workflows.
    const itemTypeA = workblockA.getItemType() ?? fallbackItemTypeA;
    const containerTypeA = workblockA.getContainerType() ?? fallbackContainerTypeA;
    const itemTypeB = workblockB.getItemType() ?? fallbackItemTypeB;
    const containerTypeB = workblockB.getContainerType() ?? fallbackContainerTypeB;

    const watchLinkField = watch('linkId');

    if (node.getLinkId()) {
      return <Redirect to={`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${node.getLinkId()}`} />;
    }

    const handleOnSubmit = handleSubmit(async ({ linkId }) => {
      const selectedLink = existingSimilarLinks.find((link) => link.get('_id') === linkId);

      const AequalsB = selectedLink.getIn(['A', 'container', 'id']) === node.getSideB().containerId;
      const BequalsA = selectedLink.getIn(['B', 'container', 'id']) === node.getSideA().containerId;

      const notSameSide = AequalsB || BequalsA;

      // flip the node sides if necessary, so that the common block matches the common container in the link
      if (notSameSide) {
        node.flipSides(`
        ${workflowModel.getNode(node.getSiblings()[0]).getContainerName()} ⇄
        ${workflowModel.getNode(node.getSiblings()[1]).getContainerName()}
      `);
      }

      trackEvent(trackingTypes.WORKFLOW_FLOW_DUPLICATE.FLOW_ACTION, {
        action_name: trackingTypes.WORKFLOW_FLOW_DUPLICATE.ACTIONS.DUPLICATE_FLOW,
        selected_tool_names: `${providerA.get('name')},${providerB.get('name')}`,
      });

      // duplicate form data builder for the flow-builder
      const syncDirection = getSyncDirection(selectedLink.get('syncSettings'));

      const formData = {
        A: {
          providerName: workblockA.getToolName(),
          providerContainerId: workblockA.getProviderContainerId(),
          providerIdentityId: workblockA.getProviderIdentityId(),
          containerId: workblockA.getContainerId(),
          existingContainer: true,
          itemType: itemTypeA,
          containerType: containerTypeA,
        },
        B: {
          providerName: workblockB.getToolName(),
          providerContainerId: workblockB.getProviderContainerId(),
          providerIdentityId: workblockB.getProviderIdentityId(),
          containerId: workblockB.getContainerId(),
          existingContainer: true,
          itemType: itemTypeB,
          containerType: containerTypeB,
        },
        duplicateLinkId: linkId,
        syncDirection,
        workflowId,
      };
      const { link } = await dispatch(draftActions.createDraft(formData));

      return history.push(`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${link._id}`);
    });

    const deleteFlow = () => {
      workflowModel.deleteFlow(node.getID());
      updateWorkflow();
    };

    const onCreateFromScratch = async () => {
      const formData = {
        A: {
          providerName: workblockA.getToolName(),
          providerContainerId: workblockA.getProviderContainerId(),
          providerIdentityId: workblockA.getProviderIdentityId(),
          containerId: workblockA.getContainerId(),
          existingContainer: true,
          itemType: itemTypeA,
          containerType: containerTypeA,
        },
        B: {
          providerName: workblockB.getToolName(),
          providerContainerId: workblockB.getProviderContainerId(),
          providerIdentityId: workblockB.getProviderIdentityId(),
          containerId: workblockB.getContainerId(),
          existingContainer: true,
          itemType: itemTypeB,
          containerType: containerTypeB,
        },
        workflowId,
      };
      const { link } = await dispatch(draftActions.createDraft(formData));
      return history.push(`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_WORKFLOW}/${workflowId}/${link._id}`);
    };

    if (!node) {
      return <Redirect to={parentMatch.url} />;
    }

    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/${LINK_SELECTION}`}
          render={() => (
            <FlowSelection
              control={control}
              providerA={providerA}
              providerB={providerB}
              existingSimilarLinks={existingSimilarLinks}
              handleOnSubmit={handleOnSubmit}
              disabled={isSubmitting || !watchLinkField}
            />
          )}
        />
        <Route
          render={() => (
            <DuplicateModal
              history={history}
              providerA={providerA}
              providerB={providerB}
              disabled={isSubmitting}
              onCreate={onCreateFromScratch}
              onDelete={deleteFlow}
              duplicateUrl={`${match.url}/${LINK_SELECTION}`}
            />
          )}
        />
      </Switch>
    );
  },
);

WorkflowDuplicateFlowForm.propTypes = {
  parentMatch: PropTypes.shape({
    params: PropTypes.shape({
      linkId: PropTypes.string,
      workflowId: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  trackEvent: PropTypes.func.isRequired,
  updateWorkflow: PropTypes.func.isRequired,
  workflowId: PropTypes.string.isRequired,
  workflowModel: PropTypes.instanceOf(WorkflowDiagramModel).isRequired,
};

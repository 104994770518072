import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';

import { Alert, Box, Button, Icon, Link, ProviderIcon, tokens, Typography } from '@unitoio/mosaic';

import * as containerActions from '~/actions/containers';
import { getContainerById, isLoadingContainer } from '~/reducers';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useLogger } from '~/hooks/useLogger';
import * as containerTypes from '~/consts/containers';
import * as trackingTypes from '~/consts/tracking';

import { useGetProviderExtraStep } from '../../../hooks/useGetProviderExtraStep';
import { useHasMissingExtraStepAction } from '../../../hooks/useHasMissingExtraStepAction';
import { InstructionsModal } from './InstructionsModal';

const InstalledInfo = styled(Box)`
  white-space: nowrap;
  line-height: ${tokens.spacing.s4};
`;

// Overwriting Typography line-height as we find a more complete solution
const TypographyWithLineHeight = styled(Typography)`
  line-height: ${tokens.spacing.s4};
`;

const BoxWithCursor = styled(Box)`
  cursor: pointer;
`;

const BoxDisabled = styled(Box)`
  cursor: wait;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: ${Box.alignItems.CENTER};
`;

function AddOnStatus({ missingAddonRequirements, onVerifyAddOn, shouldShowAddonVerificationFailedStatus }) {
  if (!missingAddonRequirements) {
    return (
      <Box alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
        <Icon name="check" color={tokens.colors.content.positive.default} />
        <Box m={[0, 0, 0, tokens.spacing.s3]}>
          <TypographyWithLineHeight variant="body2">Add-on fully configured</TypographyWithLineHeight>
        </Box>
      </Box>
    );
  }

  if (!!missingAddonRequirements && shouldShowAddonVerificationFailedStatus) {
    return (
      <Alert level="error" size="sm">
        <TypographyWithLineHeight variant="body2">{missingAddonRequirements.get('message')}</TypographyWithLineHeight>
      </Alert>
    );
  }

  return (
    <BoxWithCursor alignItems="center" p={[tokens.spacing.s3, tokens.spacing.s4]}>
      <Box m={[0, 0, 0, tokens.spacing.s3]}>
        <TypographyWithLineHeight variant="body2" onClick={onVerifyAddOn}>
          Verify add-on status
        </TypographyWithLineHeight>
      </Box>
    </BoxWithCursor>
  );
}

AddOnStatus.propTypes = {
  shouldShowAddonVerificationFailedStatus: PropTypes.bool.isRequired,
  missingAddonRequirements: PropTypes.instanceOf(Map),
  onVerifyAddOn: PropTypes.func.isRequired,
};

function useShowAddonVerificationFailedStatusAtInterval(
  missingAddonRequirements,
  setShouldShowAddonVerificationFailedStatus,
) {
  useEffect(() => {
    const timer = () => setTimeout(() => setShouldShowAddonVerificationFailedStatus(false), 4 * 1000);
    if (missingAddonRequirements) {
      timer();
    }

    return () => {
      clearTimeout(timer());
    };
  }, [missingAddonRequirements, setShouldShowAddonVerificationFailedStatus]);
}

const Step = ({ containerName, providerIdentityId, containerId, containerType, itemType }) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { reportException, reportWarning } = useLogger();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVerifyingAdonStatus, setIsVerifyingAdonStatus] = useState(false);
  const [shouldShowAddonVerificationFailedStatus, setShouldShowAddonVerificationFailedStatus] = useState(true);
  const selectedContainer = useSelector((state) => getContainerById(state, providerIdentityId, containerId));
  const isSelectedContainerLoading = useSelector((state) => isLoadingContainer(state, containerId));
  const providerName = 'googlesheets';
  const providerItemAExtraStep = useGetProviderExtraStep(providerName);
  const missingAddonRequirements = useHasMissingExtraStepAction(
    providerItemAExtraStep,
    providerName,
    providerIdentityId,
    containerId,
  );
  useShowAddonVerificationFailedStatusAtInterval(missingAddonRequirements, setShouldShowAddonVerificationFailedStatus);

  const reloadContainer = useCallback(async () => {
    if (providerIdentityId && containerId && containerType) {
      try {
        setIsVerifyingAdonStatus(true);
        const { container } = await dispatch(
          // getUncached: true
          // In case the user just added the { Unito ID, Last Modified } columns in the last 60s we need to hit the non-cached version of the sheet
          // in order to properly validate whether or not the add-on has been successfully added.
          containerActions.getContainerById({
            providerIdentityId,
            containerId,
            containerType,
            itemType,
            getUncached: true,
          }),
        );

        const hasMissingAddonRequirements = !!container?.requirements?.find(
          (requirement) =>
            requirement.context === containerTypes.REQUIREMENT_CONTEXT_ADDON &&
            requirement.type === containerTypes.REQUIREMENT_CONTEXT_ERROR,
        );

        if (hasMissingAddonRequirements) {
          setShouldShowAddonVerificationFailedStatus(true);
        }
      } catch (error) {
        if (error.code < 500) {
          reportWarning(error.message, {
            identifier: 'errorReloadingContainerAfterAddOnInstall reloadContainer GoogleSheet.Step',
          });
        } else {
          reportException(error, {
            identifier: 'errorReloadingContainerAfterAddOnInstall reloadContainer GoogleSheet.Step',
          });
        }
      } finally {
        setIsVerifyingAdonStatus(false);
      }
    }
  }, [dispatch, providerIdentityId, containerId, containerType, itemType, reportException, reportWarning]);

  useEffect(() => {
    const reloadContainerOnRender = async () => {
      await reloadContainer();
    };

    reloadContainerOnRender();
  }, [reloadContainer]);

  const onVerifyAddOnClick = async () => {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked on verify unito add on',
      selected_tool_name: providerName,
    });
    await reloadContainer();
  };

  const containerDisplayName = selectedContainer.get('displayName');
  const containerUrl = selectedContainer.get('url');

  return (
    <>
      <Box
        p={[tokens.spacing.s4]}
        m={[tokens.spacing.s5, 0, 0, 0]}
        borderRadius={tokens.spacing.s3}
        alignItems={Box.alignItems.CENTER}
        backgroundColor={tokens.colors.background.neutral.grey}
        justifyContent={Box.justifyContent.SPACE_BETWEEN}
      >
        <StyledLink href={containerUrl} isExternalLink>
          <ProviderIcon name={providerName} size="small" />
          <Box m={[0, 0, 0, tokens.spacing.s3]}>
            <TypographyWithLineHeight>{containerName}</TypographyWithLineHeight>
          </Box>
        </StyledLink>
        <InstalledInfo alignItems={Box.alignItems.CENTER} m={[0, tokens.spacing.s4, 0, 'auto']}>
          {selectedContainer.isEmpty() || isVerifyingAdonStatus || isSelectedContainerLoading ? (
            <BoxDisabled alignItems={Box.alignItems.CENTER} p={[tokens.spacing.s3, tokens.spacing.s4]}>
              <Box m={[0, 0, 0, tokens.spacing.s3]}>
                <TypographyWithLineHeight variant="body2" color={tokens.colors.content.neutral.n20}>
                  Verifying add-on status...
                </TypographyWithLineHeight>
              </Box>
            </BoxDisabled>
          ) : (
            <AddOnStatus
              shouldShowAddonVerificationFailedStatus={shouldShowAddonVerificationFailedStatus}
              missingAddonRequirements={missingAddonRequirements}
              onVerifyAddOn={onVerifyAddOnClick}
            />
          )}
        </InstalledInfo>

        <Button
          size={Button.sizes.SM}
          onClick={() => {
            setIsModalOpen(true);
            trackEvent(trackingTypes.ACTION, {
              action_name: 'clicked on install unito add on',
              selected_tool_name: providerName,
            });
          }}
        >
          Install Unito add-on
        </Button>
      </Box>
      {containerDisplayName && containerUrl && (
        <InstructionsModal
          isOpen={isModalOpen}
          onCancel={async () => {
            setIsModalOpen(false);
            await reloadContainer();
          }}
          onConfirm={async () => {
            setIsModalOpen(false);
            await reloadContainer();
          }}
          containerName={containerDisplayName}
          containerUrl={containerUrl}
        />
      )}
    </>
  );
};

Step.propTypes = {
  containerName: PropTypes.string.isRequired,
  providerIdentityId: PropTypes.string.isRequired,
  containerId: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
};

export { Step as GoogleSheetStep };

import { useFieldArray, useWatch } from 'react-hook-form';
import { Map } from 'immutable';

import * as fieldTypes from '~/consts/fields';

export function getHasTestModeEnabled(link = Map()) {
  // using the redux selector here because this is used outside of the FlowBuilder as well.
  const earliestCreatedAt = link.getIn(['syncSettings', 'earliestCreatedAt']);
  const rulesAActive = link.getIn(['rules', 'A', 'filters', 'isActive']);
  const rulesBActive = link.getIn(['rules', 'B', 'filters', 'isActive']);
  return !!earliestCreatedAt && !rulesAActive && !rulesBActive;
}

/** Checks to see if the current draft form context has 'earliestCreatedAt' field value or not.
 * In the react-hook-form form though, we translate 'earliestCreatedAt' has an individual filter for each side,
 * so here we check to see if the property is available on either side filter.
 * */
export function useHasTestModeEnabled() {
  const filtersA = useFieldArray({ name: 'A.filters' });
  const filtersB = useFieldArray({ name: 'B.filters' });
  const [rulesFiltersActiveA, rulesFiltersActiveB] = useWatch({
    name: ['rules.A.filters.isActive', 'rules.B.filters.isActive'],
  });

  // the concept of test-mode is deprecated for modern rules
  if (rulesFiltersActiveA || rulesFiltersActiveB) {
    return false;
  }

  return (
    filtersA.fields.some(({ fieldId, value }) => fieldId === fieldTypes.EARLIEST_CREATED_AT && !!value) ||
    filtersB.fields.some(({ fieldId, value }) => fieldId === fieldTypes.EARLIEST_CREATED_AT && !!value)
  );
}

import { useContext, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import * as fieldTypes from '~/consts/fields';
import { FlowBuilderErrorContext } from '~/contexts';
import { getProviderByName, getProviderVisibleFields } from '~/reducers';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';

import { useGetContainers } from './useGetContainers';
import { useAreBothSidesMergeable } from './useAreBothSidesMergeable';

const getAllMappedFieldIds = (fieldAssociations = Map(), side) =>
  fieldAssociations
    ?.filter((fieldAssociation) =>
      [fieldTypes.KINDS.PCD_TYPED_FIELD, fieldTypes.KINDS.PCD_FIELD].includes(fieldAssociation[side]?.kind),
    )
    .map((fieldAssociation) => fieldAssociation[side]?.field);

const getMissingMandatoryFields = (mandatoryFields, allMappedFieldIds, actionRules) =>
  mandatoryFields.filter(
    (field, fieldId) =>
      !allMappedFieldIds.includes(fieldId) && !actionRules?.find((action) => action.fieldId === fieldId),
  );

const handleLegacyMergeCheck = (
  bothSidesMergeable,
  fieldAssociations,
  visibleFieldsA,
  visibleFieldsB,
  allMappedFieldIdsA,
  allMappedFieldIdsB,
  watch,
) => {
  // START - LEGACY: mergeFields is only used for legacy providers (e.g. Salesforce, HubSpot, etc.)
  // TODO we might have to revisit the way we check that associations were initialzied (fieldAssociations.length)
  // TODO for custom field based providers which typically don't have any auto mappings.
  // when both sides are mergeable, and mappings were initialized, check that the selected merge fields are mapped

  const mergeFieldsA = watch('A.merge.fields') || [];
  const mergeFieldsB = watch('B.merge.fields') || [];

  const getMissingMandatoryMergeFields = (mergeFields, allMappedFieldIds, mergeableFields) =>
    mergeFields
      .filter((fieldId) => !allMappedFieldIds.includes(fieldId))
      .reduce((asObj, fieldId) => asObj.set(fieldId, mergeableFields.get(fieldId)), Map());

  if (bothSidesMergeable && fieldAssociations?.length && !visibleFieldsA.isEmpty() && !visibleFieldsB.isEmpty()) {
    const mergeableFieldsA = visibleFieldsA.filter((field) => field.get('mergeField', false));
    const mergeableFieldsB = visibleFieldsB.filter((field) => field.get('mergeField', false));

    const missingMandatoryMergeFieldsA = getMissingMandatoryMergeFields(
      mergeFieldsA,
      allMappedFieldIdsA,
      mergeableFieldsA,
    );

    const missingMandatoryMergeFieldsB = getMissingMandatoryMergeFields(
      mergeFieldsB,
      allMappedFieldIdsB,
      mergeableFieldsB,
    );
    return [missingMandatoryMergeFieldsA, missingMandatoryMergeFieldsB];
  }
  return [Map(), Map()];
};

const useGetUnmappedDateFields = (providerFields, mappedFieldIds) => {
  const isDateTypeField = (field) =>
    field.get('type') === fieldTypes.TYPES.DATE ||
    field.get('type') === fieldTypes.TYPES.DATETIME ||
    field.get('type') === fieldTypes.TYPES.ISO_DATE_STRING;

  const isRequiredDateGroupField = (field) =>
    isDateTypeField(field) && (field.get('requiredOnCreate') || field.get('requiredByGroup') === 'requireOne');

  const requiredDateFields = providerFields.filter(isRequiredDateGroupField);
  if (!requiredDateFields.isEmpty()) {
    const requiredFieldKeys = requiredDateFields.keySeq().toArray();

    const hasUnmappedFields = !requiredFieldKeys.some((fieldName) => mappedFieldIds.includes(fieldName));

    if (hasUnmappedFields) {
      return requiredDateFields;
    }
  }
  return null;
};

export function useGetMissingMandatoryFieldsToMap(enableAlert = true) {
  const { setError, clearErrors, watch } = useFormContext();
  const pageName = useContext(FlowBuilderErrorContext);
  const providerNameA = watch('A.providerName');
  const providerNameB = watch('B.providerName');
  const providerA = useSelector((state) => getProviderByName(state, providerNameA));
  const providerB = useSelector((state) => getProviderByName(state, providerNameB));
  const [containerA, containerB] = useGetContainers();
  const fieldAssociations = useWatch({ name: 'associations' });
  const actionRulesA = useWatch({ name: 'A.actions' });
  const actionRulesB = useWatch({ name: 'B.actions' });
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const isFieldAssociationSet = fieldAssociations.length > 0;

  const visibleFieldsA = useSelector((state) =>
    getProviderVisibleFields(state, providerA.get('_id'), itemTypeA, containerA?.get('id')),
  );
  const visibleFieldsB = useSelector((state) =>
    getProviderVisibleFields(state, providerB.get('_id'), itemTypeB, containerB?.get('id')),
  );

  const allMappedFieldIdsA = getAllMappedFieldIds(fieldAssociations, 'A');
  const allMappedFieldIdsB = getAllMappedFieldIds(fieldAssociations, 'B');

  const mandatoryFieldsA = visibleFieldsA.filter(
    (field) => field.get('requiredOnCreate') && field.get('type') !== fieldTypes.TYPES.ENUM,
  );
  const mandatoryFieldsB = visibleFieldsB.filter(
    (field) => field.get('requiredOnCreate') && field.get('type') !== fieldTypes.TYPES.ENUM,
  );

  let missingMandatoryFieldsA = getMissingMandatoryFields(mandatoryFieldsA, allMappedFieldIdsA, actionRulesA);
  let missingMandatoryFieldsB = getMissingMandatoryFields(mandatoryFieldsB, allMappedFieldIdsB, actionRulesB);

  // START - Handle Legacy Merge check
  const bothSidesMergeable = useAreBothSidesMergeable({ itemTypeA, itemTypeB, providerNameA, providerNameB });
  const [missingMandatoryMergeFieldsA, missingMandatoryMergeFieldsB] = handleLegacyMergeCheck(
    bothSidesMergeable,
    fieldAssociations,
    visibleFieldsA,
    visibleFieldsB,
    allMappedFieldIdsA,
    allMappedFieldIdsB,
    watch,
  );
  missingMandatoryFieldsA = missingMandatoryFieldsA.merge(missingMandatoryMergeFieldsA);
  missingMandatoryFieldsB = missingMandatoryFieldsB.merge(missingMandatoryMergeFieldsB);
  // END - Handle Legacy Merge check

  // START - Handle Calendar check
  const missingDateFieldsA = useGetUnmappedDateFields(visibleFieldsA, allMappedFieldIdsA);
  const missingDateFieldsB = useGetUnmappedDateFields(visibleFieldsB, allMappedFieldIdsB);

  if (missingDateFieldsA) {
    missingMandatoryFieldsA = missingMandatoryFieldsA.merge(missingDateFieldsA);
  }
  if (missingDateFieldsB) {
    missingMandatoryFieldsB = missingMandatoryFieldsB.merge(missingDateFieldsB);
  }
  // END - Handle Calendar check

  const missingMandatoryFieldsACount = missingMandatoryFieldsA.size;
  const missingMandatoryFieldsBCount = missingMandatoryFieldsB.size;

  useEffect(() => {
    if (enableAlert && isFieldAssociationSet) {
      if (missingMandatoryFieldsACount > 0) {
        setError(`${pageName}.missingMandatoryFieldsA`, { type: 'manual' });
      } else if (missingMandatoryFieldsBCount > 0) {
        setError(`${pageName}.missingMandatoryFieldsB`, { type: 'manual' });
      } else {
        clearErrors([`${pageName}.missingMandatoryFieldsA`, `${pageName}.missingMandatoryFieldsB`]);
      }
    }
    // We add the field association length so we clear the errors if the user fixes their mapping
    // Otherwise we don't re-render and the warning is not cleared
  }, [
    setError,
    clearErrors,
    missingMandatoryFieldsACount,
    missingMandatoryFieldsBCount,
    pageName,
    enableAlert,
    isFieldAssociationSet,
  ]);

  return [missingMandatoryFieldsA, missingMandatoryFieldsB];
}

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Box, Button, Switch, Icon } from '@unitoio/mosaic';

import * as appTypes from '~/consts/app';
import { getIsSyncInProgress, getLinkById } from '~/reducers';
import { SiteAdminContext } from '~/contexts';
import { Box as LegacyBox } from '~/components/Box/Box';
import { DiagnoseModal } from '~/containers/DiagnoseModal/DiagnoseModal';
import * as linkActions from '~/actions/links';

import { useGetLeaveFlowBuilderRoute } from '../../hooks/useGetLeaveFlowBuilderRoute';

// TODO see why mimics Box seems to be having issues with 'auto' margin
const RightNavbar = styled(LegacyBox)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;

export function FlowActionsNavbar({ disableSave = true, linkId }) {
  const dispatch = useDispatch();
  const { setValue, watch } = useFormContext();
  const history = useHistory();
  const flowBuilderSaveRedirect = useGetLeaveFlowBuilderRoute();
  const [isDiagnosisModalOpen, setIsDiagnosisModalOpen] = useState(false);

  const currentLink = useSelector((state) => getLinkById(state, linkId));
  const isSyncInProgress = useSelector((state) => getIsSyncInProgress(state, linkId));
  const isMirrorFlow = currentLink.get('kind') === appTypes.PRODUCT_NAMES.TASK_SYNC;
  const { isSiteAdmin } = useContext(SiteAdminContext);
  const watchIsAutoSync = watch('isAutoSync');

  // FIXME the event returned by the Toggle onClick is currently unusable,
  // so inverting based on watched value for now.
  const onToggleAutoSync = () => {
    setValue('isAutoSync', !watchIsAutoSync, { shouldDirty: false });
    dispatch(linkActions.editLinkFields(linkId, { isAutoSync: !watchIsAutoSync }));
  };

  const handleOnSyncNow = () => {
    dispatch(linkActions.syncLink(linkId));
  };

  const handleOnSubmit = () => {
    history.push(flowBuilderSaveRedirect);
  };

  return (
    <RightNavbar $m={[0, 1, 0, 'auto']} as="ul" className="nav">
      {isSiteAdmin && (
        <Box as="li" m={[0, 1, 0, 0]}>
          <Button variant="subtleDestructive" onClick={() => setIsDiagnosisModalOpen(true)}>
            <Icon name="heartbeat" size="sm" kind={Icon.KINDS.SOLID} /> Diagnose
          </Button>
          {isDiagnosisModalOpen && (
            <DiagnoseModal
              isOpen={isDiagnosisModalOpen}
              sync={currentLink}
              onRequestClose={() => setIsDiagnosisModalOpen(false)}
            />
          )}
        </Box>
      )}
      <Box as="li" m={[0, 1, 0, 0]}>
        <Button disabled={disableSave} onClick={handleOnSubmit}>
          Save
        </Button>
      </Box>
      <Box as="li" m={[0, 1, 0, 0]}>
        <Button disabled={!disableSave || isSyncInProgress} variant="outlined" onClick={handleOnSyncNow}>
          Sync now
        </Button>
      </Box>
      {!isMirrorFlow && (
        <Box as="li" m={[0, 1, 0, 0]}>
          <Switch value={watchIsAutoSync} onClick={onToggleAutoSync} data-testid="auto-sync-switch" />
        </Box>
      )}
    </RightNavbar>
  );
}

FlowActionsNavbar.propTypes = {
  linkId: PropTypes.string.isRequired,
  disableSave: PropTypes.bool,
};

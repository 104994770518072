import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box, Link, Modal, tokens, NewTypography } from '@unitoio/mosaic';

import * as authActions from '~/actions/auth';
import * as organizationActions from '~/actions/organizations';
import * as trackingTypes from '~/consts/tracking';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { isOnFreeTrial, isOrganizationTrialExpired } from '~/reducers';
import { history } from '~/utils/history';

const Bold = styled.span`
  font-weight: ${tokens.fontWeight.fw7};
`;

const RedBold = styled(Bold)`
  font-weight: ${tokens.fontWeight.fw7};
  color: ${tokens.colors.content.destructive.default};
`;

const StyledTextArea = styled.textarea`
  resize: none;
  outline: none;
  width: 100%;
  border-color: ${tokens.colors.content.neutral.n20};
  border-radius: ${tokens.spacing.s2};
  margin: ${tokens.spacing.s3} ${tokens.spacing.s0};
`;

const useTrackStartEvent = (isOpen) => {
  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
    }
  }, [isOpen, trackEvent]);
};

const StyledLink = styled(Link)`
  margin: 0;
`;

export const DeleteWorkspaceModal = ({ isOpen, onClose, onError }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState('');

  const { reportException } = useLogger();
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const trackEvent = useTrackEvent();

  useTrackStartEvent(isOpen);

  const isTrialing = useSelector((state) => isOnFreeTrial(state, organizationId));
  const isTrialExpired = useSelector((state) => isOrganizationTrialExpired(state, organizationId));

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(organizationActions.deleteWorkspace(organizationId));
      await dispatch(organizationActions.patchOrganization(organizationId, { trialEndFeedback: feedback }));
      trackEvent(trackingTypes.SUBMIT);
      dispatch(authActions.logoutUser(true));
      history.push('/workspace-closed');
    } catch (error) {
      reportException('Unable to delete workspace', {
        identifier: 'DeleteWorkspaceModal deleteWorkspace unableToDeleteWorkspace',
        error,
      });
      onError();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnChangeFeedback = (event) => {
    const value = event.target.value && event.target.value.trim();
    setFeedback(value);
  };

  return (
    <Modal
      isOpen={isOpen}
      title="We're sorry that Unito didn't work out for you"
      confirmLabel="Close workspace"
      isConfirmActionDestructive
      onCancel={onClose}
      onRequestClose={onClose}
      isConfirmButtonDisabled={isSubmitting}
      onConfirm={handleOnSubmit}
    >
      <Box>
        <Box m={[0, 0, tokens.spacing.s1, 0]}>
          <NewTypography.Title level={5}>
            <RedBold>Closing your workspace is irreversible</RedBold>. You will lose access to the Unito app and the
            following information will be removed:{' '}
          </NewTypography.Title>
          <NewTypography>
            <ul>
              <li>your flows</li>
              <li>your workflows</li>
              <li>your personal details</li>
            </ul>
          </NewTypography>
          <NewTypography>
            Find more information in{' '}
            <StyledLink target="_blank" href="https://guide.unito.io/how-to-close-your-unito-workspace">
              this help center article
            </StyledLink>
            .
          </NewTypography>
          {(isTrialing || isTrialExpired) && (
            <>
              <NewTypography>Before closing your workspace, can you tell us why you've decided to do so?</NewTypography>
              <StyledTextArea cols={72} rows={2} maxLength={500} onChange={handleOnChangeFeedback} />
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

DeleteWorkspaceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

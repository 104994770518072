import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import styled from 'styled-components';

import { NewAlert as Alert, NewAlertLevel } from '@unitoio/mosaic';

import { getContainerById } from '~/reducers';
import { AppcuesLink } from '~/components/AppcuesLink/AppcuesLink';
import { TemplateString } from '~/components/TemplateString/TemplateString';
import { Href } from '~/components/Href/Href';

const AlertWrapper = styled.div`
  margin-top: 1rem;

  // Fixes weird spacing on small containers when the warning are shown on flow builder
  a {
    display: inline;
    white-space: normal;
  }
`;

function renderContainerAlertFields(template) {
  if (template.match(/^href\|\|/)) {
    const parsedRegex = /^href\|\|(?<urlText>.*)\|\|(?<url>.+$)/.exec(template);
    const { urlText, url } = parsedRegex.groups;

    return (
      <Href href={url} target="_blank">
        {urlText || url}
      </Href>
    );
  }

  if (template.match(/^appcues\|\|/)) {
    const parsedRegex = /^appcues\|\|(?<text>.+)\|\|(?<appcuesId>.+$)/.exec(template);
    const { appcuesId, text } = parsedRegex.groups;

    return (
      <AppcuesLink btnProps={{ noPadding: true, type: 'link' }} ctaId={appcuesId}>
        {text}
      </AppcuesLink>
    );
  }

  return template;
}

export function ContainerAlerts({ severity, providerIdentityId = null, containerId = null }) {
  const selectedContainer = useSelector((state) => getContainerById(state, providerIdentityId, containerId));
  const accessor = severity === 'warning' ? 'richWarnings' : 'richErrors';
  const altAccessor = severity === 'warning' ? 'warnings' : 'errors';
  const richAlerts = selectedContainer.get(accessor, List());
  const alerts = richAlerts.isEmpty() ? selectedContainer.get(altAccessor, List()) : richAlerts;

  if (alerts.size === 0) {
    return null;
  }

  return (
    <>
      {alerts.map((warning, i) => {
        const index = i;
        return (
          <AlertWrapper key={index}>
            <Alert
              level={severity}
              message={<TemplateString fieldsRenderer={renderContainerAlertFields} template={warning} />}
            />
          </AlertWrapper>
        );
      })}
    </>
  );
}

ContainerAlerts.propTypes = {
  providerIdentityId: PropTypes.string,
  containerId: PropTypes.string,
  severity: PropTypes.oneOf([NewAlertLevel.WARNING, NewAlertLevel.ERROR]).isRequired,
};

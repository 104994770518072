import { Map, fromJS } from 'immutable';

import * as billingTypes from '~/consts/billing';
import * as draftTypes from '~/consts/drafts';
import * as organizationTypes from '~/consts/organizations';
import * as linkTypes from '~/consts/link';
import { normalizeEntitiesById } from '~/utils/normalizeEntitiesById';
import { normalizeEntitiesBy } from '~/utils/normalizeEntitiesBy';

export const initialState = Map({
  isLoading: false,
  isLoaded: false,
  hasFatalError: false,
  entitiesById: Map(),
  collaboratorsStatsByOrgId: Map(),
  usageByOrgId: Map(),
  usageLoadingByOrgId: Map(),
  usageErrorsByOrgId: Map(),
  metricsByOrgId: Map(),
  currentPlansByOrgId: Map(),
  reportsByOrgId: Map(),
  rolesByOrganizationId: Map(),
  flagsByOrganizationId: Map(),
  dailyActivityByOrgId: Map(),
});

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case organizationTypes.GET_METRICS_SUCCESS: {
      const metrics = fromJS(action.payload.stats);
      const { organizationId } = action.meta;
      return state.mergeIn(['metricsByOrgId', organizationId], metrics);
    }

    case organizationTypes.GET_ORGANIZATIONS_REQUEST: {
      return state.merge({
        isLoading: true,
        hasFatalError: false,
      });
    }

    case organizationTypes.GET_USAGE_REQUEST: {
      const { organizationId } = action.meta;
      return state
        .setIn(['usageLoadingByOrgId', organizationId], true)
        .setIn(['usageErrorsByOrgId', organizationId], null);
    }

    case organizationTypes.GET_USAGE_SUCCESS: {
      const usage = fromJS(action.payload.usage);
      const { organizationId } = action.meta;
      return state
        .mergeIn(['usageByOrgId', organizationId], normalizeEntitiesById(usage))
        .setIn(['usageLoadingByOrgId', organizationId], false);
    }

    case organizationTypes.GET_USAGE_FAILURE: {
      const { organizationId } = action.meta;
      const updatedState = state
        .setIn(['usageLoadingByOrgId', organizationId], false)
        .setIn(['usageErrorsByOrgId', organizationId], fromJS(action.error));
      return updatedState;
    }

    case organizationTypes.GET_ITEMS_USAGE_SUCCESS: {
      const usage = fromJS(action.payload.usage);
      const { organizationId } = action.meta;
      return state.mergeIn(['usageByOrgId', organizationId], normalizeEntitiesById(usage));
    }

    case organizationTypes.GET_SYNC_ACTIVITY_SUCCESS: {
      const activity = fromJS(action.payload.activity);
      const { organizationId } = action.meta;
      return state.mergeIn(['dailyActivityByOrgId', organizationId], normalizeEntitiesBy('date', activity));
    }

    case organizationTypes.GET_ORGANIZATION_BY_ID_SUCCESS: {
      const { organization, hasFlows, hasWorkflows, pendingDraftId } = action.payload;
      return state.mergeIn(
        ['entitiesById', organization.id],
        fromJS({ ...organization, hasFlows, hasWorkflows, pendingDraftId }),
      );
    }

    case draftTypes.CREATE_DRAFT_SUCCESS: {
      const { isFirstFlow } = action.meta;
      const {
        link: { _id: linkId, organization: organizationId },
      } = action.payload;
      if (isFirstFlow) {
        return state.mergeIn(
          ['entitiesById', organizationId],
          fromJS({
            hasFlows: true,
            pendingDraftId: linkId,
          }),
        );
      }
      return state;
    }

    case draftTypes.EDIT_DRAFT_SUCCESS: {
      const { isConvertedDraft } = action.meta || {};
      const {
        // note: after a successful draft conversion,
        // the organizationId is returned in an `organization` field.
        link: { organization: organizationId },
      } = action.payload;
      if (isConvertedDraft) {
        return state.mergeIn(
          ['entitiesById', organizationId],
          fromJS({
            hasFlows: true,
            pendingDraftId: null,
          }),
        );
      }
      return state;
    }

    case linkTypes.DELETE_LINK_SUCCESS: {
      const { linkId } = action.meta || {};

      let updatedState = state;

      for (const organizationId of state.get('entitiesById').keys()) {
        const isPendingDraftDeleted = updatedState.getIn(['entitiesById', organizationId, 'pendingDraftId']) === linkId;

        if (isPendingDraftDeleted) {
          updatedState = updatedState.setIn(['entitiesById', organizationId, 'pendingDraftId'], null);
        }
      }

      return updatedState;
    }

    case organizationTypes.GET_ORGANIZATIONS_SUCCESS: {
      const organizations = fromJS(action.payload.organizations).map((organization) =>
        organization.set('members', normalizeEntitiesById(organization.get('members'))),
      );
      return state
        .merge({
          isLoading: false,
          isLoaded: true,
          hasFatalError: false,
        })
        .set('entitiesById', normalizeEntitiesById(organizations));
    }

    case organizationTypes.GET_ORGANIZATIONS_FAILURE:
      return state.merge({
        isLoading: false,
        hasFatalError: true,
      });

    case organizationTypes.PATCH_COLLABORATOR_SUCCESS:
    case organizationTypes.GET_COLLABORATORS_SUCCESS: {
      const collaboratorStats = fromJS(action.payload.collaboratorStats);
      return state.merge(
        Map({
          collaboratorsStatsByOrgId: Map({
            [collaboratorStats.get('organization')]: collaboratorStats,
          }),
        }),
      );
    }

    case organizationTypes.UPDATE_ORGANIZATION_REQUEST: {
      const { organizationId } = action.meta;
      return state.mergeIn(['entitiesById', organizationId], Map({ isLoading: true }));
    }

    case organizationTypes.UPDATE_ORGANIZATION_FAILURE: {
      const { organizationId } = action.meta;
      return state.mergeIn(['entitiesById', organizationId], Map({ isLoading: false }));
    }

    case organizationTypes.UPDATE_ORGANIZATION_SUCCESS: {
      const { organization } = action.payload;
      return state.mergeIn(
        ['entitiesById', organization.id],
        fromJS({
          isLoading: false,
          ...organization,
        }),
      );
    }

    case billingTypes.CANCEL_SUBSCRIPTION_SUCCESS:
    case billingTypes.PAUSE_SUBSCRIPTION_SUCCESS:
    case billingTypes.UNPAUSE_SUBSCRIPTION_SUCCESS:
    case billingTypes.UPDATE_SUBSCRIPTION_SUCCESS: {
      const organization = fromJS(action.payload.organization);
      return state.mergeIn(['entitiesById', organization.get('id')], organization);
    }

    case organizationTypes.GET_MEMBERS_SUCCESS: {
      const {
        meta: { organizationId },
        payload: { members },
      } = action;
      return state.setIn(['entitiesById', organizationId, 'members'], normalizeEntitiesById(fromJS(members)));
    }

    case organizationTypes.REMOVE_ORGANIZATION_MEMBER_SUCCESS: {
      const {
        meta: { organizationId, dryRun },
        payload,
      } = action;

      if (dryRun) {
        return state;
      }

      return state.removeIn(['entitiesById', organizationId, 'members', payload.deletedMember._id]);
    }

    case organizationTypes.GET_COWORKERS_SUCCESS: {
      const {
        meta: { organizationId },
        payload: { coworkers },
      } = action;
      return state.setIn(['entitiesById', organizationId, 'coworkers'], normalizeEntitiesById(fromJS(coworkers)));
    }

    case organizationTypes.GET_REPORT_URL_SUCCESS: {
      const {
        meta: { organizationId, reportId },
        payload: { reportUrl },
      } = action;
      return state.setIn(['reportsByOrgId', organizationId, reportId], fromJS(reportUrl));
    }

    case linkTypes.GET_TASK_SYNC_TASK_COUNT_SUCCESS: {
      const { taskCount } = action.payload;
      const { organizationId } = action.meta;
      const taskSyncsCount = taskCount.all - taskCount.closed - taskCount.filteredOut;
      const mirrorUsage = fromJS([{ _id: 'MAX_MIRROR_SYNCS', usage: taskSyncsCount }]);
      return state.mergeIn(['usageByOrgId', organizationId], normalizeEntitiesById(mirrorUsage));
    }

    case billingTypes.GET_ORGANIZATION_PLAN_PROFILE_SUCCESS: {
      const {
        payload: { planProfile },
        meta: { organizationId },
      } = action;
      const featuresById = planProfile.features ? normalizeEntitiesById(fromJS(planProfile.features)) : Map();
      return state.setIn(['currentPlansByOrgId', organizationId], fromJS({ ...planProfile, features: featuresById }));
    }

    case organizationTypes.PATCH_MEMBER_SUCCESS: {
      const { member } = action.payload;
      return state.setIn(['entitiesById', member.organization, 'members', member._id], fromJS(member));
    }

    case organizationTypes.GET_ORGANIZATION_ROLES_SUCCESS: {
      const {
        payload: { roles },
        meta: { organizationId },
      } = action;
      return state.setIn(['rolesByOrganizationId', organizationId], normalizeEntitiesById(fromJS(roles)));
    }

    case organizationTypes.GET_FLAGS_SUCCESS: {
      const {
        payload: { flags },
        meta: { organizationId },
      } = action;

      return state.setIn(['flagsByOrganizationId', organizationId], fromJS(flags));
    }

    case organizationTypes.CLEAR_FLAGS_BY_ORGANIZATION_ID: {
      const { organizationId } = action.payload;
      return state.deleteIn(['flagsByOrganizationId', organizationId]);
    }

    case organizationTypes.UPDATE_FLAGS_LOCALLY: {
      const {
        payload: { orgId, flagData },
      } = action;

      return state.mergeIn(['flagsByOrganizationId', orgId], fromJS(flagData));
    }

    default:
      return state;
  }
};

export const getOrganizations = (state) => state.get('entitiesById', Map());

export const getById = (state, id) => state.getIn(['entitiesById', id], Map());

export const getMembersByOrgId = (state, organizationId) =>
  state.getIn(['entitiesById', organizationId, 'members'], Map());

export const getCoworkersByOrgId = (state, organizationId) =>
  state.getIn(['entitiesById', organizationId, 'coworkers'], Map());

export const getOrganizationRoles = (state, organizationId) =>
  state.getIn(['rolesByOrganizationId', organizationId], Map());

export const getSelectedPlanId = (state, organizationId) => state.getIn(['entitiesById', organizationId, 'planId']);

export const getName = (state, organizationId) => state.getIn(['entitiesById', organizationId, 'name']);

export const getBillingEmail = (state, organizationId) => state.getIn(['entitiesById', organizationId, 'billingEmail']);

export const getStripeSubscriptionId = (state, organizationId) =>
  state.getIn(['entitiesById', organizationId, 'stripeSubscriptionId']);

export const getCustomerId = (state, organizationId) =>
  state.getIn(['entitiesById', organizationId, 'stripeCustomerId']);

export const getNonExpiredOrFirstOrganization = (state) => {
  const fallbackOrganization = state.get('entitiesById').first();
  const nonExpiredOrganizations = state
    .get('entitiesById')
    .filter(
      (organization) =>
        ![
          organizationTypes.STATUSES.TRIAL_EXPIRED,
          organizationTypes.STATUSES.CHURNED,
          organizationTypes.STATUSES.EXPIRED,
        ].includes(organization.get('status')),
    );
  return nonExpiredOrganizations.first() || fallbackOrganization || Map();
};

export const getNonExpiredOrFirstOrganizationId = (state) => getNonExpiredOrFirstOrganization(state).get('id');

export const getOrganizationStatus = (state, organizationId) => {
  const organization = getById(state, organizationId);
  return organization.get('status');
};

export const getOrganizationFlags = (state, organizationId) => {
  const organization = getById(state, organizationId);
  return organization.get('flags', Map());
};

export const getCollaboratorsStatsByOrgId = (state, organizationId) =>
  state.getIn(['collaboratorsStatsByOrgId', organizationId], Map());

export const isPaused = (state, organizationId) => {
  const organization = getById(state, organizationId);
  return organization.get('status') === organizationTypes.STATUSES.PAUSED;
};

export const isOnFreeMirrorTrial = (state, organizationId) => {
  const organization = getById(state, organizationId);
  const planId = organization.get('planId');
  return organizationTypes.PLANS.MIRROR_TRIAL === planId;
};

export const isOnFreeTrial = (state, organizationId) => {
  const organization = getById(state, organizationId);
  const planId = organization.get('planId');
  return [organizationTypes.PLANS.MIRROR_TRIAL, organizationTypes.PLANS.TRIAL].includes(planId);
};

export const isOnCustomPlan = (state, organizationId) => {
  const organization = getById(state, organizationId);
  const isCustomInvoiced = organization.get('planId') === organizationTypes.PLANS.CUSTOM_INVOICED;
  const isEnterprise = organization.get('planId', '').includes(organizationTypes.PLAN_KEYS.ENTERPRISE);
  return isCustomInvoiced || isEnterprise;
};

export const isEnterpriseAccount = (state, organizationId) => {
  const organization = getById(state, organizationId);
  return organization.get('planId').includes(organizationTypes.PLAN_KEYS.ENTERPRISE);
};

export const isVipAccount = (state, organizationId) => {
  const organization = getById(state, organizationId);
  return organization.get('planId') === organizationTypes.VIP_PLAN;
};

export const isOnFreeWithWrikePlan = (state, organizationId) => {
  const organization = getById(state, organizationId);
  return organization.get('planId') === organizationTypes.PLANS.FREE_WITH_WRIKE;
};

export const isOnMirrorLegacy = (state, organizationId) => {
  const organization = getById(state, organizationId);
  const planId = organization.get('planId');

  return planId.includes('mirror') && !isOnFreeMirrorTrial(state, organizationId); // Check for not mirror trial because it has the key word 'mirror' in its planId
};

export const getMetrics = (state, organizationId) => state.getIn(['metricsByOrgId', organizationId]);

export const getReportUrl = (state, organizationId, reportId) =>
  state.getIn(['reportsByOrgId', organizationId, reportId], '');

export const getOrganizationTraits = (state, organizationId) => {
  const data = getById(state, organizationId).toJS();
  const { members: _members, partnerInfos: _partnerInfos, ...rest } = data;
  return rest;
};

export const getIsLoading = (state) => state.get('isLoading');

export const getIsLoaded = (state) => state.get('isLoaded');

export const getOrganizationSendEmailReceiptChoice = (state, organizationId) =>
  getById(state, organizationId).get('sendEmailReceipt', false);

export const getUsage = (state, organizationId) => state.getIn(['usageByOrgId', organizationId], Map());

export const getUsageLoadingByOrganizationId = (state, organizationId) =>
  state.getIn(['usageLoadingByOrgId', organizationId], false);

export const getUsageErrorByOrganizationId = (state, organizationId) =>
  state.getIn(['usageErrorsByOrgId', organizationId]);

export const getDailyActivityByType = (state, organizationId, activityType) => {
  const data = state.getIn(['dailyActivityByOrgId', organizationId], Map());
  // Prepare the data so it's usable by the react-vis library
  // From a daily Map of Map: { '2020-01-01: { value1: 'a', value2: 'b' }, '2020-01-02: { value1: 'aa', value2: 'bb' }, ...
  // To array of object of this format for each value: [{x: '2020-01-01', y: 'a'}, {x: '2020-01-01', y: 'aa'}, ...]
  return data
    .sortBy((v, k) => k)
    .mapEntries(([k, v]) => [k, v.get(activityType)])
    .entrySeq()
    .map(([date, count]) => ({ x: new Date(date).valueOf() + 6 * 60 * 60 * 1000, y: count })) // Add an extra 6 hours so timezone is not an issue
    .toList();
};

export const getCountItemsPerItemTypePerContainer = (state, organizationId) => {
  const data = state.getIn(['dailyActivityByOrgId', organizationId], Map());
  // Key is a date in the following format YYYY-MM-DD
  return data.sortBy((v, k) => k).mapEntries(([k, v]) => [k, v.get('countItemsPerItemTypePerContainer')]);
};

/**
 * Function to retrieve the provider name based on a provided containerId. Data is coming from dailyActivityByOrgId.
 * @param {*} state  provider state
 * @param {*} organizationId org id
 * @returns A list where the key is a date in the format YYYY-MM-DD and the value is an object with containerId:providerName
 */
export const getProviderNameFromContainer = (state, organizationId) => {
  const data = state.getIn(['dailyActivityByOrgId', organizationId], Map());
  // Key is a date in the following format YYYY-MM-DD
  return data.sortBy((v, k) => k).mapEntries(([k, v]) => [k, v.get('containerToProviderName')]);
};

export const getItemsPerLinkByOrganizationId = (state, organizationId) => {
  const dailyEntries = getDailyActivityByType(
    state.organizations,
    organizationId,
    organizationTypes.DAILY_ACTIVITY_TYPE.COUNT_ITEMS_PER_LINK,
  );
  // Return the y value of the last item of the List (i.e the item of today)
  const lastEntry = dailyEntries.last();
  return lastEntry?.y ?? Map();
};

export const getCurrentOrganizationPlan = (state, organizationId) =>
  state.getIn(['currentPlansByOrgId', organizationId], Map());

export const getAllFlags = (state, organizationId) => state.getIn(['flagsByOrganizationId', organizationId], Map());

export const getFlagValue = (state, organizationId, flagName) =>
  state.getIn(['flagsByOrganizationId', organizationId, flagName]);

export const getOrganizationHasFlows = (state, id) => state.getIn(['entitiesById', id, 'hasFlows'], false);
export const getOrganizationHasWorkflows = (state, id) => state.getIn(['entitiesById', id, 'hasWorkflows'], false);
export const getOrganizationPendingDraftId = (state, id) => state.getIn(['entitiesById', id, 'pendingDraftId'], null);

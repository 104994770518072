import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { tokens, List, Flex, NewTypography, NewTooltip, Icon } from '@unitoio/mosaic';

import { STORE_KEYS } from '~/consts/store';
import { useLocalStorage } from '~/hooks';
import { getAllLinks } from '~/reducers';

const RenderedLink = ({ id, title }) => {
  return (
    <Link reloadDocument to={`/dashboard/flow-builder/edit/${id}`}>
      {title}
    </Link>
  );
};

export const FlowHistory = () => {
  const [linkIDs] = useLocalStorage(STORE_KEYS.FLOWHISTORY, []);
  const allLoadedLinks = useSelector((state) => getAllLinks(state));

  const visitedFlowList = linkIDs.reverse().map((linkID) => {
    const foundLink = allLoadedLinks.find((link) => link.get('_id') === linkID);
    return {
      title: !!foundLink ? (
        foundLink.get('name')
      ) : (
        <Flex align="center">
          <NewTypography.Text italic style={{ marginRight: tokens.spacing.s3 }}>
            Name unknown
          </NewTypography.Text>
          <NewTooltip title="Fetch link once to get the display name in your store, if the name is available">
            <Icon name="info-circle" color={tokens.colors.content.info.default} />
          </NewTooltip>
        </Flex>
      ),
      id: linkID,
    };
  });

  return (
    <List
      dataSource={visitedFlowList}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={<RenderedLink title={item.title} id={item.id} />}
            description={
              <Flex>
                <NewTypography.Text type="secondary">
                  ID: <RenderedLink title={item.id} id={item.id} />
                </NewTypography.Text>
              </Flex>
            }
          />
        </List.Item>
      )}
    />
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useQueryParams } from '~/hooks/useQueryParams';
import * as linkActions from '~/actions/links';
import { useLocalStorage } from '~/hooks';
import { STORE_KEYS } from '~/consts/store';

export const DEFAULT_PAGE_SIZE_PAGINATED = 20;
// value to send to the backend to indicate that we do not
// wish to paginate
export const DEFAULT_PAGE_SIZE_NON_PAGINATED = 0;

// when pagination is not enabled, the backend will return this total value
export const NO_PAGINATION_TOTAL = -1;

export function useGetFlowListLinks() {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  const [storedKinds, setStoredKinds] = useLocalStorage(STORE_KEYS.KINDS, '');
  const [storedStates, setStoredStates] = useLocalStorage(STORE_KEYS.STATE, '');
  const [storedTools, setStoredTools] = useLocalStorage(STORE_KEYS, '');
  const [storedPage, setStoredPage] = useLocalStorage(STORE_KEYS.PAGE, 0);
  const [storedSearchString, setStoredSearchString] = useLocalStorage(STORE_KEYS.SEARCH, '');

  const { kinds = '', searchString = '', page = 0, siteAdminSearchString = '', states = '', tools = '' } = queryParams;
  const kindsArray = useMemo(() => kinds.split(',').filter((str) => str), [kinds]);
  const statesArray = useMemo(() => states.split(',').filter((str) => str), [states]);
  const toolsArray = useMemo(() => tools.split(',').filter((str) => str), [tools]);

  const [total, setTotal] = useState(NO_PAGINATION_TOTAL);
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);

  const setNewQueryParams = useCallback(
    (newParams) => {
      history.push({
        pathname,
        search: `?${new URLSearchParams({ ...queryParams, ...newParams })}`,
      });
    },
    [history, pathname, queryParams],
  );

  // whenever a siteadmin search is done, also reset the page
  const setSiteAdminSearch = (searchValue) => {
    setNewQueryParams({ siteAdminSearchString: searchValue, page: 0 });
  };

  const setTools = useCallback(
    (newTools = []) => {
      const areSameLength = newTools.length === toolsArray.length;
      // don't trigger a needless useEffect if newKinds are set to the same values
      if (!areSameLength || !newTools.every((st) => tools.includes(st))) {
        setStoredTools(newTools.join(','));
        setNewQueryParams({ page: 0, tools: newTools.join(',') });
      }
    },
    [tools, toolsArray.length, setNewQueryParams, setStoredTools],
  );

  const setFilters = (newFilters) => {
    const newKinds = newFilters.kinds?.join(',') ?? storedKinds;
    const newStates = newFilters.states?.join(',') ?? storedStates;
    const newTools = newFilters.tools?.join(',') ?? storedTools;
    const newPage = newFilters.page ?? storedPage;
    const newSearchString = newFilters.searchString ?? storedSearchString;

    // store values
    setStoredKinds(newKinds);
    setStoredStates(newStates);
    setStoredPage(newPage);
    setStoredSearchString(newSearchString);
    setStoredTools(newTools);

    // set the query params
    setNewQueryParams({
      page: newPage,
      kinds: newKinds,
      states: newStates,
      searchString: newSearchString,
      tools: newTools,
    });
  };

  const reset = useCallback(() => {
    setFilters({ page: 0 });
    history.push({
      pathname,
      search: `?${new URLSearchParams({})}`,
    });
  }, [pathname, history, setFilters]);

  useEffect(() => {
    const fetchLinks = async () => {
      setLoading(true);
      if (siteAdminSearchString) {
        try {
          const {
            pagination: { total: apiResponseTotal },
          } = await dispatch(
            linkActions.searchLinks({
              page,
              pageSize: DEFAULT_PAGE_SIZE_PAGINATED,
              searchString,
              siteAdminSearchString,
              kinds: kindsArray,
              state: statesArray,
              tools: toolsArray,
            }),
          );
          setTotal(apiResponseTotal);
        } catch (_absorbedError) {
          setTotal(NO_PAGINATION_TOTAL);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const {
            pagination: { total: apiResponseTotal },
          } = await dispatch(
            linkActions.getLinks({
              page,
              pageSize: DEFAULT_PAGE_SIZE_PAGINATED,
              searchString,
              kinds: kindsArray,
              states: statesArray,
              tools: toolsArray,
            }),
          );
          setTotal(apiResponseTotal);
        } catch (_absorbedError) {
          setTotal(NO_PAGINATION_TOTAL);
        } finally {
          setLoading(false);
        }
      }
    };

    if (!initializing) {
      fetchLinks();
    }
  }, [dispatch, initializing, kindsArray, statesArray, toolsArray, page, searchString, siteAdminSearchString]);

  // Initialize the query params from localStorage
  useEffect(() => {
    if (initializing && Object.keys(queryParams).length === 0) {
      setNewQueryParams({
        kinds: storedKinds,
        page: storedPage,
        searchString: storedSearchString,
        states: storedStates,
        tools: storedTools,
      });
    }
    setInitializing(false);
  }, [
    initializing,
    queryParams,
    setNewQueryParams,
    storedKinds,
    storedPage,
    storedStates,
    storedTools,
    storedSearchString,
  ]);

  return {
    setFilters,
    page,
    setSiteAdminSearch,
    siteAdminSearchString,
    pageSize: DEFAULT_PAGE_SIZE_PAGINATED,
    total,
    kinds: kindsArray,
    states: statesArray,
    tools: toolsArray,
    reset,
    searchString,
    loading,
    initializing,
  };
}

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import * as authActions from '~/actions/auth';
import * as providerActions from '~/actions/providers';
import * as providerIdentityActions from '~/actions/providerIdentity';
import * as trackingActions from '~/actions/tracking';
import * as authTypes from '~/consts/auth';
import * as trackingTypes from '~/consts/tracking';
import {
  getEmbedName,
  getProviderById,
  getUserProviderIdentitiesByProviderId,
  getProviderByName,
  getProviderByNamePreferredAuthMethod,
} from '~/reducers';
import { useWindowEvent } from '~/hooks/useWindowEvent';
import * as authUtils from '~/utils/auth';

async function getAuthenticationUrl({
  provider,
  dispatch,
  extraAuthenticationOptions,
  trackEvent,
  windowOpenerId,
  authorizationMethod,
  linkId,
}) {
  const connectResponse = await dispatch(providerActions.getProviderConnectUrl(provider.get('name'), linkId ?? ''));
  let configureUrl = connectResponse.url;

  configureUrl += configureUrl.includes('?')
    ? `&windowOpenerId=${windowOpenerId}`
    : `?windowOpenerId=${windowOpenerId}`;

  if (authUtils.providerNeedsManualConfiguration(provider)) {
    return configureUrl;
  }

  const authenticationParams = {
    authenticationAction: authTypes.AUTH_ACTIONS.CONNECT,
    authenticationTool: provider.get('name'),
    authorizationMethod,
    authenticationOptions: {
      popup: windowOpenerId,
      includesPreAuthentication: true,
      ...extraAuthenticationOptions,
    },
    authenticationUserProfile: { productName: 'projectSync' },
  };

  const response = await dispatch(authActions.authenticate(authenticationParams));
  if (response.requiresSetup) {
    return configureUrl;
  }

  trackEvent(trackingTypes.AUTH_SETUP.AUTH_SUBMIT, { selected_tool_name: provider.get('name') });
  return response.authenticationUrl;
}

function handleMessage({ dispatch, onSuccess, windowOpenerId, onValidationStart }) {
  return async function handleEvent(event) {
    const {
      data: { providerIdentityId, type },
    } = event;

    if (
      type !== authTypes.POST_MESSAGE_TYPES.PROVIDER_IDENTITY ||
      !providerIdentityId ||
      windowOpenerId !== event.data.windowOpenerId
    ) {
      return;
    }

    onValidationStart();
    const providerIdentity = await dispatch(providerIdentityActions.validateProviderIdentity(providerIdentityId));
    onSuccess(providerIdentity);
  };
}

// TODO: This should be a hook!
export function AuthWindowOpener({
  children,
  onSuccess = () => null,
  providerId,
  linkId = null,
  extraAuthenticationOptions = {},
  onValidationStart = () => null,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [windowOpenerId, setWindowOpenerId] = useState(null);
  const dispatch = useDispatch();
  let provider = useSelector((state) => getProviderById(state, { providerId }));
  const githubappuserProvider = useSelector((state) => getProviderByName(state, 'githubappuser'));
  const embedName = useSelector((state) => getEmbedName(state));
  const isEmbed = !!embedName;

  function openPopupModal(authenticationUrl) {
    const url = new URL(authenticationUrl);

    url.searchParams.append('isEmbed', isEmbed);

    const updatedUrl = url.toString();

    const authPopup = window.open(updatedUrl);

    if (authPopup) {
      authPopup.focus();
    } else {
      // eslint-disable-next-line no-alert
      window.alert(
        'We could not open a pop-up window to authorize your tool. Check that your browser is not blocking pop-ups (tip: it’s usually in the address bar).',
      );
    }
  }

  // Particular case for githubapp. I know, it's ugly and make me suffer, but we don't have quick solution for now.
  // Basically, with GitHub app we need to do 2 oauth flow. First, we need to do a flow with the githubappuser provider
  // to fetch the information of the user. Once we have that, we do the second oauth flow to get the installation of the
  // github app with all the credentials with the githubappinstallation provider.

  // The provider that is displayed on the integration page is githubappinstallation and not githubappuser because we want to hide it.
  // The problem is, since the provider is githubappinstallation we skip the first flow that is mandatory. This little crappy code allow
  // to fix that for now.

  // TODO: Find a better fix (Maybe PCD driven).
  if (provider.get('name') === 'githubappinstallation') {
    provider = githubappuserProvider;
  }
  const userProviderIdentitiesForProvider = useSelector((state) =>
    getUserProviderIdentitiesByProviderId(state, providerId),
  );

  const authorizationMethod = useSelector((state) => getProviderByNamePreferredAuthMethod(state, provider.get('name')));

  useWindowEvent('message', handleMessage({ dispatch, onSuccess, windowOpenerId, onValidationStart }));

  function trackEvent(eventName, data) {
    return dispatch(
      trackingActions.trackEvent(eventName, {
        action_taken_from: authTypes.AUTH_ACTIONS.CONNECT,
        ...data,
      }),
    );
  }

  async function handleOpenWindow() {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      trackEvent(trackingTypes.AUTH_SETUP.AUTH_START, {
        selected_tool_name: provider.get('name'),
        selected_tool: userProviderIdentitiesForProvider.isEmpty() ? 'new' : 'existing',
      });

      const uuid = v4();
      const authenticationUrl = await getAuthenticationUrl({
        dispatch,
        provider,
        extraAuthenticationOptions,
        trackEvent,
        windowOpenerId: uuid,
        authorizationMethod,
        linkId,
      });
      openPopupModal(authenticationUrl);
      setWindowOpenerId(uuid);
    } finally {
      setIsLoading(false);
    }
  }

  return <Fragment>{children(handleOpenWindow, isLoading)}</Fragment>;
}

AuthWindowOpener.propTypes = {
  children: PropTypes.func.isRequired,
  extraAuthenticationOptions: PropTypes.shape({}),
  onSuccess: PropTypes.func,
  onValidationStart: PropTypes.func,
  providerId: PropTypes.string.isRequired,
  linkId: PropTypes.string,
};

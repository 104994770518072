import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Map } from 'immutable';

import { NewSelect as Select, notification, tokens } from '@unitoio/mosaic';

import { LINK_STATES } from '~/consts/link';
import { isSavingSync } from '~/reducers';
import * as organizationActions from '~/actions/organizations';
import { capitalize } from '~/utils/capitalize';
import { Button } from '~/components/Button/Button';
import * as linkActions from '~/actions/links';
import { containerActions } from '~/actions';

const LinkInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: ${tokens.spacing.s2} ${tokens.spacing.s4};
`;

const BasicLinkInfo = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 0;

  strong {
    margin: 0 ${tokens.spacing.s3};
  }

  &:not(:last-child) {
    margin-bottom: ${tokens.spacing.s3};
  }
`;

const LinkActions = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;

  button {
    &:not(:last-child) {
      margin-right: ${tokens.spacing.s4};
    }
  }
`;

export function LinkInfo({ sync, taskCount }) {
  const [isIdentifying, setIsIdentifying] = useState(false);

  const dispatch = useDispatch();

  const isSaving = useSelector((state) => isSavingSync(state));

  const linkId = sync.get('_id');
  const linkUserId = sync.getIn(['user', '_id']);
  const linkUserEmail = sync.getIn(['user', 'email']);
  const linkKind = sync.get('kind');
  const linkState = sync.get('state');
  const linkOrganizationId = sync.getIn(['organization', '_id']) || sync.get('organization');

  const allowedLinkState = [LINK_STATES.ACTIVE, LINK_STATES.DISABLED, LINK_STATES.DISCONNECTED];
  const linkStateOptions = Object.values(LINK_STATES).map((state) => {
    if (allowedLinkState.includes(state)) {
      return { label: capitalize(state), value: state };
    }
    return { label: capitalize(state), value: state, disabled: true };
  });

  async function handleIdentify() {
    setIsIdentifying(true);
    await dispatch(identify());
    setIsIdentifying(false);
  }

  function identify() {
    dispatch(organizationActions.identify(linkOrganizationId));
    notification.success({
      message: 'Identify successful',
      description: `Organization: ${linkOrganizationId} successfully identified.`,
      placement: 'top',
    });
  }

  function automapUsers() {
    dispatch(linkActions.automapUsers(sync.get('_id')));
  }

  function resyncLink(forcedFields, forcedSide) {
    dispatch(linkActions.syncLink(sync.get('_id'), true, forcedFields, forcedSide));
  }

  function abortScanForLink() {
    dispatch(containerActions.abortScan(sync.getIn(['A', 'container', 'id'])));
    dispatch(containerActions.abortScan(sync.getIn(['B', 'container', 'id'])));
  }

  function saveLinkState(newLinkState) {
    dispatch(linkActions.patchLink(linkId, { state: newLinkState }));
  }

  return (
    <LinkInfoHeader className="btn-toolbar">
      <BasicLinkInfo>
        <strong>Id:</strong>
        {linkId}
        <strong> userId:</strong>
        {linkUserId} ({linkUserEmail})<strong> organizationId:</strong>
        {linkOrganizationId}
      </BasicLinkInfo>
      <BasicLinkInfo>
        <strong> kind: </strong>
        {linkKind}
        {taskCount && (
          <>
            <strong> task sync open: </strong>
            {taskCount.all - (taskCount.closed + taskCount.filteredOut)}
            <strong> task sync closed: </strong>
            {taskCount.closed}
            <strong> task sync archived: </strong>
            {taskCount.filteredOut}
          </>
        )}
      </BasicLinkInfo>
      <BasicLinkInfo>
        <strong>Link state:</strong>{' '}
        <Select
          showSearch={false}
          disabled={linkState === LINK_STATES.DRAFT || LINK_STATES === LINK_STATES.DUPLICATE}
          loading={isSaving}
          name="linkState"
          value={linkState}
          options={linkStateOptions}
          onChange={(value) => saveLinkState(value)}
          // context is weird here because it's an old modal so we need to force it
          dropdownStyle={{ zIndex: 11000 }}
        />
      </BasicLinkInfo>
      <LinkActions>
        <Button type="button" size="xs" btnStyle="error" onClick={() => resyncLink()} reverse>
          Trigger a resync
        </Button>
        <Button type="button" size="xs" btnStyle="error" onClick={() => abortScanForLink()} reverse>
          Stop any running scan
        </Button>
        <Button type="button" size="xs" btnStyle="error" onClick={() => automapUsers()} reverse>
          Automap Users
        </Button>
        <Button
          disabled={isIdentifying}
          type="button"
          size="xs"
          btnStyle="error"
          onClick={() => handleIdentify()}
          reverse
        >
          Identify Organization
        </Button>
      </LinkActions>
    </LinkInfoHeader>
  );
}

LinkInfo.propTypes = {
  sync: PropTypes.instanceOf(Map).isRequired,
  taskCount: PropTypes.shape({
    all: PropTypes.number.isRequired,
    closed: PropTypes.number.isRequired,
    filteredOut: PropTypes.number.isRequired,
  }).isRequired,
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { tokens, NewButton as Button, Icon, Flex, Drawer, Collapse, NewTooltip } from '@unitoio/mosaic';
import { getSelectedOrganizationId, getUserId, getUserEmail, getLoginProviderIdentity } from '~/reducers';
import { CopyToClipboard } from '~/components/CopyToClipboard/CopyToClipboard';

import { FeatureFlagOverride } from './components/FeatureFlagOverride';
import { EmbedOverride } from './components/EmbedOverride';
import { QuickDraft } from './components/QuickDraft';
import { FlowHistory } from './components/FlowHistory';
import { ExtraButtons } from './components/ExtraButtons';

const ItemContainer = styled.div`
  pre {
    margin: 0 0 0 ${tokens.spacing.s3};
    padding: ${tokens.spacing.s3};
  }
`;

const DevToolButton = styled(Button)`
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
`;

const StyledDrawer = styled(Drawer)`
  transition: opacity 0.1s;
  ${({ isDocked, isSeeThrough }) => (isDocked && isSeeThrough ? 'opacity: 0.2; &:hover{ opacity: 1; };' : '')};
`;

export const DevTools = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDocked, setIsDocked] = useState(false);
  const [isSeeThrough, setIsSeeThrough] = useState(false);

  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const userId = useSelector((state) => getUserId(state));
  const userEmail = useSelector((state) => getUserEmail(state));
  const loginProviderIdentityId = useSelector((state) => getLoginProviderIdentity(state));

  const collapsibleItems = [
    {
      label: 'Last 5 visited flows',
      children: <FlowHistory />,
      key: 'flowHistory',
    },
    {
      label: 'Quick draft',
      children: <QuickDraft />,
      key: 'quickDraft',
    },
  ];

  return (
    <>
      <DevToolButton
        shape="circle"
        data-testid="dev-tools-button"
        size="large"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          position: 'fixed',
          zIndex: 999,
          bottom: tokens.spacing.s5,
          left: tokens.spacing.s5,
        }}
      >
        <Icon name="cog" size="lg" />
      </DevToolButton>
      <StyledDrawer
        extra={
          <ExtraButtons
            isDocked={isDocked}
            isSeeThrough={isSeeThrough}
            setIsDocked={setIsDocked}
            setIsSeeThrough={setIsSeeThrough}
          />
        }
        width="33%"
        isDocked={isDocked}
        isSeeThrough={isSeeThrough}
        // this cannot be achieved in the styled component, it's above it
        rootStyle={isDocked ? { height: '55vh', bottom: 0, top: 'auto' } : {}}
        title="Admin toolbox"
        onClose={() => {
          setIsDocked(false);
          setIsOpen(!isOpen);
        }}
        open={isOpen}
        mask={!isDocked}
        placement="left"
      >
        <FeatureFlagOverride organizationId={organizationId} />
        <EmbedOverride />
        <hr />
        <ItemContainer>
          <Flex
            style={{ marginTop: tokens.spacing.s3, justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}
          >
            Your orgId: <CopyToClipboard as="pre">{organizationId}</CopyToClipboard>
          </Flex>
          <Flex
            style={{ marginTop: tokens.spacing.s3, justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}
          >
            Your userId: <CopyToClipboard as="pre">{userId}</CopyToClipboard>
          </Flex>
          <Flex
            style={{ marginTop: tokens.spacing.s3, justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}
          >
            {' '}
            Your user email: <CopyToClipboard as="pre">{userEmail}</CopyToClipboard>
          </Flex>
          {loginProviderIdentityId && (
            <Flex
              style={{
                marginTop: tokens.spacing.s3,
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {' '}
              Your login pId: <CopyToClipboard as="pre">{loginProviderIdentityId}</CopyToClipboard>
            </Flex>
          )}
        </ItemContainer>
        <hr />
        <ItemContainer style={{ marginTop: tokens.spacing.s3 }}>
          <Collapse bordered={false} items={collapsibleItems} destroyInactivePanel />
        </ItemContainer>
      </StyledDrawer>
    </>
  );
};

import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';

import * as linkTypes from '~/consts/link';

export function useIsFlowDraft() {
  const { linkId } = useParams();
  const linkState = useWatch({ name: 'state' });
  return !linkId || [linkTypes.LINK_STATES.DUPLICATE, linkTypes.LINK_STATES.DRAFT].includes(linkState);
}

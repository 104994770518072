import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, message } from '@unitoio/mosaic';

import { getSelectedOrganizationId } from '~/reducers';
import * as workflowActions from '~/actions/workflows';
import { Dropdown, DropdownEllipsisButton, DropdownItem } from '~/components/Dropdown/Dropdown';
import { RoutedModal } from '~/components/Modal/RoutedModal';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { RemoveFlowModal } from '~/components/RemoveFlowModal/RemoveFlowModal';
import * as linkActions from '~/actions/links';

import { WorkflowDiagramModel } from '../../models/WorkflowDiagramModel';

const WORKFLOW_ACTION = 'ACTION';

function useFetchWorkflowResources(
  linkId,
  organizationId,
  setModalOptionLinkId,
  setModalOptionIsLoading,
  setModalOptionIsdeletable,
) {
  const dispatch = useDispatch();
  const [workflowResources, setWorkflowResources] = useState({});

  useEffect(() => {
    setModalOptionLinkId(linkId);
    setModalOptionIsLoading(true);
    async function getResources() {
      const fetchWorkflowResources = await dispatch(
        workflowActions.generateWorkflowResourcesOnDelete(organizationId, [linkId]),
      );
      setWorkflowResources(fetchWorkflowResources);
      setModalOptionIsLoading(false);
      setModalOptionIsdeletable(fetchWorkflowResources.deletableLinks.length);
    }

    linkId && getResources();
  }, [dispatch, organizationId, linkId, setModalOptionLinkId, setModalOptionIsLoading, setModalOptionIsdeletable]);
  return { workflowResources, organizationId };
}

const EditSyncModalContainerInner = ({
  routeProps,
  onSaved,
  workflowModel,
  trackEvent,
  parentMatch,
  setModalOptionLinkId,
  setModalOptionIsLoading,
  setModalOptionIsdeletable,
}) => {
  const { match, history } = routeProps;
  const { linkId } = match?.params || {};
  const organizationId = useSelector((state) => getSelectedOrganizationId(state));
  const dispatch = useDispatch();
  const {
    workflowResources: { workflows = [], deletableLinks = [] },
  } = useFetchWorkflowResources(
    linkId,
    organizationId,
    setModalOptionLinkId,
    setModalOptionIsLoading,
    setModalOptionIsdeletable,
  );
  const workflowNames = workflows.map((workflow) => workflow.name).join(', ');
  const isDeletable = deletableLinks.length;
  const openedFlowNode = workflowModel.findNodeByLinkId(linkId);

  async function onFlowRemoval() {
    trackEvent(WORKFLOW_ACTION, { action_name: 'confirmed delete flow' });
    try {
      if (linkId && isDeletable) {
        await dispatch(linkActions.deleteLink(linkId));
      }
      workflowModel.deleteFlow(openedFlowNode.getID());
      history.push(parentMatch.url);
    } catch {
      message.error({
        content: 'Something went wrong while deleting your flow. Please get in touch with our team!',
      });
    }
    onSaved();
  }

  return (
    <Switch>
      <Route
        {...routeProps}
        path={`${match.url}/remove`}
        render={() => {
          if (!openedFlowNode) {
            return null;
          }
          const containerNodes = workflowModel.getSiblingNodes(openedFlowNode);

          if (containerNodes.length < 2) {
            return <Redirect to={routeProps.match.url} />;
          }

          return (
            <RemoveFlowModal
              isDeletable={isDeletable}
              linkId={linkId}
              workflowNames={workflowNames}
              onConfirm={onFlowRemoval} // eslint-disable-line react/jsx-no-bind
              onCancel={() => history.replace(match.url)}
            />
          );
        }}
      />
    </Switch>
  );
};

EditSyncModalContainerInner.propTypes = {
  workflowModel: PropTypes.instanceOf(WorkflowDiagramModel).isRequired,
  onSaved: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  routeProps: PropTypes.object.isRequired,
  trackEvent: PropTypes.func.isRequired,
  setModalOptionLinkId: PropTypes.func.isRequired,
  setModalOptionIsLoading: PropTypes.func.isRequired,
  setModalOptionIsdeletable: PropTypes.func.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  parentMatch: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export const EditSyncModalContainer = ({ parentMatch, onSaved, trackEvent, workflowModel, isLargeScreen }) => {
  const match = useRouteMatch();
  const [modalOptionLinkId, setModalOptionLinkId] = useState(null);
  const [modalOptionIsLoading, setModalOptionIsLoading] = useState(null);
  const [modalOptionIsdeletable, setModalOptionIsdeletable] = useState(null);

  return (
    <Switch>
      <RoutedModal
        modalOptions={
          <Dropdown btnContent={DropdownEllipsisButton}>
            {modalOptionIsLoading ? (
              <DropdownItem>
                <InlineLoading />
              </DropdownItem>
            ) : (
              <DropdownItem to={`${match.url}/links/${modalOptionLinkId}/remove`} role="button">
                <Icon name="trash" kind={Icon.KINDS.SOLID} fixedWidth />
                {modalOptionIsdeletable ? 'Delete flow' : 'Remove flow'}
              </DropdownItem>
            )}
          </Dropdown>
        }
        closeOnClickOutside={false}
        onClose={onSaved}
        title="Edit flow"
        path={`${parentMatch.path}/links/:linkId`}
        render={(routeProps) => (
          <EditSyncModalContainerInner
            setModalOptionLinkId={setModalOptionLinkId}
            setModalOptionIsLoading={setModalOptionIsLoading}
            setModalOptionIsdeletable={setModalOptionIsdeletable}
            routeProps={routeProps}
            onSaved={onSaved}
            parentMatch={parentMatch}
            trackEvent={trackEvent}
            workflowModel={workflowModel}
            isLargeScreen={isLargeScreen}
          />
        )}
      />
    </Switch>
  );
};
EditSyncModalContainer.propTypes = {
  workflowModel: PropTypes.instanceOf(WorkflowDiagramModel).isRequired,
  onSaved: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
  parentMatch: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

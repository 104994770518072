import * as linkTypes from '~/consts/link';
import { useWatch } from 'react-hook-form';

export function useAreFiltersInitialized() {
  const [filtersInitializedAtA, filtersInitializedAtB, linkState] = useWatch({
    name: ['A.filtersInitializedAt', 'B.filtersInitializedAt', 'state'],
  });

  return !!(
    filtersInitializedAtA ||
    filtersInitializedAtB ||
    // If the flow has been launched, we should consider the filters initialized even if no filtersInitalizedAt is set
    ![linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.DUPLICATE].includes(linkState)
  );
}

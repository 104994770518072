import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Icon, Flex, NewButton, NewTypography, tokens } from '@unitoio/mosaic';

import * as linkTypes from '~/consts/link';
import * as fieldTypes from '~/consts/fields';
import { DiagnoseModal } from '~/containers/DiagnoseModal/DiagnoseModal';
import { getLinkById } from '~/reducers';

export const AdminInformation = ({ link }) => {
  const [isDiagnosisModalOpen, setIsDiagnosisModalOpen] = useState(false);
  const sync = useSelector((state) => getLinkById(state, link._id));

  return (
    <Flex align="center">
      <pre style={{ padding: tokens.spacing.s3, margin: 0, background: tokens.colors.background.primary.action }}>
        <div>
          id: <NewTypography.Text copyable>{link._id}</NewTypography.Text>
        </div>
        <div>
          user: <NewTypography.Text copyable>{link.user._id}</NewTypography.Text>
        </div>
      </pre>
      <NewButton
        style={{ marginLeft: tokens.spacing.s4 }}
        danger
        className="diagnose-button"
        onClick={() => setIsDiagnosisModalOpen(true)}
        reverse
        size="small"
      >
        <Icon name="heartbeat" size="lg" kind={Icon.KINDS.SOLID} /> Diagnose
      </NewButton>
      {/* TODO: the modal needs a revamp, for now don't render it instead of leveraging isOpen, that's how it was done before anyway */}
      {isDiagnosisModalOpen && (
        <DiagnoseModal isOpen sync={sync} onRequestClose={() => setIsDiagnosisModalOpen(false)} />
      )}
    </Flex>
  );
};

// abstraction from the antD specs for a table render
export const AdminInformationRenderer = (_text, link) => <AdminInformation link={link} />;

AdminInformation.propTypes = {
  link: PropTypes.shape({
    state: PropTypes.oneOf([linkTypes.LINK_STATES.DRAFT, linkTypes.LINK_STATES.ACTIVE]),
    key: PropTypes.string,
    name: PropTypes.string,
    kind: PropTypes.oneOf([...Object.values(fieldTypes.KINDS)]),
    lastSyncRequest: PropTypes.string,
    restricted: PropTypes.bool,
    syncSettings: PropTypes.shape({
      earliestCreatedAt: PropTypes.string,
    }),
    user: PropTypes.shape({
      email: PropTypes.string,
      fullName: PropTypes.string,
    }),
  }),
};

import { useParams } from 'react-router-dom';

import { useIsFlowDraft } from './useIsFlowDraft';

export function useIsFlowDuplicate() {
  const { mode } = useParams();

  const isDuplicatedLink = mode === 'duplicate';
  const isDraft = useIsFlowDraft();

  return isDuplicatedLink || (isDuplicatedLink && isDraft);
}

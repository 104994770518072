import * as containerActions from '~/actions/containers';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useSetContainersErrors } from '~/containers/FlowBuilder/hooks/useSetContainersErrors';

export function useGetValidateContainerBySide(side) {
  const { setError, clearErrors } = useFormContext();
  const setFormErrors = useSetContainersErrors(setError, clearErrors);
  const [providerIdentityId, itemType] = useWatch({ name: [`${side}.providerIdentityId`, `${side}.itemType`] });
  const dispatch = useDispatch();
  return useCallback(
    async (containerId, containerType) => {
      try {
        const { container } = await dispatch(
          containerActions.getContainerById({
            providerIdentityId,
            containerId,
            containerType,
            itemType,
            options: {
              displayError: false,
            },
          }),
        );
        if (container.errors?.length) {
          setFormErrors({ [side]: { message: container.errors[0] } });
        }
      } catch (error) {
        setFormErrors({ [side]: error });
      }
    },
    [dispatch, providerIdentityId, itemType, setFormErrors, side],
  );
}

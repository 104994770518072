import { useSelector } from 'react-redux';
import { useWatch } from 'react-hook-form';

import { getContainerById } from '~/reducers';

export function useGetContainers() {
  const [containerIdA, containerIdB, providerIdentityIdA, providerIdentityIdB] = useWatch({
    name: ['A.containerId', 'B.containerId', 'A.providerIdentityId', 'B.providerIdentityId'],
  });
  const containerA = useSelector((state) => getContainerById(state, providerIdentityIdA, containerIdA));
  const containerB = useSelector((state) => getContainerById(state, providerIdentityIdB, containerIdB));

  return [containerA, containerB];
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Flex, NewButton as Button, tokens, Icon, Space } from '@unitoio/mosaic';

import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as fieldTypes from '~/consts/fields';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { getProviderById } from '~/reducers';
import { borderRadius, color } from 'theme';

import { getSortedProviderNames } from '~/utils/getSortedProviderNames';
import { ProviderTermsByName } from '~/components/ProviderTerms/ProviderTermsByName';
import { LinkContainerIcons } from './LinkContainerIcons';
import { LinkInformation } from './LinkInformation';
import { LinkItemDeleteModal } from './LinkItemDeleteModal';

const LinkActionsFlex = styled(Flex)`
  visibility: hidden;
`;

const LinkInfoFlex = styled(Flex)`
  border-radius: ${borderRadius.double};
  border: 1px solid ${color.dark.dark5};
  display: flex;
  position: relative;

  &:hover {
    background-color: ${color.brand.hintBlue};

    ${LinkActionsFlex} {
      visibility: visible;
    }
  }
`;

const StyledLink = styled(Link)`
  font-size: ${tokens.fontSize.f7};
`;

export function LinkItemHoverActions({ sync = Map(), onDeleteSync, optionalTrackingProps = {} }) {
  const [isSyncDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const providerA = useSelector((state) =>
    getProviderById(state, { providerId: sync.getIn(['A', 'providerIdentity', 'providerId']) }),
  );
  const providerB = useSelector((state) =>
    getProviderById(state, { providerId: sync.getIn(['B', 'providerIdentity', 'providerId']) }),
  );
  const providerNames = getSortedProviderNames(providerA.get('name'), providerB.get('name'));
  const itemTypeA = sync.getIn(['A', 'itemType']);
  const itemTypeB = sync.getIn(['B', 'itemType']);
  const trackEvent = useTrackEvent({ ...optionalTrackingProps, selected_tool_names: providerNames });
  const taskTermPlural = (
    <ProviderTermsByName
      providerNameA={providerA.get('name')}
      providerNameB={providerB.get('name')}
      termKey="task"
      pcdv3={!!itemTypeA && !!itemTypeB}
      itemTypeA={itemTypeA}
      itemTypeB={itemTypeB}
      containerIdA={sync.getIn(['A', 'container', 'id'])}
      containerIdB={sync.getIn(['B', 'container', 'id'])}
      fallbackTerm="items"
      plurality={fieldTypes.PLURALITY.PLURAL}
    />
  );

  return (
    <Flex align="center">
      <LinkItemDeleteModal
        isOpen={isSyncDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onRequestClose={() => setDeleteModalOpen(false)}
        sync={sync}
        onDeleteSync={() => {
          trackEvent(trackingTypes.ACTION, {
            action_name: 'clicked on confirm to delete flow',
          });
          onDeleteSync();
        }}
      />
      <LinkInfoFlex align="center" flex={1} style={{ padding: `${tokens.spacing.s3} ${tokens.spacing.s4}` }}>
        <Flex align="center">
          <LinkContainerIcons
            containerA={sync.getIn(['A', 'container'])}
            containerB={sync.getIn(['B', 'container'])}
            providerA={providerA}
            providerB={providerB}
            readOnlyA={sync.getIn(['syncSettings', 'A', 'readOnly'])}
            readOnlyB={sync.getIn(['syncSettings', 'B', 'readOnly'])}
          />
        </Flex>

        <Flex align="center" flex={1} style={{ paddingLeft: tokens.spacing.s3 }}>
          <LinkInformation linkId={sync.get('_id')} taskTermPlural={taskTermPlural} />
        </Flex>

        <LinkActionsFlex align="center">
          <Space size="small">
            <StyledLink
              to={`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${sync.get('_id')}/${
                routes.FLOW_BUILDER_PAGES.TOOL_SELECTION
              }`}
              onClick={() =>
                trackEvent(trackingTypes.ACTION, {
                  action_name: 'clicked on update account',
                })
              }
              target="_blank"
            >
              Change Account
            </StyledLink>
            <Button
              type="link"
              onClick={() => {
                trackEvent(trackingTypes.ACTION, {
                  action_name: 'clicked on delete flow',
                });
                setDeleteModalOpen(true);
              }}
              size="small"
              name="delete"
              icon={<Icon color={tokens.colors.content.destructive.default} name="trash" type={Icon.KINDS.SOLID} />}
            />
          </Space>
        </LinkActionsFlex>
      </LinkInfoFlex>
    </Flex>
  );
}

LinkItemHoverActions.propTypes = {
  sync: PropTypes.instanceOf(Map),
  onDeleteSync: PropTypes.func.isRequired,
  optionalTrackingProps: PropTypes.shape({
    flow_qty: PropTypes.number.isRequired,
  }),
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { SubmissionError } from 'redux-form';

import { notification } from '@unitoio/mosaic';

import {
  getProviderById,
  getDefaultParamAccountId,
  getDefaultParamContainerId,
  getProviderByNamePreferredAuthMethod,
} from '~/reducers';
import * as authTypes from '~/consts/auth';
import * as authActions from '~/actions/auth';
import * as loggingActions from '~/actions/logging';

const mapStateToProps = (state, ownProps) => {
  const provider = getProviderById(state, ownProps);
  return {
    provider,
    preferredAuthMethod: getProviderByNamePreferredAuthMethod(state, provider.get('name')),
    accountId: getDefaultParamAccountId(state),
    containerId: getDefaultParamContainerId(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProviderIdentityFailure: (error) => {
    if (error) {
      notification.error({
        message: 'Oops, something went wrong',
        description: error?.message,
        placement: 'top',
      });
    }
  },
  authenticate: async (providerName, values) => {
    // force the cast to string because some providers like monday send it as num
    const accountId = values.accountId ? `${values.accountId}` : undefined;
    const containerId = values.containerId ? `${values.containerId}` : undefined;
    try {
      const { authenticationUrl, requiresSetup } = await dispatch(
        authActions.authenticate({
          authenticationTool: providerName,
          authorizationMethod: values.preferredAuthMethod,
          authenticationOptions: {
            apiUrl: values.apiUrl,
            clientId: values.clientId,
            clientSecret: values.clientSecret,
            domain: values.domain,
            popup: !!window.opener,
            embed: true, // This HOC is only used by embed
          },
          authenticationEmbedOptions: {
            accountId,
            containerId,
          },
        }),
      );

      return { authenticationUrl, requiresSetup };
    } catch (err) {
      dispatch(
        loggingActions.reportException(err, {
          authenticationTool: providerName,
          authorizationMethod: values.preferredAuthMethod,
          authenticationEmbedOptions: {
            accountId,
            containerId,
          },
        }),
      );

      throw new SubmissionError({ _error: err.message });
    }
  },
});

export const LegacyEmbedOAuthPopupHoC = (WrappedComponent) => {
  class LegacyEmbedOAuthPopup extends Component {
    static propTypes = {
      accountId: PropTypes.string,
      containerId: PropTypes.string,
      botGuidance: PropTypes.bool,
      domain: PropTypes.string,
      isEmbed: PropTypes.bool,
      isLogin: PropTypes.bool,
      onClick: PropTypes.func,
      onFailure: PropTypes.func,
      onSuccess: PropTypes.func,
      preferredAuthMethod: PropTypes.string.isRequired,
      provider: PropTypes.instanceOf(Map).isRequired,
      providerId: PropTypes.string.isRequired,
      providerIdentityId: PropTypes.string,
      getProviderIdentityFailure: PropTypes.func.isRequired,
      authenticate: PropTypes.func.isRequired,
    };

    static defaultProps = {
      accountId: undefined,
      botGuidance: false,
      isLogin: false,
      isEmbed: false,
      domain: '',
      onClick: () => null,
      onFailure: () => null,
      onSuccess: () => null,
      // If reconnecting a provider identity, give its id
      providerIdentityId: '',
    };
    // eslint-disable-next-line
    setEventListener({ onFailure, onSuccess, getProviderIdentityFailure, dispatch }) {
      const handleMessage = async function handler(e) {
        // eslint-disable-line
        try {
          const { origin } = new URL(window.location);
          // Some tracking libraries also send a postMessage event, only listen to the one defined by Unito
          if (
            (process.env.REACT_APP_ENV !== 'development' && e.origin !== origin) ||
            e.data.type !== authTypes.POST_MESSAGE_TYPES.PROVIDER_IDENTITY
          ) {
            return;
          }

          if (e.data.success) {
            onSuccess && onSuccess(e.data);
          } else if (e.data.success === false) {
            getProviderIdentityFailure(e.data.error);
            onFailure && onFailure(e.data.error);
          }
        } catch (err) {
          dispatch(loggingActions.reportException(err));
        }

        global.window.removeEventListener('message', handleMessage, false);
      };

      // Don't use an arrow function, or the event listener will never fire the function
      global.window.addEventListener('message', handleMessage, false);
    }

    handleAuthorizeProvider = async (values) => {
      const { onClick } = this.props;
      await this.openPopupWindow(values);
      this.setEventListener(this.props);
      onClick && onClick();
    };

    openPopupWindow = async (values) => {
      const authUrl = await this.getAuthUrl(values);
      // Fix for trello powerup embed.
      // When in electron embed context, instead of using a plain old window.open(url),
      // we specify a 'auth' target to prevent redirection to the browser
      // and thus allow cookies to be set properly within electron when authorizing unito.
      const isElectronUserAgent = navigator.userAgent.includes('Desktop');
      const authPopup = isElectronUserAgent ? window.open(authUrl, 'auth', 'resizable') : window.open(authUrl);
      authPopup && authPopup.focus();
    };

    getAuthUrl = async (values) => {
      const { authenticate, provider, accountId, containerId, preferredAuthMethod } = this.props;
      const { authenticationUrl } = await authenticate(provider.get('name'), {
        ...values,
        accountId,
        containerId,
        preferredAuthMethod,
      });
      return authenticationUrl;
    };

    render() {
      return <WrappedComponent {...this.props} onClick={this.handleAuthorizeProvider} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(LegacyEmbedOAuthPopup);
};

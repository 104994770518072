import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Icon } from '@unitoio/mosaic';

import * as trackingActions from '~/actions/tracking';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import { AccountStatusBadge } from '~/containers/AccountStatusBadge/AccountStatusBadge';
import { WorkflowNameById } from '~/containers/WorkflowNameById/WorkflowNameById';
import { getSelectedOrganizationId } from '~/reducers';
import { color, fontSize } from 'theme';
import { UnitoLogo } from '~/components/UnitoLogo/UnitoLogo';
import { Href } from '~/components/Href/Href';
import { Box } from '~/components/Box/Box';

import { RightHeaderContent } from './RightHeaderContent';

const Navbar = styled((props) => <Box {...props} />)`
  align-items: center;
  background: ${color.content.neutral.white};
  border-bottom: 1px solid ${color.content.neutral.disabled};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 4.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const WorkflowName = styled.div`
  font-size: ${fontSize.f5};
`;

function HeaderContainerComponent({ selectedOrganizationId, trackEvent }) {
  const match = useRouteMatch();
  const history = useHistory();

  if (!selectedOrganizationId) {
    return null;
  }

  const trackHeaderEvent = (actionName, data) => {
    const eventName = trackingTypes.HEADER_BAR_EVENTS.ACTION_NAME;
    trackEvent(eventName, { action_name: actionName, ...data });
  };

  return (
    <Navbar $m={[0]} $p={[0, 1]}>
      <Box $m={[0, 'auto', 0, 0]}>
        <Switch>
          <Route path={`${match.path}:workflowId`}>
            <Button
              variant={Button.variants.SUBTLE}
              startIcon="chevron-left"
              iconKind={Icon.KINDS.SOLID}
              onClick={() => history.push(routes.ABSOLUTE_PATHS.WORKFLOWS)}
            >
              Back to workflows list
            </Button>
          </Route>
          <Route path={match.path}>
            <AccountStatusBadge type="button" trackEvent={trackHeaderEvent} />
          </Route>
        </Switch>
      </Box>

      <Box $m={[0, 'auto']}>
        <Switch>
          <Route
            path={`${match.path}:workflowId`}
            render={(routeProps) => (
              <WorkflowName>
                <WorkflowNameById workflowId={routeProps.match.params.workflowId} />
              </WorkflowName>
            )}
          />
          <Route path={match.path}>
            <Href to={routes.ABSOLUTE_PATHS.WORKFLOWS}>
              <UnitoLogo kind="horizontal" color="color" width="132px" />
            </Href>
          </Route>
        </Switch>
      </Box>

      <Box $m={[0, 0, 0, 'auto']}>
        <RightHeaderContent />
      </Box>
    </Navbar>
  );
}

HeaderContainerComponent.propTypes = {
  selectedOrganizationId: PropTypes.string,
  trackEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  selectedOrganizationId: getSelectedOrganizationId(state),
});

const mapDispatchToProps = (dispatch) => ({
  trackEvent: (...params) => dispatch(trackingActions.trackEvent(...params)),
});

export const HeaderContainerWorkflow = connect(mapStateToProps, mapDispatchToProps)(HeaderContainerComponent);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import { useWatch, useFormContext } from 'react-hook-form';

import * as fieldActions from '~/actions/fields';
import * as fieldTypes from '~/consts/fields';
import { getFieldValues } from '~/reducers';
import { useValueDebounce } from '~/hooks/useValueDebounce';
import { SelectField } from '~/components/SelectField/SelectField';

import { useGetField } from '../hooks/useGetField';
import { useFetchSelectedSearchableFieldValue } from '../hooks/useFetchSelectedSearchableFieldValue';
import { useGetNonEnumCustomFieldValues } from '../hooks/useGetCustomFieldValues';
import { LOADING_STATUSES } from '../consts/flowBuilder';
import { useFetchFieldValues } from '../hooks/useFetchFieldValues';

async function fetchFieldValues({ setStatus, dispatch, payload, reportException }) {
  try {
    setStatus(LOADING_STATUSES.LOADING);
    await dispatch(fieldActions.fetchFieldValues(payload));
    setStatus(LOADING_STATUSES.LOADED);
  } catch (err) {
    reportException(err, payload);
    setStatus(LOADING_STATUSES.ERROR);
  }
}

/**
 * If the field values were previously stored in the redux state, we can skip fetching the values
 */
function useSetValuesAsLoaded({ status, fieldValues, setStatus }) {
  useEffect(() => {
    if (status === LOADING_STATUSES.INITIAL && !fieldValues.isEmpty()) {
      setStatus(LOADING_STATUSES.LOADED);
    }
  }, [fieldValues, setStatus, status]);
}

function useFetchFieldValuesForFieldId({
  name,
  fieldId,
  parentFieldId,
  kind,
  containerId,
  providerIdentityId,
  searchString,
  containerSide,
  multiSelect,
  shouldSetDefault,
  itemType,
  containerType,
}) {
  const fieldValues = useSelector((state) => getFieldValues(state, { containerSide, kind, fieldId }));
  // TODO: fix as none of these statuses are actually reflected in the UI right now
  const [status, setStatus] = useState(LOADING_STATUSES.INITIAL);

  const nonEnumCustomFieldValues = useGetNonEnumCustomFieldValues({
    providerIdentityId,
    kind,
    fieldId,
    containerSide,
    containerId,
    itemType,
  });
  useSetValuesAsLoaded({ status, setStatus, fieldValues });
  useFetchSelectedSearchableFieldValue({
    containerId,
    containerSide,
    containerType,
    fieldId,
    parentFieldId,
    kind,
    name,
    providerIdentityId,
    setStatus,
    status,
    multiSelect,
    shouldSetDefault,
    fieldValues,
    customFieldValues: nonEnumCustomFieldValues,
    itemType,
  });
  useFetchFieldValues({
    containerId,
    containerSide,
    containerType,
    fieldId,
    parentFieldId,
    kind,
    providerIdentityId,
    searchString,
    fieldValues,
    setStatus,
    status,
    itemType,
    fetchFieldValues,
  });

  const field = useGetField({ providerIdentityId, fieldId, itemType, containerId });
  const mergedFieldValues = fieldValues.merge(nonEnumCustomFieldValues);

  if (mergedFieldValues.isEmpty() && !field.get('searchable') && field.get('type') !== fieldTypes.TYPES.ENUM) {
    const defaultFieldValues = Map({
      false: Map({ displayName: 'Empty value', id: 'false', type: 'boolean' }),
      true: Map({ displayName: 'Any value', id: 'true', type: 'boolean' }),
    });

    return [status, defaultFieldValues];
  }

  return [status, mergedFieldValues];
}

function useFixLegacyCustomFields({ name, options, value, kind }) {
  const { setValue } = useFormContext();
  const hasBooleanAsString =
    Array.isArray(value) && value.find((val) => typeof val === 'string' && ['true', 'false'].includes(val));
  const hasBoolean = options?.find((option) => typeof option.value === 'boolean');
  const hasTwoOptions = options?.length === 2;
  const isCustomField = kind === fieldTypes.KINDS.CUSTOM_FIELD;
  const shouldFix = hasBoolean && hasTwoOptions && hasBooleanAsString && isCustomField;

  useEffect(() => {
    if (shouldFix) {
      const newBooleanValue = value.map((val) => val === true || val === 'true');
      setValue(name, newBooleanValue);
    }
  }, [name, setValue, shouldFix, value]);
}

function getOptions(fieldValues, isOptionDisabled) {
  return fieldValues
    .map((fieldValue) => {
      const buildFieldValueOption = (fv) => {
        const { disabled, disabledText } = isOptionDisabled(fv.toJS()) || {};
        const option = {
          label: fv.get('displayName') ?? fv.get('id'),
          value: fv.get('type') === 'boolean' ? fv.get('id') === 'true' : fv.get('id'),
          disabled,
          disabledText: fv.get('isHidden') ? 'Archived' : disabledText,
        };

        const color = fv.get('color') ? { id: fv.get('id'), value: fv.get('color') } : undefined;
        return { ...option, color };
      };

      const childrenOptions = fieldValue.get('children')?.map(buildFieldValueOption);
      if (!childrenOptions) {
        return buildFieldValueOption(fieldValue);
      }

      return {
        label: fieldValue.get('displayName') ?? fieldValue.get('id'),
        value: fieldValue.get('type') === 'boolean' ? fieldValue.get('id') === 'true' : fieldValue.get('id'),
        options: childrenOptions.toJS(),
      };
    })
    .toArray();
}

function getNoResultsText(field, fieldDisplayName, searchString) {
  if (field.get('searchable') && !searchString) {
    return `Start typing to search a ${fieldDisplayName}...`;
  }

  return `Could not find matching ${fieldDisplayName}`;
}

export function sortFields(fieldA, fieldB) {
  if (fieldA?.disabled) {
    return 1;
  }

  if (fieldB?.disabled) {
    return -1;
  }

  if (!fieldA?.label || !fieldB?.label) {
    if (fieldA?.label) {
      return -1;
    }
    return fieldB?.label ? 1 : 0;
  }

  return fieldA.label.toLowerCase().localeCompare(fieldB.label.toLowerCase(), 'en', { sensitivity: 'base' });
}

export function FieldValuesSelect({
  name,
  onChange,
  readOnly = false,
  providerIdentityId,
  containerId,
  kind,
  fieldId,
  isOptionDisabled = () => undefined,
  parentFieldId,
  placeholder,
  containerSide,
  multiSelect = false,
  itemType,
  containerType,
  ...rest
}) {
  const [searchValue, setSearchValue] = useState();
  const field = useGetField({ providerIdentityId, fieldId, itemType, containerId });
  const selectedFieldValueId = useWatch({ name });
  const searchString = useValueDebounce(searchValue, 500);
  const [loadedState, fieldValues] = useFetchFieldValuesForFieldId({
    containerSide,
    name,
    containerId,
    fieldId,
    parentFieldId,
    kind,
    providerIdentityId,
    searchString,
    multiSelect,
    itemType,
    containerType,
  });
  const options = getOptions(fieldValues, isOptionDisabled).sort(sortFields);
  const fieldDisplayName = field.getIn(['displayName', 'singular'], 'value');
  /**
   * LEGACY FIX
   * Context : about a months worth of links have custom fields value saved as strings, this fixes those for now
   */
  useFixLegacyCustomFields({ name, options, value: selectedFieldValueId, kind });
  const selectedOption = options.find((option) => selectedFieldValueId === option.value.toString());

  return (
    <SelectField
      name={name}
      readOnly={readOnly}
      noResultsText={getNoResultsText(field, fieldDisplayName, searchString)}
      isLoading={field.get('searchable') ? false : loadedState !== LOADING_STATUSES.LOADED && !selectedOption?.label}
      optionsLoading={
        field.get('searchable')
          ? loadedState === LOADING_STATUSES.LOADING || (loadedState === LOADING_STATUSES.INITIAL && !!selectedOption)
          : loadedState === LOADING_STATUSES.LOADING && !selectedOption?.label
      }
      searchable
      label={`Choose a ${fieldDisplayName}`}
      onChange={onChange}
      options={options}
      onSearch={setSearchValue}
      searchPlaceholder={`Search for a ${fieldDisplayName}`}
      placeholder={placeholder || `Select a ${fieldDisplayName}`}
      multiSelect={multiSelect}
      {...rest}
    />
  );
}

FieldValuesSelect.propTypes = {
  containerSide: PropTypes.oneOf(['A', 'B']).isRequired,
  containerId: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  parentFieldId: PropTypes.string,
  kind: PropTypes.oneOf(Object.values(fieldTypes.KINDS)).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  providerIdentityId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  multiSelect: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  itemType: PropTypes.string.isRequired,
  containerType: PropTypes.string.isRequired,
};

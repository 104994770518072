import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';

import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as containerTypes from '~/consts/containers';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import * as containerActions from '~/actions/containers';
import { getProviderIdentityById, getProviderIdentityProviderName } from '~/reducers';
import { isValidUrl } from '~/utils/isValidUrl';
import { useSetContainersErrors } from '~/containers/FlowBuilder/hooks/useSetContainersErrors';

import { loadingStates } from '../utils/form';
import { useIsFlowDuplicate } from './useIsFlowDuplicate';

export function useFetchContainers({
  containerId,
  containerType,
  providerIdentityId,
  isSearchable,
  searchValue,
  side,
  itemType,
}) {
  const [loadedState, setContainersState] = useState(loadingStates.INITIAL);
  const providerName = useSelector((state) => getProviderIdentityProviderName(state, providerIdentityId));
  const linkState = useWatch({ name: 'state' });
  const [containers, setContainers] = useState([]);
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { reportException } = useLogger();

  const { setError, clearErrors } = useFormContext();

  // To re-trigger the effects below whenever there is a change in the provider identity (refreshing its state/renewing creds)
  // we rely on the instance of the PI Map to re-trigger the actions to make sure we display the right containers/re-check
  // if the PI has access to the current container.
  const providerIdentity = useSelector((state) => getProviderIdentityById(state, providerIdentityId));

  const setFormErrors = useSetContainersErrors(setError, clearErrors);
  // We consider the flow to be in draft mode when coming from the OneClick journey in order to be able to reuse components/hooks
  // from the FlowBuilder. Defaulting to isDraft=true because the flow is created only once out of the OneClick journey.
  const isInOneClick = useLocation().pathname.includes(routes.BASE_PATHS.ONE_CLICK_EXPORT);
  const isDraft = linkState === linkTypes.LINK_STATES.DRAFT || isInOneClick;
  const isDuplicate = useIsFlowDuplicate();
  const isDuplicateOrDraft = isDuplicate || isDraft;

  /** -- Fetch by container id -- */
  // If a flow is in draft state, but requires a typeahead and the user hasn't entered a search string we will want to do getContainerById.
  const shouldGetContainerByIdForDraft = containerId && isDraft && isSearchable && !searchValue;
  // If the flow is a launched flow (not a draft or duplicate) we will want to do getContainerById
  const shouldFetchContainerById = (containerId && !isDuplicateOrDraft) || shouldGetContainerByIdForDraft;

  /** -- Fetch all containers -- */
  // If a flow is not searchable it means that when need to retrieve all the containers
  const shouldFetchContainers = isDuplicateOrDraft && !isSearchable;
  // Avoid calling the endpoint if the containers are already loaded
  const hasContainers = !isDuplicateOrDraft && containers.length > 0;

  /** -- Search containers -- */
  // If a search string is entered we will want to call getContainers to actually search for the containers.
  const shouldSearchContainers = isSearchable && !!searchValue;

  const handleFetch = useCallback(
    async (fetchAction) => {
      setContainersState(loadingStates.LOADING);
      try {
        const result = await dispatch(fetchAction);
        setContainersState(loadingStates.LOADED);
        return result;
      } catch (error) {
        setFormErrors({ [side]: error });
        setContainersState(loadingStates.ERROR);

        if (error.name !== 'ForbiddenError') {
          trackEvent(trackingTypes.EVENT_NAME.BLOCKED, { reason: error.message });

          reportException(`error while fetching containers`, {
            identifier: 'useFetchContainers fetchContainers errorWhileFetchingContainers',
            error,
          });
        }

        return undefined;
      }
    },
    [dispatch, setFormErrors, side, trackEvent, reportException],
  );

  const fetchContainerById = useCallback(async () => {
    const fetchAction = containerActions.getContainerById({
      providerIdentityId: providerIdentity.get('_id'),
      containerId,
      containerType,
      options: { displayError: false },
      itemType,
    });
    const response = await handleFetch(fetchAction);
    if (response?.container) {
      setContainers([response?.container]);
    }
  }, [providerIdentity, containerId, containerType, itemType, handleFetch]);

  const searchContainers = useCallback(async () => {
    const actionName = isValidUrl(searchValue)
      ? containerTypes.TRACKING.ACTION_NAMES.SEARCHING_BY_URL
      : containerTypes.TRACKING.ACTION_NAMES.SEARCHING;

    trackEvent(trackingTypes.EVENT_NAME.ACTION, { action_name: actionName, selected_tool_name: providerName });

    const fetchAction = containerActions.getContainers({
      providerIdentityId: providerIdentity.get('_id'),
      searchValue,
      containerType,
      options: { displayError: false },
      itemType,
    });

    const response = await handleFetch(fetchAction);
    if (response?.containers) {
      setContainers(response?.containers);
    }

    if (response?.containers?.length === 0) {
      trackEvent(trackingTypes.EVENT_NAME.BLOCKED, {
        reason: containerTypes.TRACKING.ERROR_REASONS.NO_PROJECTS,
      });
    }
  }, [containerType, handleFetch, itemType, providerIdentity, providerName, searchValue, trackEvent]);

  const fetchContainers = useCallback(async () => {
    const fetchAction = containerActions.getContainers({
      providerIdentityId: providerIdentity.get('_id'),
      containerType,
      options: { displayError: false },
      itemType,
    });

    // Only retrigger a fetch if there are no containers
    // To avoid recalling the endpoint on containerId change when the containers are already loaded
    if (hasContainers) {
      return;
    }

    const response = await handleFetch(fetchAction);
    if (response?.containers) {
      setContainers(response?.containers);
    }

    if (response?.containers?.length === 0) {
      trackEvent(trackingTypes.EVENT_NAME.BLOCKED, {
        reason: containerTypes.TRACKING.ERROR_REASONS.NO_PROJECTS,
      });
    }
  }, [containerType, handleFetch, hasContainers, itemType, providerIdentity, trackEvent]);

  // About missingRequiredParams:
  // When coming from OneClick™ the useFetchContainers hook is run before the providerIdentityId is set in the form.
  // This is because the ContainerSelect component (in which the hook is called) is always visible even when no providerIdentity
  // has been selected(see OneClickExport/components/ToolSelection).
  // We do not have this problem in the regular FlowBuilder™ because the ContainerSelect stays hidden until a providerIdentity is selected.
  useEffect(() => {
    const identity = providerIdentity.get('_id');
    const missingRequiredParams = !identity || !containerType || !itemType;
    if (missingRequiredParams) {
      return;
    }

    if (shouldFetchContainers) {
      fetchContainers();
    }
  }, [containerType, containerId, fetchContainers, itemType, providerIdentity, shouldFetchContainers]);

  useEffect(() => {
    const identity = providerIdentity.get('_id');
    const missingRequiredParams = !identity || !containerType || !itemType;
    if (missingRequiredParams) {
      return;
    }

    if (shouldSearchContainers) {
      searchContainers();
    }
  }, [containerType, itemType, providerIdentity, searchContainers, shouldSearchContainers]);

  useEffect(() => {
    const identity = providerIdentity.get('_id');
    const missingRequiredParams = !identity || !containerType || !itemType;
    if (missingRequiredParams) {
      return;
    }
    if (shouldFetchContainerById) {
      fetchContainerById();
    }
  }, [containerType, fetchContainerById, itemType, providerIdentity, shouldFetchContainerById]);

  return [loadedState, containers];
}

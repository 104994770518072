import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, useFormState } from 'react-hook-form';

import { Box, LoadingPlaceholder, tokens } from '@unitoio/mosaic';

import { FlowBuilderErrorContext } from '~/contexts';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { getProviderByName, getUserProviderIdentities, getEmbedName, getProviderCapabilitiesV3 } from '~/reducers';
import * as containerActions from '~/actions/containers';
import { otherSide as fromOtherSide } from '~/utils/otherSide';
import * as trackingTypes from '~/consts/tracking';
import * as appTypes from '~/consts/app';
import * as containerTypes from '~/consts/containers';
import { ContainerInfo } from '~/containers/ContainerInfo/ContainerInfo';
import { useSetContainersErrors } from '~/containers/FlowBuilder/hooks/useSetContainersErrors';
import {
  loadingStates,
  getContainerFromNodes,
  getParentContainerIdFromNodes,
  resetFormValuesForToolSideChange,
} from '../../containers/FlowBuilder/utils/form';

import { ProvidersSelect } from './ProvidersSelect';
import { ProviderIdentitiesSelect } from './ProviderIdentitiesSelect';
import { PathFinderConnectTools } from '../../containers/FlowBuilder/pages/ConnectToolsRevamp/PathFinderConnectTools';
import { useIsFlowDuplicate } from '../../containers/FlowBuilder/hooks/useIsFlowDuplicate';
import { useFetchModernContainerById } from './hooks/useFetchModernContainerById';

// forcing a min-height here to prevent the Box from shrinking
// when the loader is removed and the ContainerSelect is displayed
const StyledBox = styled(Box)`
  min-height: ${tokens.spacing.s5};
`;

export function WorkItemSelectorRevamp({ isEdit = false, side, loadingState, isDuplicating }) {
  const { watch, setValue, clearErrors, setError } = useFormContext();
  const dispatch = useDispatch();
  const { errors } = useFormState();
  const otherSide = fromOtherSide(side);
  const selectedProviderName = watch(`${side}.providerName`);
  const otherProviderName = watch(`${otherSide}.providerName`);
  const selectedItemType = watch(`${side}.itemType`);
  const selectedContainerType = watch(`${side}.containerType`);
  const selectedProviderIdentityId = watch(`${side}.providerIdentityId`);
  const selectedContainerId = watch(`${side}.containerId`);
  const selectedNodes = watch(`${side}.nodes`);
  const provider = useSelector((state) => getProviderByName(state, selectedProviderName));
  const userProviderIdentities = useSelector((state) => getUserProviderIdentities(state, false)); // false because we don't want providers that are "onlyAuthenticate" (githubappuser)
  const providerIdentities = userProviderIdentities.filter((pi) => pi.get('providerName') === selectedProviderName);

  const setFormErrors = useSetContainersErrors(setError, clearErrors);

  const capabilitiesV3 = useSelector((state) =>
    getProviderCapabilitiesV3(state, {
      providerIdentityId: selectedProviderIdentityId,
      providerName: selectedProviderName,
      itemType: selectedItemType,
    }),
  );

  const trackEvent = useTrackEvent();
  const embedName = useSelector((state) => getEmbedName(state));
  const lockedWrikeEmbed =
    embedName === appTypes.EMBED.WRIKE &&
    otherProviderName !== appTypes.EMBED.WRIKE &&
    selectedProviderName === appTypes.EMBED.WRIKE;
  const pageName = useContext(FlowBuilderErrorContext);
  const isDuplicate = useIsFlowDuplicate();
  const isSearchable = capabilitiesV3?.getIn(['containers', selectedContainerType, 'searchable', 'isSearchable']);

  useFetchModernContainerById(side);

  const fetchContainersByProviderIdentity = async (providerIdentityId, containerType) => {
    if (!isSearchable && selectedContainerType) {
      try {
        const response = await dispatch(
          containerActions.getContainers({
            providerIdentityId,
            displayError: false,
            containerType,
            itemType: selectedItemType,
          }),
        );
        if (response.containers?.length === 0) {
          trackEvent(trackingTypes.EVENT_NAME.BLOCKED, {
            reason: containerTypes.TRACKING.ERROR_REASONS.NO_PROJECTS,
          });
        }
        return response.containers;
      } catch (containerError) {
        setFormErrors({ [side]: containerError });
        trackEvent(trackingTypes.EVENT_NAME.BLOCKED, { reason: containerError.message });
      }
    }
    return null;
  };

  if ([loadingStates.LOADING, loadingStates.INITIAL].includes(loadingState) || isDuplicating) {
    return (
      <Box flexDirection="row" alignItems="center" justifyContent="space-between">
        <LoadingPlaceholder width={tokens.spacing.s9} height={tokens.spacing.s3} borderRadius={tokens.spacing.s4} />
        <LoadingPlaceholder width={tokens.spacing.s9} height={tokens.spacing.s6} borderRadius={tokens.spacing.s4} />
      </Box>
    );
  }

  const handleProviderChange = async (newProvider) => {
    setValue(`${side}.providerName`, newProvider, { shouldDirty: true });

    // this needs to be set here for the very first tool selection
    const providerIdentity = userProviderIdentities.filter((pi) => pi.get('providerName') === newProvider);
    const isAutoSelectProviderIdentityId = providerIdentity.size === 1;
    const defaultProviderIdentityId = isAutoSelectProviderIdentityId ? providerIdentity?.keySeq().first() : null;
    setValue(`${side}.providerIdentityId`, defaultProviderIdentityId);

    // NOTE: To not change current behavior, we reset the container infos
    // But test in the future if we can reset the whole form instead (keep the other side config as is (rules and B))
    // using only reset();
    setValue(`${side}.containerId`, null, { shouldDirty: true });
    setValue(`${side}.containerDisplayName`, null, { shouldDirty: true });
    setValue(`${side}.containerUrl`, null, { shouldDirty: true });
    setValue(`${side}.containerPath`, null, { shouldDirty: true });
    setValue(`${side}.itemType`, null, { shouldDirty: true });
    setValue(`${side}.containerType`, null, { shouldDirty: true });
    setValue(`${side}.nodes`, [], { shouldDirty: true });
    resetFormValuesForToolSideChange(setValue, side, newProvider);
    // NOTE: Is this required? The reset functionality on the main useFlowBuilderForm hook should be handling this
    // To be tested
    clearErrors([`${pageName}.${side}.providerIdentityId`, `${pageName}.${side}.containerId`]);

    trackEvent(trackingTypes.ACTION, {
      action_name: 'selected a tool',
      selected_tool_name: newProvider,
    });
  };

  const handleProviderIdentityChange = async (newProviderIdentityId) => {
    if (newProviderIdentityId === selectedProviderIdentityId) {
      return;
    }
    trackEvent(trackingTypes.ACTION, {
      action_name: 'selected existing account',
      selected_tool_name: selectedProviderName,
      connected_account_qty: providerIdentities.size,
      connected_account: providerIdentities.isEmpty() ? 'new' : 'existing',
    });

    const containers = await fetchContainersByProviderIdentity(newProviderIdentityId, selectedContainerType);

    // in edit mode:
    // only save the new identity if it has access to the same containerId
    if (isEdit) {
      if (containers && containers.some((container) => container.id === selectedContainerId)) {
        setValue(`${side}.providerIdentityId`, newProviderIdentityId, { shouldDirty: true });
      }
      return;
    }
    // in draft:
    // simply override the identity and reset the rest
    setValue(`${side}.providerIdentityId`, newProviderIdentityId, { shouldDirty: true });
    // for modern connectors, since everything is credential based, we reset the container infos
    setValue(`${side}.containerId`, null, { shouldDirty: true });
    setValue(`${side}.containerDisplayName`, null, { shouldDirty: true });
    setValue(`${side}.containerUrl`, null, { shouldDirty: true });
    setValue(`${side}.containerPath`, null, { shouldDirty: true });
    setValue(`${side}.itemType`, null, { shouldDirty: true });
    setValue(`${side}.containerType`, null, { shouldDirty: true });
    setValue(`${side}.nodes`, [], { shouldDirty: true });
    resetFormValuesForToolSideChange(setValue, side, selectedProviderName);
  };

  // TODO: move to new wrapper component when ready [container-builder]
  const handleNodeChange = (nodes, containerType) => {
    const {
      id: containerId,
      url: containerUrl,
      label: containerDisplayName,
      path: containerPath,
    } = getContainerFromNodes(nodes) || {};
    const parentContainerId = getParentContainerIdFromNodes(nodes);

    setValue(`${side}.nodes`, nodes, { shouldDirty: true });
    setValue(`${side}.containerId`, containerId, { shouldDirty: true });
    setValue(`${side}.parentContainerId`, parentContainerId, { shouldDirty: true });
    setValue(`${side}.containerType`, containerType, { shouldDirty: true });
    setValue(`${side}.containerDisplayName`, containerDisplayName, { shouldDirty: true });
    setValue(`${side}.containerUrl`, containerUrl, { shouldDirty: true });
    setValue(`${side}.containerPath`, containerPath, { shouldDirty: true });
    resetFormValuesForToolSideChange(setValue, side, selectedProviderName);
    clearErrors(`${pageName}.${side}.containerId`);
  };

  // TODO: move to new wrapper component when ready [container-builder]
  const handleNodeItemTypeChange = (itemType) => {
    setValue(`${side}.itemType`, itemType, { shouldDirty: true });
    resetFormValuesForToolSideChange(setValue, side, selectedProviderName);
  };

  const providerIdentityError = errors[side]?.providerIdentityId?.error;
  const containerError = errors[side]?.containerId?.error;

  const hasErrors = containerError || providerIdentityError;
  const readOnly = isEdit && !hasErrors;
  const isAutoSaving =
    loadingState !== loadingStates.SAVED &&
    loadingState !== loadingStates.LOADED &&
    loadingState !== loadingStates.ERROR;

  return (
    <>
      <ProvidersSelect
        onChange={handleProviderChange}
        side={side}
        readOnly={!!(readOnly || isDuplicate || lockedWrikeEmbed)}
      />
      {selectedProviderName && (
        <ProviderIdentitiesSelect
          provider={provider}
          onChange={handleProviderIdentityChange}
          side={side}
          readOnly={!!isDuplicate}
        />
      )}
      {selectedProviderIdentityId && (
        <StyledBox m={[tokens.spacing.s4, 0, 0, 0]}>
          <PathFinderConnectTools
            side={side}
            providerIdentityId={selectedProviderIdentityId}
            onNodeChange={handleNodeChange}
            onItemTypeChange={handleNodeItemTypeChange}
            selectedNodes={selectedNodes}
            selectedItemType={selectedItemType}
            readOnly={isEdit}
            isSaving={isAutoSaving}
          />
        </StyledBox>
      )}
      {selectedProviderName && selectedProviderIdentityId && <ContainerInfo providerName={selectedProviderName} />}
    </>
  );
}

WorkItemSelectorRevamp.propTypes = {
  isEdit: PropTypes.bool,
  loadingState: PropTypes.string.isRequired,
  side: PropTypes.oneOf(['A', 'B']).isRequired,
  isDuplicating: PropTypes.bool.isRequired,
};

import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { destroy } from 'redux-form';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import styled, { createGlobalStyle } from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { message } from '@unitoio/mosaic';

import * as workflowActions from '~/actions/workflows';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import { color } from 'theme';
import { InlineLoading } from '~/components/InlineLoading/InlineLoading';
import { RoutedModal } from '~/components/Modal/RoutedModal';
import * as workflowTypes from '~/consts/workflows';
import * as providerContainerActions from '~/actions/providerContainers';
import * as linkActions from '~/actions/links';

import { WorkflowWorkBlockAdd } from './components/WorkflowWorkBlockAdd';
import { RemoveBlockModal } from './components/Modals/RemoveBlockModal';
import { WorkblockConfigurationModal } from './components/Modals/WorkblockConfigurationModal';
import { FlowModal } from './components/Modals/FlowModal';

/* eslint-disable */
const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;
/* eslint-enable */

const Canvas = styled(CanvasWidget)`
  cursor: none !important;
  min-height: calc(100vh - 9rem);
  width: 100%;
`;

const WorkflowContainerWrapper = styled.div`
  --grid-size: ${(workflowTypes.GRID_SIZE * workflowTypes.INITIAL_ZOOM_LEVEL) / 100}px;
  --offset-x: ${(props) => props.$offsetX}px;
  --offset-y: ${(props) => props.$offsetY}px;
  min-height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: ${color.light.primary};
  background-image:
    linear-gradient(to right, ${color.dark.whisper} 1px, transparent 1px),
    linear-gradient(to bottom, ${color.dark.whisper} 1px, transparent 1px);
  background-position-x: var(--offset-x);
  background-position-y: var(--offset-y);
  background-size: var(--grid-size) var(--grid-size);

  svg:not(:root) {
    overflow: visible !important;
  }
`;

export const WorkflowContainer = ({
  isLoading = false,
  workflow,
  workflowEngine,
  checkSaveRequired = () => true,
  onSaveWorkflow,
  onUpdatedNewWorkBlock = () => null,
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const trackEvent = useTrackEvent();
  const { workflowId } = match.params;
  const [workblockAddPoint, setWorkblockAddPoint] = useState({ x: 0, y: 0 });
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const [lastKnownPosition, setLastKnownPosition] = useState({ x: 0, y: 0 });
  const [newNode, setNewNode] = useState(null);
  const [isSavingNewBlock, setIsSavingNewBlock] = useState(false);
  const dispatch = useDispatch();

  if (isLoading || !workflowEngine) {
    return <InlineLoading />;
  }

  const workflowModel = workflowEngine.getModel();

  const updateWorkflow = async (ignoreRedirect = false) => {
    if (!ignoreRedirect) {
      history.push(match.url);
    }
    dispatch(destroy('syncForm'));
    setIsSavingNewBlock(false);
    setNewNode(null);
    if (checkSaveRequired(workflow.get('diagramRepresentation'), workflowModel.serialize())) {
      await saveWorkflow();
    }
  };

  async function handleMouseUp(e) {
    setIsLinking(false);
    const createdWorkBlock = workflowModel.conditionallyCreateBlock(e, lastKnownPosition);
    setLastKnownPosition({ x: null, y: null });

    if (createdWorkBlock) {
      setNewNode(createdWorkBlock);
      trackEvent(trackingTypes.ADD_BLOCK_START);
      history.push(`${match.url}/blocks/add`);
      return;
    }

    if (checkSaveRequired(workflow.get('diagramRepresentation'), workflowModel.serialize())) {
      await saveWorkflow();
    }
  }

  function handleMouseDown(e) {
    setIsLinking(true);
    setLastKnownPosition({ x: e.clientX, y: e.clientY });
  }

  async function updateNewWorkBlock({
    created,
    blockId,
    name,
    itemType,
    containerId,
    containerType,
    providerIdentityId,
    providerContainerId,
    toolName,
  }) {
    newNode.initialize({
      providerIdentityId,
      name,
      containerId,
      blockId,
      providerContainerId,
      toolName,
      itemType,
      containerType,
    });

    if (newNode.isPlaceholder()) {
      return updateWorkflow();
    }

    if (created) {
      onUpdatedNewWorkBlock(newNode);
    } else {
      message.info({
        content: 'This block of work is already included in your workflow!',
      });
      workflowModel.removeNode(newNode);
    }

    return updateWorkflow();
  }

  function handleOnAddBlockFailure() {
    message.error({
      content: 'Something went wrong while adding your block of work. Please get in touch with our team!',
    });

    workflowModel.removeNode(newNode);
    return updateWorkflow();
  }

  function removeAndResetNewNode() {
    if (newNode && !newNode.isSetupCompleted()) {
      workflowModel.removeNode(newNode);
      setNewNode(null);
    }
  }

  async function saveWorkflow() {
    if (isSavingNewBlock) {
      return null;
    }
    return onSaveWorkflow(workflowId, workflowModel);
  }

  function handleMouseMove(e) {
    setWorkblockAddPoint(workflowModel.calculateWorkBlockAddPoint(e));
    setShowPlaceholder(!workflowModel.isCursorOverlapping(e));
  }

  // The React-diagram library mishandle the macbook trackpad zoom in/out gesture (2 finger pinch). This disables zooming with 2 finger pinch.
  // source: https://medium.com/@auchenberg/detecting-multi-touch-trackpad-gestures-in-javascript-a2505babb10e
  function handleMouseWheel(e) {
    if (e.ctrlKey) {
      e.stopPropagation();
    }
  }

  async function handleOnCreateSync(formData, node) {
    const syncData = { ...formData, workflowId };
    const link = await dispatch(linkActions.createFlow(syncData));

    const workblocks = workflowModel.getSiblingNodes(node);

    await Promise.all(
      ['A', 'B'].map(async (side, index) => {
        const workblock = workblocks[index];
        if (workblock.isPlaceholder()) {
          const { providerContainer } = await dispatch(
            providerContainerActions.createProviderContainer(
              link[side].providerIdentity._id,
              link[side].container.id,
              link[side].containerType,
              link[side].itemType,
            ),
          );
          const { block } = await dispatch(
            workflowActions.addBlock({
              workflowId,
              providerIdentityId: link[side].providerIdentity._id,
              providerContainerId: providerContainer._id,
              itemType: link[side].itemType,
              containerType: link[side].containerType,
            }),
          );

          workblock.setToolName(link[side].providerIdentity.providerName);
          workblock.setBlockId(block._id);
          workblock.setProviderContainerId(providerContainer._id);
          workblock.setProviderIdentityId(link[side].providerIdentity._id);
          workblock.setContainerId(link[side].container.id);
          workblock.setContainerName(link[side].container.displayName);
          workblock.setItemType(link[side].itemType);
          workblock.setContainerType(link[side].containerType);
        }
      }),
    );

    await updateWorkflow();

    node.setLinkId(link._id);
    history.push(`${match.url}/links/${link._id}`);
    return link;
  }

  return (
    <Fragment>
      <GlobalStyle />
      <FlowModal
        engine={workflowEngine}
        onCreateSync={handleOnCreateSync} // eslint-disable-line react/jsx-no-bind
        parentMatch={match}
        updateWorkflow={updateWorkflow}
        trackEvent={trackEvent}
        workflowModel={workflowModel}
      />
      <Switch>
        {newNode && (
          <WorkblockConfigurationModal
            onClose={removeAndResetNewNode} // eslint-disable-line react/jsx-no-bind
            onError={handleOnAddBlockFailure} // eslint-disable-line react/jsx-no-bind
            onSubmit={updateNewWorkBlock} // eslint-disable-line react/jsx-no-bind
            workflowId={workflowId}
          />
        )}
        <RoutedModal
          path={`${match.path}/blocks/delete/:blockId`}
          onClose={updateWorkflow}
          size="small"
          title="Delete block from your workflow?"
          render={(routeProps, closeModal) => {
            const { blockId } = routeProps.match.params;
            const node = workflowModel.findNodeByBlockId(blockId);
            if (!node) {
              return <Redirect to={match.url} />;
            }

            return (
              <RemoveBlockModal
                {...routeProps}
                workflowModel={workflowModel}
                node={node}
                updateWorkflow={() => closeModal() && updateWorkflow()}
              />
            );
          }}
        />
      </Switch>

      <WorkflowContainerWrapper
        data-testid="workflow-container-div"
        className="workflow-container"
        onMouseDown={handleMouseDown} // eslint-disable-line react/jsx-no-bind
        onMouseUp={handleMouseUp} // eslint-disable-line react/jsx-no-bind
        onMouseMove={handleMouseMove} // eslint-disable-line react/jsx-no-bind
        onWheelCapture={handleMouseWheel} // eslint-disable-line react/jsx-no-bind
        $offsetX={workflowModel.getOffsetX()}
        $offsetY={workflowModel.getOffsetY()}
      >
        {showPlaceholder && !isLinking && (
          <Route exact path={match.path}>
            <WorkflowWorkBlockAdd
              scale={workflowModel.getScale()}
              quadrilleSize={workflowModel.getScaledQuadrilleSize()}
              point={workblockAddPoint}
            />
          </Route>
        )}
        <Canvas engine={workflowEngine} />
      </WorkflowContainerWrapper>
    </Fragment>
  );
};

WorkflowContainer.propTypes = {
  isLoading: PropTypes.bool,
  workflow: PropTypes.instanceOf(Map).isRequired,
  workflowEngine: PropTypes.shape({
    getModel: PropTypes.func.isRequired,
    zoomToFitAllNodes: PropTypes.func.isRequired,
  }).isRequired,
  onSaveWorkflow: PropTypes.func.isRequired,
  // Extra action to perform when a new workblock is updated, called with workblock node
  onUpdatedNewWorkBlock: PropTypes.func,
  /* Extra optional action to perform to see if a save is necessary or not.
     The function will be called with the workflow diagramRepresentation
     and the serialized workflow model. */
  checkSaveRequired: PropTypes.func,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import { getPlanTier } from '~/utils/getPlanTier';

import { CompanyDetailsStep } from './CompanyDetailsStep';
import { PaymentDetailsStep } from './PaymentDetailsStep';

const stripePromise = loadStripe(process.env.REACT_APP_UNITO_STRIPE_PUBLISHABLE_KEY);

export function PaymentCheckoutModal({ onCancel, isOpen, onSuccess, planId, organizationId, orgCustomerId }) {
  const [companyDetailsModalState, setCompanyDetailsModalState] = useState();
  const trackEvent = useTrackEvent({ plan_selected: planId, plan_tier_selected: getPlanTier(planId) });

  useEffect(() => {
    if (isOpen) {
      trackEvent(trackingTypes.START);
      setOpenCompanyDetailsStepModal(true);
    }
  }, [isOpen, trackEvent]);

  const [openCompanyDetailsStepModal, setOpenCompanyDetailsStepModal] = useState(false);
  const [openPaymentDetailsStep, setOpenPaymentDetailsStep] = useState(false);

  return (
    <Elements stripe={stripePromise}>
      <CompanyDetailsStep
        setCompanyDetailsFormState={setCompanyDetailsModalState}
        onNextStep={() => {
          setOpenCompanyDetailsStepModal(false);
          setOpenPaymentDetailsStep(true);
        }}
        defaultValues={companyDetailsModalState}
        isOpen={openCompanyDetailsStepModal}
        onCancel={() => {
          onCancel();
          setOpenCompanyDetailsStepModal(false);
        }}
        trackEvent={trackEvent}
      />

      <PaymentDetailsStep
        planId={planId}
        onSuccess={onSuccess}
        onPreviousStep={() => {
          setOpenPaymentDetailsStep(false);
          setOpenCompanyDetailsStepModal(true);
        }}
        defaultValues={{
          addressCountry: companyDetailsModalState?.country,
          addressZip: companyDetailsModalState?.postalCode,
        }}
        isOpen={openPaymentDetailsStep}
        onClose={() => {
          onCancel();
          setOpenPaymentDetailsStep(false);
        }}
        organizationId={organizationId}
        orgCustomerId={orgCustomerId}
        trackEvent={trackEvent}
      />
    </Elements>
  );
}

PaymentCheckoutModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  planId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  orgCustomerId: PropTypes.string.isRequired,
};

import * as websocketTypes from '~/consts/websocket';
import { getUserId, getSelectedOrganizationId } from '~/reducers';

export const connected = () => ({
  type: websocketTypes.WS_CONNECTED,
  meta: {
    socketReadyState: websocketTypes.WEBSOCKET_STATE.OPEN,
  },
});

export const disconnected = () => ({
  type: websocketTypes.WS_DISCONNECTED,
  meta: {
    socketReadyState: websocketTypes.WEBSOCKET_STATE.CLOSED,
  },
});

export const connect = (authToken) => ({
  type: websocketTypes.WS_CONNECT,
  meta: {
    socketReadyState: websocketTypes.WEBSOCKET_STATE.CONNECTING,
    authToken,
  },
});

export const disconnect = () => ({
  type: websocketTypes.WS_DISCONNECT,
});

export const subscribe =
  ({ currentPage, organizationId: fromParamsOrganizationId, userId: fromParamsUserId, linkIds }) =>
  (dispatch, getState) => {
    const state = getState();
    const userId = fromParamsUserId || getUserId(state);
    const organizationId = fromParamsOrganizationId || getSelectedOrganizationId(state);

    return dispatch({
      type: websocketTypes.WS_SUBSCRIBE,
      meta: { currentPage },
      payload: {
        organizationId,
        userId,
        linkIds,
      },
    });
  };

export const updateTopicByPage = (currentPage, topic, payload) => (dispatch) => {
  const type = websocketTypes.DISPATCH_TYPES_BY_PAGE[currentPage];
  if (!type) {
    return null;
  }
  // payload comes from Kirby with linkId as key
  const [linkId] = Object.keys(payload);

  return dispatch({
    type,
    payload,
    meta: { topic, currentPage, linkId },
  });
};

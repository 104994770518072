import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Flex, LoadingSpinner, NewCol, NewRow, NewTypography, tokens, Toggle } from '@unitoio/mosaic';

import { getUserEmail } from '~/reducers';
import { useFlowNotifications } from '../../hooks/useFlowNotifications';

const AlertsContainer = styled.div`
  margin: ${tokens.spacing.s8} ${tokens.spacing.s6};
`;

const CenteredFlex = styled(Flex)`
  padding: ${tokens.spacing.s8} 0;
  width: '100%',
  height: '100%',
`;

export function AlertsPage() {
  const { linkId } = useParams();
  const userEmail = useSelector((state) => getUserEmail(state));
  const { notifications, loading, error, subscribe, unsubscribe } = useFlowNotifications(linkId);

  if (loading) {
    return (
      <CenteredFlex justify="center" align="center">
        <LoadingSpinner />
      </CenteredFlex>
    );
  }

  if (error) {
    return (
      <CenteredFlex>Error loading notifications. Try again and contact support if the error persists.</CenteredFlex>
    );
  }

  return (
    <AlertsContainer>
      <NewRow align="middle">
        <NewCol span={18}>
          <NewTypography.Paragraph>
            I want to be notified by email ({userEmail}) when something prevents this flow from syncing.
          </NewTypography.Paragraph>
        </NewCol>
        <NewCol span={2} offset={4}>
          <Toggle value={notifications} onClick={() => (notifications ? unsubscribe() : subscribe())} />
        </NewCol>
      </NewRow>
    </AlertsContainer>
  );
}

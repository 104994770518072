import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Toggle, tokens } from '@unitoio/mosaic';

import * as activityLogsActions from '~/actions/activityLogs';
import { getActivityLogs, getIsLoadingActivityLogs } from '~/reducers';
import { useDataTable } from '~/hooks/useDataTable';
import { useInterval } from '~/hooks/useInterval';
import { useLogger } from '~/hooks/useLogger';
import { ActivityLogsDataTable } from '~/components/ActivityLogsDataTable/ActivityLogsDataTable';

import { timestampColumn, activityTypeColumn, updatedFieldsColumn, statusColumn } from './columns';

export const dataTableSort = [
  {
    id: 'changeTime',
    desc: true,
  },
];

export const activityLogsColumns = [
  timestampColumn,
  activityTypeColumn('Activity type'),
  updatedFieldsColumn,
  statusColumn,
];

function useFetchActivityLog({ setIsLiveData, generalSearch, organizationId }) {
  const dispatch = useDispatch();
  const { reportException } = useLogger();
  const [isInitialLogsFetch, setIsInitialLogsFetch] = useState(true);
  const isFetchingActivityLogs = useSelector(getIsLoadingActivityLogs);
  const [error, setError] = useState(null);
  const activityLogsData = useSelector(getActivityLogs);
  const fetchActivityLogs = useCallback(
    async (page = 0, pageSize = 10) => {
      setError(null);
      if (!isFetchingActivityLogs) {
        try {
          await dispatch(
            activityLogsActions.fetchActivityLogs(
              organizationId,
              page,
              pageSize,
              { generalSearch },
              dataTableSort,
              null,
              true,
            ),
          );
        } catch (err) {
          reportException(err);
          setError(err);
          setIsLiveData(false);
        }
        setIsInitialLogsFetch(false);
      }
    },
    [dispatch, generalSearch, isFetchingActivityLogs, organizationId, reportException, setIsLiveData],
  );

  return { fetchActivityLogs, activityLogsData, isFetchingActivityLogs, isInitialLogsFetch, error };
}

export function ActivityLogs({
  title,
  organizationId,
  pageSize = 0,
  renderEmptyState,
  generalSearch = '',
  showAutoRefreshToggle = true,
}) {
  const [isLiveData, setIsLiveData] = useState(true);
  const { activityLogsData, fetchActivityLogs, isInitialLogsFetch, isFetchingActivityLogs, error } =
    useFetchActivityLog({
      setIsLiveData,
      organizationId,
      generalSearch,
    });

  const tableData = activityLogsData.get('activityLogs').toJS();
  const recordsCount = activityLogsData.get('activityLogsCount');
  const dataTableData = useDataTable({
    dataTableSort,
    recordsCount,
    columns: activityLogsColumns,
    data: tableData,
    fetchData: fetchActivityLogs,
    currentPage: 0,
    pageSize,
  });

  useInterval(fetchActivityLogs, isLiveData ? 5000 : null);
  const defaultEmptyState = error?.message ? (
    <>
      {error.message}
      <br />
      Try to turn on Auto-refresh via the toggle above
    </>
  ) : (
    'No recent activity to show'
  );

  return (
    <>
      <Box alignItems="center" justifyContent="space-between">
        {title}
        {showAutoRefreshToggle && (
          <Box alignItems="center">
            <Toggle value={isLiveData} onClick={() => setIsLiveData(!isLiveData)} />
            <Box m={[0, 0, 0, tokens.spacing.s3]}>Auto-refresh</Box>
          </Box>
        )}
      </Box>
      <ActivityLogsDataTable
        dataTableData={dataTableData}
        hasData={tableData.length !== 0}
        isLoading={isInitialLogsFetch || (isFetchingActivityLogs && !isLiveData)}
        emptyStateComponent={renderEmptyState ? renderEmptyState(error) : defaultEmptyState}
      />
    </>
  );
}

ActivityLogs.propTypes = {
  pageSize: PropTypes.number,
  generalSearch: PropTypes.string,
  title: PropTypes.node,
  renderEmptyState: PropTypes.func,
  organizationId: PropTypes.string,
  showAutoRefreshToggle: PropTypes.bool,
};

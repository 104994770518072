import React, { useState } from 'react';

import { NewButton as Button, Icon, Flex, NewTooltip, tokens } from '@unitoio/mosaic';

export const ExtraButtons = ({ isDocked, isSeeThrough, setIsDocked, setIsSeeThrough }) => {
  const [powerMode, setPowerMode] = useState(false);
  const powerModeProps = powerMode
    ? {
        transform: 'grow-14',
        spin: true,
        color: tokens.colors.content.branding.unito,
      }
    : {};

  return (
    <Flex>
      <NewTooltip title="Secret power mode - increases the power of the toolbox">
        <Button type="text" onClick={() => setPowerMode(!powerMode)} />
      </NewTooltip>
      {isDocked && (
        <Button type="text" onClick={() => setIsSeeThrough(!isSeeThrough)}>
          <NewTooltip title="See through the toolbox on hover out">
            <Icon {...powerModeProps} name="eye" kind={isSeeThrough ? Icon.KINDS.SOLID : Icon.KINDS.REGULAR} />
          </NewTooltip>
        </Button>
      )}
      <Button type="text" onClick={() => setIsDocked(!isDocked)}>
        <NewTooltip title="Dock/undock the toolbox">
          <Icon {...powerModeProps} name="lock" kind={isDocked ? Icon.KINDS.SOLID : Icon.KINDS.REGULAR} />
        </NewTooltip>
      </Button>
    </Flex>
  );
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import { Select } from '@unitoio/mosaic';

import * as containerActions from '~/actions/containers';
import { getWorkspaces } from '~/reducers';

function useFetchWorkspaces(providerIdentityId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    async function fetchWorkspaces() {
      setIsLoading(true);
      await dispatch(containerActions.getWorkspaces(providerIdentityId));
      setIsLoading(false);
    }
    fetchWorkspaces();
  }, [dispatch, providerIdentityId]);
  return isLoading;
}

const StyledSelect = styled(Select)`
  width: max-content;
`;

function getOptions(workspaces, workspaceIdsToDisable = List()) {
  return workspaces
    .map((workspace) => {
      const depth = workspace.get('depth');
      const isTopLevelWithChildren = depth === 1 && workspace.has('children');
      return {
        isTitle: isTopLevelWithChildren,
        workspace,
        label: workspace.get('displayName'),
        value: workspace.get('id'),
        depth,
        disabled: isTopLevelWithChildren || workspaceIdsToDisable.includes(workspace.get('id')),
      };
    })
    .toArray();
}

export function WorkspacesSelectRevamp({
  control,
  name,
  providerIdentityId,
  workspaceIdsToDisable,
  workspaceTerm,
  providerDisplayName,
}) {
  const isLoading = useFetchWorkspaces(providerIdentityId);
  const workspaces = useSelector((state) => getWorkspaces(state, { providerIdentityId }));
  const options = getOptions(workspaces, workspaceIdsToDisable);

  return (
    <Controller
      render={({ field: { value, onChange } }) => (
        <StyledSelect
          value={value}
          isLoading={isLoading}
          onChange={(val) => {
            onChange(val);
          }}
          options={options}
          placeholder={`Select a ${providerDisplayName} ${workspaceTerm}`}
        />
      )}
      control={control}
      name={name}
    />
  );
}

WorkspacesSelectRevamp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  providerIdentityId: PropTypes.string,
  workspaceIdsToDisable: PropTypes.instanceOf(List),
  workspaceTerm: PropTypes.string.isRequired,
  providerDisplayName: PropTypes.string.isRequired,
};

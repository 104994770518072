import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Result, NewButton } from '@unitoio/mosaic';

import * as authActions from '~/actions/auth';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as trackingTypes from '~/consts/tracking';
import { history } from '~/utils/history';

const defaultOnContactSupport = () => {
  // If user is blocking, send an email to support instead
  if (!window.HubSpotConversations) {
    const message = 'Hey Unito! It looks like I was removed from my workspace and I need help to know what to do next.';
    window.open(
      `mailto:help@unito.io?subject=Need support: I am no longer a member of a Unito workspace!&body=${message}`,
      '_blank',
    );
    return;
  }
  // Note: hubspot has limitations on its chat widget and doesn't offer an easy way to pre-populate the chat bubble
  // with a message, as we do for the email above. For now, we're just opening the chat bubble.
  window.HubSpotConversations.widget.open();
};

export const NoWorkspacesPage = ({ onContactSupport = defaultOnContactSupport }) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(trackingTypes.START);
  }, [trackEvent]);

  const handleOnContactSupport = () => {
    trackEvent(trackingTypes.ACTION, { action_name: 'clicked on contact support' });
    onContactSupport();
  };

  const handleOnLogout = async () => {
    trackEvent(trackingTypes.ACTION, { action_name: 'clicked on log out' });
    await dispatch(authActions.logoutUser());
    history.push('/login');
  };

  return (
    <Result
      status="404"
      title="You don't seem to be part of a Unito workspace!"
      subTitle="If this is unexpected or you have logged into the incorrect account, log out below or contact our team for support."
      extra={[
        <NewButton onClick={handleOnContactSupport} type="primary">
          Contact support
        </NewButton>,
        <NewButton onClick={handleOnLogout} type="default">
          Log out
        </NewButton>,
      ]}
    />
  );
};

// exposing callback as prop to test it more easily
NoWorkspacesPage.propTypes = {
  onContactSupport: PropTypes.func,
};

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import * as featureTypes from '~/consts/features';
import { getOrganizationPlanProfile } from '~/reducers';

export const FeatureLimit = ({ featureName }) => {
  const feature = useSelector((state) => getOrganizationPlanProfile(state)).getIn(['features', featureName]);
  const featureLimit = String(feature.get('limit')).toLowerCase();
  const featureDisplayName = feature.get('displayName');

  return (
    <span>
      {featureLimit} {featureDisplayName}
    </span>
  );
};

FeatureLimit.propTypes = {
  featureName: PropTypes.oneOf(Object.values(featureTypes.FEATURES)).isRequired,
};

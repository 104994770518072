import React from 'react';
import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Box, tokens } from '@unitoio/mosaic';
import * as appcuesTypes from '~/consts/appcues';
import * as featureTypes from '~/consts/features';
import { getFeatureFlagValue } from '~/reducers';

import { GuideStepTips } from '../../../components/GuideStepTips';
import { LegacySyncStatus } from '../../../components/LegacySyncStatus';
import { FlowHealth } from './FlowHealth';
import { Resources } from './Resources';

import { useIsFlowDraft } from '../../../hooks/useIsFlowDraft';
import {
  useGetLastCompletedFlowStep,
  FLOW_STEP_TOOLS,
  FLOW_STEP_MAPPING,
} from '../../../hooks/useGetLastCompletedFlowStep';

const topResources = [
  {
    iconName: 'book',
    to: 'https://guide.unito.io/',
    text: 'Welcome to Unito!',
  },
  {
    iconName: 'camera-movie',
    appcuesId: appcuesTypes.CTA_IDS.FLOW_BUILDER_GUIDE,
    text: 'How to build a flow',
  },
];

const trailingResources = [
  {
    iconName: 'book',
    to: 'https://guide.unito.io/3-ways-to-test-your-flows-without-losing-your-work',
    text: 'How to build a test flow',
  },
  {
    iconName: 'book',
    to: 'https://guide.unito.io/the-differences-between-creating-and-editing-a-flow',
    text: 'The differences between creating and editing a flow',
  },
  {
    iconName: 'link',
    to: 'https://unito.io/connectors/',
    text: 'Supported fields by integration',
  },
];

const getVideoTutorialResource = (isDraftFlow, lastCompletedStep) => {
  if (!lastCompletedStep) {
    return {
      iconName: 'camera-movie',
      appcuesId: appcuesTypes.CTA_IDS.FLOW_BUILDER_GUIDE_PREPPING_FLOW,
      text: 'Prepping your workflow',
    };
  }

  if (!isDraftFlow) {
    return {
      iconName: 'camera-movie',
      appcuesId: appcuesTypes.CTA_IDS.FLOW_BUILDER_GUIDE_LAUNCH_EDIT_DUPLICATE_FLOW,
      text: 'Launching, editing and duplicating workflows',
    };
  }

  if (lastCompletedStep === FLOW_STEP_TOOLS) {
    return {
      iconName: 'camera-movie',
      appcuesId: appcuesTypes.CTA_IDS.FLOW_BUILDER_RULES_AND_MAPPINGS,
      text: 'Rules and mappings',
    };
  }
  if (lastCompletedStep === FLOW_STEP_MAPPING) {
    return {
      iconName: 'camera-movie',
      appcuesId: appcuesTypes.CTA_IDS.FLOW_BUILDER_GUIDE_VERIFY_WORKFLOWS,
      text: 'Verifying your workflows',
    };
  }

  return {
    iconName: 'camera-movie',
    appcuesId: appcuesTypes.CTA_IDS.FLOW_BUILDER_RULES_AND_MAPPINGS,
    text: 'Rules and mappings',
  };
};

export const Sidebar = () => {
  const isDraftFlow = useIsFlowDraft();
  const { linkId } = useParams();
  const [providerNameA, providerNameB] = useWatch({ name: ['A.providerName', 'B.providerName'] });
  const lastCompletedStep = useGetLastCompletedFlowStep(linkId);
  const guideVideoTutorial = getVideoTutorialResource(isDraftFlow, lastCompletedStep);
  // build resources here since video tutorial is dynamic in the guide
  const resources = [...topResources, guideVideoTutorial, ...trailingResources];
  const isSyncStatusRevampEnabled = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.SYNC_STATUS_REVAMP),
  );

  return (
    <>
      {isDraftFlow ? (
        <GuideStepTips linkId={linkId} />
      ) : (
        <Box m={[tokens.spacing.s8, 0, 0, 0]}>{isSyncStatusRevampEnabled ? <FlowHealth /> : <LegacySyncStatus />}</Box>
      )}
      <Box m={[tokens.spacing.s6, 0, 0]}>
        <Resources items={resources} providerNameA={providerNameA} providerNameB={providerNameB} />
      </Box>
    </>
  );
};

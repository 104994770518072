import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { notification } from '@unitoio/mosaic';
import * as notificationActions from '~/actions/notifications';

export function useFlowNotifications() {
  const { linkId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchNotifications() {
      try {
        const { subscribed } = await dispatch(notificationActions.getSubscription(linkId));
        setNotifications(subscribed);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    fetchNotifications();
  }, [linkId, setNotifications, dispatch]);

  const subscribe = useCallback(async () => {
    dispatch(notificationActions.subscribe(linkId))
      .then(() => setNotifications(true))
      .catch(() => {
        setNotifications(false);
        notification.error({
          message: 'Failed to subscribe to notifications. Please try again and contact support if the error persists.',
        });
      });
  }, [linkId, setNotifications, dispatch]);

  const unsubscribe = useCallback(async () => {
    dispatch(notificationActions.unsubscribe(linkId))
      .then(() => setNotifications(false))
      .catch(() => {
        setNotifications(true);
        notification.error({
          message:
            'Failed to unsubscribe from notifications. Please try again and contact support if the error persists.',
        });
      });
  }, [linkId, setNotifications, dispatch]);

  return { loading, error, notifications, unsubscribe, subscribe };
}

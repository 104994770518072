import React from 'react';
import { useParams } from 'react-router-dom';

import { Box, tokens } from '@unitoio/mosaic';
import { ErrorBoundary } from '@unitoio/sherlock';

import { useGetOrganizationActivities } from '~/hooks/useGetOrganizationActivities';
import { useGetOrgMetrics } from '~/hooks/useGetOrgMetrics';
import { useLogger } from '~/hooks/useLogger';

import { useGetOrganizationUsage } from '~/hooks/useGetOrganizationUsage';
import { useFetchBillingOrganization } from '../../hooks/useFetchBillingOrganization';
import { PlanBlock } from './PlanBlock';
import { UsageBlock } from './UsageBlock';
import { CancelOrPauseBlock } from './CancelOrPauseBlock';
import { DetailedPlanBlock } from './DetailedPlanBlock';
import { BillingOverviewErrorState } from './BillingOverviewErrorState';

const BillingOverviewRevamp = () => {
  const { organizationId } = useParams();
  const { isLoading: isLoadingBillingOrganization, hasFailed: hasFetchBillingOrganizationFailed } =
    useFetchBillingOrganization(organizationId);
  const { isActivitiesLoading: isActivityLoading, hasFetchActivitiesFailed: hasFetchActivityFailed } =
    useGetOrganizationActivities(organizationId);
  const { isLoading: isLoadingMetrics, hasFailed: hasFetchMetricsFailed } = useGetOrgMetrics(organizationId);
  const { isUsageLoading, usageError } = useGetOrganizationUsage(organizationId);

  if (hasFetchBillingOrganizationFailed || hasFetchActivityFailed || hasFetchMetricsFailed || usageError) {
    return <BillingOverviewErrorState />;
  }

  return (
    <Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <PlanBlock isLoadingBillingOrganization={isLoadingBillingOrganization || isUsageLoading} />
        <UsageBlock isLoading={isActivityLoading || isLoadingMetrics || isUsageLoading} />
      </Box>
      <Box m={[0, 0, tokens.spacing.s7, 0]}>
        <DetailedPlanBlock />
      </Box>
      <Box m={[0, 0, tokens.spacing.s4, 0]}>
        <CancelOrPauseBlock />
      </Box>
    </Box>
  );
};

const BillingOverviewWithErrorBoundary = (props) => {
  const { reportException } = useLogger();
  return (
    <ErrorBoundary
      FallbackComponent={BillingOverviewErrorState}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, { ...errMessageContext, componentStack })
      }
    >
      <BillingOverviewRevamp {...props} />
    </ErrorBoundary>
  );
};

export { BillingOverviewWithErrorBoundary as BillingOverview };

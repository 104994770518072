import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { List, Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';

import { FlowBuilderErrorContext } from '~/contexts';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import { useGetItemTypes } from '~/containers/FlowBuilder/hooks/useGetItemTypes';
import { AuthWindowOpener } from '~/containers/authentication/AuthWindowOpener';
import { getUserProviderIdentities } from '~/reducers';
import * as providerIdentityActions from '~/actions/providerIdentity';
import * as trackingTypes from '~/consts/tracking';
import * as linkTypes from '~/consts/link';
import { SelectField } from '~/components/SelectField/SelectField';

import { useGetContainerTypes } from '~/containers/FlowBuilder/hooks/useGetContainerTypes';
import { Placeholder } from './Placeholder';
import { SelectorLabel, WORK_ITEM_SELECTOR_VARIANT } from './SelectorLabel';
import { SelectedProviderIdentityError } from './SelectedProviderIdentityError';

const sortProviderIdentities = (providerIdentityA, providerIdentityB) =>
  providerIdentityA
    .get('providerName')
    .localeCompare(providerIdentityB.get('providerName'), 'en', { sensitivity: 'base' }) ||
  providerIdentityA
    .get('profileDisplayName')
    .localeCompare(providerIdentityB.get('profileDisplayName'), 'en', { sensitivity: 'base' });

function useFetchAllowedProviderIdentityIdsForContainer({
  providerIdentityId,
  containerId,
  containerType,
  itemType,
  isDraft,
  isWorkflowContext,
}) {
  const dispatch = useDispatch();
  const [allowedProviderIdentityIds, setAllowedProviderIdentityIds] = useState(List());

  useEffect(() => {
    async function fetchAllowedProviderIdentities() {
      if (!containerId || !providerIdentityId) {
        return;
      }

      const { providerIdentityIds } = await dispatch(
        providerIdentityActions.fetchAllowedProviderIdentities(
          providerIdentityId,
          containerId,
          containerType,
          itemType,
        ),
      );
      setAllowedProviderIdentityIds(List(providerIdentityIds));
    }

    if (!isDraft || isWorkflowContext) {
      fetchAllowedProviderIdentities();
    }
  }, [containerId, containerType, itemType, dispatch, isDraft, providerIdentityId, isWorkflowContext]);

  return allowedProviderIdentityIds;
}

/**
 * Component to select an identity for a provider.
 *
 * @param {bool} isDraft - Force the link to be a draft (or not). If omitted, the link is a draft (or not) based on its current state.
 */
export function ProviderIdentitiesSelect({
  readOnly = false,
  side,
  onChange = () => null,
  provider,
  isDraft: overrideDraft = null,
  label = 'with the account',
  workItemSelectorVariant = WORK_ITEM_SELECTOR_VARIANT.NATURAL_LANGUAGE,
}) {
  const [createLoading, setCreateLoading] = useState(false);
  const {
    formState: { errors },
    clearErrors,
    watch,
  } = useFormContext();
  const selectedProviderName = watch(`${side}.providerName`);
  const selectedProviderIdentityId = watch(`${side}.providerIdentityId`);
  const selectedContainerId = watch(`${side}.containerId`);
  const {
    params: { linkId, workflowId },
  } = useRouteMatch();
  const linkState = useWatch({ name: 'state' });
  const [containerTypeA, containerTypeB] = useGetContainerTypes();
  const [itemTypeA, itemTypeB] = useGetItemTypes();
  const containerType = side === 'A' ? containerTypeA : containerTypeB;
  const itemType = side === 'A' ? itemTypeA : itemTypeB;
  const isDraft = overrideDraft ?? linkState === linkTypes.LINK_STATES.DRAFT;
  const pageName = useContext(FlowBuilderErrorContext);

  // the fetch below is only called for edit mode of a real link, so we can safely rely on the container types from
  // the link
  const allowedProviderIdentityIds = useFetchAllowedProviderIdentityIdsForContainer({
    providerIdentityId: selectedProviderIdentityId,
    containerId: selectedContainerId,
    containerType,
    itemType,
    isDraft,
    isWorkflowContext: !!workflowId,
  });

  const trackEvent = useTrackEvent();

  const userProviderIdentities = useSelector((state) => getUserProviderIdentities(state, false)); // false because we don't want providers that are "onlyAuthenticate" (githubappuser)
  const providerIdentities = userProviderIdentities.filter((pi) => pi.get('providerName') === selectedProviderName);
  const providerIdentity = userProviderIdentities.get(selectedProviderIdentityId);

  function handleOnAddNewPI(addedProviderIdentity) {
    onChange(addedProviderIdentity._id);
    setCreateLoading(false);
    clearErrors(`${pageName}.${side}.providerIdentityId`);
  }

  function getOptions() {
    return providerIdentities
      .sort(sortProviderIdentities)
      .map((entity) => {
        const hasNoAccess =
          readOnly || ((!isDraft || !!workflowId) && !allowedProviderIdentityIds.includes(entity.get('_id')));
        return {
          disabled: hasNoAccess,
          value: entity.get('_id'),
          label: entity.get('profileDisplayName'),
          icon: {
            type: 'avatar',
            name: entity.get('profileDisplayName'),
            image: entity.getIn(['profileAvatars', 0]),
          },
        };
      })
      .toArray();
  }

  function handleConnectNewAccount(openProviderAuthPage) {
    trackEvent(trackingTypes.ACTION, {
      action_name: 'clicked add a new account',
      selected_tool_name: selectedProviderName,
      connected_account_qty: providerIdentities.size,
      connected_account: providerIdentities.isEmpty() ? 'new' : 'existing',
    });
    openProviderAuthPage();
  }

  const providerIdentityError = errors[pageName]?.[side]?.providerIdentityId;

  return (
    <AuthWindowOpener
      providerId={provider.get('_id')}
      onSuccess={handleOnAddNewPI} // eslint-disable-line react/jsx-no-bind
      onValidationStart={() => setCreateLoading(true)}
      linkId={linkId}
    >
      {(openProviderAuthPage) => (
        <>
          <SelectorLabel workItemSelectorVariant={workItemSelectorVariant} withMargin label={label}>
            <SelectField
              name={`${side}.providerIdentityId`}
              placeholder={Placeholder({ text: 'Choose account' })}
              onChange={onChange}
              value={selectedProviderIdentityId}
              onCreate={() => handleConnectNewAccount(openProviderAuthPage)}
              createText={`Connect a new ${provider.get('displayName')} account`}
              createLoading={createLoading}
              readOnly={readOnly}
              options={getOptions()}
              isError={!!providerIdentityError}
            />
          </SelectorLabel>
          {!!selectedProviderIdentityId && !!providerIdentityError && (
            <SelectedProviderIdentityError
              onReconnect={openProviderAuthPage}
              containerType={containerType}
              providerIdentity={providerIdentity}
              providerIdentityError={providerIdentityError}
            />
          )}
        </>
      )}
    </AuthWindowOpener>
  );
}

ProviderIdentitiesSelect.propTypes = {
  readOnly: PropTypes.bool,
  side: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  provider: PropTypes.instanceOf(Map).isRequired,
  isDraft: PropTypes.bool,
  workItemSelectorVariant: PropTypes.oneOf(Object.values(WORK_ITEM_SELECTOR_VARIANT)),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

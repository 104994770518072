import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Map, List } from 'immutable';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { tokens, Button } from '@unitoio/mosaic';

import * as trackingTypes from '~/consts/tracking';
import * as featureTypes from '~/consts/features';
import * as authUtils from '~/utils/auth';
import { ProviderItem } from '~/components/ProviderItem/ProviderItem';
import { ProviderIdentityList } from '~/components/ProviderIdentityList/ProviderIdentityList';
import { AuthWindowOpener } from '~/containers/authentication/AuthWindowOpener';
import { getFeatureFlagValue } from '~/reducers';

import { AddProviderModal } from './Modal';

const Providers = styled.ul`
  list-style: none;
  padding: 0;
`;

const Provider = styled.li`
  display: grid;
  grid-template-columns: auto auto;
  border: 1px solid ${tokens.colors.content.neutral.n10};
  margin-bottom: ${tokens.spacing.s4};
  border-radius: ${tokens.spacing.s2};
  padding: ${tokens.spacing.s3} ${tokens.spacing.s5};
`;

const Identities = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonMargin = styled.div`
  margin-left: ${tokens.spacing.s5};
  display: inline-flex;
`;

export const ProviderList = ({ providerList, onDisconnectProviderIdentity, providerIdentityList, trackEvent }) => {
  const [selectedProviderState, setSelectedProviderState] = useState({
    selectedProvider: Map(),
    selectedProviderIdentity: Map(),
    isModalOpen: false,
  });

  const { selectedProvider, selectedProviderIdentity, isModalOpen } = selectedProviderState;

  const trackOnAddConnector = (connectorName) =>
    trackEvent(trackingTypes.CONNECTORS_EVENTS.ACTION_NAME, {
      action_name: trackingTypes.CONNECTORS_EVENTS.ACTIONS.ADD_CONNECTOR,
      selected_tool_name: connectorName,
    });

  const trackOnRemoveConnector = (connectorName) =>
    trackEvent(trackingTypes.CONNECTORS_EVENTS.ACTION_NAME, {
      action_name: trackingTypes.CONNECTORS_EVENTS.ACTIONS.REMOVE_CONNECTOR,
      selected_tool_name: connectorName,
    });

  const disconnectConnector = async (providerIdentityId) => {
    setSelectedProviderState((prevState) => ({
      ...prevState,
      isModalOpen: false,
    }));
    trackOnRemoveConnector(selectedProvider.get('name'));
    await onDisconnectProviderIdentity(providerIdentityId);
  };

  const connectConnector = (openPopupWindow) => {
    openPopupWindow();
    trackOnAddConnector(selectedProvider.get('name'));
  };

  const closeModal = () => {
    setSelectedProviderState({
      selectedProviderIdentity: Map(),
      selectedProvider: Map(),
      isModalOpen: false,
    });
  };

  const showModal = (providerIdentity) => {
    setSelectedProviderState({
      selectedProviderIdentity: providerIdentity,
      selectedProvider: providerList.find((p) => p.get('name') === providerIdentity.get('providerName')),
      isModalOpen: true,
    });
  };

  const getProviderIdentities = (providerName) =>
    providerIdentityList.filter(
      (providerIdentity) => providerIdentity.get('providerName').split('_old')[0] === providerName,
    );

  const providerNamesToHide = useSelector((state) =>
    getFeatureFlagValue(state, featureTypes.FEATURES.HIDE_CONNECTOR_BY_PROVIDER_NAME),
  );

  const hiddenProviderNames = providerNamesToHide?.get('hiddenConnectors')?.toArray() ?? [];

  return (
    <>
      <Providers data-testid="provider-list">
        {providerList
          .valueSeq()
          .filter((provider) => !hiddenProviderNames.includes(provider.get('name')))
          .map((provider) => {
            const authorizationMethods = authUtils.getProviderAuthorizationMethods(provider);
            const isDeprecatedProvider = authorizationMethods.every((authMethod) =>
              authMethod.get('supportedAuthActions', List()).isEmpty(),
            );
            return (
              <Provider key={provider.get('_id')}>
                <ProviderItem provider={provider} bold />
                <Identities>
                  <ProviderIdentityList
                    providerIdentityList={getProviderIdentities(provider.get('name'))}
                    onClickProviderIdentity={showModal}
                  />
                  {!isDeprecatedProvider && (
                    <ButtonMargin>
                      <AuthWindowOpener providerId={provider.get('_id')}>
                        {(openPopupWindow, isLoading) => (
                          <Button
                            data-testid={`${provider.get('name')}-connect-button`}
                            size="xs"
                            variant="outlined"
                            disabled={isLoading}
                            onClick={() => connectConnector(openPopupWindow)}
                          >
                            Connect account {isLoading && '...'}
                          </Button>
                        )}
                      </AuthWindowOpener>
                    </ButtonMargin>
                  )}
                </Identities>
              </Provider>
            );
          })
          .toArray()}
      </Providers>
      <AddProviderModal
        closeModal={closeModal}
        disconnect={disconnectConnector}
        isOpen={isModalOpen}
        provider={selectedProvider}
        providerIdentity={selectedProviderIdentity}
      />
    </>
  );
};

ProviderList.propTypes = {
  providerList: PropTypes.instanceOf(Map).isRequired,
  onDisconnectProviderIdentity: PropTypes.func.isRequired,
  providerIdentityList: PropTypes.instanceOf(Map).isRequired,
  trackEvent: PropTypes.func.isRequired,
};
